import React, { useEffect, useState, useRef } from "react";
import { getFormateName, getUserId } from "../../../utils/commonFunctions";
import moment from "moment";
import axios from "axios";
import { token } from "../../../utils/authentication";
import MessageSkeleton from "./MessageSkeleton";
import MessageCard from "./MessageCard";
import { notification } from "antd";
import { Colors } from "../../../constants/Colors";
import { useSearchParams } from "react-router-dom";
import { useSocket } from "../../../hooks/useSocket";

const RightSide = ({
  latestMessage,
  members,
  messages,
  setMessages,
  previousMessages,
  setPreviousMessages,
  socketData,
  setSocketData,
  page = 1,
  setPage,
}) => {
  const [limit, setLimit] = useState(10);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);
  const { senderDetails, senderId } = latestMessage || {};
  const member = members[0];
  const userId = getUserId();
  const [newMessage, setNewMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [loader, setLoader] = useState(false); // [loader , setLoader] = useState(false) is added  to manage the loader
  const [sendMessageLoader, setSendMessageLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get("conversationId");
  const { notifications, sendMessage, sendNotification, getMessages } =
    useSocket();

  useEffect(() => {
    if (getMessages) {
      setSocketData(getMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMessages]);

  // get messages by conversation id
  useEffect(() => {
    if (conversationId) {
      setPreviousMessages(false);
      setLoader(true);

      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/messages?conversationId=${conversationId}&limit=${limit}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          const descendingMessages = res.data.messages.reverse();
          const uniqueMessages = descendingMessages.filter(
            (msg) => !messages.some((existing) => existing._id === msg._id)
          );
          const modifiedMessages = uniqueMessages.map((message) => {
            return {
              myMessage: userId === message.senderDetails?.senderId,
              senderName: message.senderDetails?.name,
              message: message.text,
              _id: message?._id,
              time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
            };
          });
          // const modifiedMessages = descendingMessages.map((message) => {
          //   return {
          //     myMessage: userId === message.senderDetails?.senderId,
          //     senderName: message.senderDetails?.name,
          //     message: message.text,
          //     _id: message?._id,
          //     time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
          //   };
          // });
          setPreviousMessages(res.data.next);
          // setMessages([...modifiedMessages, ...messages]);
          setMessages((prev) => [...modifiedMessages, ...prev]);
          if (page > 1) {
            setScrollToTop(true);
            setScrollToBottom(false);
          } else {
            setScrollToTop(false);
            setScrollToBottom(true);
          }
        });
    }
  }, [conversationId, limit, page, userId]);

  useEffect(() => {
    if (socketData && socketData?.conversationId === conversationId) {
      // console.log("Socket data", socketData);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          myMessage: socketData.senderId === getUserId(),
          senderName: socketData.senderDetails?.name,
          message: socketData.text,
          _id: socketData?._id, // Ensure you use unique IDs here
          time: moment(socketData.dateTime).format("DD MMM YY @ h:mm A"),
        },
      ]);
      // setMessages([
      //   ...messages,
      //   {
      //     myMessage: socketData.senderId === getUserId(),
      //     senderName: socketData.senderDetails?.name,
      //     message: socketData.text,
      //     time: moment(socketData.dateTime).format("DD MMM YY @ h:mm A"),
      //   },
      // ]);
      setScrollToBottom(true);
    }
  }, [conversationId, socketData]);

  const sendMessage1 = (e) => {
    e.preventDefault();
    if (!newMessage || !newMessage?.trim()) {
      notification.error({
        message: "Message cannot be empty",
      });
      return;
    }

    // console.log("newMessage", newMessage);
    setSendMessageLoader(true);
    const payload = {
      senderId: userId,
      conversationId: conversationId,
      dateTime: new Date(),
      text: newMessage,
    };
    sendMessage(payload);
    setSendMessageLoader(false);
    setNewMessage("");
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/messages/create`, payload, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        setSendMessageLoader(false);
      });
  };

  // console.log("messages~~", messages);

  const profilePicture =
    member?.profilePicture && member?.profilePicture != "undefined"
      ? member?.profilePicture.includes("lh3.googleusercontent.com")
        ? member?.profilePicture
        : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${member?.profilePicture}`
      : member?.role === "CLIENT"
      ? "/assets/Client.jpg"
      : "/assets/Employee.jpg";

  // Avoid rendering if latestMessage is missing required details
  // if (!latestMessage || !conversationId || !senderId || !senderDetails) {
  //   return null;
  // }

  return (
    <>
      {/* header */}
      <div className="header py-2">
        <div className="img-text">
          <div className="user-img">
            <img className="dp" src={profilePicture} alt="" />
          </div>
          <h4>
            {member?.name || member?.restaurantName}
            <br />
            <span>
              <i
                className="fa-solid fa-circle"
                style={{ color: "#07e743" }}
              ></i>{" "}
              Online
            </span>
          </h4>
        </div>
        <div className="nav-icons">
          <li>
            <i className="fa-solid fa-magnifying-glass"></i>
          </li>
          <li>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </li>
        </div>
      </div>

      {/* Chat Container */}
      <div ref={chatContainerRef} className="chat-container">
        {previousMessages && (
          <div
            style={{
              textAlign: "center",
              color: Colors.primary,
              cursor: previousMessages ? "pointer" : "not-allowed",
              marginBottom: "10px",
              backgroundColor: "lightgray",
            }}
            onClick={() => {
              if (previousMessages) {
                setLimit(limit);
                setPage(page + 1);
              }
            }}
          >
            Load More{" "}
          </div>
        )}
        {loader ? (
          <MessageSkeleton />
        ) : (
          messages?.map((message, index) => {
            return <MessageCard message={message} key={index} />;
          })
        )}
      </div>

      {/* Input Bottom */}
      <form onSubmit={sendMessage1} className="chatbox-input">
        {/* <i className="fa-regular fa-face-grin me-2"></i>
        <i className="fa-sharp fa-solid fa-paperclip"></i> */}
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
        />
        <button type="submit" className="btn btn-link">
          <i class="fa-solid fa-paper-plane"></i>
        </button>
      </form>
    </>
  );
};

export default RightSide;

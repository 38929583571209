import React from "react";
import "./customModal.scss";

const CustomModal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* <div className="modal-header">
          {title && <h2>{title}</h2>}

          <button className="close-btn" onClick={onClose}>
            ×
          </button>
        </div> */}
        <div className="modal-body overflow-scroll">{children}</div>

        <div>
          <button>Ok</button> <button>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;

import { Button, Col, Row, TimePicker, notification } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FcCalendar } from "react-icons/fc";
import nodate from "./nodate.png";

const MHCalender = ({ dates, setDates, onSubmitHandler }) => {
    const [seletedMonth, setSeletedMonth] = useState(moment().month());
    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const calendarDays = [];
    const daysInMonth = moment().month(seletedMonth).daysInMonth();
    const fistDayName = moment().date(1).month(seletedMonth).format("ddd");
    let dayIndex = dayNames.indexOf(fistDayName);
    while (dayIndex > 0) {
        calendarDays.push(
            <div key={`empty-${dayIndex}`} className="day empty"></div>
        );
        dayIndex--;
    }
    const checkDate = (day, seletedMonth) => {
        const newday = moment().date(day).month(seletedMonth).format("YYYY-MM-DD");
        const allStartDay = dates.map((date) => date.startDate);
        const allEndDay = dates.map((date) => date.endDate);
        const startAndEndSameDay = dates
            .filter((date) => date.startDate === date.endDate)
            .map((date) => date.startDate);
        const innerDay = [];
        dates.forEach((date) => {
            if (date.startDate !== date.endDate && date.startDate && date.endDate) {
                const start = moment(date.startDate);
                const end = moment(date.endDate);
                while (start <= end) {
                    innerDay.push(start.format("YYYY-MM-DD"));
                    start.add(1, "days");
                }
            }
        });

        if (moment(newday, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD"))) {
            return "past-date";
        } else if (startAndEndSameDay.includes(newday)) {
            return "jobStartAndEnd";
        } else if (allStartDay.includes(newday)) {
            return "job-start";
        } else if (allEndDay.includes(newday)) {
            return "job-end";
        } else if (innerDay.includes(newday)) {
            return "job-range";
        }
    };
    for (let day = 1; day <= daysInMonth; day++) {
        calendarDays.push(
            <div>
                <div
                    key={day}
                    className={`calendars-day ${checkDate(day, seletedMonth)}`}
                    onClick={() => handleClick(day, seletedMonth)}
                >
                    {day}
                </div>
            </div>
        );
    }

    const checkOverLap = (startDate, endDate, newDate) => {
        const s = moment(startDate, "YYYY-MM-DD");
        const e = moment(endDate, "YYYY-MM-DD");
        const start = s.isBefore(e) ? s : e;
        let end = s.isBefore(e) ? e : s;
        let isOverLap = false;
        while (start <= end) {
            for (let i = 0; i < newDate.length - 1; i++) {
                if (
                    moment(start).isBetween(
                        moment(newDate[i].startDate),
                        moment(newDate[i].endDate),
                        undefined,
                        "[]"
                    )
                ) {
                    isOverLap = true;
                    break;
                }
            }
            start.add(1, "days");
        }
        return isOverLap;
    };

    const handleClick = (day, seletedMonth) => {
        const date = moment().date(day).month(seletedMonth).format("YYYY-MM-DD");
        const newDates = [...dates];
        const isExist = dataExistOrNot(newDates, date);
        if (isExist) {
            newDates.splice(isExist - 1, 1);
            setDates(newDates);
            return;
        }
        if (newDates.length === 0) {
            const newObj = {
                startDate: date,
                endDate: undefined,
                startTime: undefined,
                endTime: undefined,
            };
            newDates.push(newObj);
        } else if (
            newDates[newDates.length - 1].startDate &&
            newDates[newDates.length - 1].endDate
        ) {
            const newObj = {
                startDate: date,
                endDate: undefined,
                startTime: undefined,
                endTime: undefined,
            };
            newDates.push(newObj);
        } else if (
            newDates[newDates.length - 1].startDate &&
            !newDates[newDates.length - 1].endDate
        ) {
            if (
                checkOverLap(newDates[newDates.length - 1].startDate, date, newDates)
            ) {
                notification["error"]({
                    message: "Overlap with other Selected Date",
                });
                return;
            } else if (
                moment(date).isBefore(newDates[newDates.length - 1].startDate)
            ) {
                newDates[newDates.length - 1].endDate =
                    newDates[newDates.length - 1].startDate;
                newDates[newDates.length - 1].startDate = date;
            } else {
                newDates[newDates.length - 1].endDate = date;
            }
        }
        setDates(newDates);
    };

    const dataExistOrNot = (newDates, date) => {
        let exist = 0;
        if (newDates.length === 0) return exist;
        else if (
            newDates[newDates.length - 1].startDate &&
            !newDates[newDates.length - 1].endDate
        )
            return exist;
        newDates.forEach((d, i) => {
            if (
                moment(d.startDate).format("YYYY-MM-DD") === date ||
                moment(d.endDate).format("YYYY-MM-DD") === date
            ) {
                exist = i + 1;
                return;
            } else {
                if (d.startDate !== d.endDate) {
                    const start = moment(d.startDate);
                    const end = moment(d.endDate);
                    while (start <= end) {
                        if (start.format("YYYY-MM-DD") === date) {
                            exist = i + 1;
                        }
                        start.add(1, "days");
                    }
                }
            }
        });
        return exist;
    };

    return (
        <>
            <Row>
                <Col sm={24} md={24} lg={24} xl={24}>
                    <div className="d-flex justify-content-between align-items-center pe-3 pb-1">
                        <Row style={{ width: "100%" }}>
                            <Col sm={24} md={24} lg={18} xl={18}>
                                <div className="d-flex justify-content-center align-items-center">
                                    <button
                                        className="border-0 mr-1"
                                        style={{
                                            backgroundColor: "transparent",
                                            color: "#58c8c8",
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                            if (seletedMonth === moment().month()) return;
                                            setSeletedMonth(seletedMonth - 1);
                                        }}
                                    >
                                        <i
                                            className="fa fa-chevron-left border-0"
                                            color="black"
                                        ></i>
                                    </button>
                                    <div
                                        className="calendars-header-nav-title"
                                        style={{
                                            color: "#58c8c8",
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {moment().month(seletedMonth).format("MMMM YYYY")}
                                    </div>
                                    <button
                                        className="border-0"
                                        style={{
                                            backgroundColor: "transparent",
                                            color: "#58c8c8",
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                            if (seletedMonth === moment().month() + 2) return;
                                            setSeletedMonth(seletedMonth + 1);
                                        }}
                                    >
                                        {/* <i className="fa fa-chevron-right" color="black"></i> */}
                                        <i className="fa fa-chevron-right" color="black"></i>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            {/* crear all Button  */}
                            <Button
                                className="me-2"
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                }}
                                onClick={() => {
                                    setDates([]);
                                }}
                            >
                                Clear All
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col
                    sm={24}
                    md={24}
                    lg={16}
                    xl={16}
                    style={{
                        border: "1px solid #58c8c8",
                        borderRadius: "4px",
                        width: "100%",
                    }}
                >
                    <div className="calendars-day-name">
                        <div className="calendars-header text-center">
                            {dayNames.map((dayName, index) => (
                                <div key={index} className="day-name">
                                    {dayName}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="calendars-grid text-center">{calendarDays}</div>
                </Col>
                <Col
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    style={{
                        width: "100%",
                    }}
                    className="mt-3 mt-lg-0 ms-0 ps-lg-3"
                >
                    <div
                        style={{
                            border: "1px solid #58c8c8",
                            borderRadius: "4px",
                            maxHeight: "450px",
                            minHeight: "450px",
                            width: "100%",
                            overflowY: "auto",
                        }}
                    >
                        <div
                            style={{
                                overflowY: "auto",
                                width: "100%",
                            }}
                        >
                            {dates?.length ? (
                                <>
                                    {dates.map((date, index) => {
                                        const format = "hh:mm A";
                                        const startTime = new Date(moment(date?.startTime, format));

                                        console.log({ startTime });

                                        // Calculate disabled hours/minutes for the end time picker
                                        // const getDisabledHours = () => {
                                        //     if (!startTime) return [];
                                        //     return Array.from(
                                        //         { length: startTime.getHours() },
                                        //         (_, i) => i
                                        //     );
                                        // };

                                        const getDisabledHours = () => {
                                            // Make sure to parse startTime properly using moment
                                            if (!dates[index].startTime) return [];
                                            const startHour = moment(dates[index].startTime, format).hour();
                                            
                                            // Disable all hours before the startHour
                                            return Array.from({ length: startHour }, (_, i) => i);
                                        };

                                        const getDisabledMinutes = (selectedHour) => {
                                            if (!dates[index].startTime) return [];
                                            const startHour = moment(dates[index].startTime, format).hour();
                                            const startMinute = moment(dates[index].startTime, format).minute();
                                    
                                            if (selectedHour === startHour) {
                                                // Disable minutes before the selected startMinute only if it's the same hour
                                                return Array.from({ length: startMinute + 1 }, (_, i) => i);
                                            }
                                            return []; // No minutes disabled for other hours
                                        };

                                        // const getDisabledMinutes = (hour) => {
                                        //     if (!startTime || hour !== startTime.getHours())
                                        //         return [];
                                        //     return Array.from(
                                        //         { length: startTime.getMinutes() + 1 },
                                        //         (_, i) => i
                                        //     );
                                        // };

                                        const handleStartTimeChange = (time, timeString) => {
                                            const newDates = [...dates];
                                            newDates[index].startTime = timeString;
                                            newDates[index].endTime = null; // Reset endTime when startTime changes
                                            setDates(newDates);
                                        };
                                    
                                        return (
                                            <Col className="mt-3">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <FcCalendar
                                                        style={{
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                    {moment(date.startDate).format("DD MMM YYYY")} -{" "}
                                                    {date.endDate
                                                        ? moment(date.endDate).format("DD MMM YYYY")
                                                        : "No End Date Selected"}
                                                    <AiFillDelete
                                                        style={{
                                                            marginLeft: "5px",
                                                            cursor: "pointer",
                                                            color: "red",
                                                        }}
                                                        onClick={() => {
                                                            const newDates = [...dates];
                                                            newDates.splice(index, 1);
                                                            setDates(newDates);
                                                        }}
                                                    />
                                                </div>
                                                {date.startDate && date.endDate && (
                                                    <>
                                                        <div
                                                            className="d-flex justify-content-center align-items-center fw-bold"
                                                            style={{ fontSize: "16px", color: "#58c8c8" }}
                                                        >
                                                            {moment(date.endDate).diff(
                                                                moment(date.startDate),
                                                                "days"
                                                            ) + 1}{" "}
                                                            Days
                                                        </div>
                                                        <Row gutter={[16, 16]} className="mx-2 mt-2">
                                                            <Col sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <TimePicker
                                                                    style={{ width: "100%" }}
                                                                    value={
                                                                        date.startTime
                                                                            ? moment(date.startTime, format)
                                                                            : undefined
                                                                    }
                                                                    format={format} 
                                                                    // onChange={(time, timeString) => {
                                                                    //     const newDates = [...dates];
                                                                    //     newDates[index].startTime = timeString; 
                                                                    //     setDates(newDates);
                                                                    // }}
                                                                    onChange={handleStartTimeChange}
                                                                />
                                                            </Col>
                                                            <Col sm={12} md={12} lg={12} xl={12}
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <TimePicker
                                                                    style={{ width: "100%" }}
                                                                    defaultValue={
                                                                        date?.endTime
                                                                            ? moment(date?.endTime, format)
                                                                            : undefined
                                                                    }
                                                                    value={
                                                                        date.endTime
                                                                            ? moment(date.endTime, format)
                                                                            : undefined
                                                                    }
                                                                    format={format}
                                                                    onChange={(time, timeString) => {
                                                                        const newDates = [...dates];
                                                                        newDates[index].endTime = timeString;
                                                                        setDates(newDates);
                                                                    }}
                                                                    disabled={!date.startTime}
                                                                    disabledHours={getDisabledHours}
                                                                    disabledMinutes={getDisabledMinutes}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Col>
                                        );
                                    })}
                                </>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "445px",
                                    }}
                                >
                                    <img src={nodate} alt="No Date" />
                                </div>
                            )}
                        </div>

                        <div className="d-flex justify-content-center mt-3"></div>
                    </div>
                </Col>
                <div style={{ width: "100%", textAlign: "right" }} className="mt-3">
                    {
                        // check all date has start time and end time
                        dates.every((date) => date.startTime && date.endTime) ? (
                            <Button
                                style={{
                                    backgroundColor: "#58c8c8",
                                    color: "white",
                                }}
                                onClick={onSubmitHandler}
                            >
                                Submit
                            </Button>
                        ) : (
                            <Button
                                style={{
                                    backgroundColor: "#58c8c8",
                                    color: "white",
                                }}
                                onClick={() => {
                                    notification["error"]({
                                        message: "Please Select Start Time and End Time",
                                    });
                                }}
                            >
                                Submit
                            </Button>
                        )
                    }
                </div>
            </Row>
        </>
    );
};

export default MHCalender;

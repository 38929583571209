import React from "react";
import CookieConsent from "react-cookie-consent";

import { useTranslation } from "react-i18next";
import { GiGraduateCap } from "react-icons/gi";
import { Link } from "react-router-dom";
import Banner from "./Banner";
import Contact from "./Contact";
import DownloadApp from "./DownloadApp";
import Position from "./Position";
import Services from "./Services";
import { Helmet } from "react-helmet";

function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Find skilled temporary staff in Dubai, London, and Italy with PLAGIT. We provide flexible recruitment solutions for hospitality, retail, and events to meet your business needs."
        />
        <title>Reliable Temporary Job Recruitment Agencies | PLAGIT</title>
      </Helmet>

      <div
        className="text-center text-white "
        style={{
          backgroundColor: "#111111",
        }}
      >
        <p>
          <GiGraduateCap
            style={{
              fontSize: "20px",
              marginRight: "5px",
              color: "#ffffff",
            }}
          />
          <span className="text-white">{t("home_academy_banner")}</span>
          <Link to="/plagit-academy" className="btn btn-sm">
            <span
              style={{
                color: "#ffffff",
                textDecoration: "underline",
                fontWeight: "bold",
                // marginLeft: "5px",
              }}
            >
              {t("visit_here")}
            </span>
          </Link>
        </p>
      </div>
      <Banner />
      <Services />
      <Position />
      {/* <FindJobs /> */}
      <DownloadApp />
      {/* <Testimonial /> */}
      <Contact />

      <CookieConsent
        location="bottom"
        buttonText="Allow Cookies!!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#58c8c8" }}
        buttonStyle={{ background: "#ffffff", fontSize: "16px" }}
        expires={150}
      >
        “This website collects cookies to deliver better user experience” “We
        collect cookies to analyze our website traffic and performance; we never
        collect any personal data” “Cookies help us display personalized product
        recommendations and ensure you have great shopping experience”
        {/* <span style={{ fontSize: "14px" }}>
          This bit of text is smaller :O
        </span> */}
      </CookieConsent>
    </div>
  );
}

export default Home;

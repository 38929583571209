import React from "react";
// import StarIcon from "./icons/star_icon.png";
import BefcaseIcom from "./icons/befcase.png";
import ClockIcom from "./icons/clock.png";
import RateIcom from "./icons/rate.png";
import "./employee_card_style.scss";
import { Link } from "react-router-dom";
// import { useSelector,shallowEqual } from "react-redux";
import { useUser } from "../../../contexts/user-context";

export default function EmployeeCard({
  userName,
  profileImage,
  userId,
  chatId,
  experience,
  rate,
  currency,
  jobType,
  bookNow,
  bookMarkUserID,
  bookMarkUniform,
  addShortListOnclikHandler,
  setIsSidebarOpen,
  user,
  setSelectedEmployee,
  country,
}) {
  const { currentUser } = useUser();
  // const { userInfo } = useSelector(
  //   ({ auth }) => ({
  //     userInfo: auth.user,
  //   }),
  //   shallowEqual
  // );
  const chatHandler = () => {
    setSelectedEmployee(user);
    setIsSidebarOpen(true);
  };

  console.log("current-user-employee-card~~", currentUser);
  console.log("user-employee-card~~", user);

  return (
    <div className="employee_card">
      <div className="card">
        <a href={userId} target="_blank" rel="noreferrer">
          <img
            src={profileImage}
            className="card-img-top"
            alt="Employee images"
            style={{
              height: "300px",
            }}
          />
        </a>
        <div className="card-body">
          <h5 className="card-title">
            <a
              className="text-decoration-none text-dark"
              href={userId}
              target="_blank"
              rel="noreferrer"
            >
              {userName}
            </a>

            <div
              style={{
                cursor: "pointer",
              }}
              className="text-dark"
              onClick={chatHandler}
            >
              <i className="fa-brands fa-rocketchat"></i>
            </div>
          </h5>

          <div className="info_list">
            <div className="item_row">
              <div className="icon_div">
                <img src={BefcaseIcom} alt="icon" />
              </div>
              <div className="label_info">
                <span>Exp:</span> {experience}
              </div>
            </div>

            <div className="item_row">
              <div className="icon_div">
                <img src={ClockIcom} alt="icon" />
              </div>
              <div className="label_info">
                <span>Total hours:</span> {user?.totalWorkingHour}
              </div>
            </div>

            <div className="item_row">
              <div className="icon_div">
                <img src={RateIcom} alt="icon" />
              </div>
              <div className="label_info">
                <span>Rate:</span> {currency} {rate} {jobType}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="Dashboard2BookNowButton">
              <img
                onClick={() => {
                  addShortListOnclikHandler(bookMarkUserID, bookMarkUniform);
                }}
                style={{
                  cursor: "pointer",
                }}
                src="assets/frontend/images/Dashboardimages/dashboard2/bookmark.png"
                alt="bookmark"
              />
            </div>
            {country === currentUser?.countryName && (
              <Link to={bookNow}>
                <button className="btn btn_book_now">Book Now</button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
const CustomBackButton = ({ link }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    const routeLInk = link ? link : -1;
    navigate(routeLInk);
  };
  return (
    <div onClick={handleClick} className="back_btn custom_back_button">
      <img src="/assets/arrow.png" className="img-fluid" alt="arrow" />
    </div>
  );
};

export default CustomBackButton;

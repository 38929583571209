import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  TimePicker,
  Tooltip,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FcInfo } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import {
  getFormateName,
  getUserCurrencyByCountry,
  getUserInfo,
} from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { useTranslation } from "react-i18next";
import { donwloadCSV } from "../../../utils/static/donwloadCSV";
import * as Action from "../../../pages/Client/_redux/clientActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { minutestoHours } from "../../../utils/date";
import { buildCheckInOutHistoryUrl } from "../../../utils/url";
import { useQuery, useQueryClient } from "react-query";
import axiosInstance from "../../../utils/API";
import { formattedCheckInCheckOutData } from "../../../utils/checkInCheckOut";
import SmallLoading from "../../Shared/SmallLoading";
import { InvoiceDownloadButton } from "./_components/InvoiceDownload";
import { Colors } from "../../../constants/Colors";
const fetchCheckInOutHistories = async ({ queryKey }) => {
  const [
    _key,
    { clientId, currentPage, pageSize, dateFilter, getName, status },
  ] = queryKey;
  const url = buildCheckInOutHistoryUrl({
    clientId,
    currentPage,
    pageSize,
    dateFilter,
    getName,
    status,
  });
  const response = await axiosInstance.get(url);
  return response.data;
};

const useCheckInOutHistories = ({
  clientId,
  currentPage,
  pageSize,
  dateFilter,
  getName,
  status,
}) => {
  return useQuery(
    [
      "checkInOutHistories",
      { clientId, currentPage, pageSize, dateFilter, getName, status },
    ],
    fetchCheckInOutHistories,
    {
      keepPreviousData: true,
    }
  );
};

function CheckInCheckOut() {
  const { t } = useTranslation();
  const jwtDecode = jwtTokenDecode();
  const queryClient = useQueryClient();
  const [getEmployee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [modal, setModal] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const { currencyType } = useContext(ContextApi);
  //Set filter data
  const [getName, setName] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedClientsData, setselectedClientsData] = useState(null);
  const [pageSize, setpageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = getUserInfo();
  const [dateFilter, setDateFilter] = useState(null);
  const userId = jwtDecode?._id || userInfo?._id;

  // const { historyList, totalPages, listLoading, totalCount } = useSelector(
  //   ({ client }) => ({
  //     listLoading: client?.listLoading,
  //     historyList: client?.historyData?.entities ?? [],
  //     totalPages: client?.historyData?.totalPages,
  //     totalCount: client?.historyData?.totalCount,
  //   }),
  //   shallowEqual
  // );
  const { data, isLoading, error, refetch } = useCheckInOutHistories({
    clientId: userId,
    currentPage,
    pageSize,
    dateFilter,
    getName,
  });

  // console.log("historyList~~", data);

  useEffect(() => {
    // setEmployee(historyList || []);
    // setTotalData(totalCount);
    if (data && Object.keys(data).length > 0) {
      const employees = formattedCheckInCheckOutData(data);
      // console.log(employees);
      setEmployee(employees);
      setTotalData(data?.total);
    }
  }, [data]);

  // useEffect(() => {
  //   if (currentPage && pageSize && userId) {
  //     dispatch(
  //       Action.fetchHistoryList(
  //         userId,
  //         currentPage,
  //         pageSize,
  //         dateFilter,
  //         getName
  //       )
  //     );
  //   }
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  //   dateFilter,
  //   getName,
  //   jwtDecode?._id,
  //   userInfo?._id,
  // ]);

  const handleDateFilter = (date, dateString) => {
    setDateFilter(dateString);
    console.log("date", dateString);
  };

  // const isEditable = (checkInTime) => {
  //   // console.log("checkInTime~~",checkInTime)
  //   const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000); // 12 hours ago
  //   return new Date(checkInTime) >= twelveHoursAgo;
  // };

  function isEditable(pastTime) {
    const twelveHoursInMs = 12 * 60 * 60 * 1000;
    const currentTime = new Date();
    const timeDifference = currentTime - new Date(pastTime);

    return timeDifference < twelveHoursInMs;
  }

  const showModal = (_id, data) => {
    setModalVisible(true);
    // console.log(_id);
    setSelectedItemId(data.currentHiredEmployeeId);
    setselectedClientsData(data.checkInCheckOutDetails);
    // console.log("data", data);
  };

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedItemId(null);
    setselectedClientsData(null);
  };

  const handleNameChange = (e) => {
    // Use e.target.value to get the new name value
    const newName = e.target.value;
    setName(newName);
    // console.log("getName", newName);
  };

  // const fetchEmployees = useCallback(async () => {
  //   setLoading(true);

  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories?clientId=${jwtDecode._id}&page=${currentPage}&limit=${pageSize}` +
  //         `${dateFilter ? `&filterDate=${dateFilter}` : ""}` +
  //         `${getName ? `&employeeName=${getName}` : ""}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token()}`,
  //         },
  //       }
  //     );

  //     if (res && res?.data.statusCode === 200) {
  //       const checkInCheckOutHistories = res.data.result.map((item) => {
  //         const finalCheckIn =
  //           item?.checkInCheckOutDetails?.clientCheckInTime ||
  //           item?.checkInCheckOutDetails?.checkInTime;
  //         const finalCheckOut =
  //           item?.checkInCheckOutDetails?.clientCheckOutTime ||
  //           item?.checkInCheckOutDetails?.checkOutTime;
  //         const finalBreakTime =
  //           item?.checkInCheckOutDetails?.clientBreakTime ||
  //           item?.checkInCheckOutDetails?.breakTime;
  //         const isEditCheckIn = item?.checkInCheckOutDetails?.clientCheckInTime
  //           ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             ) ===
  //             dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             )
  //             ? false
  //             : true
  //           : false;
  //         const isEditCheckOut = item?.checkInCheckOutDetails
  //           ?.clientCheckOutTime
  //           ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             ) ===
  //             dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             )
  //             ? false
  //             : true
  //           : false;
  //         const isEditBreakTime = item?.checkInCheckOutDetails?.clientBreakTime
  //           ? item?.checkInCheckOutDetails?.breakTime ===
  //             item?.checkInCheckOutDetails?.clientBreakTime
  //             ? false
  //             : true
  //           : false;
  //         console.log(item?.checkInCheckOutDetails?.checkInTime);
  //         return {
  //           date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
  //           id: item?._id,
  //           country: item?.restaurantDetails?.countryName,
  //           restaurantName: item?.restaurantDetails?.restaurantName,
  //           employeeName: item?.employeeDetails?.name,
  //           position: item?.employeeDetails?.positionName,
  //           restaurantRate:
  //             item?.employeeDetails?.hourlyRate +
  //             " " +
  //             getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
  //             "/Hour",
  //           customerRate:
  //             item?.employeeDetails?.contractorHourlyRate +
  //             " " +
  //             getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
  //             "/Hour",
  //           checkIn: item?.checkInCheckOutDetails?.checkInTime
  //             ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
  //                 .format("hh:mm:ss A")
  //                 .toString()
  //             : null,
  //           finalCheckIn: finalCheckIn
  //             ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
  //             : null,
  //           isEditCheckIn: isEditCheckIn,

  //           checkOut: item?.checkInCheckOutDetails?.checkOutTime
  //             ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
  //                 .format("hh:mm:ss A")
  //                 .toString()
  //             : null,
  //           finalcheckOut: finalCheckOut
  //             ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
  //             : null,
  //           isEditCheckOut: isEditCheckOut,

  //           break: item?.checkInCheckOutDetails?.breakTime,
  //           finalBreakTime: finalBreakTime ? finalBreakTime : 0,
  //           isEditBreakTime: isEditBreakTime,

  //           totalHours: finalCheckOut
  //             ? minutestoHours(
  //                 dayjs(finalCheckOut)
  //                   .diff(dayjs(finalCheckIn), "seconds", true)
  //                   .toFixed(0) -
  //                   finalBreakTime * 60
  //               )
  //             : null,
  //           workedHour: item?.workedHour,
  //           paid: item?.paid,
  //           totalAmount: finalCheckOut
  //             ? (
  //                 (dayjs(finalCheckOut)
  //                   .diff(dayjs(finalCheckIn), "seconds", true)
  //                   .toFixed(0) -
  //                   finalBreakTime * 60) *
  //                 (item?.employeeDetails?.hourlyRate / 3600)
  //               ).toFixed(2) +
  //               " " +
  //               getUserCurrencyByCountry(item?.restaurantDetails?.countryName)
  //             : null,
  //           clientComment: item?.checkInCheckOutDetails?.clientComment,
  //           mainItem: item,
  //         };
  //       });

  //       setEmployee(checkInCheckOutHistories || []);
  //       setTotalData(res.data.total);
  //       setLoading(false);
  //     } else if (res && res?.data.statusCode === 400) {
  //       setError(res.errors);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setError(error);
  //     setLoading(true);
  //     console.log(error);
  //   }
  // }, [currentPage, pageSize, dateFilter, getName]);

  // useEffect(() => {
  //   fetchEmployees();
  // }, [fetchEmployees]);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const myParam = urlParams.get("page");
  //   const myParam2 = urlParams.get("limit");
  //   // console.log(myParam, myParam2);
  //   if (myParam === null || myParam2 === null) {
  //     navigate(`/dashboard-history?page=1&limit=10`);
  //     window.location.reload();
  //   }
  //   setpageSize(myParam2);
  //   setCurrentPage(myParam);
  // }, [navigate]);

  const handleExportData = () => {
    const excelData = getEmployee.map((item) => {
      return {
        Date: item?.date,
        Country: item?.country,
        RestaurantName: item?.restaurantName,
        EmployeeName: item?.employeeName,
        Position: item?.position,
        RestaurantRate: item?.restaurantRate,
        CustomerRate: item?.customerRate,
        CheckIn: item?.finalCheckIn,
        CheckOut: item?.finalCheckOut,
        Break: item?.finalBreakTime,
        TotalHours: item?.totalHours,
        TotalAmount: item?.totalAmount,
        ClientComment: item?.clientComment,
      };
    });
    donwloadCSV(excelData, "check-in-check-out-histories");
  };

  const dynamicInvoiceLinkGenerator = (country) => {
    console.log(country);
    if (country === "United Kingdom") {
      return "payment-invoice-details";
    } else if (country === "United Arab Emirates") {
      return "payment-invoice-details2";
    } else {
      return "payment-invoice-details3";
    }
  };

  //   const columns = [
  //     {
  //       title: "#",
  //       dataIndex: "id",
  //       // render upto totalData
  //       render: (value, record, index) => {
  //         return (
  //           <span>
  //             {(currentPage - 1) * pageSize + (index + 1)}
  //             {/* {index + 1} */}
  //           </span>
  //         );
  //       },
  //     },
  //     {
  //       title: t("date"),
  //       dataIndex: "date",
  //       sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
  //     },
  //     {
  //       title: t("employee_name"),
  //       dataIndex: "employeeName",
  //     },
  //     {
  //       title: t("position"),
  //       dataIndex: "position",
  //       // sorter: (a, b) => a.active.length - b.active.length,
  //     },
  //     {
  //       title: t("per_hour_rate"),
  //       dataIndex: "restaurantRate",
  //     },

  //     {
  //       title: t("check_in"),
  //       dataIndex: "checkIn",
  //       render: (text, record) => {
  //         return (
  //           <div>
  //             {record?.checkIn}
  //             {/* {record?.isEditCheckIn && (
  //               <div
  //                 style={{
  //                   textDecoration: "line-through",
  //                   color: "red",
  //                 }}
  //               >
  //                 {record?.checkIn}
  //               </div>
  //             )}
  //             <div
  //               style={
  //                 record?.isEditCheckIn
  //                   ? { color: "#58c8c8", fontWeight: "bold" }
  //                   : null
  //               }
  //             >
  //               {record?.finalCheckIn}
  //             </div> */}
  //           </div>
  //         );
  //       },
  //     },
  //     {
  //       title: t("check_out"),
  //       dataIndex: "checkOut",
  //       render: (text, record) => {
  //         return (
  //           <div>
  //             {record?.checkOut}
  //             {/* {record?.isEditCheckOut && (
  //               <div
  //                 style={{
  //                   textDecoration: "line-through",
  //                   color: "red",
  //                 }}
  //               >
  //                 {record?.checkOut}
  //               </div>
  //             )}
  //             <div
  //               style={
  //                 record?.isEditCheckOut
  //                   ? { color: "#58c8c8", fontWeight: "bold" }
  //                   : null
  //               }
  //             >
  //               {record?.finalcheckOut}
  //             </div> */}
  //           </div>
  //         );
  //       },
  //     },

  //     {
  //       title: t("break"),
  //       dataIndex: "break",
  //       render: (text, record) => {
  //         return (
  //           <div>
  //             {record?.break} min
  //             {/* {record?.isEditBreakTime && (
  //               <div
  //                 style={{
  //                   textDecoration: "line-through",
  //                   color: "red",
  //                 }}
  //               >
  //                 {record?.break} min
  //               </div>
  //             )}
  //             <div
  //               style={
  //                 record?.isEditBreakTime
  //                   ? { color: "#58c8c8", fontWeight: "bold" }
  //                   : null
  //               }
  //             >
  //               {record?.finalBreakTime} min
  //             </div> */}
  //           </div>
  //         );
  //       },
  //     },
  //     /* {
  //           title: t('total_hours'),
  //           dataIndex: "totalHours",
  //         },
  //         {
  //           title: t("total_amount"),
  //           dataIndex: "totalAmount",
  //         }, */
  //     {
  //       title: t("total_hours"),
  //       dataIndex: "workedHour",
  //     },
  //     {
  //       title: t("total_amount"),
  //       dataIndex: "paid",
  //     },
  //     {
  //       title: t("client_comment"),
  //       dataIndex: "clientComment",
  //     },
  //     /* {
  //           title: t('action'),
  //           dataIndex: "_id",
  //           render: (_id, record) => {
  //             return isEditable(
  //               record?.mainItem?.checkInCheckOutDetails?.checkInTime
  //             ) ? (
  //               <div className="text-center">
  //                 <Button
  //                   className="InnerTableButton"
  //                   onClick={() => {
  //                     // showModal(_id, record);
  //                     console.log("record", record);
  //                     setModal(true);
  //                     form.setFieldsValue({
  //                       _id: record?.mainItem?.currentHiredEmployeeId,
  //                       checkInTime:
  //                         record?.mainItem?.checkInCheckOutDetails
  //                           ?.clientCheckInTime ||
  //                         record?.mainItem?.checkInCheckOutDetails?.checkInTime
  //                           ? dayjs(
  //                               record?.mainItem?.checkInCheckOutDetails
  //                                 ?.clientCheckInTime ||
  //                                 record?.mainItem?.checkInCheckOutDetails
  //                                   ?.checkInTime
  //                             )
  //                           : null,
  //                       checkOutTime:
  //                         record?.mainItem?.checkInCheckOutDetails
  //                           ?.clientCheckOutTime ||
  //                         record?.mainItem?.checkInCheckOutDetails?.checkOutTime
  //                           ? dayjs(
  //                               record?.mainItem?.checkInCheckOutDetails
  //                                 ?.clientCheckOutTime ||
  //                                 record?.mainItem?.checkInCheckOutDetails
  //                                   ?.checkOutTime
  //                             )
  //                           : null,
  //                       breakTime:
  //                         record?.mainItem?.checkInCheckOutDetails?.clientBreakTime ||
  //                         record?.mainItem?.checkInCheckOutDetails?.breakTime
  //                           ? record?.mainItem?.checkInCheckOutDetails
  //                               ?.clientBreakTime ||
  //                             record?.mainItem?.checkInCheckOutDetails?.breakTime
  //                           : null,
  //                       clientComment: record?.mainItem?.checkInCheckOutDetails
  //                         ?.clientComment
  //                         ? record?.mainItem?.checkInCheckOutDetails?.clientComment
  //                         : null,
  //                     });
  //                   }}
  //                 >
  //                   Update
  //                 </Button>
  //               </div>
  //             ) : (
  //               <div className="text-center">
  //                 <Tooltip title="Can't edit after 12 hours">
  //                   <span style={{ color: "red" }}>
  //                     <FcInfo />
  //                   </span>
  //                 </Tooltip>
  //               </div>
  //             );
  //           },
  //         }, */
  //   ];

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (value, record, index) => (
        <span>{(currentPage - 1) * pageSize + (index + 1)}</span>
      ),
    },
    {
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: t("employee_name"),
      dataIndex: "employeeName",
    },
    {
      title: t("position"),
      dataIndex: "position",
    },
    {
      title: t("per_hour_rate"),
      dataIndex: "restaurantRate",
    },
    {
      title: t("check_in"),
      dataIndex: "checkIn",
      // render: (text, record) => <div>{record?.checkIn}</div>,
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckIn && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkIn}
              </div>
            )}
            <div
              style={
                record?.isEditCheckIn
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckIn}
            </div>
          </div>
        );
      },
    },
    {
      title: t("check_out"),
      dataIndex: "checkOut",
      // render: (text, record) => <div>{record?.checkOut}</div>,
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckOut && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkOut}
              </div>
            )}
            <div
              style={
                record?.isEditCheckOut
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckOut}
            </div>
          </div>
        );
      },
    },
    {
      title: t("break"),
      dataIndex: "break",
      // render: (text, record) => <div>{record?.break} min</div>,
      render: (text, record) => {
        return (
          <div>
            {record?.isEditBreakTime && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.break} min
              </div>
            )}
            <div
              style={
                record?.isEditBreakTime
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalBreakTime} min
            </div>
          </div>
        );
      },
    },
    {
      title: t("total_hours"),
      dataIndex: "workedHour",
    },
    {
      title: t("total_amount"),
      dataIndex: "totalAmount",
    },
    {
      title: t("Tips"),
      dataIndex: "tips",
      render: (id, record) => {
        return (
          <div>
            {getUserCurrencyByCountry(record.country)}
            {record?.tips}
          </div>
        );
      },
    },
    {
      title: t("Travel Cost"),
      dataIndex: "travel_cost",
      render: (id, record) => {
        return (
          <div>
            {getUserCurrencyByCountry(record.country)}
            {record?.travel_cost}
          </div>
        );
      },
    },
    {
      title: t("client_comment"),
      dataIndex: "clientComment",
    },
    {
      title: t("action"),
      dataIndex: "_id",
      fixed: "right",
      render: (_id, record) => (
        <div className="text-center">
          {isEditable(record?.mainItem?.checkInCheckOutDetails?.checkInTime) &&
          record?.mainItem?.checkInCheckOutDetails?.checkOut ? (
            <>
              {!record?.hasReview && (
                <Button
                  type="button"
                  style={{
                    cursor: "pointer",
                    backgroundColor: Colors.primary,
                    color: "#fff",
                  }}
                  onClick={async () => {
                    try {
                      setLoading(record.currentHiredEmployeeId);
                      const { data } = await axiosInstance.put(
                        "/check-in-check-out-histories/review-employee",
                        {
                          hasReview: true,
                          currentHiredEmployeeId: [
                            record.currentHiredEmployeeId,
                          ],
                        }
                      );
                      setLoading(null);

                      // console.log("data",data)

                      if (data?.status === "success") {
                        toast.success("Reviewed successfully");
                        queryClient.invalidateQueries([
                          "checkInOutHistories",
                          {
                            clientId: userId,
                            currentPage,
                            pageSize,
                            dateFilter,
                            getName,
                          },
                        ]);
                        refetch();
                      }
                    } catch (error) {
                      setLoading(null);
                      console.error("API Error:", error);
                    }
                  }}
                >
                  {loading === record.currentHiredEmployeeId ? (
                    <SmallLoading />
                  ) : (
                    "Pay Now"
                  )}
                </Button>
              )}

              {record?.clientComment ||
              record?.travel_cost ||
              record?.tips ||
              record?.isEditBreakTime ||
              record?.isEditCheckIn ||
              record?.isEditCheckOut ? null : (
                <Button
                  className="InnerTableButton ms-2"
                  onClick={() => {
                    console.log("Editing record", record);
                    setModal(true);
                    form.setFieldsValue({
                      currentHiredEmployeeId: record.currentHiredEmployeeId,
                      travelCost: record.travel_cost,
                      tips: record.tips,
                      checkInTime: record.checkIn
                        ? dayjs(
                            record?.mainItem?.checkInCheckOutDetails
                              ?.checkInTime
                          )
                        : null,
                      checkOutTime: record.checkOut
                        ? dayjs(
                            record?.mainItem?.checkInCheckOutDetails
                              ?.checkOutTime
                          )
                        : null,
                      breakTime: record.break,
                      clientComment: record.clientComment,
                      prevCheckInTime:
                        record?.mainItem?.checkInCheckOutDetails?.checkInTime,
                      prevCheckOutTime:
                        record?.mainItem?.checkInCheckOutDetails?.checkOutTime,
                    });
                  }}
                >
                  Edit
                </Button>
              )}
            </>
          ) : record?.mainItem?.checkInCheckOutDetails?.checkOut ? (
            <Tooltip title="Can't edit after 12 hours">
              <span style={{ color: "red" }}>
                <FcInfo />
              </span>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ];

  const [form] = Form.useForm();

  const saveHandler = () => {
    const values = form.getFieldsValue(true);
    const payload = {
      id: values.currentHiredEmployeeId,
      checkIn: !dayjs(values.checkInTime).isSame(values.prevCheckInTime),
      checkOut: !dayjs(values.checkOutTime).isSame(values.prevCheckOutTime),
      clientComment: values.clientComment ? values.clientComment : "",
      clientCheckInTime: dayjs(values.checkInTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      clientCheckOutTime: dayjs(values.checkOutTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      clientBreakTime: values.breakTime ? values.breakTime : 0,
      tips: values.tips,
      travel_cost: values.travelCost,
    };
    setModalLoader(true);
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/current-hired-employees/update-status`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((response) => {
        setModalLoader(false);
        if (response.data.statusCode === 200) {
          setModal(false);
          // fetchEmployees();
          toast.success("Updated successfully");
          refetch();
        }
      })
      .catch((error) => {
        setModalLoader(false);
        console.error("API request error:", error);
      });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // console.log("Form values:", values);
        const payload = {
          id: selectedItemId,
          checkIn: selectedClientsData.checkIn,
          checkOut: selectedClientsData.checkOut,
          clientComment: values.complainComment,
          clientCheckInTime: selectedClientsData.checkInTime,
          clientCheckOutTime: selectedClientsData.checkOutTime,
        };
        if (values.complainOption === "checkInBefore") {
          payload.clientCheckInTime = Number(values.complainMinutes) * -1;
          payload.clientCheckOutTime = 0;
          payload.clientBreakTime = 0;
        } else if (values.complainOption === "checkInAfter") {
          payload.clientCheckOutTime = 0;
          payload.clientCheckInTime = Number(values.complainMinutes);
          payload.clientBreakTime = 0;
        } else if (values.complainOption === "checkOutBefore") {
          payload.clientCheckInTime = 0;
          payload.clientCheckOutTime = Number(values.complainMinutes) * -1;
          payload.clientBreakTime = 0;
        } else if (values.complainOption === "checkOutAfter") {
          payload.clientCheckInTime = 0;
          payload.clientCheckOutTime = Number(values.complainMinutes);
          payload.clientBreakTime = 0;
        } else if (values.complainOption === "breaktime") {
          payload.clientCheckInTime = 0;
          payload.clientCheckOutTime = 0;
          payload.clientBreakTime = Number(values.complainMinutes);
        }

        console.log("Payload:", payload); // Add this line to log the payload

        axios
          .put(
            `${process.env.REACT_APP_API_BASE_URL}/current-hired-employees/update-status`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token()}`,
              },
            }
          )
          .then((response) => {
            // console.log("API response:", response.data);
            if (response.data.statusCode === 200) {
              // fetchEmployees();
              toast.success("Updated successfullly");
            }
            // fetchEmployees();
          })
          .catch((error) => {
            console.error("API request error:", error);
          });

        setModalVisible(false);
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.log("Form validation failed:", errorInfo);
      });
  };

  return (
    <div>
      <section className="pt-4 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 mb-3">
              <div className="innerDashSearchItems d-flex align-items-center">
                <Link to="/client-dashboard">
                  <button className="innerdashboardBackButton">
                    <img
                      src="assets/frontend/images/InnerDashboard/arrow.png"
                      className="img-fluid"
                      alt="arrow"
                    />
                  </button>
                </Link>
                <img
                  src="assets/frontend/images/InnerDashboard/mapSearch.png"
                  className="img-fluid"
                  alt="mapSearch"
                />
                <span className="innerDashSearchItemsSpan dashboard-title">
                  {t("dashboard")}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <DatePicker
                placeholder={t("select_date")}
                size="large"
                className="w-100"
                onChange={handleDateFilter}
              />
            </div>
            <div className="col-9 col-md-3 mb-3">
              <Input
                placeholder={t("search_employee_name")}
                onChange={handleNameChange}
                className="form-control"
              />
            </div>
            <div className="col-3 col-md-2 mb-3">
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "#58c8c8",
                  color: "#fff",
                  width: "100%",
                }}
                onClick={handleExportData}
              >
                {t("export")}
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="InnnerDashboardTable">
        <div className="container">
          <Table
            columns={columns}
            dataSource={getEmployee}
            loading={isLoading}
            scroll={{ x: "max-content" }}
            pagination={{
              pageSize: Number(pageSize),
              current: Number(currentPage),
              showSizeChanger: false,
              onChange: (page, pageSize) => {
                setCurrentPage(page);
                setpageSize(pageSize);
                navigate(`?page=${page}&limit=${pageSize}`);
              },
              total: totalData,
            }}
          />
        </div>
      </section>

      <Modal
        title="Complain"
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form}>
          <Form.Item
            name="complainOption"
            label="Complain Option"
            rules={[{ required: true, message: "Please select an option" }]}
          >
            <Select placeholder="Select an option">
              <Select.Option value="checkInBefore">
                Check In Before
              </Select.Option>
              <Select.Option value="checkInAfter">Check In After</Select.Option>
              <Select.Option value="checkOutBefore">
                Check Out Before
              </Select.Option>
              <Select.Option value="checkOutAfter">
                Check Out After
              </Select.Option>

              <Select.Option value="breaktime">Break Time</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="complainMinutes"
            label="Complain Minutes"
            rules={[{ required: true, message: "Please enter minutes" }]}
          >
            <Input type="number" placeholder="Minutes" />
          </Form.Item>
          <Form.Item
            name="complainComment"
            label="Complain Comment"
            rules={[{ required: true, message: "Please enter comment" }]}
          >
            <Input.TextArea placeholder="Comment" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Check In Check Out"
        visible={modal}
        width={1000}
        onCancel={() => setModal(false)}
        footer={
          <>
            <button
              className="btn btn-primary"
              disabled={modalLoader}
              style={{ marginRight: "10px" }}
              onClick={() => saveHandler()}
            >
              {modalLoader ? "Loading..." : "Update"}
            </button>
            <button
              className="btn btn-danger"
              onClick={() => setModal(false)}
              disabled={modalLoader}
            >
              Cancel
            </button>
          </>
        }
      >
        <Form layout="vertical" form={form}>
          <Row gutter={[10, 8]} className="mt-5 mb-4">
            <Col sm={24} md={12} lg={6}>
              <label>Check In Time</label>
              <Form.Item name="checkInTime">
                <TimePicker
                  onChange={(value) =>
                    form.setFieldsValue({ checkInTime: value })
                  }
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  showTime={true}
                  format={"HH:mm:ss"}
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={6}>
              <label>Check Out Time</label>
              <Form.Item name="checkOutTime">
                <TimePicker
                  onChange={(value) =>
                    form.setFieldsValue({ checkOutTime: value })
                  }
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  showTime={true}
                  format={"HH:mm:ss"}
                  disabled={!form.getFieldValue("checkOutTime")}
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={6}>
              <label>Break Time (Min)</label>
              <Form.Item name="breakTime">
                <InputNumber
                  type="number"
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Break Time"
                  onChange={(e) => form.setFieldsValue({ breakTime: e })}
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={6}>
              <label>Client Comment</label>
              <Form.Item name="clientComment">
                <Input
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Client Comment"
                  onChange={(e) =>
                    form.setFieldsValue({ clientComment: e.target.value })
                  }
                />
              </Form.Item>
            </Col>

            {/* Travel Cost */}
            <Col sm={24} md={12} lg={6}>
              <label>Travel Cost</label>
              <Form.Item name="travelCost">
                <InputNumber
                  type="number"
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Travel Cost"
                  onChange={(e) => form.setFieldsValue({ travelCost: e })}
                />
              </Form.Item>
            </Col>

            {/* Tips */}
            <Col sm={24} md={12} lg={6}>
              <label>Tips</label>
              <Form.Item name="tips">
                <InputNumber
                  type="number"
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Tips"
                  onChange={(e) => form.setFieldsValue({ tips: e })}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default CheckInCheckOut;

import { Card, Skeleton } from 'antd'
import React from 'react'

export default function PositionSkeletonCard() {
    return ( 
            <div className="w-100 mt-3">
                <Skeleton.Image className="w-100" style={{ height: 80 }} />
                <Skeleton
                className='mt-3'
                    loading={true}
                    active
                    paragraph={false}  
                    title={{ width: "100%" }}
                />
            </div>  
    )
} 

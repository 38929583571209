import { Helmet } from "react-helmet-async";

const ChangeTitle = ({ title, meta }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={meta} />
    </Helmet>
  );
};

export default ChangeTitle;

import { Col, Modal, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FcCalendar } from "react-icons/fc";

const BookingDates = ({
  isModalVisible,
  handleOk,
  handleCancel,
  activeEmployee,
  countDates,
  employeeId,
}) => {
  const { t } = useTranslation();
  // const employeeBookedDate = activeEmployee?.bookedDate?.sort((a, b) => {
  //   return new Date(a.startDate) - new Date(b.startDate);
  // }
  // );
  const expiredDate = activeEmployee?.bookedDate?.filter((item) => {
    return new Date(item.endDate) < new Date();
  });
  const activeDate = activeEmployee?.bookedDate?.filter((item) => {
    return new Date(item.endDate) >= new Date();
  });
  return (
    <Modal
      title={t("booked_dates")}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={t("cancel")}
      okText={t("ok")}
    >
      {activeDate?.map((item, index) => (
        <>
          <Row
            key={index}
            style={{
              background: "#FAFAFA",
              padding: "10px",
              margin: "10px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Col style={{}}>
              <div>
                <FcCalendar
                  style={{
                    marginRight: "5px",
                  }}
                />
                {item?.startDate} - {item?.endDate}
              </div>
              <div
                style={{
                  color: "#58c8c8",
                  fontWeight: "bold",
                }}
              >
                {countDates(item?.startDate, item?.endDate)} {t("days")}
              </div>
              <div>
                {item?.startTime} - {item?.endTime}
              </div>
            </Col>
          </Row>
        </>
      ))}
      {expiredDate?.map((item, index) => (
        <>
          <Row
            key={index}
            style={{
              background: "#ffcccc",
              padding: "10px",
              margin: "10px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Col style={{}}>
              <div>
                <FcCalendar
                  style={{
                    marginRight: "5px",
                  }}
                />
                {item?.startDate} - {item?.endDate}
              </div>
              <div
                style={{
                  color: "#58c8c8",
                  fontWeight: "bold",
                }}
              >
                {countDates(item?.startDate, item?.endDate)} {t("days")}
              </div>
              <div>
                {item?.startTime} - {item?.endTime}
              </div>
            </Col>
          </Row>
        </>
      ))}
    </Modal>
  );
};

export default BookingDates;

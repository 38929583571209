/* eslint-disable react/prop-types */
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState, useEffect } from "react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

const CustomRightArrow = ({ onClick }) => {
  const isMobile = window.innerWidth <= 464;

  return (
    <button
      onClick={onClick}
      style={{
        position: isMobile ? "absolute" : "absolute",
        top: isMobile ? "50%" : "18px",
        right: isMobile ? "10px" : "10px",
        transform: "translateY(-50%)", // Centering on mobile
        backgroundColor: "#58C8C8",
        border: "none",
        cursor: "pointer",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        zIndex: 121212,
      }}
    >
      <MdArrowForwardIos className="text-white" />
    </button>
  );
};

const CustomLeftArrow = ({ onClick }) => {
  const isMobile = window.innerWidth <= 464;

  const [leftStyles, setLeftStyles] = useState({});

  useEffect(() => {
    const temp = {
      position: isMobile ? "absolute" : "absolute",
      top: isMobile ? "50%" : "18px",
      transform: "translateY(-50%)", // Centering on mobile
      backgroundColor: "#58C8C8",
      border: "none",
      cursor: "pointer",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: 1000,
    };

    if (isMobile) {
      temp.left = "10px";
    } else {
      temp.right = "60px";
    }

    setLeftStyles(temp);
  }, [isMobile]);

  return (
    <button onClick={onClick} style={leftStyles}>
      <MdArrowBackIosNew className="text-white" />
    </button>
  );
};

const CarouselTemplate = ({ children }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 250 },
      items: 1,
    },
  };

  return (
    <div className="w-full mx-auto ">
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        // Remove this to ensure arrows show on mobile devices
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {/* {coupons.map((coupon, index) => (
          <div key={index} className="mx-3 relative  mt-16">
            <CouponCard
              imageUrl={coupon.imageUrl}
              restaurantLogo={coupon.restaurantLogo}
              restaurantName={coupon.restaurantName}
              discount={coupon.discount}
              offerDetails={coupon.offerDetails}
              expirationDate={coupon.expirationDate}
            />
          </div>
        ))} */}
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselTemplate;

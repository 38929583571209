import React from "react";
import "./breadcrumb_style.scss";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomBackButton from "../Shared/CustomBackButton";

export default function BackButton({
  pageIcon,
  pageTitle,
  hasButton,
  buttonUrl,
  buttonTitle,
  additionDataList,
}) {
  const navigate = useNavigate();

  return (
    <div className="innerDashSearchItems d-flex align-items-center my-2 justify-content-between">
      <div className="d-flex align-items-center gap-3">
        <CustomBackButton />
        {/* <button onClick={() => navigate(-1)} type="button" className="btn btn-outline-dark page-back-btn"><i className="fa-solid fa-angle-left"></i> Back</button> */}

        {pageIcon && (
          <img
            src={pageIcon}
            className="img-fluid ms-2 page-icon"
            alt="Page Icon"
          />
        )}
        {pageTitle && (
          <span className="innerDashSearchItemsSpan page-title">
            {pageTitle}
          </span>
        )}

        {additionDataList && (
          <div
            className="page-additional-data"
            style={{ marginLeft: "-8px", display: "inline" }}
          >
            {additionDataList.map((data, index) => (
              <span key={index} className="page-additional-data">
                {data}
              </span>
            ))}
          </div>
        )}
      </div>

      {hasButton && (
        <div>
          <Link to={buttonUrl} className="btn title-bar-btn">
            {buttonTitle}
          </Link>
        </div>
      )}
    </div>
  );
}

import axios from "axios";
import { useQuery } from "react-query";
import { token } from "../../utils/authentication";
import { getPage } from "../../utils/getPage";

// fetch api call
export const fetchHandler = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users`, {
    headers: {
      Authorization: `Bearer ${token()}`,
    },
  });
  return res;
};

// fetch api call
export const fetchReferPersonListForDropdownHandler = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/users/list?isReferPerson=YES`,
    {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return res;
};

// fetch api call
export const fetchUserListForDropdownHandler = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/users/list`,
    {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return res;
};

// add api call
export const addHandler = async (receivedEmployeeFields) => {
  const res = fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/employee-register`,
    {
      method: "POST",
      body: JSON.stringify(receivedEmployeeFields),
    }
  );
  return res;
};

//employee register api call
export const employeeRegisterHandler = async (receivedBlogFields) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/employee-register`,
    {
      method: "POST",
      body: receivedBlogFields,
    }
  );
  return res;
};

//HR fetch api call
export const fetchEmployeeListHandler = async (
  limit,
  getName,
  getStatus,
  getCountryName,
  locationsearch,
  filterPosition,
  currentPage
) => {
  const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;

  const res = await axios.get(
    `${unicodeUri}/users?page=${currentPage || 1}&limit=${limit || 10}` +
      (getName ? `&searchKeyword=${getName}` : ``) +
      (getStatus ? `&active=${getStatus}` : ``) +
      (filterPosition ? `&positionId=${filterPosition}` : ``) +
      (getCountryName ? `&countryName=${getCountryName}` : ``) +
      `&requestType=EMPLOYEE`,
    {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return res;
};

//HR fetch api call
export const fetchClientListHandler = async (
  limit,
  getName,
  getStatus,
  getCountryName,
  locationsearch,
  currentPage
) => {
  const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;

  const res = await axios.get(
    `${unicodeUri}/users?page=${currentPage || 1}&limit=${limit || 10}` +
      (getName ? `&searchKeyword=${getName}` : ``) +
      (getStatus ? `&active=${getStatus}` : ``) +
      (getCountryName ? `&countryName=${getCountryName}` : ``) +
      `&requestType=CLIENT`,
    {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return res;
};

//Certificate upload api call
export const certificateUploadHandler = async (receivedCertificateFields) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/certificate/upload`,
    {
      method: "PUT",
      body: receivedCertificateFields,
    }
  );
  return res;
};

//Removed certificate upload api call
export const removedCertificateHandler = async (receivedCertificateFields) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/certificate/upload`,
    {
      method: "PUT",
      body: receivedCertificateFields,
    }
  );
  return res;
};

//Employee update api call
export const employeeUpdateHandler = async (receivedBlogFields) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/update-employee`,
    {
      method: "PUT",
      body: receivedBlogFields,
    }
  );
  return res;
};

//fetch api call
export const fetchClientListForDropdownHandler = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/users?requestType=CLIENT&skipLimit=YES`,
    {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return res;
};

// delete user api call
export const userDeleteHandler = async (id) => {
  const res = await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/users/remove`,
    {
      id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return res;
};

export const useFetchEmployeeList = (
  limit,
  getName,
  getStatus,
  getCountryName,
  loc,
  filterPosition,
  currentPage
) => {
  return useQuery(
    [
      "employeeList",
      limit,
      getName,
      getStatus,
      getCountryName,
      loc?.search,
      filterPosition,
      currentPage,
    ],
    async () => {
      const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;
      const response = await axios.get(
        `${unicodeUri}/users?skipLimit=false&page=${currentPage || 1}&limit=${
          limit || 10
        }` +
          (getName ? `&searchKeyword=${getName}` : ``) +
          (getStatus ? `&active=${getStatus}` : ``) +
          (filterPosition ? `&positionId=${filterPosition}` : ``) +
          (getCountryName ? `&countryName=${getCountryName}` : ``) +
          `&requestType=EMPLOYEE`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      return response.data;
    }
  );
};

export const useFetchClientList = (
  limit,
  getName,
  getStatus,
  getCountryName,
  loc,
  filterPosition,
  currentPage
) => {
  return useQuery(
    [
      "employeeList",
      limit,
      getName,
      getStatus,
      getCountryName,
      loc?.search,
      filterPosition,
      currentPage,
    ],
    async () => {
      const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;
      const response = await axios.get(
        `${unicodeUri}/users?page=${currentPage || 1}&limit=${limit || 10}` +
          (getName ? `&searchKeyword=${getName}` : ``) +
          (getStatus ? `&active=${getStatus}` : ``) +
          (filterPosition ? `&positionId=${filterPosition}` : ``) +
          (getCountryName ? `&countryName=${getCountryName}` : ``) +
          `&requestType=CLIENT`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      return response.data;
    }
  );
};

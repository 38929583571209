import { Button, Col, Form, Modal, Row, Select, Slider } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { FcCalendar } from "react-icons/fc";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchPositionListForDropdownHandler } from "../../../api/position/index";
// import { MHInput } from "../../../common/components";
import { MHInput } from "../../../common/Components";
import {
  getUserCurrencyByCountry,
  getUserId,
} from "../../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { allSkills, language } from "../../../utils/language";
import JobDetails from "./JobDetails";
import moment from "moment";
import "./create_job_post.scss";
import { getCurrency } from "../../../utils/static/getCurrency";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import Loader from "../../../components/loadar/Loader";
import MHCalender from "../../../components/frontend/calender/MHCalender";
import BookForJob from "../../../components/frontend/calender/BookForJob";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  fetchNationalities,
  fetchSkills,
} from "../../../services/commonServices";
import { groupDates, ungroupDates } from "../../../utils/date";
import {
  fetchJobDetails,
  jobCreate,
  jobUpdate,
} from "../../../services/jobServices";
import SmallLoading from "../../../components/Shared/SmallLoading";
import * as Action from "../_redux/clientActions";

const CreateJobPost = () => {
  const { t } = useTranslation();
  const jwtDecode = jwtTokenDecode();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  // const [nationalities, setNationalities] = useState([]);
  const [selectedNationality, setSelectedNationality] = useState(undefined);
  const [languages, setLanguages] = useState(language);
  const [rangeValue, setRangeValue] = useState([0, 15000]);
  const [ageRangeValue, setAgeRangeValue] = useState([18, 80]);
  const [expRangeValue, setExpRangeValue] = useState([0, 50]);
  const [rangeUpdateValue, setRangeUpdateValue] = useState([]);
  const [calenderModal, setCalenderModal] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState([]);
  const [prevModal, setPrevModal] = useState(false);
  const [prevDate, setPrevDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [dates, setDates] = useState([]);
  const [finalizedDates, setFinalizedDates] = useState([]);
  const [totalDays, setTotalDays] = useState(0);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const { Option } = Select;

  // fetch job details
  const { data, isLoading } = useQuery(
    ["jobDetails", id],
    () => fetchJobDetails(id),
    {
      enabled: !!id,
    }
  );

  // fetch nationalities
  const { data: nationalities, isLoading: nationalityLoading } = useQuery(
    ["nationalities"],
    () => fetchNationalities()
  );

  // fetch all skills
  const { data: skillsData = [], isLoading: skillsLoading } = useQuery(
    ["skills"],
    () => fetchSkills()
  );
  // fetch all position list for dropdown
  const fetchPositionData = useCallback(async () => {
    await fetchPositionListForDropdownHandler().then((res) => {
      setPositions(res?.data?.positions);
      form.setFieldsValue({
        position: res?.data?.positions[0]?.name,
      });
      setSelectedPosition(res?.data?.positions[0]?._id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/commons/nationality`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setNationalities(data.nationalities);
  //     });
  // }, []);

  useEffect(() => {
    fetchPositionData(); // fetch all position list for dropdown
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [startDate, setStartDate] = useState(null);

  const onStartChange = (date) => {
    setStartDate(date);
  };

  const disablePastDates = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledEndDate = (current) => {
    return (
      current &&
      (current < moment().startOf("day") || (startDate && current < startDate))
    );
  };

  const currentDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (id && data) {
      form.setFieldsValue({
        position: data.positionId.name,
        vacancy: data.vacancy,
        // dateRange: [moment(data.dates[0].startDate), moment(data.dates[0].endDate)],
        nationalities: data.nationalities,
        skills: data.skills,
        languages: data.languages,
        description: data.description,
        jobPostDate: moment(data.publishedDate).isAfter(currentDate)
          ? dayjs(data.publishedDate)
          : null,
        jobEndDate: moment(data.endDate).isAfter(currentDate)
          ? dayjs(data.endDate)
          : null,
        country: data.country,
        dates: ungroupDates(data?.dates),
      });
      setDescription(data.description);
      setPrevDate(data.dates);
      setSelectedPosition(data.positionId._id);
      setRangeValue([data.minRatePerHour, data.maxRatePerHour]);
      setAgeRangeValue([data.minAge, data.maxAge]);
      setExpRangeValue([data.minExperience, data.maxExperience]);
      setTotalDays(data.dates.length);
      // setDates(data.dates);
      // setFinalizedDates(data.dates);
      setSelectedMonths(ungroupDates(data?.dates));
    }
  }, [id, form, data, currentDate]);

  // useEffect(() => {
  //   if (id) {
  //     setLoading(true);
  //     axios
  //       .get(`${process.env.REACT_APP_API_BASE_URL}/job/${id}`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token()}`,
  //         },
  //       })
  //       .then((res) => {
  //         setLoading(false);
  //         const data = res.data.details;
  //         form.setFieldsValue({
  //           position: data.positionId.name,
  //           vacancy: data.vacancy,
  //           // dateRange: [moment(data.dates[0].startDate), moment(data.dates[0].endDate)],
  //           nationalities: data.nationalities,
  //           skills: data.skills,
  //           languages: data.languages,
  //           description: data.description,
  //           jobPostDate: dayjs(data.publishedDate),
  //           jobEndDate: dayjs(data.endDate),
  //           country: data.country,
  //           dates: ungroupDates(data?.dates),
  //         });
  //         setDescription(data.description);
  //         setPrevDate(data.dates);
  //         setSelectedPosition(data.positionId._id);
  //         setRangeValue([data.minRatePerHour, data.maxRatePerHour]);
  //         setAgeRangeValue([data.minAge, data.maxAge]);
  //         setExpRangeValue([data.minExperience, data.maxExperience]);
  //         setTotalDays(data.dates.length);
  //         // setDates(data.dates);
  //         // setFinalizedDates(data.dates);
  //         setSelectedMonths(ungroupDates(data?.dates));
  //       });
  //   }
  // }, [id, form]);

  const onFinish = async () => {
    // if (!totalDays && totalDays == 0) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Empty Date",
    //     text: "Please Select Working Date and Time",
    //     confirmButtonColor: "#58c8c8",
    //   });
    //   return;
    // }
    const values = form.getFieldsValue(true);
    // setLoading(true);
    const payload = {
      positionId: selectedPosition,
      clientId: getUserId(),
      minRatePerHour: rangeValue[0],
      maxRatePerHour: rangeValue[1],
      vacancy: values.vacancy,
      // dates: finalizedDates,
      nationalities: values?.nationalities,
      skills: values.skills,
      minExperience: expRangeValue[0],
      maxExperience: expRangeValue[1],
      languages: values.languages,
      description: description,
      publishedDate: dayjs(values.jobPostDate).format("YYYY-MM-DD"),
      endDate: dayjs(values.jobEndDate).format("YYYY-MM-DD"),
      minAge: ageRangeValue[0],
      maxAge: ageRangeValue[1],
      dates: groupDates(selectedMonths) || [],
    };

    setLoading(true);

    let response = null;

    if (id) {
      payload.id = id;
      response = await jobUpdate(payload);
      setLoading(false);
    } else {
      response = await jobCreate(payload);
      setLoading(false);
    }

    if (response && response?.data?.status === "success") {
      const payloadMyJob = {
        clientId: jwtDecode?._id,
        status: "PUBLISHED",
        page: 1,
      };
      const payload = {
        clientId: jwtDecode?._id,
        page: 1,
      };
      // refetch job list
      dispatch(Action.getJobList(payload, 6));
      dispatch(Action.getMyJobList(payloadMyJob, 6));

      // open updated modal
      setModal(true);
      // reset input fields
      form.resetFields();
    }

    // console.log("payload~~", payload);
    // if (id) {
    //   payload.id = id;
    //   await fetch(`${process.env.REACT_APP_API_BASE_URL}/job/update`, {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token()}`,
    //     },
    //     body: JSON.stringify(payload),
    //   })
    //     .then((res) => {
    //       form.resetFields();
    //       res.json();
    //     })
    //     .then(() => {
    //       setLoading(false);
    //       setModal(true);
    //     });
    //   return;
    // } else {
    //   await fetch(`${process.env.REACT_APP_API_BASE_URL}/job/create`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token()}`,
    //     },
    //     body: JSON.stringify(payload),
    //   })
    //     .then((res) => res.json())
    //     .then((data) => {
    //       setLoading(false);
    //       form.resetFields();
    //       setModal(true);
    //     });
    // }
  };

  const countDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start.toDateString() === end.toDateString()) {
      return "1";
    }

    const timeDifference = Math.abs(end - start);
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;
    return `${dayDifference}`;
  };

  const trackStyle = [{ backgroundColor: "#58c8c8" }];
  const handleStyle = {
    backgroundColor: "#58c8c8",
    borderColor: "#58c8c8",
  };

  // generate months
  useEffect(() => {
    const generateMonths = () => {
      const currentMonth = moment().startOf("month");
      const monthsList = [];

      // Generate the next 12 months starting from the current month
      for (let i = 0; i < 12; i++) {
        const monthMoment = currentMonth.clone().add(i, "months");
        const monthValue = monthMoment.format("YYYY-MM");
        const monthLabel = monthMoment.format("MMM YY");
        monthsList.push({ value: monthValue, label: monthLabel });
      }

      setAvailableMonths(monthsList);
    };

    generateMonths();
  }, []);

  // useEffect(() => {
  //   console.log(dates);
  // }, [dates]);
  useEffect(() => {
    const totalDay = finalizedDates?.length
      ? finalizedDates?.reduce((acc, item) => {
          const start = new Date(item?.startDate);
          const end = new Date(item?.endDate);
          const dayDifference = Math.ceil(
            Math.abs(end - start) / (1000 * 3600 * 24)
          );
          return acc + dayDifference + 1;
        }, 0)
      : 0;
    setTotalDays(totalDay);
  }, [finalizedDates]);

  const handleChange = (value) => {
    setSelectedMonths(value);
  };

  // const months = [
  //   "2024-11",
  //   "2025-01",
  //   "2025-02",
  //   "2025-03",
  //   "2025-06",
  //   "2024-04",
  // ];
  // const demo = [
  //   {
  //     startDate: "2024-11",
  //     endDate: "2024-11",
  //   },
  //   {
  //     startDate: "2025-01",
  //     endDate: "2025-04",
  //   },
  //   {
  //     startDate: "2025-06",
  //     endDate: "2025-06",
  //   },
  // ];

  // console.log("selectedMonths~~", selectedMonths);

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-10 mx-auto">
          <div className="card create_job_card">
            <div className="card-header">
              <div className="left_dev">
                <Link className="back_btn" to="/client-dashboard">
                  <img
                    src="https://mhpremierstaffingsolutions.com/assets/frontend/images/InvoiceAndPayment/arrow.png"
                    className="img-fluid"
                    alt="arrow"
                  />
                </Link>
                {
                  <img
                    src="/assets/frontend/images/Dashboardimages/dashboard 1/createJobPost.png"
                    className="img-fluid"
                    style={{
                      height: "30px",
                      width: "30px",
                    }}
                    alt="arrow"
                  />
                }
                <span className="innerDashSearchItemsSpan">
                  {id ? t("update_job_post") : t("create_job_post")}
                </span>
              </div>

              <div className="right_div">
                <Link to="/job-requests">
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#58c8c8",
                      border: "none",
                      color: "#fff",
                      height: "30px",
                      fontSize: "14px",
                      fontWeight: "600",
                      borderRadius: "0px 15px 0px 15px",
                    }}
                  >
                    {t("my_job_posts")}
                  </Button>
                </Link>
              </div>
            </div>

            <div className="card-body">
              {isLoading ? (
                <Loader />
              ) : (
                <Form form={form} layout="vertical" onFinish={onFinish}>
                  <Row gutter={[20, 4]}>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                      <Form.Item
                        label={t("position")}
                        name="position"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Position!",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{
                            width: "100%",
                          }}
                          disabled={id ? true : false}
                          allowClear={false}
                          optionFilterProp="children"
                          showSearch={true}
                          placeholder="Select Position"
                          value={selectedPosition}
                          onChange={(value, item) => {
                            setSelectedPosition(value);
                          }}
                          initialvalues={
                            positions ? positions[0]?.name : undefined
                          }
                        >
                          {positions?.map((item, index) => (
                            <Select key={index} value={item?._id}>
                              {item?.name}
                            </Select>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                      <Form.Item
                        label={t("nationality")}
                        name="nationalities"
                        rules={[
                          {
                            required: true,
                            message: t("please_enter_nationality"),
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{
                            width: "100%",
                          }}
                          maxTagCount="responsive"
                          mode="multiple"
                          allowClear={false}
                          optionFilterProp="children"
                          showSearch={true}
                          placeholder={t("select_natinality")}
                          onChange={(value, item) => {
                            setSelectedNationality(value);
                          }}
                          defaultValue={
                            nationalities ? nationalities[0]?.name : undefined
                          }
                        >
                          <Select value={"Any"}>Any</Select>
                          {nationalities?.map((item, index) => (
                            <Select key={index} value={item?.nationality}>
                              {item?.nationality}
                            </Select>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                      <Form.Item
                        label={t("skills")}
                        name="skills"
                        rules={[
                          {
                            required: true,
                            message: t("please_select_your_skills"),
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          mode="multiple"
                          maxTagCount="responsive"
                          allowClear={false}
                          style={{ width: "100%" }}
                          placeholder={t("select_skills")}
                        >
                          {skillsData?.map((item, index) => (
                            <Select key={index} value={item?.name}>
                              {item?.name}
                            </Select>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="select-date-month"
                    >
                      <Form.Item
                        label={t("select_months")}
                        rules={[
                          {
                            required: true,
                            message: t("please_select_months"),
                          },
                        ]}
                        name="dates"
                      >
                        <Select
                          mode="multiple"
                          style={{
                            width: "100%",
                          }}
                          placeholder={t("enter_months")}
                          onChange={handleChange}
                          maxTagCount="responsive"
                          optionLabelProp="label"
                        >
                          {availableMonths.map((month, index) => (
                            <Option
                              key={index}
                              value={month.value}
                              label={month.label}
                            >
                              {month.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <Form.Item label={t("salary_range")} name="hourlyRate">
                        <div
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                            padding: "0px 2px",
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="minHourlyRate">
                                {t("min")}{" "}
                                <span className="minHourlyRateSpan">
                                  {rangeValue[0]}{" "}
                                  {getUserCurrencyByCountry(
                                    jwtDecode?.countryName
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div
                                style={{ textAlign: "right" }}
                                className="minHourlyRate"
                              >
                                {t("max")}{" "}
                                <span className="minHourlyRateSpan">
                                  {rangeValue[1]}{" "}
                                  {getUserCurrencyByCountry(
                                    jwtDecode?.countryName
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <Slider
                            range
                            min={0}
                            max={15000}
                            onAfterChange={(val) => {
                              setRangeUpdateValue(val);
                            }}
                            value={rangeValue}
                            onChange={(value) => {
                              setRangeValue(value);
                            }}
                            trackStyle={trackStyle}
                            handleStyle={[handleStyle, handleStyle]}
                          />
                        </div>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <Form.Item label={t("experience")} name="experience">
                        <div
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                            padding: "0px 2px",
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="minHourlyRate">
                                {t("min")}{" "}
                                <span className="minHourlyRateSpan">
                                  {expRangeValue[0]}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div
                                style={{ textAlign: "right" }}
                                className="minHourlyRate"
                              >
                                {t("max")}{" "}
                                <span className="minHourlyRateSpan">
                                  {expRangeValue[1]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <Slider
                            range
                            min={0}
                            max={50}
                            onAfterChange={(val) => {
                              setExpRangeValue(val);
                            }}
                            value={expRangeValue}
                            onChange={(value) => {
                              setExpRangeValue(value);
                            }}
                            trackStyle={trackStyle}
                            handleStyle={[handleStyle, handleStyle]}
                          />
                        </div>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <Form.Item label="Age" name="age">
                        <div
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                            padding: "0px 2px",
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="minHourlyRate">
                                {t("min")}{" "}
                                <span className="minHourlyRateSpan">
                                  {ageRangeValue[0]}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div
                                style={{ textAlign: "right" }}
                                className="minHourlyRate"
                              >
                                {t("max")}{" "}
                                <span className="minHourlyRateSpan">
                                  {ageRangeValue[1]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <Slider
                            range
                            min={18}
                            max={80}
                            onAfterChange={(val) => {
                              setAgeRangeValue(val);
                            }}
                            value={ageRangeValue}
                            onChange={(value) => {
                              setAgeRangeValue(value);
                            }}
                            trackStyle={trackStyle}
                            handleStyle={[handleStyle, handleStyle]}
                          />
                        </div>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                      <MHInput
                        type="date"
                        label={t("job_post_date")}
                        placeholder={t("enter_job_post_date")}
                        name="jobPostDate"
                        disablePastDates={disablePastDates}
                        onChange={onStartChange}
                        rules={[
                          {
                            required: true,
                            message: t("please_input_your_job_post_date!"),
                          },
                        ]}
                      />
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                      <MHInput
                        type="date"
                        label={t("job_end_date")}
                        placeholder={t("enter_job_end_date")}
                        name="jobEndDate"
                        disablePastDates={disabledEndDate}
                        rules={[
                          {
                            required: true,
                            message: t("please_input_your_job_end_date!"),
                          },
                        ]}
                      />
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                      <Form.Item
                        label={t("preferred_language")}
                        name="languages"
                        rules={[
                          {
                            required: true,
                            message: t("please_input_your_preferred_language!"),
                          },
                        ]}
                      >
                        {/* multi select  */}
                        <Select
                          size="large"
                          mode="multiple"
                          allowClear={false}
                          maxTagCount="responsive"
                          style={{ width: "100%" }}
                          placeholder={t("select_preferred_language")}
                        >
                          {languages?.map((item, index) => (
                            <Select key={index} value={item?.value}>
                              {item?.label}
                            </Select>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                      <MHInput
                        type="number"
                        label={t("vacancy")}
                        placeholder={t("enter_vacancy_number")}
                        // defaultValue={1}
                        min={1}
                        max={100}
                        name="vacancy"
                        rules={[
                          {
                            required: true,
                            message: t("please_input_your_acancy"),
                          },
                        ]}
                      />
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item label={t("description")} name="description">
                        <JobDetails
                          values={description}
                          setValue={setDescription}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="button_group">
                        {/* <Button
                          type="primary"
                          className="select_date_btn"
                          onClick={() => {
                            setDates(finalizedDates);
                            setCalenderModal(true);
                          }}
                        >
                          <span>
                            {t("select_date")} ({totalDays ? totalDays : 0})
                          </span>
                        
                        </Button> */}

                        <span></span>

                        <button
                          className="btn btn-lg btn-success submit_btn"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <SmallLoading />
                          ) : id ? (
                            t("update_job_post")
                          ) : (
                            t("create_job_post")
                          )}
                          {/* {id ? t("update_job_post") : t("create_job_post")} */}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title=""
        open={modal}
        closable={false}
        centered={true}
        confirmLoading={false}
        onCancel={() => {
          setModal(false);
        }}
        footer={null}
        width={400}
      >
        <p
          style={{
            fontSize: "20px",
            fontWeight: "600",
            textAlign: "center",
            color: "#000",
            padding: "0px 60px",
          }}
        >
          {id
            ? t("your_job_offer_has_been_updated")
            : t("your_job_offer_has_been_posted")}
        </p>
        <button
          className="btn btn-lg btn-success"
          style={{
            backgroundColor: "#58c8c8",
            border: "none",
            color: "#fff",
            width: "100%",
            height: "50px",
            fontSize: "18px",
            fontWeight: "600",
            borderRadius: "0px 15px 0px 15px",
          }}
          onClick={() => {
            setModal(false);
            navigate("/job-requests");
          }}
        >
          {t("ok")}
        </button>
      </Modal>
      <Modal
        title=""
        open={false}
        closable={true}
        confirmLoading={false}
        onCancel={() => {
          setCalenderModal(false);
        }}
        footer={null}
        width={"90%"}
      >
        <BookForJob
          id={id}
          setSelectedDateTime={setSelectedDateTime}
          setCalenderModal={setCalenderModal}
        />
      </Modal>
      <Modal
        title=""
        open={calenderModal}
        closable={true}
        confirmLoading={false}
        onCancel={() => {
          setCalenderModal(false);
        }}
        footer={null}
        width={"80%"}
      >
        <MHCalender
          dates={dates}
          setDates={setDates}
          onSubmitHandler={() => {
            setFinalizedDates(dates);
            setCalenderModal(false);
          }}
        />
      </Modal>
      <Modal
        title="Date & Time"
        open={prevModal}
        onOk={() => {
          setPrevModal(false);
        }}
        onCancel={() => {
          setPrevModal(false);
        }}
      >
        {prevDate?.map((item, index) => (
          <>
            <Row
              key={index}
              style={{
                background: "#FAFAFA",
                padding: "10px",
                margin: "10px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Col style={{}}>
                <div>
                  <FcCalendar
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  {item?.startDate} - {item?.endDate}
                </div>
                <div
                  style={{
                    color: "#58c8c8",
                    fontWeight: "bold",
                  }}
                >
                  {countDates(item?.startDate, item?.endDate)} Days
                </div>
                <div>
                  {item?.startTime} - {item?.endTime}
                </div>
              </Col>
            </Row>
          </>
        ))}
      </Modal>
    </div>
  );
};

export default CreateJobPost;

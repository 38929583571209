import { authSlice, callTypes } from "./authSlicer";
import * as requestFromServer from "./authRequest";
const { actions } = authSlice;

export const login = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return dispatch(actions.login(payload));
};

export const fullFillUser = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return dispatch(actions.setUser(payload));
};

export const setLoader = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return dispatch(actions.setLoading(payload));
};

export const logout = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return dispatch(actions.logout());
};

// Function to get notification unread count
export const getNotificationUnreadCount = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .getNotificationUnreadCount()
    .then((response) => {
      if (response.data.status === "success") {
        dispatch(
          actions.fetchNotificationUnreadCount({
            unreadCount: response.data?.total,
          })
        );
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// Function to get notification list
export const getNotificationList = (page) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .getNotificationList(page)
    .then((response) => {
      if (response.data.status === "success") {
        const data = response.data.notifications;
        dispatch(
          actions.fetchNotificationList({ notifications: data, page: page })
        );
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// user profile
export const getUserProfile = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .getUserInfo(id)
    .then((response) => {
      if (response?.data?.status === "success") {
        const data = response?.data?.details;
        dispatch(actions.setUser(data));
        // dispatch(fetchPosts("active", 20, 1));
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CiDiscount1 } from "react-icons/ci";
import { IoDiamondOutline } from "react-icons/io5";

import "./PricingCard.css";
import axios from "axios";
import { token } from "../../../utils/authentication";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { getUserInfo } from "../../../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import API from "../../../utils/API";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const PricingCard = ({ fetchSubscriptionData, setLoading }) => {
  const jwt_decode = jwtTokenDecode();
  const id = jwt_decode?._id;
  const navigate = useNavigate();
  const [plan, setPlan] = useState();
  const [getSingleEmployeeDetails, setSingleEmployeeDetails] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClick = async () => {
    await onCardAdd();
    await fetchSubscriptionData();
  };

  const onCardAdd = async () => {
    setLoading(true);
    setLoader(true);
    await API.post(`/users/validated`, {
      email: getUserInfo().email,
    })
      .then(async (response) => {
        if (response.data?.details === "CARD_VALID") {
          try {
            await API.post(`/subscription/create`, {
              plan: plan?._id,
              currency: plan?.currency,
              yearlyCharge: plan?.hasDiscount
                ? plan?.discountType == "percent"
                  ? plan?.yearlyCharge -
                    Number(plan?.yearlyCharge * (plan?.discount / 100))
                  : plan?.yearlyCharge - plan?.discount
                : plan?.yearlyCharge,
              monthlyCharge: plan?.hasDiscount
                ? plan?.discountType == "percent"
                  ? plan?.monthlyCharge -
                    Number(plan?.monthlyCharge * (plan?.discount / 100))
                  : plan?.monthlyCharge - plan?.discount
                : plan?.monthlyCharge,
              startDate: moment().format("YYYY-MM-DD"),
              endDate: moment().add(1, "year").format("YYYY-MM-DD"),
              paymentDate: moment().format("YYYY-MM-DD"),
              nextPaymentDate: moment().add(1, "month").format("YYYY-MM-DD"),
            }).then((res) => {
              // setSessionId(res.data.details.id);
              if (res.data?.status == "success") {
                toast.success("Subscription Done!");
                if (getUserInfo()?.role == "CLIENT") {
                  navigate("/client-dashboard");
                } else {
                  navigate("/candidate/home");
                }
              } else {
                toast.error(res?.message);
              }
            });
          } catch (error) {
            console.error("Error fetching session ID:", error);
          }
        } else {
          console.log("++++****");
          try {
            await API.post(`/users/get-session`, {
              email: getUserInfo().email,
              returnUrl: window.location.href,
            }).then((res) => {
              // setSessionId(res.data.details.id);
              navigate("/card-verify?sessionId=" + res.data.details.id);
            });
          } catch (error) {
            console.error("Error fetching session ID:", error);
          }
        }
      })
      .catch(async (error) => {
        console.error("There was an error fetching the position:", error);
        if (error.response?.data?.message === "Verify Your Card, Please!") {
          try {
            await API.post(`/users/get-session`, {
              email: getUserInfo().email,
              returnUrl: window.location.href,
            }).then((res) => {
              // setSessionId(res.data.details.id);
              navigate("/card-verify?sessionId=" + res.data.details.id);
            });
          } catch (error) {
            console.error("Error fetching session ID:", error);
          }
        }
      });
    setLoading(false);
    setLoader(false);
  };

  //Fetch refer person list for dropdown
  const fetchPlanData = useCallback(async () => {
    try {
      setCardLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/subscription-plan`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setPlan(res?.data?.result[0]);
      setCardLoading(false);
    } catch (error) {
      setCardLoading(false);
    }
  }, []);

  //Fetch refer person list for dropdown
  const fetchSingleEmployeeData = useCallback(async () => {
    try {
      setCardLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setSingleEmployeeDetails(res?.data?.details);
      setCardLoading(false);
    } catch (error) {
      setCardLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSingleEmployeeData();
  }, [id]);

  useEffect(() => {
    fetchPlanData();
  }, []);

  const getEmployeeCurrency = (key) => {
    switch (key) {
      case "United Kingdom":
        return "£";

      case "United Arab Emirates":
        return "AED";

      case "Italy":
        return "€";

      default:
        return "";
    }
  };

  const getEmployeePrice = (key, plan) => {
    switch (key) {
      case "United Kingdom":
        return plan?.monthlyChargeinPound;

      case "United Arab Emirates":
        return plan?.monthlyChargeinDerham;

      case "Italy":
        return plan?.monthlyChargeinEuro;

      default:
        return "";
    }
  };

  return (
    <div className="pricing-card">
      <span
        className="icon"
        style={{
          background: "#58C8C8",
          color: "#fff",
          padding: "10px 12px",
          borderRadius: "14px",
        }}
      >
        <IoDiamondOutline />
      </span>
      <h2 className="mt-3">Plagit +</h2>
      <p>Unleash the Power of Your Business with Pro Plan.</p>
      <div className="price">
        <span>
          {getEmployeeCurrency(getUserInfo()?.countryName)}{" "}
          {plan?.hasDiscount
            ? plan?.discountType == "percent"
              ? Math.round(
                  getEmployeePrice(getUserInfo()?.countryName, plan) -
                    Number(
                      getEmployeePrice(getUserInfo()?.countryName, plan) *
                        (plan?.discount / 100)
                    )
                )
              : getEmployeePrice(getUserInfo()?.countryName, plan) -
                plan?.discount
            : getEmployeePrice(getUserInfo()?.countryName, plan)}
        </span>
        <small>per month</small>
      </div>
      <div className="discount">
        {plan?.hasDiscount && (
          <h1
            style={{
              fontSize: "16px",
              color: "#797878",
              textDecoration: "line-through",
            }}
          >
            {getEmployeePrice(getUserInfo()?.countryName, plan)}
            {getEmployeeCurrency(getUserInfo()?.countryName)}
          </h1>
        )}

        {plan?.hasDiscount && (
          <h1
            style={{
              fontSize: "16px",
              background: "#58C8C8",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "25px",
              width: "150px",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <CiDiscount1
              style={{
                fontSize: "20px",
                // marginRight: "5px",
              }}
            />
            {plan?.discount}
            {plan?.discountType == "percent" ? "%" : "$"} Discount
          </h1>
        )}
      </div>
      <ul className="features">
        {plan?.keys?.map((item, i) => (
          <li key={i}>✔️ {item}</li>
        ))}
      </ul>
      <button onClick={handleClick} disabled={loader} className="get-started">
        Get Started{" "}
        {loader && (
          <Spin
            indicator={<LoadingOutlined spin />}
            size="default"
            style={{ color: "#fff" }}
          />
        )}
      </button>
    </div>
  );
};

export default PricingCard;

import { Col, Modal, Pagination, Row } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { MdWork } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { addShortHandler } from "../../../api/shortList";
import { token } from "../../../utils/authentication";
import {
  getUserCurrencyByCountry,
  getUserId,
} from "../../../utils/commonFunctions";
import { responseNotification } from "../../../utils/notifcation";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/loadar/Loader";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import * as Action from "../_redux/clientActions";
import { useDispatch } from "react-redux";
import { Colors } from "../../../constants/Colors";
import { useUser } from "../../../contexts/user-context";
import EmployeeCard from "../../../components/Cards/EmployeeCard/EmployeeCard";
import BackButton from "../../../components/Breadcrumbs/BackButton";
import JobRequestSkeleton from "./JobRequestSkeleton";
import EmployeeChat from "./EmployeeChat";

const JobRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useUser();
  const params = new URLSearchParams(window.location.search);
  const job_id = params.get("job_id");
  const [jobId, setJobId] = useState();
  const [addShortListData, setAddShortListData] = useState({});
  const [shortListLoading, setShortListLoading] = useState(false);
  const [shouldRefetchShortList, setShouldRefetchShortList] = useState(false);
  const [error, setError] = useState(undefined);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const { jobList, totalPages, myJobsList, loading, totalCount } = useSelector(
    ({ client }) => ({
      jobList: client?.job.entities ?? [],
      totalPages: client?.job.totalPages,
      totalCount: client?.job.totalCount,
      loading: client?.job.loading,
      myJobsList: client?.job.myjob ?? [],
    }),
    shallowEqual
  );
  const addShortListOnclikHandler = (employeeId, uniform) => {
    console.log(`employeeId`, employeeId);
    console.log(`uniform`, uniform);
    const shortListReceivedField = { employeeId };
    if (shortListReceivedField) {
      setAddShortListData(shortListReceivedField);

      setShortListLoading(true);

      addShortHandler(shortListReceivedField)
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 201) {
            setError(undefined);
            setShortListLoading(false);
            setShouldRefetchShortList(!shouldRefetchShortList);

            responseNotification("Short list created successfully!", "success");
            console.log(`res`, res);
            // setLoadingShortList(true);
            // window.location.reload();
            navigate(`/short-list`);
          } else if (res?.statusCode === 400) {
            setError(res?.errors?.[0].msg);
            setShortListLoading(false);
          } else if (res?.statusCode === 500) {
            setError(res?.message);
            setShortListLoading(false);
          }
        });
    }
  };
  useEffect(() => {
    // setLoading(true);
    const params = {
      userType: "CLIENT",
      clientId: getUserId(),
      page: page,
    };
    if (job_id) {
      params["job_id"] = job_id;
    }
    dispatch(Action.getJobList(params, limit));
  }, [page, limit, dispatch, job_id]);

  const handleDelete = (id) => {
    // setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/job/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        // setLoading(false);
        const data = jobList.filter((item) => item._id !== id);
        // setJobList(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  return (
    <div
      className="container mt-2"
      style={{
        minHeight: "80vh",
      }}
    >
      <BackButton
        pageIcon={
          "/assets/frontend/images/Dashboardimages/dashboard 1/jobRequest.png"
        }
        pageTitle={t("job_posts")}
        hasButton={true}
        buttonUrl={"/create-job"}
        buttonTitle={"Create New Job"}
        additionDataList={[jobList.length]}
      />

      {loading ? (
        // <Loader />
        <JobRequestSkeleton count={6} />
      ) : (
        <div className="allJobRequest pb-4">
          {jobList.map((item, index) => {
            return (
              <div className="singleJobRequest my-3" key={index}>
                <div
                  className="singleJobRequestTitle"
                  style={{
                    border: "1px solid #A6A6A6",
                    backgroundColor: item?.accordian ? "#dfe0e5" : "#fff",
                    padding: "15px",
                    borderRadius: "16px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setJobId(item?._id);
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "-10px",
                    }}
                  >
                    <span
                      className=""
                      style={{
                        backgroundColor: "#58c8c8",
                        border: "none",
                        padding: "10px",
                        color: "#fff",
                        width: "100%",
                        height: "50px",
                        fontSize: "12px",
                        fontWeight: "600",
                        borderRadius: "0px 15px 0px 15px",
                      }}
                    >
                      {t("view_job_details")}
                    </span>
                  </div>
                  <div className="">
                    <div className="ml-4">
                      <Row gutter={[16, 2]}>
                        <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                          <Row gutter={[16, 2]}>
                            <Col
                              xs={20}
                              sm={20}
                              md={8}
                              lg={8}
                              xl={8}
                              className="my-auto"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    item?.positionId?.logo
                                      ? process.env
                                          .REACT_APP_ASSETS_FRONTEND_URL +
                                        "/" +
                                        item?.positionId?.logo
                                      : "/assets/Employee.jpg"
                                  }
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    marginRight: "10px",
                                  }}
                                  alt="chef"
                                />
                                <div className="dashboard2Rate">
                                  <div className="fw-bolder">
                                    {item?.positionId?.name}{" "}
                                    {item?.users?.length > 0 ? (
                                      <span
                                        style={{
                                          backgroundColor: "#58c8c8",
                                          color: "#fff",
                                          padding: "2px 5px",
                                          fontSize: "14px",
                                          borderRadius: "5px",
                                          marginLeft: "4px",
                                        }}
                                      >
                                        {item?.users?.length}
                                      </span>
                                    ) : null}
                                  </div>
                                  <img
                                    src="assets/frontend/images/Dashboardimages/dashboard2/rate.png"
                                    className="img-fluid"
                                    alt="rate"
                                  />
                                  <span className="Dashboard2Perhour me-2">
                                    {" "}
                                    {t("rate")} :
                                  </span>
                                  <span className="Dashboard2Perhour">
                                    {item?.minRatePerHour} -{" "}
                                    {item?.maxRatePerHour}{" "}
                                    {getUserCurrencyByCountry(item?.country)}{" "}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col
                              xs={20}
                              sm={20}
                              md={6}
                              lg={6}
                              xl={6}
                              className="my-auto"
                            >
                              <div className="dashboard2Rate d-flex gap-1 align-items-center">
                                <MdWork
                                  style={{ fontSize: "20px", color: "#58c8c8" }}
                                />
                                <span className="Dashboard2Perhour">
                                  {" "}
                                  {t("exp_:")}
                                </span>
                                <span className="Dashboard2Perhour">
                                  {item?.minExperience} - {item?.maxExperience}{" "}
                                  {t("years")}
                                </span>
                              </div>
                            </Col>
                            <Col
                              xs={20}
                              sm={20}
                              md={7}
                              lg={7}
                              xl={7}
                              className="my-auto"
                            >
                              <div className="dashboard2Rate d-flex gap-1 align-items-center">
                                <FcCalendar
                                  style={{
                                    fontSize: "20px",
                                    color: "#58c8c8",
                                  }}
                                />
                                {/* <span className="Dashboard2Perhour"> Avaiable :</span> */}
                                <span className="Dashboard2Perhour">
                                  {moment(item?.publishedDate).format(
                                    "DD/MM/YYYY"
                                  ) +
                                    " - " +
                                    moment(item?.endDate).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            </Col>
                            <Col
                              xs={20}
                              sm={20}
                              md={3}
                              lg={3}
                              xl={3}
                              className="my-auto"
                            >
                              {/* status chips  */}
                              <div className="dashboard2Rate">
                                <div className="d-flex align-items-center">
                                  <span className="Dashboard2Perhour d-sm-block d-md-none">
                                    {" "}
                                    {t("status")} :
                                  </span>
                                  <span className="Dashboard2Perhour">
                                    {item?.status === "IN_QUEUE" ? (
                                      <span
                                        style={{
                                          backgroundColor: "#FFEB3B",
                                          color: "#000",
                                          padding: "4px 2px",
                                          fontSize: "12px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {t("in_queue")}
                                      </span>
                                    ) : item?.status === "PUBLISHED" ? (
                                      <span
                                        style={{
                                          backgroundColor: "#4CAF50",
                                          color: "#fff",
                                          padding: "4px 2px",
                                          fontSize: "12px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {t("published")}
                                      </span>
                                    ) : item?.status === "CLOSED" ? (
                                      <span
                                        style={{
                                          backgroundColor: "#f44336",
                                          color: "#fff",
                                          padding: "4px 2px",
                                          fontSize: "12px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {t("closed")}
                                      </span>
                                    ) : null}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                          <div className="d-flex justify-content-end pt-4 gap-3">
                            <div
                              onClick={() => {
                                navigate(`/edit-job/${item?._id}`);
                              }}
                            >
                              <AiFillEdit size={22} />
                            </div>
                            <div
                              onClick={() => {
                                Modal.confirm({
                                  title: t("warning"),
                                  okText: t("yes"),
                                  cancelText: t("no"),
                                  onOk: () => {
                                    handleDelete(item._id);
                                  },
                                  okButtonProps: {
                                    style: {
                                      backgroundColor: Colors.primary,
                                    },
                                  },
                                  onCancel: () => {},
                                  content: t(
                                    "are_you_sure_you_want_to_delete_this_job?"
                                  ),
                                  style: {
                                    marginTop: "20vh",
                                  },
                                });
                              }}
                            >
                              <AiFillDelete color="red" size={20} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                {item?._id == jobId ? (
                  <div className="row my-2">
                    {item?.users?.length === 0 ? (
                      <div className="mx-auto">
                        <h5>{t("no_request_found_for_this_job")}</h5>
                      </div>
                    ) : (
                      item?.users?.map((user, idx) => {
                        return (
                          <>
                            <div className="col-md-3">
                              <EmployeeCard
                                userName={user?.name}
                                userId={`/profile/${user?._id}`}
                                chatId={`/client-chating`}
                                setIsSidebarOpen={setIsSidebarOpen}
                                user={user}
                                setSelectedEmployee={setSelectedEmployee}
                                profileImage={
                                  user?.profilePicture &&
                                  user?.profilePicture != "undefined"
                                    ? user?.profilePicture.includes(
                                        "lh3.googleusercontent.com"
                                      )
                                      ? user?.profilePicture
                                      : `${process.env.REACT_APP_ASSETs_BASE_URL}/${user?.profilePicture}`
                                    : "/assets/Employee.jpg"
                                }
                                experience={
                                  user?.employeeExperience
                                    ? user.employeeExperience + " years"
                                    : "N/A"
                                }
                                rate={user?.hourlyRate}
                                currency={getUserCurrencyByCountry(
                                  item?.country
                                )}
                                jobType={t("per_hour")}
                                bookNow={`/booknow/${user?._id}?positionId=${user?.positionId}`}
                                addShortListOnclikHandler={
                                  addShortListOnclikHandler
                                }
                                bookMarkUserID={user?._id}
                                bookMarkUniform={user?.hasUniform}
                                country={user?.countryName}
                              />
                            </div>
                          </>
                        );
                      })
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}

          {jobList?.length > 0 && (
            <section className="mt-5 mb-4 d-flex mx-auto justify-content-center">
              <Pagination
                current={page}
                total={totalCount}
                pageSize={limit}
                size="default"
                align="center"
                showSizeChanger={false}
                onChange={(page, limit) => {
                  setPage(page);
                }}
              />
            </section>
          )}

          <EmployeeChat
            setOpen={setIsSidebarOpen}
            open={isSidebarOpen}
            userInfo={selectedEmployee}
            setUserInfo={setSelectedEmployee}
          />
        </div>
      )}
    </div>
  );
};

export default JobRequest;

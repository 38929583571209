import React, { useEffect, useState, useRef } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { getProfilePicture } from "../../Shared/getProfilePictureUrl";
import { useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";
import { getFormateName, getUserId } from "../../../utils/commonFunctions";
import { notification } from "antd";
import { token } from "../../../utils/authentication";
import axios from "axios";
import moment from "moment";
import { useUser } from "../../../contexts/user-context";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { Skeleton } from "antd";
import { useSocket } from "../../../hooks/useSocket";
const SocialConversation = ({
  selectedUser,
  messages,
  setSocketData,
  setPreviousMessages,
  page,
  setMessages,
  socketData,
  previousMessages,
  setPage,
  setSelectedUser,
  onMessageSent,
}) => {
  const [searchParams] = useSearchParams();
  const { sendMessage, getMessages } = useSocket();
  const conversationId = searchParams.get("conversationId");
  const { currentUser } = useUser();
  const jwtDecode = jwtTokenDecode();
  const [loader, setLoader] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isTop, setIsTop] = useState(false);
  const userId = getUserId();
  const [newMessage, setNewMessage] = useState("");
  const [sendMessageLoader, setSendMessageLoader] = useState(false);
  const limit = 16;
  const { members, latestMessage, isAdmin } = selectedUser;
  const member = members[0];
  const profilePicture = getProfilePicture(
    member?.profilePicture,
    member?.role
  );
  const name = member?.name || member?.restaurantName;
  const [socket, setSocket] = useState();

  // console.log("conversationId~~", conversationId);

  useEffect(() => {
    if (getMessages) {
      setSocketData(getMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMessages]);
  // Scroll to the latest message when messages update
  useEffect(() => {
    if (isTop) {
      chatContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isTop]);
  // get messages by conversation id
  useEffect(() => {
    if (conversationId) {
      setPreviousMessages(false);
      setLoader(true);

      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/messages?conversationId=${conversationId}&limit=${limit}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          const descendingMessages = res.data.messages.reverse();
          const uniqueMessages = descendingMessages.filter(
            (msg) => !messages.some((existing) => existing._id === msg._id)
          );
          const modifiedMessages = uniqueMessages.map((message) => {
            return {
              myMessage: userId === message.senderDetails?._id,
              senderName:
                message.senderDetails?.role == "CLIENT"
                  ? message.senderDetails?.restaurantName
                  : message.senderDetails?.name,
              senderProfilePicture: message.senderDetails?.profilePicture,
              senderRole: message.senderDetails?.role,
              message: message.text,
              _id: message?._id,
              time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
            };
          });
          setPreviousMessages(res.data.next);
          setMessages((prev) => [...modifiedMessages, ...prev]);
        });
    }
  }, [conversationId, page, userId]);

  useEffect(() => {
    if (socketData && socketData?.conversationId === conversationId) {
      console.log("Socket data", socketData);

      const myMessage = socketData.senderId === getUserId();
      const senderName = myMessage
        ? currentUser?.name ||
          jwtDecode?.name ||
          currentUser?.restaurantName ||
          jwtDecode?.restaurantName
        : name;
      const senderRole = myMessage
        ? currentUser?.role || jwtDecode?.role
        : member?.role;
      const senderProfilePicture = myMessage
        ? currentUser?.profilePicture || jwtDecode?.profilePicture
        : member?.profilePicture;
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          myMessage: myMessage,
          senderName,
          senderProfilePicture,
          senderRole,
          message: socketData.text,
          _id: socketData?._id,
          time: moment(socketData.dateTime).format("DD MMM YY @ h:mm A"),
        },
      ]);
      setSocketData({});
    }
  }, [conversationId, socketData]);

  const sendMessage1 = (e) => {
    e.preventDefault();
    if (!newMessage || !newMessage?.trim()) {
      notification.error({
        message: "Message cannot be empty",
      });
      return;
    }

    // console.log("newMessage", newMessage);
    setSendMessageLoader(true);
    const payload = {
      senderId: userId,
      conversationId: conversationId,
      dateTime: new Date(),
      text: newMessage,
    };
    // const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    //   withCredentials: false,
    //   transports: ["websocket"],
    // });
    // if (socket) socket.emit("message", payload);
    sendMessage(payload);
    setSendMessageLoader(false);
    setNewMessage("");
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/messages/create`, payload, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        onMessageSent();
      })
      .catch((err) => {
        setSendMessageLoader(false);
      });
  };

  // console.log("messages~~", messages);
  return (
    selectedUser && (
      <div className="chat__box__single">
        <div className="chat-top fs-14 px-2 py-2 border-bottom">
          <div className="d-flex align-items-center">
            <div className="me-2">
              <img
                src={profilePicture}
                alt="profile"
                className="profile_thumb rounded-50"
              />
            </div>
            <div>
              <div>{isAdmin ? "Plagit Support" : getFormateName(name)} :</div>
              <div style={{ fontSize: "12px", color: "#999" }}>Active now</div>
            </div>
            <div
              className="ms-auto d-flex align-items-center"
              style={{ gap: "6px" }}
            >
              {/* <FaPlus /> */}
              <FaTimes
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedUser(null);
                }}
              />
            </div>
          </div>
        </div>
        <div className="message-bottom " ref={chatContainerRef}>
          <div className="messages ">
            {/* <div className="fs-14 day d-flex align-items-center justify-content-center align-items-center my-2">
              <div className="day_border"></div>
              <div className="px-2">THURSDAY</div>
              <div className="day_border"></div>
            </div> */}
            {previousMessages && (
              <div
                className="load-more"
                onClick={() => {
                  setPage((prev) => prev + 1);
                  setIsTop(true);
                }}
              >
                Load More
              </div>
            )}
            {loader
              ? Array(5)
                  .fill()
                  .map(
                    (
                      _,
                      index // Example: 5 skeleton loaders
                    ) => (
                      <div
                        key={index}
                        className="d-flex align-items-start my-3 px-2"
                      >
                        <div className="me-2">
                          <Skeleton.Avatar active size="large" />
                        </div>
                        <div className="w-full">
                          <Skeleton.Input
                            active
                            size="small"
                            className="mb-2"
                          />
                          <Skeleton.Input active size="small" />
                          <Skeleton.Input
                            active
                            size="small"
                            className="mt-2"
                          />
                        </div>
                      </div>
                    )
                  )
              : messages?.length > 0
              ? messages.map((message, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex align-items-start my-3 px-2"
                    >
                      <div className="me-2">
                        <img
                          src={getProfilePicture(
                            message?.senderProfilePicture,
                            message?.senderRole
                          )}
                          alt=""
                          className="profile_thumb rounded-50"
                        />
                      </div>
                      <div>
                        <div
                          className="d-flex fs-14 align-items-center mb-2"
                          style={{ gap: "8px" }}
                        >
                          <div>
                            {message?.senderRole == "ADMIN"
                              ? "Plagit Support"
                              : getFormateName(message?.senderName)}{" "}
                            :
                          </div>
                          {console.log({ message })}
                          <div style={{ fontSize: "12px", color: "#999" }}>
                            {message?.time}
                          </div>
                        </div>
                        <div style={{ fontSize: "12px", color: "#000" }}>
                          {message?.message}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
            <div ref={messagesEndRef} />
          </div>
          <div className="border-top">
            <form
              onSubmit={sendMessage1}
              className="message_send d-flex px-2 mt-3"
              style={{ gap: "6px" }}
            >
              <div className="flex-grow-1">
                <textarea
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Write message..."
                  value={newMessage}
                ></textarea>
              </div>
              <div>
                <button type="submit" className="send-message">
                  <i className="fa-solid fa-paper-plane"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default SocialConversation;

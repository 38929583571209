import React, { useState } from "react";
import { Modal, Button } from "antd";
import { Colors } from "../constants/Colors";

const useCustomConfirm = () => {
  const [visible, setVisible] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null);

  const confirm = (message) => {
    return new Promise((resolve) => {
      setVisible(true);
      setResolvePromise(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (resolvePromise) resolvePromise(true);
    setVisible(false);
  };

  const handleCancel = () => {
    if (resolvePromise) resolvePromise(false);
    setVisible(false);
  };

  const CustomConfirmDialog = ({ message }) => (
    <Modal
      title="Confirmation"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <p>{message || "Are you sure you want to proceed?"}</p>
      <div style={{ textAlign: "right" }}>
        <Button style={{ marginRight: 10 }} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: Colors.primary,
          }}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );

  return { confirm, CustomConfirmDialog };
};

export default useCustomConfirm;

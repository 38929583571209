export const calculateTotalDays = (requestDates) => {
  // Check if the array is empty
  if (requestDates?.length === 0) {
    return 0;
  }
  let totalDays = 0;
  requestDates?.forEach((date) => {
    const { startDate, endDate } = date;
    // Convert the startDate and endDate to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);
    // Calculate the difference in time between the two dates
    const timeDifference = end.getTime() - start.getTime();
    // Calculate the difference in days
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    // If the startDate and endDate are the same, it's 1 day. Otherwise, add the dayDifference.
    totalDays += startDate === endDate ? 1 : dayDifference + 1; // Adding 1 to include both start and end dates
  });
  return totalDays;
};

// console.log(`selectedEmployee`, selectedEmployee);

export const calculateEstimatedAmount = (data) => {
  let totalHours = 0;
  const hourlyRate = data.employeeDetails.hourlyRate;

  data.requestDate.forEach((item) => {
    // Calculate number of days
    const startDay = new Date(item.startDate);
    const endDay = new Date(item.endDate);
    const numDays = (endDay - startDay) / (1000 * 60 * 60 * 24) + 1;
    // Calculate time interval in hours
    const [startHour, startPeriod] = item.startTime.split(" ")[0].split(":");
    const [endHour, endPeriod] = item.endTime.split(" ")[0].split(":");
    let startIn24Hour =
      startPeriod === "PM" ? parseInt(startHour) + 12 : parseInt(startHour);
    let endIn24Hour =
      endPeriod === "PM" ? parseInt(endHour) + 12 : parseInt(endHour);

    const timeInterval = endIn24Hour - startIn24Hour;
    const totalHoursForThisRequest = numDays * timeInterval;

    totalHours += totalHoursForThisRequest;
  });

  // Multiply by hourly rate to get total amount
  const totalAmount = totalHours * hourlyRate;

  return totalAmount;
};

import { Card, Skeleton } from 'antd'
import React from 'react';
import './job_post_style.scss';

export default function JobPostSkeleton() {
    return (
        <Card className="job_post_card">
            <div className="d-flex">
                <Skeleton.Avatar loading={true} active size={50} circle />
                <Skeleton
                    loading={true}
                    active
                    paragraph={{
                        rows: 1,
                        width: ["100%"],
                    }}
                />
            </div>

            <div className="skeleton_content_body">
                <Skeleton
                    loading={true}
                    active
                    paragraph={{
                        rows: 3,
                        width: ["100%"],
                    }}
                />
            </div>
            {/* <div className="w-100 mt-3">
                <Skeleton.Image className="w-100" style={{ height: 220 }} />
            </div>  */}
        </Card>
    )
}
 

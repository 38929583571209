import React from 'react';
import './plagit_plus_style.scss';
import CouponCarousel from './CouponCarousel';
import EventCardCarousel from './EventCardCarousel';

export default function PlagitPlusPage() {
    const coupons = [
        {
            imageUrl: "https://i.ibb.co.com/7JB6qjF/image-2.png",
            restaurantLogo: "https://i.ibb.co.com/fqFs1HR/image-4.png",
            restaurantName: "Food King Restaurant",
            discount: "20% Off",
            offerDetails: "When total purchase over $100",
            expirationDate: "Aug 20, 2024",
        },
        {
            imageUrl: "https://i.ibb.co.com/ZL8XrVk/image.png",
            restaurantLogo: "https://i.ibb.co.com/F3wR0gG/image-5.png",
            restaurantName: "Mita Food Restaurant",
            discount: "Buy 1 Get 1 Free",
            offerDetails: "Only on Selected Foods",
            expirationDate: "Aug 30, 2024",
        },
        {
            imageUrl: "https://i.ibb.co.com/mTbtZW9/image-1.png",
            restaurantLogo: "https://i.ibb.co.com/F3wR0gG/image-5.png",
            restaurantName: "Foodgasm Restaurant",
            discount: "Save $20",
            offerDetails: "When total purchase over $100",
            expirationDate: "Sep 15, 2024",
        },
        {
            imageUrl: "https://i.ibb.co.com/mTbtZW9/image-1.png",
            restaurantLogo: "https://i.ibb.co.com/fqFs1HR/image-4.png",
            restaurantName: "King’s Cafe",
            discount: "15% Off",
            offerDetails: "When total purchase over $50",
            expirationDate: "Sep 01, 2024",
        },
        {
            imageUrl: "https://i.ibb.co.com/mTbtZW9/image-1.png",
            restaurantLogo: "https://i.ibb.co.com/vcpPx6f/image-3.png",
            restaurantName: "Queen’s Diner",
            discount: "25% Off",
            offerDetails: "On all items",
            expirationDate: "Sep 15, 2024",
        },
    ];

    const events = [
        {
            title: "Environmental Awareness Event",
            location: {
                address: "4540 American River Dr",
                latitude: "38.5689",
                longitude: "-121.3771",
            },
            date: "15 Sep, 2024",
            time: {
                start: "11:00 PM",
                end: "2:00 AM",
            },
            entryFee: "Free Entry",
            imageUrl: "https://i.ibb.co/4pr9Y3K/image-9.png",
            detailsUrl: "/events/environmental-awareness",
        },
        {
            title: "Sustainability Summit",
            location: {
                address: "123 Green Valley Rd",
                latitude: "37.7749",
                longitude: "-122.4194",
            },
            date: "20 Oct, 2024",
            time: {
                start: "10:00 AM",
                end: "4:00 PM",
            },
            entryFee: "$10",
            imageUrl: "https://i.ibb.co/gj7nyrR/image-8.png",
            detailsUrl: "/events/sustainability-summit",
        },
        {
            title: "Clean Energy Expo",
            location: {
                address: "789 Solar Park Ave",
                latitude: "34.0522",
                longitude: "-118.2437",
            },
            date: "01 Nov, 2024",
            time: {
                start: "9:00 AM",
                end: "5:00 PM",
            },
            entryFee: "Free Entry",
            imageUrl: "https://i.ibb.co/tDTHyJD/image-7.png",
            detailsUrl: "/events/clean-energy-expo",
        },
        {
            title: "Wildlife Conservation Talk",
            location: {
                address: "456 Forest Way",
                latitude: "40.7128",
                longitude: "-74.0060",
            },
            date: "05 Dec, 2024",
            time: {
                start: "6:00 PM",
                end: "8:00 PM",
            },
            entryFee: "$5",
            imageUrl: "https://i.ibb.co/JnWjSQX/image-6.png",
            detailsUrl: "/events/wildlife-conservation",
        },
        {
            title: "Urban Gardening Workshop",
            location: {
                address: "789 City Farm Ln",
                latitude: "51.5074",
                longitude: "-0.1278",
            },
            date: "12 Jan, 2025",
            time: {
                start: "1:00 PM",
                end: "4:00 PM",
            },
            entryFee: "Free Entry",
            imageUrl: "https://i.ibb.co/4pr9Y3K/image-9.png",
            detailsUrl: "/events/urban-gardening",
        },
    ];

    return (
        <div className="plagit_coupons_main_wrapper container">
            {/* <div className='row'>
                <div className="col-md-12">
                    <h1 className="titel_name">
                        All Coupons
                    </h1>
                </div>

                {coupons.map((item, index) => (
                    <div className="col-md-4 mb-4" key={index}>
                        <div className='ticket-shape-bg'>
                            <div className='card-body coupon_body ticket-shape'>
                                <div className="coupon-card">
                                    <img
                                        src={item.imageUrl}
                                        alt="Coupon"
                                        className="coupon-image"
                                    />

                                    <div className="coupon-content">
                                        <div className="restaurant-name">
                                            <img
                                                src={item.restaurantLogo}
                                                alt="Restaurant Logo"
                                                className="restaurant-logo"
                                            />
                                            {item.restaurantName}
                                        </div>
                                        <div className="coupon-discount">{item.discount}</div>
                                        <div className="coupon-condition"> {item.offerDetails} </div>

                                        <div className='card_footer'>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="coupon-expiry">
                                                    Before <br />
                                                    {item.expirationDate}
                                                </div>
                                                <button className="coupon-button">Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div> */}

            <div className='row mt-4'>
                <div className="col-md-12 position-relative">
                    <h1 className="titel_name position-absolute ps-3">
                        All Coupons
                    </h1>

                    <CouponCarousel coupons={coupons} />
                </div>
            </div>

            <div className='row my-3'>
                <div className="col-md-12">
                    <img
                        src="https://i.ibb.co.com/mvZWWG2/add.png"
                        className="w-100 img-fluid ps-3 pe-3"
                        alt="ad"
                    />
                </div>
            </div>


            {/* <div className='row'> 
                {events.map((item, i) => (
                    <div className='col-md-4 mb-4' key={i}>
                        <div className="event-card">
                            <img
                                src={item.imageUrl}
                                alt="Event"
                                className="event-image"
                            />

                            <div className="event-content">
                                <h2 className="event_title">{item.title}</h2>

                                <div className="d-flex align-items-center mb-2">
                                    <i className="fas fa-map-marker-alt event-icon"></i>
                                    <span className="event-location">{item.location.address}</span>
                                </div>

                                <div className="d-flex align-items-center mb-2">
                                    <div>
                                        <i className="fas fa-calendar-alt event-icon"></i>
                                        <span className="event-date-time">{item.date}</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-clock event-icon"></i>
                                        <span className="event-date-time">From {item.time.start}</span>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-between'>
                                    <h3 className="event-free-entry">{item.entryFee}</h3>
                                    <button className="event-button">Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))} 
            </div> */}


            <div className='row mt-4 mb-5'>
                <div className="col-md-12 position-relative">
                    <h1 className="titel_name position-absolute ps-3">
                        All Events
                    </h1>
                    <EventCardCarousel events={events} />
                </div>
            </div>
        </div>
    )
} 
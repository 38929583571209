import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  FcCalendar,
  FcConferenceCall,
  FcMoneyTransfer,
  FcReadingEbook,
  FcBusinessman,
} from "react-icons/fc";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { ContextApi } from "../../../../hooks/ContextApi";
import { token } from "../../../../utils/authentication";
import { jwtTokenDecode } from "../../../../utils/jwtDecode";
import PreLoader from "../../../loadar/PreLoader";
import job from "./job.svg";
import "./jobDetails.css";
import language from "./language.svg";
import skills from "./skills.svg";
import { Colors } from "../../../../constants/Colors";
import { useQuery } from "react-query";
import { fetchJobDetails } from "../../../../services/jobServices";
import JobNotFoundCard from "../../../JobNotFoundCard/JobNotFoundCard";
import { FaMapMarkerAlt, FaGlobe } from "react-icons/fa";
import SmallLoading from "../../../Shared/SmallLoading";
import { useTranslation } from "react-i18next";
import { getUserCurrencyByCountry } from "../../../../utils/commonFunctions";
import { fetchSingleEmployeeData } from "../../../../services/userServices";
import JobDetailsSkeleton from "./JobDetailsSkeleton";
import { isRtlCountry } from "../../../../utils/country";

const JobDetails = () => {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [jobDates, setJobDates] = useState(null);
  const [jobUsers, setJobUsers] = useState(null);
  const { currencyType } = useContext(ContextApi);
  const navigate = useNavigate();
  const decodedata = jwtTokenDecode(token());
  const [unavailableDatesFromAPI, setUnavailableDatesFromAPI] = useState([]);
  const [bookedDates, setBookedDates] = useState([]);
  const [pendingDates, setPendingDates] = useState([]);
  const [hasOverlap, setHasOverlap] = useState(false);
  const [matchedUser, setMatchedUser] = useState(false);
  const [interestLoading, setInterestLoading] = useState(false);

  const { t } = useTranslation();
  // fetch jon details
  const {
    data: jobData,
    isLoading,
    refetch: jobDataRefetch,
  } = useQuery(["jobDetails", id], () => fetchJobDetails(id), {
    enabled: !!id,
  });

  const { data: currentUser = {}, isLoading: employeeLoading } = useQuery(
    ["singleEmployee", decodedata?._id],
    () => fetchSingleEmployeeData(decodedata?._id), // Fetching function
    {
      enabled: !!decodedata?._id,
    }
  );

  // assign variable
  useEffect(() => {
    if (jobData) {
      setJobDetails(jobData);
      setJobDates(jobData.dates);
      setJobUsers(jobData.users);
    }
  }, [jobData]);

  const fetchData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/working-history/${decodedata._id}`;
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      // console.log(data)
      const unavailableDatesFromAPI = data.result.unavailableDates.map(
        (dateRange) => ({
          from: new Date(dateRange.startDate),
          to: new Date(dateRange.endDate),
        })
      );
      setUnavailableDatesFromAPI(unavailableDatesFromAPI);
      const bookedDatesFromAPI = data.result.bookedDates.map((dateRange) => ({
        from: new Date(dateRange.startDate),
        to: new Date(dateRange.endDate),
      }));
      setBookedDates(bookedDatesFromAPI);
      const pendingDatesFromAPI = data.result.pendingDates.map((dateRange) => ({
        from: new Date(dateRange.startDate),
        to: new Date(dateRange.endDate),
      }));
      setPendingDates(pendingDatesFromAPI);
    }
  };

  useEffect(() => {
    fetchData();
  }, [decodedata._id]);

  const goBack = () => {
    navigate(-1);
  };

  const checkDateRange = (startDate, endDate) => {
    const startDateWithTime = moment(startDate, "YYYY-MM-DD HH:mm");
    const endDateWithTime = moment(endDate, "YYYY-MM-DD HH:mm");
    const dateRange = {
      from: startDateWithTime.toDate(),
      to: endDateWithTime.toDate(),
    };

    const isBooked = bookedDates.some((bookedRange) =>
      isRangeOverlapping(bookedRange, dateRange)
    );

    const isPending = pendingDates.some((pendingRange) =>
      isRangeOverlapping(pendingRange, dateRange)
    );

    const isUnavailable = unavailableDatesFromAPI.some((unavailableRange) =>
      isRangeOverlapping(unavailableRange, dateRange)
    );

    return isBooked || isPending || isUnavailable;
  };

  const isRangeOverlapping = (a, b) => {
    return a.from <= b.to && b.from <= a.to;
  };

  useEffect(() => {
    const checkOverlapForJobDates = () => {
      const anyOverlap = jobDates?.some((jobDate) =>
        checkDateRange(jobDate.startDate, jobDate.endDate)
      );
      if (anyOverlap) {
        setHasOverlap(true);
      }
    };
    checkOverlapForJobDates();
  }, [checkDateRange, jobDates]);

  // console.log("hasoveerloaded", hasOverlap);
  jobDates?.forEach((jobDate) => {
    const overlapInfo = checkDateRange(jobDate.startDate, jobDate.endDate);

    if (
      overlapInfo.isBooked ||
      overlapInfo.isPending ||
      overlapInfo.isUnavailable
    ) {
      console.log(
        `Overlap detected for job date: ${jobDate.startDate} to ${
          jobDate.endDate
        }. Overlapping with: ${overlapInfo.isBooked ? "booked dates, " : ""}${
          overlapInfo.isPending ? "pending dates, " : ""
        }${overlapInfo.isUnavailable ? "unavailable dates" : ""}`
      );
    } else {
      console.log(
        `No overlap detected for job date: ${jobDate.startDate} to ${jobDate.endDate}`
      );
    }
  });

  const handleMouseEnter = () => {
    // Display the tooltip or take any other action when the cursor enters the button
    if (hasOverlap) {
      toast.error("Cannot select when overlaps are detected");
    }
  };

  const handleInterestedClick = async () => {
    try {
      setInterestLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/job/add-interest`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
          body: JSON.stringify({
            id: jobDetails._id,
            employeeId: decodedata._id,
          }),
        }
      );

      if (response.ok) {
        // The interest was added successfully
        toast.success("Interest added successfully");
        await jobDataRefetch();
        // You can perform additional actions if needed
        // window.location.reload();
      } else {
        const errorData = await response.json();

        if (
          response.status === 401 &&
          errorData.message.includes(
            "You are not available or already hired in this dates"
          )
        ) {
          // Handle the specific error where the user is not available or already hired in the specified dates
          // Display a custom error message or take appropriate actions
          toast.error(errorData.message);
        } else {
          // Handle other errors
          console.error("Failed to add interest:", errorData.message);
          toast.error("Failed to add interest");
        }
      }
    } catch (error) {
      console.error("Error adding interest:", error);
      toast.error("Error adding interest");
    } finally {
      setInterestLoading(false);
    }
  };

  // console.log("jobsUsers", jobUsers);
  // console.log("decodedata", decodedata);
  // console.log("jobDetails", jobDetails);
  const isMatch = jobUsers?.some((jobUser) => jobUser._id === decodedata._id);
  useEffect(() => {
    if (isMatch) {
      setMatchedUser(true);
    }
  }, [jobUsers, isMatch]);

  const clientProfilePicture =
    jobDetails?.clientId?.profilePicture &&
    jobDetails?.clientId?.profilePicture !== "undefined"
      ? jobDetails?.clientId?.profilePicture?.includes(
          "lh3.googleusercontent.com"
        )
        ? jobDetails?.clientId?.profilePicture
        : `${process.env.REACT_APP_ASSETs_BASE_URL}/${jobDetails?.clientId?.profilePicture}`
      : jobDetails?.clientId?.role === "CLIENT"
      ? "/assets/Client.jpg"
      : "/assets/Employee.jpg";

  // console.log("jobDetails?.country ~~", jobDetails?.country);
  // console.log("currentUser ~~", currentUser?.countryName);

  if (isLoading || employeeLoading) {
    return <JobDetailsSkeleton />;
  }

  return (
    <div
      className="container mt-4 mx-auto "
      style={{
        borderRadius: "10px",
        borderColor: "#FFFFFF",
        border: "1px solid #FFFFFF",
        minHeight: "70vh",
        marginBottom: "20px",
      }}
    >
      <div className="row mt-4">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "10px",
            height: "50px",
            // color: "#58c8c8",
          }}
        >
          <IoChevronBackCircleOutline
            size={25}
            backgroundColor="#A6A6A6"
            color="#58c8c8"
            onClick={goBack}
            cursor={"pointer"}
          />
          <img style={{ marginLeft: "10px" }} src={job} alt="job" />

          <span style={{ marginLeft: "3px", fontWeight: "600" }}>
            Job Post Details
          </span>
        </div>
      </div>
      {jobDetails && Object.keys(jobDetails).length >= 0 ? (
        <Row>
          <Col md={4}>
            {/* Left side - Job details */}
            <Card
              style={{
                backgroundColor: "#FFFFFF",
                padding: "20px",
                borderRadius: "10px",
                // borderColor: "#A6A6A6",
                // border: "1px solid #A6A6A6",
              }}
            >
              <Card.Img
                variant="top"
                src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${jobDetails.positionId.logo}`}
                style={{
                  objectFit: "contain",
                  height: "85px",
                  width: "85px",
                  margin: "0 auto",
                  marginTop: "20px",
                }}
              />
              <Card.Body>
                <Card.Title className="text-center fw-bold">
                  {jobDetails.positionId.name}
                </Card.Title>

                <hr
                  style={{
                    backgroundColor: "#58c8c8",
                    height: "2px",
                    border: "none",
                  }}
                />
                {/* <div className="d-flex align-items-center">
                  <strong
                    style={{
                      color: Colors.primary,
                    }}
                  >
                    Restaurant Name :{" "}
                  </strong>
                  <span className="ms-1">
                    {jobDetails.clientId.restaurantName}
                  </span>
                </div> */}
                {/* <div className="d-flex align-items-center fw-semibold">
                  <strong>Country:</strong>{" "}
                  <span>{jobDetails.clientId.countryName}</span>
                </div> */}
                {/* <div className="d-flex  fw-semibold">
                  <EnvironmentOutlined />{" "}
                  <span>{jobDetails.clientId.restaurantAddress}</span>
                </div> */}
                <Card.Text>Requirements</Card.Text>
                <Card.Text>
                  <FcMoneyTransfer
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  {t("salary_range")} :{" "}
                  {isRtlCountry(jobDetails?.country) ? (
                    <span>
                      {getUserCurrencyByCountry(jobDetails?.country)}
                      {jobDetails.maxRatePerHour}-{" "}
                      {getUserCurrencyByCountry(jobDetails?.country)}{" "}
                      {jobDetails.minRatePerHour}
                    </span>
                  ) : (
                    <span>
                      {jobDetails.minRatePerHour}{" "}
                      {getUserCurrencyByCountry(jobDetails?.country)}-{" "}
                      {jobDetails.maxRatePerHour}
                      {getUserCurrencyByCountry(jobDetails?.country)}
                    </span>
                  )}
                  {/* {t("salary_range")} : {jobDetails.minRatePerHour}
                  {getUserCurrencyByCountry(jobDetails?.country)} -
                  {jobDetails.maxRatePerHour}
                  {getUserCurrencyByCountry(jobDetails?.country)} */}
                </Card.Text>
                <Card.Text>
                  <FcReadingEbook
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  Experience: {jobDetails.minExperience} {" - "}
                  {jobDetails.maxExperience} years
                </Card.Text>
                <Card.Text>
                  <FcConferenceCall
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  Nationality: {displayNationalities(jobDetails.nationalities)}
                </Card.Text>
                <Card.Text>
                  <img
                    src={language}
                    style={{
                      marginRight: "5px",
                      height: "15px",
                      width: "15px",
                    }}
                    alt="languages"
                  />
                  Languages: {jobDetails.languages.join(", ")}
                </Card.Text>
                <div>
                  {matchedUser ? (
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#1DBF73",
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        width: "100%",
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginTop: "10px",
                        cursor: "not-allowed",
                      }}
                    >
                      Already Applied
                    </Button>
                  ) : (
                    decodedata.role === "EMPLOYEE" &&
                    currentUser?.positionName?.toLowerCase() ===
                      jobDetails.positionId.name?.toLowerCase() &&
                    currentUser?.countryName === jobDetails?.country && (
                      <Button
                        type="primary"
                        style={{
                          backgroundColor:
                            hasOverlap ||
                            currentUser.positionName !==
                              jobDetails.positionId.name
                              ? "red"
                              : "#58c8c8",
                          color:
                            hasOverlap ||
                            currentUser.positionName !==
                              jobDetails.positionId.name
                              ? "black"
                              : "#FFFFFF",
                          border: "none",
                          borderRadius: "10px",
                          width: "100%",
                          // height: "40px",
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginTop: "10px",
                          pointerEvents:
                            hasOverlap ||
                            currentUser.positionName !==
                              jobDetails.positionId.name
                              ? "none"
                              : "auto",
                        }}
                        disabled={
                          hasOverlap ||
                          currentUser.positionName !==
                            jobDetails.positionId.name
                        }
                        onMouseDownCapture={handleMouseEnter}
                        onClick={handleInterestedClick}
                      >
                        {interestLoading ? <SmallLoading /> : "I'm Interested"}
                      </Button>
                    )
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={8}>
            {/* Right side - Additional details */}
            {/* client details card */}

            <div
              style={{ borderRadius: "10px" }}
              className="  w-100 bg-white rounded-4 p-3  mb-3"
            >
              <h5 className="fw-bold  d-flex align-items-center">
                <FcBusinessman
                  style={{
                    marginRight: "5px",
                  }}
                />
                {t("client_information")}
              </h5>
              <div className="d-flex mt-4  align-items-start">
                <a
                  href={
                    decodedata?.role === "CLIENT"
                      ? `/profile/${jobDetails?.clientId?._id}`
                      : `/candidate/profile/${jobDetails?.clientId?._id}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={clientProfilePicture}
                    className="card-img-top client-profile-image"
                    alt="Restaurant Profile"
                    style={{
                      height: "120px",
                      width: "180px",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                </a>

                <div className="ms-3">
                  <h5 className="h5 job-client-header">
                    <a
                      href={
                        decodedata?.role === "CLIENT"
                          ? `/profile/${jobDetails?.clientId?._id}`
                          : `/candidate/profile/${jobDetails?.clientId?._id}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none job-client-name text-dark"
                    >
                      {jobDetails?.clientId?.restaurantName}
                    </a>
                  </h5>
                  <p className="m-0">
                    <FaGlobe
                      style={{
                        color: Colors.primary,
                        fontSize: "16px",
                      }}
                      className="me-2"
                    />
                    <strong>Country:</strong>{" "}
                    {jobDetails?.clientId?.countryName}
                  </p>

                  <p className="m-0 pt-2">
                    <FaMapMarkerAlt
                      style={{
                        color: Colors.primary,
                        fontSize: "16px",
                      }}
                      className="me-2"
                    />
                    <strong>Address:</strong>{" "}
                    {jobDetails?.clientId?.restaurantAddress}
                  </p>
                </div>
              </div>
            </div>

            <div className="dates detailsCard">
              <h5 className="fw-bold d-flex align-items-center">
                <FcCalendar
                  style={{
                    marginRight: "5px",
                  }}
                />
                Time period
              </h5>
              {jobDetails.dates.map((date, index) => {
                // const startDateWithTime = moment(
                //   `${date.startDate} ${date.startTime}`,
                //   "YYYY-MM-DD HH:mm"
                // );
                // const endDateWithTime = moment(
                //   `${date.endDate} ${date.endTime}`,
                //   "YYYY-MM-DD HH:mm"
                // );
                // const daysDifference = Math.max(
                //   endDateWithTime.diff(startDateWithTime, "days"),
                //   1
                // );

                return (
                  <li
                    className=""
                    key={index}
                    style={{
                      marginLeft: "16px",
                    }}
                  >
                    {moment(date?.startDate).format("LL")} {" - "}
                    {moment(date?.endDate).format("LL")}
                  </li>
                );
              })}
              {/* {jobDetails.dates.map((date, index) => {
                const startDateWithTime = moment(
                  `${date.startDate} ${date.startTime}`,
                  "YYYY-MM-DD HH:mm"
                );
                const endDateWithTime = moment(
                  `${date.endDate} ${date.endTime}`,
                  "YYYY-MM-DD HH:mm"
                );
                const daysDifference = Math.max(
                  endDateWithTime.diff(startDateWithTime, "days"),
                  1
                );

                return (
                  // <div key={index}>
                  <li
                    className=""
                    key={index}
                    style={{
                      marginLeft: "16px",
                    }}
                  >
                    {startDateWithTime.format("DD MMM YY")} -{" "}
                    {endDateWithTime.format("dddd, DD MMM YY")} (
                    {daysDifference} Day{daysDifference > 1 ? "s" : ""}){" "}
                    {startDateWithTime.format("h:mm A")} -{" "}
                    {endDateWithTime.format("h:mm A")}
                  </li>
                  // </div>
                );
              })} */}
            </div>

            <div className="skills detailsCard">
              <h5 className="fw-bold d-flex align-items-center">
                <img src={skills} style={{ marginRight: "5px" }} alt="skills" />
                Skills Requirements
              </h5>
              <ul>
                {jobDetails.skills.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </div>

            <div className="skills detailsCard">
              <h5 className="fw-bold d-flex align-items-center">
                <img src={skills} style={{ marginRight: "5px" }} alt="skills" />
                Comment
              </h5>
              <div
                dangerouslySetInnerHTML={{ __html: jobDetails.description }}
              />
            </div>
          </Col>
        </Row>
      ) : isLoading ? (
        <JobDetailsSkeleton />
      ) : (
        jobData && Object.keys(jobData).length === 0 && <JobNotFoundCard />
      )}
    </div>
  );
};

const displayNationalities = (nationalities) => {
  if (nationalities && nationalities.length > 0) {
    return nationalities.join(", ");
  }
  return "";
};

export default JobDetails;

import { Button, Drawer, notification } from "antd";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./employee-chat.scss";
import moment from "moment";
import { getUserId, getUserInfo } from "../../../utils/commonFunctions";
import axios from "axios";
import { token } from "../../../utils/authentication";
import { io } from "socket.io-client";
import Loader from "../../../components/loadar/Loader";
import { useSocket } from "../../../hooks/useSocket";

const GetMsgType = ({ message, myRole }) => {
  if (!message.myMessage) {
    return (
      <li>
        <div className="message-data">
          <span className="message-data-time">{message.time}</span>
        </div>
        <div className="message my-message">{message.message}</div>
      </li>
    );
  } else {
    return (
      <li className="clearfix">
        <div className="message-data align-right">
          <span className="message-data-time">{message.time}</span>{" "}
        </div>
        <div className="message other-message float-right">
          {message.message}
        </div>
      </li>
    );
  }
};

const EmployeeChat = ({ setOpen, open, userInfo, setUserInfo }) => {
  const userId = getUserId();
  const [newMessage, setNewMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [previousMessages, setPreviousMessages] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [socketData, setSocketData] = useState({});
  const [loader, setLoader] = useState(false); // [loader , setLoader] = useState(false) is added  to manage the loader
  const [sendMessageLoader, setSendMessageLoader] = useState(false);
  const { getMessages, sendMessage } = useSocket();
  // console.log("userInfo~~", userInfo);

  const onClose = () => {
    setUserInfo();
    setOpen(false);
    setMessages([]);
    setNewMessage("");
    setConversationId("");
    setLimit(10);
    setPage(1);
    setPreviousMessages(false);
    setScrollToBottom(false);
    setScrollToTop(false);
  };

  const sendMessageHandler = () => {
    if (!newMessage) {
      notification.error({
        message: "Message cannot be empty",
      });
      return;
    }
    setSendMessageLoader(true);
    const payload = {
      senderId: userId,
      conversationId: conversationId,
      dateTime: new Date(),
      text: newMessage,
    };
    // const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    //   withCredentials: false,
    //   transports: ["websocket", "polling", "flashsocket"],
    // });
    // socket.emit("message", payload);
    sendMessage(payload);
    setSendMessageLoader(false);
    setNewMessage("");
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/messages/create`, payload, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        setSendMessageLoader(false);
      });
  };

  // get conversation id

  useEffect(() => {
    if (open) {
      const senderId = userId;
      const receiverId = userInfo?._id;
      const payload = {
        senderId: senderId,
        receiverId: receiverId,
        isAdmin: false,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setConversationId(res.data.details._id);
        });
    }
  }, [userInfo, limit, page, open, userId]);

  // get messages by conversation id
  useEffect(() => {
    if (conversationId && open) {
      setPreviousMessages(false);
      setLoader(true);
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/messages?conversationId=${conversationId}&limit=${limit}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          const descendingMessages = res.data.messages.reverse();
          const modifiedMessages = descendingMessages.map((message) => {
            return {
              myMessage: userId === message.senderDetails?.senderId,
              senderName: message.senderDetails?.name,
              message: message.text,
              time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
            };
          });
          setPreviousMessages(res.data.next);
          setMessages([...modifiedMessages, ...messages]);
          if (page > 1) {
            setScrollToTop(true);
            setScrollToBottom(false);
          } else {
            setScrollToTop(false);
            setScrollToBottom(true);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, limit, page, open]);

  useEffect(() => {
    // const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    //   withCredentials: false,
    //   transports: ["websocket", "polling", "flashsocket"],
    // });
    // socket.on("connect", () => {
    //   console.log("Socket connected");
    // });
    // socket.on("message", (data) => {
    //   setSocketData(data);
    // });
    // socket.on("error", (error) => {
    //   console.error("Socket error:", error);
    // });
    // socket.on("disconnect", () => {
    //   console.log("Socket disconnected");
    // });
    // return () => {
    //   console.log("Socket disconnecting");
    //   socket.disconnect();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (getMessages) {
      setSocketData(getMessages);
    }
  }, [getMessages]);

  useEffect(() => {
    if (socketData && socketData?.conversationId === conversationId) {
      console.log("Socket data", socketData);
      setMessages([
        ...messages,
        {
          myMessage: socketData.senderId === getUserId(),
          senderName: socketData.senderDetails?.name,
          message: socketData.text,
          time: moment(socketData.dateTime).format("DD MMM YY @ h:mm A"),
        },
      ]);
      setScrollToBottom(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  useLayoutEffect(() => {
    if (scrollToBottom) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, scrollToBottom]);

  useLayoutEffect(() => {
    if (scrollToTop) {
      chatContainerRef.current.scrollTop = 0;
    }
  }, [scrollToTop]);

  const { employeeDetails, profilePicture } = userInfo || {};
  const googleBaseUrl = "lh3.googleusercontent.com";
  const assetBaseUrl = process.env.REACT_APP_ASSETs_BASE_URL || "";
  const defaultProfilePicture = "/assets/Employee.jpg";

  const profileImage = (() => {
    const picture = employeeDetails?.profilePicture || profilePicture;
    if (picture?.includes(googleBaseUrl)) {
      return picture;
    }
    return picture ? `${assetBaseUrl}/${picture}` : defaultProfilePicture;
  })();

  return (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            fontWeight: "bold",
            alignItems: "center",
          }}
        >
          <img
            src={profileImage}
            // src={
            //   userInfo?.employeeDetails?.profilePicture?.includes(
            //     "lh3.googleusercontent.com"
            //   ) ||
            //   userInfo?.profilePicture?.includes("lh3.googleusercontent.com")
            //     ? userInfo?.employeeDetails?.profilePicture ||
            //       userInfo?.profilePicture
            //     : process.env.REACT_APP_ASSETs_BASE_URL +
            //         "/" +
            //         (userInfo?.employeeDetails?.profilePicture ||
            //           userInfo?.profilePicture) || "/assets/Employee.jpg"
            // }
            className="Dashboard2-card-img-top"
            alt="profilePicture"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
          {userInfo?.employeeDetails?.name || userInfo?.name}
        </div>
      }
      placement={"right"}
      width={500}
      onClose={onClose}
      open={open}
      style={{
        padding: "0px",
        position: "relative",
      }}
    >
      <div className="chat">
        {previousMessages && (
          <div
            style={{
              textAlign: "center",
              color: "blue",
              cursor: previousMessages ? "pointer" : "not-allowed",
              marginBottom: "10px",
              backgroundColor: "lightgray",
            }}
            onClick={() => {
              if (previousMessages) {
                setLimit(limit);
                setPage(page + 1);
              }
            }}
          >
            Load More{" "}
          </div>
        )}

        <ul className="chat-history" ref={chatContainerRef}>
          {loader && messages?.length === 0 ? (
            <Loader />
          ) : (
            messages.map((message, index) => (
              <GetMsgType message={message} myId={1} key={index} />
            ))
          )}
        </ul>
        <div class="chat-message clearfix">
          <textarea
            name="message-to-send"
            id="message-to-send"
            placeholder="Type your message"
            rows="3"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          ></textarea>
          <Button
            loading={sendMessageLoader}
            onClick={sendMessageHandler}
            style={{
              color: "#58c8c8",
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default EmployeeChat;

import React, { useEffect } from "react";
import "./HistorySection.css";
import { Tabs, Badge } from "antd";
import EmployeeBookedHistory from "../../../../pages/Candidate/BookHistory/EmployeeBookedHistory";
import EmployeeHiredHistory from "../../../../pages/Candidate/HiredHistory/EmployeeHiredHistory";
import { Colors } from "../../../../constants/Colors";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import * as Action from "../../../../pages/Candidate/_redux/candidateActions";
import { useDispatch } from "react-redux";
export default function HistorySection() {
  const dispatch = useDispatch();

  const { totalBookedCount } = useSelector(
    ({ candidate }) => ({
      totalBookedCount: candidate?.bookedHistory.totalCount ?? 0,
    }),
    shallowEqual
  );
  const { totalCount } = useSelector(
    ({ candidate }) => ({
      totalCount: candidate?.hiredHistory.totalCount ?? 0,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(Action.getHiredList());
    dispatch(Action.getBookedList());
  }, [dispatch]);
  const onChange = (key) => {
    // console.log(key);
  };

  const tabComponent = [
    {
      key: 1,
      label: "Booked History",
      children: <EmployeeBookedHistory />,
      badgeCount: totalBookedCount,
    },
    {
      key: 2,
      label: "Hired History",
      children: <EmployeeHiredHistory />,
      badgeCount: totalCount,
    },
  ];
  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-md-12 history_tabs_wrapper">
          <Tabs
            tabPosition="left"
            onChange={onChange}
            // type="card"
            items={tabComponent.map((item, i) => {
              const id = String(i + 1);
              return {
                label: (
                  <Badge count={item.badgeCount} offset={[10, 0]}>
                    <span style={{ color: Colors.primary }}>{item.label}</span>
                  </Badge>
                ),
                key: id,
                children: item.children,
              };
            })}
          />
        </div>
      </div>
    </div>
  );
}

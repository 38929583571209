export const getCurrencySymbol = (country) => {
  switch (country) {
    case "United States of America":
      return "$";
    case "United Kingdom":
      return "£";
    case "UK":
      return "£";
    case "United Arab Emirates":
      return "د.إ";
    case "Italy":
      return "€";
    case "European Union":
      return "€";
    default:
      return "$";
  }
};

const rtlCountries = [
  "saudi arabia",
  "united arab emirates",
  "qatar",
  "bahrain",
  "oman",
  "kuwait",
  "jordan",
  "iraq",
  "syria",
  "lebanon",
  "yemen",
  "egypt",
  "libya",
  "sudan",
  "algeria",
  "morocco",
  "tunisia",
  "iran",
  "pakistan",
  "israel",
  "afghanistan",
];

export function isRtlCountry(country) {
  if (!country) {
    return false;
  }
  return rtlCountries.includes(country.toLowerCase());
}

import React from "react";
import { FiEdit3 } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";

export default function EditCardHeader({ title, isEdit, setIsEdit, children }) {
  const { t } = useTranslation();

  return (
    <div className="card custom_card_plagit mb-4">
      <div className="card-header">
        <h5 className="d-flex"> {title} </h5>
        {isEdit ? (
          <button className="close_btn" onClick={() => setIsEdit(false)}>
            <IoCloseSharp />
            {t("close")}
          </button>
        ) : (
          <button className="edit_btn" onClick={() => setIsEdit(true)}>
            <FiEdit3 />
            {t("edit")}
          </button>
        )}
      </div>

      <div className="card-body">{children}</div>
    </div>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import PostFeedCardMulti from "./SocialFeed/PostFeedCardMulti";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Actions from "../../../pages/Client/_redux/clientActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { CreatePostCard } from "./CreatePostCard";
import SkeletonPostCard from "../../Cards/SocialFeeds/SkeletonPostCard";
import CreatePost from "./../../Cards/JobPost/CreatePost";
import { useUser } from "../../../contexts/user-context";

export default function SocialFeeds() {
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");
  const jwtDecode = jwtTokenDecode();
  const { currentUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [edit, setEdit] = useState(true);
  const [selectedPost, setSelectedPost] = useState({});
  const { user } = useSelector((state) => state.auth);

  const {
    postList,
    myPostList,
    totalPostPages,
    postLoading,
    totalMyPostPages,
  } = useSelector(
    ({ client }) => ({
      postLoading: client?.listLoading,
      postList: client?.postData.entities ?? [],
      totalPostPages: client?.postData.totalPages,
      myPostList: client?.myPostData?.entities ?? [],
      totalMyPostPages: client.myPostData?.totalPages,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  // Fetch posts using Redux
  const fetchPostListData = useCallback(() => {
    dispatch(Actions.fetchPosts(type, pageSize, currentPage, jwtDecode?._id));
  }, [currentPage, dispatch, jwtDecode?._id, pageSize, type]);

  useEffect(() => {
    fetchPostListData();
  }, [currentPage, type, fetchPostListData]);

  useEffect(() => {
    if (currentPage <= totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [currentPage, totalPages]);

  // assign pages
  useEffect(() => {
    if (type === "mypost") {
      setTotalPages(totalMyPostPages);
    } else {
      setTotalPages(totalPostPages);
    }
  }, [type, totalMyPostPages, totalPostPages]);

  // assign posts
  useEffect(() => {
    if (type === "mypost") {
      setPosts(myPostList);
    } else {
      setPosts(postList);
    }
  }, [type, myPostList, postList]);

  // for tracking current page
  useEffect(() => {
    let temp = Math.ceil(posts.length / pageSize);

    if (type === "mypost") {
      temp = Math.ceil(myPostList.length / pageSize);
      setCurrentPage(temp);
    } else {
      temp = Math.ceil(postList.length / pageSize);
      setCurrentPage(temp);
    }
  }, [myPostList.length, pageSize, postList.length, posts.length, type]);

  // Function to handle post updates
  const handleUpdatePost = async (updatedPost) => {
    try {
      // Update the local state with the updated post
      setPosts(
        posts.map((post) => (post._id === updatedPost._id ? updatedPost : post))
      );
    } catch (error) {
      console.error("Failed to update post:", error);
    }
  };

  // Function to handle post updates
  const handleDeletePost = async (post) => {
    // console.log("post~~", post);
    // try {
    //   // Update the local state with the updated post
    //   const allpost = [...posts];
    //   const data = allpost.filter((item) => item?._id != post?._id);
    //   console.log({ data });
    //   setPosts(data);
    // } catch (error) {
    //   console.error("Failed to update post:", error);
    // }
  };

  //   const fetchPostListData = async () => {
  //     setLoading(true);
  //     let endpoint;

  //     // Use appropriate endpoint based on type
  //     if (type === "mypost") {
  //       endpoint = `${process.env.REACT_APP_API_BASE_URL}/social-feed?user=${jwtDecode?._id}&limit=${pageSize}&page=${currentPage}`;
  //     } else {
  //       endpoint = `${process.env.REACT_APP_API_BASE_URL}/social-feed?active=true&limit=${pageSize}&page=${currentPage}`;
  //     }

  //     try {
  //       const res = await axios.get(endpoint, {
  //         headers: {
  //           Authorization: `Bearer ${token()}`,
  //         },
  //       });

  //       if (res.data?.status === "success") {
  //         const newPosts = res.data?.socialFeeds?.posts || [];

  //         // If we're on the first page, reset the posts list
  //         if (currentPage === 1) {
  //           setPosts(newPosts);
  //         } else {
  //           // Append new posts to the existing list and filter duplicates
  //           setPosts((prev) => {
  //             const allPosts = [...prev, ...newPosts];
  //             const uniquePosts = Array.from(
  //               new Set(allPosts.map((post) => post._id))
  //             ).map((id) => allPosts.find((post) => post._id === id));
  //             return uniquePosts;
  //           });
  //         }

  //         // Handle whether there are more posts to load
  //         if (newPosts.length < pageSize) {
  //           setHasMore(false); // No more posts to load
  //         } else {
  //           setHasMore(true); // More posts to load
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch posts:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  // Fetch more posts when scrolled

  const fetchMorePosts = () => {
    const temp = Math.ceil(posts.length / pageSize);
    if (currentPage < totalPages) {
      if (posts.length > 0 && pageSize > 0 && temp !== currentPage) {
        setCurrentPage(temp + 1);
      } else {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    } else {
      setHasMore(false);
    }
  };

  // console.log("currentPage~~", currentPage);

  const [isTabSticky, setIsTabSticky] = useState(false);

  useEffect(() => {
    const handleScrollPage = () => {
      if (window.innerWidth >= 995 && window.scrollY > 150) {
        setIsTabSticky(true);
      } else {
        setIsTabSticky(false);
      }
    };

    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, []);

  return (
    <div className="container-fluid mx-auto social_feed_container">
      <div className="row">
        <div className="col-12 col-lg-9 mx-auto p-3 p-lg-4">
          <div className="row position-relative">
            <div className="col-lg-7 order-lg-1 order-2">
              <InfiniteScroll
                dataLength={posts.length ?? 0}
                next={fetchMorePosts}
                hasMore={hasMore}
                loader={postLoading ? <SkeletonPostCard /> : null}
                endMessage={
                  <p style={{ textAlign: "center" }}>You have seen all post.</p>
                }
                scrollThreshold={0.9}
              >
                {posts.map((item, i) => (
                  <PostFeedCardMulti
                    key={i}
                    userName={
                      item?.user?.role === "CLIENT"
                        ? item?.user?.restaurantName
                        : item?.user?.name
                    }
                    userImage={
                      item?.user?.profilePicture &&
                      item?.user?.profilePicture !== "undefined"
                        ? item?.user?.profilePicture?.includes(
                            "lh3.googleusercontent.com"
                          )
                          ? item?.user?.profilePicture
                          : `${process.env.REACT_APP_ASSETs_BASE_URL}/${item?.user?.profilePicture}`
                        : item?.user?.role === "CLIENT"
                        ? "/assets/Client.jpg"
                        : item?.user === user?._id
                        ? currentUser?.profilePicture?.includes(
                            "lh3.googleusercontent.com"
                          )
                          ? currentUser?.profilePicture
                          : `${process.env.REACT_APP_ASSETs_BASE_URL}/${currentUser?.profilePicture}`
                        : "/assets/Employee.jpg"
                    }
                    postTime={item?.createdAt}
                    content={item?.content}
                    postContentImg={item?.media}
                    post={item}
                    handleUpdatePost={handleUpdatePost}
                    setEdit={setEdit}
                    setSelectedPost={setSelectedPost}
                    setCurrentPage={setCurrentPage}
                    handleDeletePosts={handleDeletePost}
                  />
                ))}

                {postLoading && (
                  <>
                    <SkeletonPostCard />
                    <SkeletonPostCard />
                    <SkeletonPostCard />
                  </>
                )}
              </InfiniteScroll>
            </div>

            <div className="col-lg-5 order-lg-2 order-1 mb-4 position-relative">
              <div className={`${isTabSticky ? "sticky_create_post" : ""}`}>
                <CreatePost
                  setCurrentPage={setCurrentPage}
                  edit={edit}
                  selectedPost={selectedPost}
                  setSelectedPost={setSelectedPost}
                  handleUpdatePost={handleUpdatePost}
                  // fetchPostListData={fetchPostListData}
                  post={posts}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

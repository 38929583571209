import API from "../../../utils/API";
import { isValidDate } from "../../../utils/date";

export const fetchShortlistRequest = (limit, page) => {
  return API.get(`/short-list/list?limit=${limit}&page=${page}`);
};

// function to fetch history data for client
export const fetchCheckInCheckOutHistories = (
  clientId,
  currentPage,
  pageSize,
  dateFilter = "",
  getName = "",
  status = ""
) => {
  return API.get(
    `/check-in-check-out-histories?clientId=${clientId}&page=${currentPage}&limit=${pageSize}` +
      `${dateFilter ? `&filterDate=${dateFilter}` : ""}` +
      `${getName ? `&employeeName=${getName}` : ""}` +
      `${status ? `&status=${status}` : ""}`
  );
};
// function to fetch history employee data
export const fetchEmployeePaymentHistoryList = (
  employeeId,
  currentPage,
  pageSize,
  status,
  dateFilter,
  getName
) => {
  return API.get(
    `/check-in-check-out-histories?employeeId=${employeeId}&page=${currentPage}&limit=${pageSize}&status=${status}` +
      `${dateFilter ? `&filterDate=${dateFilter}` : ""}` +
      `${getName ? `&employeeName=${getName}` : ""}`
  );
};
// function to fetch  client payment history data for client
export const fetchCLientPaymentHistoryList = (
  clientId,
  currentPage,
  pageSize,
  status,
  dateFilter,
  getName
) => {
  return API.get(
    `/check-in-check-out-histories?clientId=${clientId}&page=${currentPage}&limit=${pageSize}&status=${status}` +
      `${dateFilter ? `&filterDate=${dateFilter}` : ""}` +
      `${getName ? `&employeeName=${getName}` : ""}`
  );
};
// function to fetch checkin and checkout data for employee dashboard
export const fetchEmployeeCheckInCheckoutHistoryList = (
  currentPage,
  pageSize,
  dateFilter
) => {
  return API.get(
    `/check-in-check-out-histories/list?page=${currentPage}&limit=${pageSize}` +
      `${dateFilter ? `&filterDate=${dateFilter}` : ""}`
  );
};
// Function to fetch posts
export function postList(type, limit, page, userId) {
  let endpoint;
  if (type === "mypost") {
    endpoint = `/social-feed?user=${userId}&limit=${limit}&page=${page}`;
  } else {
    endpoint = `/social-feed?active=true&limit=${limit}&page=${page}`;
  }
  return API.get(endpoint);
}
// Function my employee list
export function MyEmployeeList(startDate, endDate, hiredBy) {
  let queryString = "hiredBy=" + encodeURIComponent(hiredBy);

  if (startDate && isValidDate(startDate)) {
    queryString += "&startDate=" + encodeURIComponent(startDate);
  }

  if (endDate && isValidDate(endDate)) {
    queryString += "&endDate=" + encodeURIComponent(endDate);
  }

  console.log("queryString~~", queryString);

  // Construct the endpoint
  const endpoint = `/book-history/client-employee?${queryString}`;
  return API.get(endpoint);
}

// Function to create a new post
export function createPost(content, media) {
  return API.post("/social-feed/create", {
    content,
    media,
  });
}

// Function to update an existing post
export function updatePost(postId, content, media) {
  return API.put(`/social-feed/update/${postId}`, {
    content,
    media,
  });
}

// Function to delete a post
export function deletePost(postId) {
  return API.delete(`/social-feed/${postId}`);
}

// Function to upload a file
export function uploadFile(file) {
  const formData = new FormData();
  formData.append("file", file);

  return API.post("/commons/upload-file", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function jobList(queryParams, limit) {
  const queryString = new URLSearchParams(queryParams).toString();
  return API.get(`/job?limit=${limit}&${queryString}`);
}

import { getUserCurrencyByCountry } from "./commonFunctions";
import { minutestoHours } from "./date";
import dayjs from "dayjs";

export const formattedCheckInCheckOutData = (data = {}) => {
  const checkInCheckOutHistories = data?.result.map((item) => {
    const finalCheckIn =
      item?.checkInCheckOutDetails?.clientCheckInTime ||
      item?.checkInCheckOutDetails?.checkInTime;
    const finalCheckOut =
      item?.checkInCheckOutDetails?.clientCheckOutTime ||
      item?.checkInCheckOutDetails?.checkOutTime;
    const finalBreakTime =
      item?.checkInCheckOutDetails?.clientBreakTime ||
      item?.checkInCheckOutDetails?.breakTime;

    const isEditCheckIn = item?.checkInCheckOutDetails?.clientCheckInTime
      ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ) !==
        dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      : false;
    const isEditCheckOut = item?.checkInCheckOutDetails?.clientCheckOutTime
      ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ) !==
        dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      : false;
    const isEditBreakTime = item?.checkInCheckOutDetails?.clientBreakTime
      ? item?.checkInCheckOutDetails?.breakTime !==
        item?.checkInCheckOutDetails?.clientBreakTime
      : false;

    return {
      date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
      id: item?._id,
      status: item?.status,
      hasReview: item?.hasReview,
      currentHiredEmployeeId: item?.currentHiredEmployeeId,
      country: item?.restaurantDetails?.countryName,
      restaurantName: item?.restaurantDetails?.restaurantName,
      employeeName: item?.employeeDetails?.name,
      position: item?.employeeDetails?.positionName,
      restaurantRate: `${
        item?.employeeDetails?.hourlyRate
      } ${getUserCurrencyByCountry(item?.restaurantDetails?.countryName)}/Hour`,
      customerRate: `${
        item?.employeeDetails?.contractorHourlyRate
      } ${getUserCurrencyByCountry(item?.restaurantDetails?.countryName)}/Hour`,
      checkIn: item?.checkInCheckOutDetails?.checkInTime
        ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
            .format("hh:mm:ss A")
            .toString()
        : null,
      finalCheckIn: finalCheckIn
        ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
        : null,
      isEditCheckIn,

      checkOut: item?.checkInCheckOutDetails?.checkOutTime
        ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
            .format("hh:mm:ss A")
            .toString()
        : null,
      finalCheckOut: finalCheckOut
        ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
        : null,
      isEditCheckOut,

      break: item?.checkInCheckOutDetails?.breakTime,
      finalBreakTime: finalBreakTime ? finalBreakTime : 0,
      isEditBreakTime,
      totalHours: finalCheckOut
        ? minutestoHours(
            dayjs(finalCheckOut)
              .diff(dayjs(finalCheckIn), "seconds", true)
              .toFixed(0) -
              finalBreakTime * 60
          )
        : null,
      workedHour: item?.workedHour,
      paid: item?.paid,
      tips: item?.tips || 0,
      travel_cost: item?.travel_cost || 0,
      totalAmount: ` ${item?.totalAmount} ${getUserCurrencyByCountry(
        item?.restaurantDetails?.countryName
      )}`,
      // totalAmount: finalCheckOut
      //   ? `${(
      //       (dayjs(finalCheckOut)
      //         .diff(dayjs(finalCheckIn), "seconds", true)
      //         .toFixed(0) -
      //         finalBreakTime * 60) *
      //       (item?.employeeDetails?.hourlyRate / 3600)
      //     ).toFixed(2)} ${getUserCurrencyByCountry(
      //       item?.restaurantDetails?.countryName
      //     )}`
      //   : null,
      clientComment: item?.checkInCheckOutDetails?.clientComment,
      mainItem: item,
    };
  });

  return checkInCheckOutHistories;
};

import React from "react";
import { useTranslation } from "react-i18next";
import Appstore from "./Appstore.png";
import Playstore from "./Playstore.png";
import {
  APP_STORE,
  eventTracker,
  PLAY_STORE,
} from "../../../utils/eventTracker";
import { SHA256 } from "crypto-js";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
function DownloadApp() {
  const { t } = useTranslation();
  // const jwtDecode = jwtTokenDecode();
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth?.user,
    }),
    shallowEqual
  );
  return (
    <section className="downloadApp">
      <div
        className="container"
        style={
          {
            // border:"1px solid #58c8c8",
          }
        }
      >
        <div className="row">
          <div className="col-lg-6">
            <img
              src="assets/frontend/images/indexImages/PERTICLE2.png"
              className="downloadPerticle img-fluid"
              alt="image"
            />
            <div className="appAvailableImage">
              <img
                className="img-fluid"
                src="assets/frontend/images/indexImages/lineImg.png"
                alt="image"
              />
            </div>
            <div className="h2DownloadWrapper">
              <h2>{t("home_footer_download_the_app")}</h2>
            </div>
            <div className="pDownloadWrapper">
              <p>{t("home_help_hire_great")}</p>
            </div>
            <div className="buttonDownloadWrapper d-flex justify-content-around align-items-center">
              <a
                href="https://apps.apple.com/us/app/mh/id6446052294"
                target="_blank"
                onClick={() => {
                  const hashedEmail = user?.email
                    ? SHA256(user?.email).toString()
                    : null;

                  eventTracker(
                    PLAY_STORE,
                    "App Clicked/Downloads",
                    "Click",
                    "android app",
                    {
                      email: hashedEmail,
                      name: user?.name,
                      restaurantName: user?.restaurantName,
                    }
                  );
                }}
              >
                <img
                  className="img-fluid"
                  src={Appstore}
                  style={{
                    height: "75px",
                    marginRight: "10px",
                  }}
                  alt="the"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.invain.mh"
                target="_blank"
                onClick={() => {
                  const hashedEmail = user?.email
                    ? SHA256(user?.email).toString()
                    : null;

                  eventTracker(
                    APP_STORE,
                    "App Clicked/Downloads",
                    "Click",
                    "ios app",
                    {
                      email: hashedEmail,
                      name: user?.name,
                      restaurantName: user?.restaurantName,
                    }
                  );
                }}
              >
                <img
                  className="img-fluid"
                  src={Playstore}
                  style={{
                    height: "75px",
                  }}
                  alt="the"
                />
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src="assets/frontend/images/indexImages/PERTICLE1 (2).png"
              className="downloadPerticle2 img-fluid"
              alt="image"
            />
            <div className="phoneImgWrapper">
              <img
                className="img-fluid"
                src="assets/frontend/images/indexImages/phone.png"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DownloadApp;

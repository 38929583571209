export const GTM_ID = "GTM-MSPPHC5H";

export const pageview = (url, title, fullurl) => {
  window.dataLayer.push({
    event: "pageview",
    url: url,
    pageTitle: title,
    pageUrl: fullurl,
  });
};

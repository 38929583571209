// src/hooks/useSocket.js
// import { useState, useEffect } from "react";

// export function useSocket() {
//   const [messages, setMessages] = useState([]);
//   const [notifications, setNotifications] = useState([]);

//   useEffect(() => {
//     if ("serviceWorker" in navigator) {
//       // Listen for messages from the service worker
//       navigator.serviceWorker.onmessage = (event) => {
//         const { type, data } = event.data;
//         if (type === "RECEIVED_CHAT_MESSAGE") {
//           setMessages(data);
//           // setMessages((prevMessages) => [...prevMessages, data]);
//         } else if (type === "RECEIVED_NOTIFICATION") {
//           setNotifications((prevNotifications) => [...prevNotifications, data]);
//         }
//       };
//     }
//   }, []);

//   const sendMessage = (text) => {
//     const message = { text, timestamp: Date.now() };
//     navigator.serviceWorker.controller?.postMessage({
//       type: "SEND_CHAT_MESSAGE",
//       data: message,
//     });
//     // setMessages((prevMessages) => [...prevMessages, message]);
//     setMessages(message);
//   };

//   const sendNotification = (notification) => {
//     navigator.serviceWorker.controller?.postMessage({
//       type: "SEND_NOTIFICATION",
//       data: notification,
//     });
//   };

//   return { messages, notifications, sendMessage, sendNotification };
// }

// src/hooks/useSocket.js
import { useEffect, useState } from "react";
import socket from "../utils/socket";

export function useSocket() {
  const [getMessages, setMessages] = useState();
  const [getNewMessages, setNewMessages] = useState();
  const [getNotifications, setNotifications] = useState();

  useEffect(() => {
    // Listen for incoming messages
    socket.on("message", (message) => {
      setMessages(message);
    });

    // Listen for incoming notifications
    socket.on("notification", (notification) => {
      setNotifications(notification);
    });

    // Listen for incoming msg from server
    socket.on("new_message", (msg) => {
      setNewMessages(msg);
    });

    // Clean up listeners on unmount
    return () => {
      socket.off("message");
      socket.off("notification");
      socket.off("new_message");
    };
  }, []);

  // Function to send a chat message
  const sendMessage = (text) => {
    const message = text;
    socket.emit("message", message);
  };

  // Function to send a notification
  const sendNotification = (notification) => {
    socket.emit("notification", notification);
  };

  return {
    getMessages,
    getNotifications,
    sendMessage,
    sendNotification,
    getNewMessages,
  };
}

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, Modal } from "antd";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { fromLatLng, setKey, setLocationType } from "react-geocode";
import { responseNotification } from "../../utils/notifcation";
import LocationSearch from "../frontend/profile/LocationSearch";
import "../frontend/profile/google-map.scss";
import pin from "../frontend/profile/mapMarker.png";
setKey(`AIzaSyDj8C1VcZWPlnRrHMc_2VjMLVZ3HmVxdWw`);
setLocationType("ROOFTOP");

const EmployeeGoogleLogin = ({
  position,
  setName,
  name,
  setMyLocation,
  setIsModalOpen,
  myLocation,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinate] = useState({});
  const [maploaded, setMapLoaded] = useState(false);
  const containerStyle = {
    width: "100%",
    height: "500px",
    borderRadius: "10px",
    boxShadow: "0 0 6px rgb(0 0 0 / 30%)",
  };
  const handleOk = () => {
    if (!name) {
      responseNotification("Address is required", "error");

      return;
    } else if (loading) {
      responseNotification("Please move to add a valid location", "error");
      return;
    }

    setIsModalOpen(false);
  };
  const handleDrag = (e) => {
    fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        console.log({ address });
        console.log({
          lat: parseFloat(e.latLng.lat()),
          lng: parseFloat(e.latLng.lng()),
        });
        setName(address);
        setLoading(false);
        setMyLocation({
          lat: parseFloat(e.latLng.lat()),
          lng: parseFloat(e.latLng.lng()),
        });
        setCoordinate({
          lat: parseFloat(e.latLng.lat()),
          lng: parseFloat(e.latLng.lng()),
        });
      },
      (error) => {
        setLoading(true);
        responseNotification("You select an invalid location", "error");
        setName("");
        console.error(error);
      }
    );
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setMyLocation({ lat: latitude, lng: longitude });
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  return (
    <div>
      {/* position input address and lat long  */}
      <div className=" d-flex flex-row gap-2 px-2 py-3 justify-content-evenly">
        <div className="w-100">
          <label htmlFor="positionId" className="form-label">
            {t("please_select_your_position")}
          </label>
          <div className="dropdownbuttonwrapper">
            <div className="dropdown">
              <div className="inputLogoWrapper">
                <img
                  className="img-fluid"
                  src="assets/frontend/images/registrationFormImages/clientFormPictures/selectFrom.png"
                  alt="image"
                />
              </div>
              <select
                id="positionId"
                className="form-select custom_select w-100"
                aria-label={t("please_select_position")}
                required
              >
                <option selected disabled>
                  {t("please_select_position")}
                </option>
                {position?.map((item, index) => (
                  <option key={index} value={item?._id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className=" w-100">
          <label className="block mb-1">Address</label>
          <Input
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="form input  mt-1 w-100"
            style={{
              paddingTop: "11px",
              paddingBottom: "11px",
            }}
            value={name}
          />
        </div>
      </div>

      <div className="relative">
        <LoadScript
          googleMapsApiKey={"AIzaSyDj8C1VcZWPlnRrHMc_2VjMLVZ3HmVxdWw"}
          loadingElement={<div>Loading...</div>}
          libraries={["places"]}
          style={{
            position: "relative",
          }}
        >
          {maploaded && (
            <LocationSearch
              setMyLocation={setMyLocation}
              myLocation={myLocation}
              setCoordinate={setCoordinate}
              setName={setName}
              setLoading={setLoading}
            />
          )}
          <GoogleMap
            id="marker-example"
            onLoad={() => setMapLoaded(true)}
            mapContainerStyle={containerStyle}
            zoom={15}
            onZoomChanged={() => {}}
            center={myLocation}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              gestureHandling: "greedy",
            }}
          >
            <>
              <Marker
                position={myLocation}
                animation="BOUNCE"
                icon={{
                  url: pin,
                  scaledSize: { width: 30, height: 45 },
                }}
                onDragEnd={(e) => {
                  handleDrag(e);
                }}
                draggable={true}
                title="Move Pin"
                shape="MarkerShapeCircle"
              ></Marker>
            </>
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default EmployeeGoogleLogin;

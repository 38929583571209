import ImgCrop from "antd-img-crop";
import React, { useCallback, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Form, Upload, Select } from "antd";
import axios from "axios";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { employeeRegisterHandler } from "../../../api/employee";
import { fetchPositionListForDropdownHandler } from "../../../api/position";
import { responseNotification } from "../../../utils/notifcation";
import FilteredCountryWiseValidationRules from "../../../utils/static/filteredCountry";
import PhoneInput from "react-phone-input-2";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";
import { Helmet } from "react-helmet";
import { eventTracker, SIGN_UP } from "../../../utils/eventTracker";
import { SHA256 } from "crypto-js";
import { gapi } from "gapi-script";
import GoogleLoginButton from "../../GoogleLogin/GoogleLoginButton";
import { clientId } from "../../../constants/Url";
import FacebookLoginButton from "../../FacebookLogin/FacebookLoginButton";
import MapModal from "../profile/MapModal";
import axiosInstance from "../../../utils/API";
import CustomModal from "../../CustomModal/CustomModal";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Input, Modal } from "antd";
import { fromLatLng, setKey, setLocationType } from "react-geocode";
import LocationSearch from "../../../components/frontend/profile/LocationSearch";
import "../../../components/frontend/profile/google-map.scss";
import pin from "../../../components/frontend/profile/pin.png";
import EmployeeGoogleLogin from "../../EmployeeGoogleLogin/EmployeeGoogleLogin";
import { useDispatch } from "react-redux";
import * as Action from "../../../pages/Auth/_redux/authActions";
import { DownOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
const fetchNationalities = async () => {
  const res = await axiosInstance.get("/commons/nationality");
  return res?.data?.nationalities;
};
function EmployeeRegister() {
  const { t } = useTranslation();
  const [referPerson, setReferPerson] = useState([]);
  const [sourceFrom, setSourceFrom] = useState([]);
  const [position, setPosition] = useState([]);

  const [loading, setLoading] = useState(false);
  const [googleLoginUser, setGoogleLoginUser] = useState(null);
  const [getError, setError] = useState();

  const [profilePicture, setProfilePicture] = useState([]);
  const [summaryPdf, setSummaryPdf] = useState([]);
  const [summaryPdfFileShow, setSummaryPdfFileShow] = useState(undefined);

  const [emiratesIdFile, setEmiratesIdFile] = useState([]);
  const [visaFile, setVisaFile] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [extraFields, setExtraFields] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [documentUrl, setDocumentUrl] = useState({});
  const [fileLists, setFileLists] = useState({});
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [myLocation, setMyLocation] = useState({});
  const [name, setName] = useState("");
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }

    gapi.load("client:auth2", start);
  });

  // fetch all countries
  const { data: nationalities, isLoading: nationalityLoading } = useQuery(
    ["nationalities"],
    () => fetchNationalities()
  );

  // console.log("nationalities", nationalities);

  const handleFileChange = (info, fieldName) => {
    if (info.file.status === "done") {
      // File upload is complete
      const updatedFileLists = { ...fileLists };
      updatedFileLists[fieldName] = info.fileList;
      setFileLists(updatedFileLists);
    }

    // Create a payload for the API request
    const payload = new FormData();
    payload.append("document", info.file.originFileObj);
    fetch(
      "http://server.mhpremierstaffingsolutions.com/api/v1/document/upload-document",
      {
        method: "POST",
        body: payload,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response here
        console.log("data -----", data);
        setDocumentUrl((prev) => {
          return {
            ...prev,
            [fieldName]: data?.details?.document,
          };
        });
        setUploadedFiles((prevUploadedFiles) => ({
          ...prevUploadedFiles,
          [fieldName]: data?.document?._id,
        }));
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const fetchDocumentFields = async () => {
    const data = { country: selectedCountry };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/document/get-fields`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      return responseData?.details?.fields;
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      return [];
    }
  };

  // if selectedCountry is not null then fetch document fields
  useEffect(() => {
    if (selectedCountry) {
      fetchDocumentFields().then((fields) => {
        setExtraFields(fields);
      });
    }
  }, [selectedCountry]);

  // console.log(extraFields);

  const onProfileChange = ({ fileList: newFileList }) => {
    setProfilePicture(newFileList);
  };

  // image preview
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);

      // Display the file name below the image
      imgWindow.document.write(`<p>${file.name}</p>`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/users/list?isReferPerson=YES`
        );
        setReferPerson(response?.data?.users);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSourceFromData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/sources/list-for-dropdown`
      );
      setSourceFrom(response?.data?.sources);
    };

    fetchSourceFromData();
  }, []);

  //Fetch position list for dropdown
  const fetchPositionData = useCallback(async () => {
    await fetchPositionListForDropdownHandler().then((res) => {
      setPosition(res?.data?.positions);
    });
  }, []);

  useEffect(() => {
    fetchPositionData();
  }, []);

  const onFinish = async (values) => {
    // console.log("values~~", values);
    const registerData = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      countryName: values?.countryName,
      presentAddress: values?.restaurantAddress,
      lat: myLocation?.lat,
      long: myLocation?.lng,
      positionId: values?.positionId,
      password: values?.password,
      role: "EMPLOYEE",
    };

    // console.log("registerData~~", registerData);
    setLoading(true);
    const response = await axiosInstance.post(
      "/users/employee-register",
      registerData
    );
    if (response?.data?.status === "success") {
      localStorage.setItem("accessToken", response?.data?.token);
      dispatch(Action.login(response?.data?.token));
      const jwtDecode = jwt_decode(response?.data?.token);
      dispatch(Action.fullFillUser(jwtDecode));
      navigate("/candidate/profile");
    } else if (response?.status === "error") {
      setErrors(response?.errors || []);
    }
    // console.log("registerData~~", response);

    setLoading(false);

    // if (true) {
    //   setLoading(true);

    //   const file = new FormData();

    //   file.append("firstName", values?.firstName);
    //   file.append("lastName", values?.lastName);
    //   file.append("email", values?.email);
    //   file.append("phoneNumber", values?.phoneNumber);
    //   file.append("countryName", values?.countryName);
    //   file.append("positionId", values?.positionId);

    //   if (_.size(summaryPdf)) {
    //     file.append("cv", summaryPdf?.[0].originFileObj);
    //   }

    //   if (_.size(profilePicture)) {
    //     file.append("profilePicture", profilePicture?.[0].originFileObj);
    //   }

    //   const transformedData = [];

    //   for (const fieldName in documentUrl) {
    //     const fileNames = documentUrl[fieldName];
    //     for (const fileName of fileNames) {
    //       transformedData.push({
    //         inputType: "file",
    //         label: "",
    //         placeholder: "",
    //         disabled: false,
    //         required: true, // You can adjust this as needed
    //         value: fileName,
    //       });
    //     }
    //   }

    //   const transformedPayload = JSON.stringify(transformedData);

    //   // Append the array as "documents"
    //   file.append("documents", JSON.stringify(transformedPayload));

    //   await employeeRegisterHandler(file)
    //     .then((res) => res.json())
    //     .then((res) => {
    //       if (res.statusCode === 201) {
    //         responseNotification(
    //           "Candidate registered successfully!",
    //           "success"
    //         );
    //         const hashedEmail = values.email
    //           ? SHA256(values.email).toString()
    //           : null;

    //         eventTracker(
    //           SIGN_UP,
    //           "User Engagement",
    //           "Click",
    //           "Sign Up Button",
    //           { email: hashedEmail, user_type: "candidate", ...values }
    //         );
    //         // form.resetFields();
    //         setSummaryPdf([]);
    //         navigate("/register-welcome");
    //       } else if (res?.statusCode === 400) {
    //         // toast.error(res?.message);
    //         responseNotification(res?.message, "error");
    //         setError(res?.message);
    //         console.log("res: ", res);
    //         setLoading(false);
    //       } else {
    //         setLoading(false);
    //         setError(res?.message);
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       setLoading(false);
    //     });
    // }
  };

  const handleLoginSuccess = async (response) => {
    console.log("Login Success:", response);

    if (response?.profileObj) {
      const { email, givenName, familyName, imageUrl } = response.profileObj;

      const user = {
        email,
        firstName: givenName,
        lastName: familyName,
        // profilePicture: imageUrl,
        isSocialAccount: true,
        accountType: "Google",
        role: "EMPLOYEE",
      };

      // console.log("user~~", user);

      const createResponse = await axiosInstance.post(
        "/users/employee-register",
        user
      );
      // console.log("createResponse~~", createResponse);
      if (createResponse?.data?.status === "success") {
        localStorage.setItem("accessToken", createResponse?.data?.token);
        dispatch(Action.login(createResponse?.data?.token));
        const jwtDecode = jwt_decode(createResponse?.data?.token);
        dispatch(Action.fullFillUser(jwtDecode));
        navigate("/candidate/profile");
      } else if (createResponse?.status === "error") {
        setErrors(createResponse?.errors || []);
      }

      // console.log("name~~", name);
      // console.log("user~~login~~", user);
      // console.log("myLocation~~", myLocation);
    }
    // Handle the response object, which contains user info and token
  };

  const handleLoginFailure = (response) => {
    console.log("Login Failed:", response);
  };

  //CV onchange
  const summaryPdfChange = ({ fileList: newFileList }) => {
    setSummaryPdf(newFileList);
    setSummaryPdfFileShow(newFileList[0]?.originFileObj?.name);
  };

  const { Option } = Select;

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content=" Access the PLAGIT portal. Log in to connect with top-tier talent, manage job postings, and ensure your company's recruitment success."
        />
        <title>
          Candidate Register | PLAGIT: Your Gateway to Talent Excellence
        </title>
      </Helmet>

      <section className="total_wrapper">
        <div className="container-fluid">
          <div className="row custom_coloring_row">
            {/*leftpart start*/}

            <div className="col-lg-6 col-md-6 Login_Page_left_part Registration_left_part">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Link to="/">
                      <img
                        src="assets/frontend/images/registrationFormImages/clientAndEmployee/Group 685.png"
                        className="img-fluid"
                        alt="image"
                      />
                    </Link>

                    <Link className="top_logo_left" to="/">
                      <img
                        src={"/assets/plagit_logo.png"}
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="col-lg-12 helps_you_hire">
                    <h4>
                      PLAGIT {t("helps_you_hire_great")} &amp;{" "}
                      {t("experienced_workers_at_a_moment's_notice")}
                    </h4>
                  </div>

                  <div className="col-lg-12 text-center">
                    <img
                      className="img-fluid cover_image"
                      src={`/assets/banner_image.png`}
                      alt="cover"
                    />
                  </div>
                </div>
                <div className="row footer_apps_download_link_btn">
                  <div className="col-lg-7">
                    <img
                      className="img-fluid apps_download_btn_image_text"
                      src={"/assets/apps_download_text.png"}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-5 apps_download_brn_group">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.invain.mh&pli=1"
                      target="_blank"
                      className="play_store_btn"
                    >
                      <img
                        className="img-fluid"
                        src={"/assets/play_store_btn.png"}
                        alt="cover"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/plagit/id6446052294"
                      target="_blank"
                      className="apple_store_btn"
                    >
                      <img
                        className="img-fluid"
                        src={"/assets/apple_store_btn.png"}
                        alt="cover"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/*Right Part Start*/}
            <div className="col-lg-6 col-md-6 col-sm-12 Registration_page_right_part">
              <div className="eclips_wrapper1">
                <img
                  className="img-fluid"
                  src="assets/frontend/images/registrationFormImages/clientAndEmployee/Ellipse 70.png"
                />
              </div>
              <div className="eclipse_wrapper2">
                <img
                  className="img-fluid"
                  src="assets/frontend/images/registrationFormImages/clientFormPictures/../clientAndEmployee/Ellipse 69.png"
                  alt="image"
                />
              </div>

              <div className="clientButtonWrapper d-flex justify-content-center align-items-center">
                <ul className="nav mobile_320  ClientButtonNavTabs nav-tabs">
                  <li className="nav-item ClientButtonNavItem">
                    <Link
                      to="/client-register"
                      className="text-decoration-none"
                    >
                      <button className="nav-link client_tab_button">
                        {/* Client */}
                        {t("client")}
                      </button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/employee-register"
                      className="text-decoration-none"
                    >
                      <button className="nav-link active employee_tab_button">
                        {t("candidate")}
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="formWrappper">
                <div className="tab-content pt-2">
                  <Form
                    className="ant-form ant-form-vertical"
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                  >
                    <div className="tab-pane fade show active">
                      <div className="container py-5">
                        {/* profile picture upload button */}
                        {/* <div className="row">
                          <div className="col-lg-12">
                            <div className="uploadImageWrapper d-flex justify-content-center align-items-center">
                              <Form.Item
                                name="profilePicture"
                                hasFeedback
                                rules={[
                                  {
                                    // required: true,
                                    message:
                                      "Please enter your profile picture",
                                  },
                                ]}
                              >
                                <div>
                                  <ImgCrop rotate aspect={500 / 600}>
                                    <Upload
                                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                      // fileList={profilePicture}
                                      onChange={onProfileChange}
                                      onPreview={onPreview}
                                    >
                                      {profilePicture?.length < 1 && (
                                        <>
                                          <img
                                            style={{
                                              height: "100px",
                                              width: "100px",
                                              marginTop: "10px",
                                            }}
                                            src="assets/frontend/images/registrationFormImages/employeeFormPictures/uploadeImage.png"
                                            alt="Default Image"
                                          />
                                        </>
                                      )}
                                    </Upload>
                                  </ImgCrop>
                                </div>
                              </Form.Item>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="or-container mx-2 mb-2 pb-2">
                          <hr className="or-line" />
                          <span className="or-text">SignUp With</span>
                          <hr className="or-line" />
                        </div>

                        <div className="d-flex align-items-center w-100 justify-content-evenly">
                          <GoogleLoginButton
                            handleLoginFailure={handleLoginFailure}
                            handleLoginSuccess={handleLoginSuccess}
                          />

                          <FacebookLoginButton />
                        </div>

                        <div className="or-container mx-2 mb-2 pb-2">
                          <hr className="or-line" />
                          <span className="or-text">OR</span>
                          <hr className="or-line" />
                        </div> */}

                        <div className="row">
                          <div className="col-lg-6">
                            {/*  frist name */}
                            <Form.Item
                              name="firstName"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("please_enter_your_first_name"),
                                },
                                {
                                  pattern: /^\S.*\S$/,
                                  message: t(
                                    "first_name_field_does_not_accept_trailing_spaces"
                                  ),
                                },
                                {
                                  pattern:
                                    /^[a-zA-Z@~`!@#$%^&*()_=+\\;:'\"/?.>,<,-\s]*$/,
                                  message: t(
                                    "first_name_field_does_not_accept_numbers"
                                  ),
                                },
                              ]}
                            >
                              <div>
                                <div className="inputLogoWrapper">
                                  <img
                                    className="img-fluid"
                                    src="assets/frontend/images/registrationFormImages/employeeFormPictures/Icon.png"
                                    alt="image"
                                  />
                                </div>
                                <input
                                  placeholder={t("enter_first_name")}
                                  type="text"
                                  className="form-control custom_client_input_for_registration_page"
                                />
                              </div>
                            </Form.Item>
                            {/* email */}
                            <Form.Item
                              name="email"
                              hasFeedback
                              rules={[
                                {
                                  type: "email",
                                  message: t(
                                    "please_enter_a_valid_email_address"
                                  ),
                                },
                                {
                                  required: true,
                                  message: t("please_enter_your_email"),
                                },
                              ]}
                            >
                              <div>
                                <div className="inputLogoWrapper">
                                  <img
                                    className="img-fluid"
                                    src="assets/frontend/images/registrationFormImages/clientFormPictures/Email.png"
                                    alt="image"
                                  />
                                </div>
                                <input
                                  placeholder={t("enter_email")}
                                  type="email"
                                  className="form-control custom_client_input_for_registration_page"
                                />
                              </div>
                            </Form.Item>
                            {/* password */}
                            <Form.Item
                              name="password"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("please_enter_password"),
                                },
                              ]}
                            >
                              <Input.Password
                                size="large"
                                prefix={
                                  <img
                                    src="assets/frontend/images/login_page_images/Icon.png"
                                    className="img-fluid password_logo"
                                    alt="Password Icon"
                                  />
                                }
                                // className="py-3"
                                style={{
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                                placeholder={t("enter_password")}
                              />
                            </Form.Item>
                            {/* select position */}
                            <Form.Item
                              name="positionId"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("please_select_your_position"),
                                },
                              ]}
                            >
                              <div className="dropdownbuttonwrapper">
                                <div className="dropdown">
                                  <div className="inputLogoWrapper">
                                    <img
                                      className="img-fluid"
                                      src="assets/frontend/images/registrationFormImages/clientFormPictures/selectFrom.png"
                                      alt="image"
                                    />
                                  </div>
                                  <select
                                    className="form-select custom_select"
                                    aria-label="Select country"
                                  >
                                    <option selected disabled>
                                      {t("please_select_position")}
                                    </option>
                                    {position?.map((item, index) => (
                                      <option key={index} value={item?._id}>
                                        {item?.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </Form.Item>
                          </div>

                          <div className="col-lg-6">
                            {/* last name */}
                            <Form.Item
                              name="lastName"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("please_enter_your_last_name"),
                                },
                                {
                                  pattern: new RegExp(
                                    /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                                  ),
                                  message: t(
                                    "last_name_field_does_not_accept_numbers"
                                  ),
                                },
                              ]}
                            >
                              <div>
                                <div className="inputLogoWrapper">
                                  <img
                                    className="img-fluid"
                                    src="assets/frontend/images/registrationFormImages/employeeFormPictures/Icon.png"
                                    alt="image"
                                  />
                                </div>
                                <input
                                  placeholder={t("enter_last_name")}
                                  type="text"
                                  className="form-control custom_client_input_for_registration_page"
                                />
                              </div>
                            </Form.Item>

                            {/* phone number */}
                            {/* <Form.Item
                              name="phoneNumber"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                                {
                                  validator: (_, value) =>
                                    value && isValidPhoneNumber(value)
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error(
                                            t(
                                              "please_enter_valid_phone_number_for_selected_country"
                                            )
                                          )
                                        ),
                                },
                              ]}
                            >
                              <div>
                                <div className="inputLogoWrapper">
                                  <img
                                    className="img-fluid"
                                    src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                    alt="image"
                                  />
                                </div>
                                <PhoneInput
                                  onlyCountries={["ae", "it", "gb"]}
                                  placeholder="Enter phone number"
                                  isValid
                                  inputProps={{
                                    name: "phoneNumber",
                                    // required: true,
                                  }}
                                />
                              </div>
                            </Form.Item> */}

                            <Form.Item
                              name="restaurantAddress"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("please_enter_present_address"),
                                },
                              ]}
                            >
                              <div>
                                <div className="inputLogoWrapper">
                                  <img
                                    className="img-fluid"
                                    src="assets/frontend/images/registrationFormImages/clientFormPictures/Subtract.png"
                                    alt="image"
                                  />
                                </div>
                                <input
                                  onClick={() => {
                                    setMapModal(true);
                                  }}
                                  autoComplete="off"
                                  placeholder={t("enter_present_address")}
                                  type="text"
                                  value={form?.getFieldValue(
                                    "restaurantAddress"
                                  )}
                                  className="form-control custom_client_input_for_registration_page"
                                />
                              </div>
                            </Form.Item>

                            <Form.Item
                              name="countryName"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("please_select_your_country_name"),
                                },
                              ]}
                            >
                              {/* <div className="dropdownbuttonwrapper">
                                <div className="dropdown">
                                  <div className="inputLogoWrapper">
                                    <img
                                      className="img-fluid"
                                      src="assets/frontend/images/registrationFormImages/clientFormPictures/selectFrom.png"
                                      alt="image"
                                    />
                                  </div>

                                  <select
                                    className="form-select custom_select"
                                    aria-label={t("select_country")}
                                    onChange={(e) => {
                                      setSelectedCountry(e.target.value);
                                    }}
                                  >
                                    <option selected disabled>
                                      {t("please_select_country_name")}
                                    </option>
                                    {nationalities?.map((item, index) => (
                                      <option key={index} value={item?.country}>
                                        {item?.country}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div> */}

                              <Select
                                showSearch
                                placeholder={t("please_select_country_name")}
                                optionFilterProp="children"
                                onChange={(value) => setSelectedCountry(value)}
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                className="custom-select-padding"
                                size="large"
                                addonBefore={
                                  <div className="inputLogoWrapper">
                                    <img
                                      className="img-fluid"
                                      src="assets/frontend/images/registrationFormImages/clientFormPictures/selectFrom.png"
                                      alt="image"
                                    />
                                  </div>
                                }
                              >
                                {nationalities?.map((item, index) => (
                                  <Option key={index} value={item?.country}>
                                    {item?.country}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>

                        {/* cv upload button */}
                        {/* <Form.Item
                          name="summaryPdf"
                          rules={[
                            {
                              required: true,
                              message: `CV is required`,
                            },
                          ]}
                          style={{ textAlign: "center" }}
                        >
                          <div className="row">
                            <div className="col-lg-12 d-flex justify-content-center align-items-center">
                              <label className="logo-upload-btn">
                                <div
                                  style={{
                                    background: "##A7DFDF",
                                    padding: "7px",
                                    borderRadius: "10px",
                                    borderStyle: "dashed",
                                    borderColor: "#58c8c8",
                                    borderWidth: "2px",
                                    width: "270px",
                                    color: "#58c8c8",
                                    textAlign: "center",
                                  }}
                                >
                                  {t("upload_your_curriculum_vitae")} (cv)
                                  <AiOutlineCloudUpload
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "20px",
                                    }}
                                  />
                                </div>

                                <div style={{ width: "270px" }}>
                                  <Upload
                                    listType="picture"
                                    defaultFileList={[...summaryPdf]}
                                    fileList={summaryPdf}
                                    onChange={summaryPdfChange}
                                    maxCount={1}
                                    accept=".pdf, .PDF, docs, DOCS, .doc, .DOC, .docx"
                                  ></Upload>
                                </div>
                              </label>
                            </div>
                          </div>
                        </Form.Item> */}

                        {/* {extraFields.length > 0 &&
                          extraFields?.map((field, index) => (
                            <div
                              className="uploadImageWrapper d-flex justify-content-center align-items-center"
                              key={index}
                            >
                              <Form.Item
                                name={field.label}
                                rules={[
                                  {
                                    required: field.required,
                                    message: `${field.placeholder} is required`,
                                  },
                                ]}
                              >
                                <div className="row">
                                  <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                    <label className="logo-upload-btn">
                                      <div
                                        style={{
                                          background: "#A7DFDF",
                                          padding: "7px",
                                          borderRadius: "10px",
                                          borderStyle: "dashed",
                                          borderColor: "#58c8c8",
                                          borderWidth: "2px",
                                          width: "270px",
                                          color: "#58c8c8",
                                          textAlign: "center",
                                        }}
                                      >
                                        {field.label}
                                        <AiOutlineCloudUpload
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </div>
                                      <div style={{ width: "270px" }}>
                                        <Upload
                                          listType="picture"
                                          maxCount={1}
                                          accept=".pdf, .PDF, .doc, .DOC, .docx"
                                          fileList={
                                            fileLists[field.label] || []
                                          }
                                          onChange={(info) =>
                                            handleFileChange(info, field.label)
                                          }
                                        ></Upload>

                                        {documentUrl[field.label] && (
                                          <>{documentUrl[field.label]}</>
                                        )}
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </Form.Item>
                            </div>
                          ))} */}

                        {errors?.length > 0 && (
                          <div className="alert alert-danger" role="alert">
                            {errors.map((error, index) => (
                              <p key={index} className="mb-1">
                                <strong>Error in {error.param}:</strong>{" "}
                                {error.msg}
                              </p>
                            ))}
                          </div>
                        )}

                        <div className="row">
                          <div className="col-lg-12">
                            {/* Register Button */}
                            <div className="registerButton text-center">
                              <Form.Item>
                                <button
                                  disabled={loading}
                                  className="btn employee_register_button"
                                  type="submit"
                                  style={{
                                    width: "270px",
                                  }}
                                >
                                  {!loading && t("register")}
                                  {loading && (
                                    <span
                                      className="indicator-progress"
                                      style={{ display: "block" }}
                                    >
                                      {t("please_wait...")}
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  )}
                                </button>
                              </Form.Item>
                            </div>
                            {/* Register Button End */}

                            <div className="accountandregisterwrapper text-center">
                              <span className="donthaveaccounttext">
                                {t("already_have_an_account")}
                              </span>
                              <Link to="/login" style={{ color: "#58c8c8" }}>
                                {t("login")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MapModal
          isModalOpen={mapModal}
          setIsModalOpen={setMapModal}
          myLocation={myLocation}
          setMyLocation={setMyLocation}
          name={name}
          setName={setName}
          form={form}
          isGoogleLogin={isGoogleLogin}
          isEmployee={isEmployee}
          position={position}
        />
        <CustomModal
          isOpen={isEmployeeModalOpen}
          onClose={() => setIsEmployeeModalOpen((prev) => !prev)}
        >
          <EmployeeGoogleLogin
            position={position}
            setMyLocation={setMyLocation}
            myLocation={myLocation}
            name={name}
            setName={setName}
            setIsModalOpen={setIsEmployeeModalOpen}
          />
        </CustomModal>
      </section>
    </>
  );
}

export default EmployeeRegister;

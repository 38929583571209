import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Table,
  Upload,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FcApprove } from "react-icons/fc";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { MdFlag } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
// import defaultImage from "../../../assets/images/default.png";
import { token } from "../../../utils/authentication";
import { getUserCurrencyByCountry } from "../../../utils/commonFunctions";
import Loader from "../../../components/loadar/Loader";
import { useTranslation } from "react-i18next";

function EmployeePortfolioDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [imageFileList, setImageFileList] = useState([]);
  const [videoFileList, setVideoFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [privewFile, setPreviewFile] = useState("");
  const [loadingVlog, setLoadingVlog] = useState(false);
  const [getSingleEmployeeDetails, setSingleEmployeeDetails] = useState([]);

  const [form] = Form.useForm();

  const goBack = () => {
    navigate(-1);
  };
  const loadAllVlog = async () => {
    setLoadingVlog(true);
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/vlog?employeeId=${id}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        })
        .then((res) => {
          setLoadingVlog(false);
          setFileList(res.data?.vlogs);
          console.log(res.data);
        });
    } catch (err) {
      setLoadingVlog(false);
      console.log(err);
    }
  };
  //Fetch refer person list for dropdown
  const fetchSingleEmployeeData = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setSingleEmployeeDetails(res?.data?.details);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    loadAllVlog();
    fetchSingleEmployeeData();
  }, []);

  const handleImageChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setImageFileList(fileList);
  };
  const handleVideoChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setVideoFileList(fileList);
    console.log(fileList);
  };
  const uploadButton = (
    <div className="d-flex mt-1">
      <InboxOutlined className="mt-1" />
      {t("upload")}
    </div>
  );

  const itemStyle = {
    marginBottom: "15px",
    border: "1px solid #eaeaea",
    padding: "10px",
    borderRadius: "5px",
    width: "100%",
  };
  const handleSubmit = async () => {
    const values = form.getFieldsValue(true);
    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("type", modalVisible);
    formData.append("user", id);
    formData.append("active", true);
    if (values?.image && values?.image?.fileList && modalVisible === "image") {
      values.image.fileList.forEach((image) => {
        if (image.originFileObj) {
          console.log(image.originFileObj);
          formData.append("file", image.originFileObj);
        }
      });
    } else if (
      values?.video &&
      values?.video?.fileList &&
      modalVisible === "video"
    ) {
      values.video.fileList.forEach((video) => {
        if (video.originFileObj) {
          console.log(video.originFileObj);
          formData.append("file", video.originFileObj);
        }
      });
    }

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/vlog/create`, formData, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        loadAllVlog();
      });
  };
  const handleDelete = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/vlog/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        loadAllVlog();
      });
  };
  const handleActive = async (id, e) => {
    await axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/vlog/update-status`,
        {
          id: id,
          active: e,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        loadAllVlog();
      });
  };

  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      width: "30%",
    },
    {
      title: t("type"),
      dataIndex: "type",
      key: "type",
      width: "30%",
    },
    {
      title: t("active"),
      dataIndex: "active",
      key: "active",
      render: (text, record) => (
        // <div className="d-flex align-items-center justify-content-center">
        //   {record.active === true ? "Active" : "Inactive"}
        // </div>
        <Switch
          size="small"
          defaultChecked={record.active}
          onChange={(e) => {
            handleActive(record._id, e);
          }}
        />
      ),
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            type="primary"
            className="ml-auto"
            onClick={() => {
              console.log(record);
              setPreviewFile({
                type: record.type,
                file: record.link,
              });
            }}
          >
            {t("preview")}
          </Button>
          <Popconfirm
            title={t("are_you_sure_you_want_to_delete_this_portfolio")}
            onConfirm={() => handleDelete(record._id)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button
              style={{
                backgroundColor: "red",
                color: "white",
                marginLeft: "10px",
              }}
              className="ml-2"
            >
              {t("delete")}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="container-fluid px-4">
      <div className="d-flex justify-content-between mt-4">
        <div>
          <div
            style={{
              color: "#58c8c8",
            }}
            onClick={goBack}
          >
            <IoChevronBackCircleOutline
              size={28}
              backgroundColor="#A6A6A6"
              color="#58c8c8"
            />
            <span style={{ marginLeft: "10px" }}>{t("go_back")}</span>
          </div>
        </div>
        <div className="d-flex">
          <div
            style={{
              marginRight: "10px",
            }}
          >
            <Button
              type="primary"
              size="large"
              className="mr-2"
              onClick={() => setModalVisible("image")}
            >
              {t("add_new_image")}
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              size="large"
              className="ml-2"
              onClick={() => setModalVisible("video")}
            >
              {t("add_new_video")}
            </Button>
          </div>
        </div>
      </div>
      <Row gutter={[8, 8]} className="mt-4">
        <Col sm={24} md={10} lg={8}>
          <div className="row">
            <div
              className="selectedEmpProfilePic"
              style={{
                borderRadius: 14,
                width: "515px",
                height: "500px",
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  borderRadius: 14,
                  // width: "397px",
                  // height: "400px",
                }}
                src={
                  getSingleEmployeeDetails?.profilePicture
                    ? getSingleEmployeeDetails.profilePicture.includes(
                        "lh3.googleusercontent.com"
                      )
                      ? getSingleEmployeeDetails.profilePicture
                      : `${process.env.REACT_APP_ASSETs_BASE_URL}/${getSingleEmployeeDetails.profilePicture}`
                    : "/assets/default.png"
                }
                // src={
                //   getSingleEmployeeDetails?.profilePicture
                //     ? process.env.REACT_APP_ASSETs_BASE_URL +
                //       "/" +
                //       getSingleEmployeeDetails?.profilePicture
                //     : "/assets/default.png"
                // }
                className="img-fluid"
                alt="custom-image1"
              />
            </div>
          </div>
          <div className="row">
            <div className="profileMarker"></div>
          </div>
          <div className="row">
            <div
              className="selectProfileRowForMargin"
              style={{ width: "auto", borderRadius: 14 }}
            >
              <div className="card selectEmpCardCard_body card-body">
                <div className="sctEmplTitleBox">
                  <h5 className="card-title slctProfileNameTitle">
                    {getSingleEmployeeDetails?.name}{" "}
                    {getSingleEmployeeDetails?.certified && (
                      <img
                        src={"/assets/employee/verified.png"}
                        alt="verified"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                  </h5>
                  <span className="sctProfileAge">
                    {t("date_of_birth")}: &nbsp;
                    {moment(getSingleEmployeeDetails?.dateOfBirth)
                      .format("YYYY-MM-DD")
                      .valueOf()}
                  </span>
                  <span className="slctProfiletotalRating">
                    <img
                      src="/assets/frontend/images/selectedEmployee/Star 1.png "
                      className="img-fluid scltEmpProfileRatingIcon"
                      alt="alt-rating"
                    />{" "}
                    &nbsp;
                    {getSingleEmployeeDetails?.rating}
                  </span>
                  <div className="slctproLine">
                    <img
                      src="/assets/frontend/images/selectedEmployee/Line 1.png"
                      className="img-fluid"
                      alt="alt-line"
                    />
                  </div>
                </div>
                <div className="row slctEmpProfileRateRow">
                  <div className="col-xl-7 col-md-6">
                    <span className="slctProfileRateName">
                      <img
                        className="img-fluid SlctProrateIcon"
                        src="/assets/frontend/images/selectedEmployee/rate.png"
                        alt="alt-rate"
                      />
                      {t("rate")}: &nbsp;
                      <span className="slctprofilePerHourPrice">
                        {getSingleEmployeeDetails?.hourlyRate}
                        {getUserCurrencyByCountry(
                          getSingleEmployeeDetails?.countryName
                        ) + t("per_hour")}
                      </span>
                    </span>
                  </div>
                  <div className="col-xl-5 col-md-6">
                    <span className="slctProfileRateName">
                      <img
                        className="img-fluid SlctProrateIcon"
                        src="/assets/frontend/images/selectedEmployee/experience.png"
                        alt="alt-exp"
                      />
                      {t("exp")}: &nbsp;
                      <span className="slctProfileExpValue">
                        {getSingleEmployeeDetails?.employeeExperience}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="row slctEmpProfileRateRow">
                  <div
                    className="col-xl-12 col-md-12"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className="dashboard2TotalHourwrapper">
                        <MdFlag
                          style={{
                            fontSize: "22px",
                            color: "#58c8c8",
                          }}
                        />
                        <span className="dashboard2totalhourspan">
                          {t("nationality")}:
                        </span>
                        <span className="dashboard2totalhoursspent">
                          {getSingleEmployeeDetails?.nationality}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="dashboard2TotalHourwrapper">
                        <FcApprove style={{ fontSize: "22px" }} />
                        <span className="dashboard2totalhourspan">
                          {t("license_no")}: &nbsp;
                        </span>
                        <span className="dashboard2totalhoursspent">
                          {getSingleEmployeeDetails?.licensesNo}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="row slctEmpProfileRateRow">
                  <div
                    className="col-xl-12 col-md-12"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className="dashboard2TotalHourwrapper">
                        <img
                          src={"/assets/height.png"}
                          style={{ height: "22px" }}
                          alt="height"
                        />
                        <span className="dashboard2totalhourspan">
                          {t("height")}:
                        </span>
                        <span className="dashboard2totalhoursspent">
                          {getSingleEmployeeDetails?.height} cm
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="dashboard2TotalHourwrapper">
                        <img
                          src={"/assets/shirt.png"}
                          alt="dress"
                          style={{
                            height: "22px",
                          }}
                        />
                        <span className="dashboard2totalhourspan">
                          {t("dress_size")}: &nbsp;
                        </span>
                        <span className="dashboard2totalhoursspent">
                          {getSingleEmployeeDetails?.dressSize}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row slctEmpProfileRateRow">
                  <div>
                    <div className="dashboard2TotalHourwrapper">
                      <img
                        src={"/assets/employee/verified.png"}
                        alt="org"
                        style={{ height: "22px" }}
                      />
                      <span className="dashboard2totalhourspan">
                        {t("current_organisation")} :&nbsp;
                      </span>
                      <span className="dashboard2totalhoursspent">
                        {getSingleEmployeeDetails?.currentOrganisation}
                      </span>
                    </div>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      width: "80%", // Adjust the width as needed
                    }}
                  >
                    <span className="">
                      <img src={"/assets/employee/verified.png"} alt="org" style={{ height: "20px" }} />
                      &nbsp;Current Organisation :&nbsp;
                    </span>
                    <span className="selectEmpLicenseValue">
                      {getSingleEmployeeDetails?.currentOrganisation}
                    </span>
                  </div> */}
                </div>

                {/* <div className="mt-3">
                  <div
                    className="selectEmployeeCardBookNowButton "
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#58c8c8",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        textDecoration: "none",
                        textAlign: "center",
                      }}
                      onClick={goBack}
                    >
                      Back to List
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={14} lg={16}>
          {loadingVlog ? (
            <Loader />
          ) : (
            <Table
              columns={columns}
              // dataSource={getSingleEmployeeDetails?.vlogs}
              dataSource={fileList}
              loading={loadingVlog}
              pagination={false}
            />
          )}
        </Col>
      </Row>
      <Modal
        title={t("add_new_image_or_video")}
        open={modalVisible}
        width={1000}
        cancelText={t("cancel")}
        okText={t("ok")}
        onOk={() => {
          setLoadingVlog(true);
          handleSubmit();
          setModalVisible(false);
        }}
        onCancel={() => {
          form.resetFields();
          setImageFileList([]);
          setVideoFileList([]);
          setModalVisible(false);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          className="mb-5"
          onFinish={(values) => {
            console.log(values);
          }}
        >
          <Row gutter={[8, 8]}>
            <Col sm={24} md={12} lg={12}>
              <Form.Item
                name="title"
                label={t("title")}
                style={{ ...itemStyle, minWidth: "100%" }}
                maxLength={50}
              >
                <Input placeholder="Title" style={{ minWidth: "100%" }} />
              </Form.Item>
            </Col>
            {modalVisible === "image" ? (
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  name="image"
                  label={t("upload_new_image")}
                  style={{ ...itemStyle, minWidth: "100%" }}
                >
                  <Upload
                    fileList={imageFileList}
                    style={{ minWidth: "100%", border: "1px solid #eaea" }}
                    accept="image/*"
                    onChange={handleImageChange}
                    listType="picture"
                    beforeUpload={() => false}
                  >
                    {uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            ) : (
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  name="video"
                  label={t("upload_new_video")}
                  style={{ ...itemStyle, minWidth: "100%" }}
                >
                  <Upload
                    fileList={videoFileList}
                    style={{ minWidth: "100%", border: "1px solid #eaea" }}
                    accept="video/*"
                    onChange={handleVideoChange}
                    listType="video"
                    beforeUpload={() => false}
                  >
                    {uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
      <Modal
        open={privewFile}
        footer={null}
        onCancel={() => setPreviewFile(false)}
        width={1000}
        height={500}
      >
        {privewFile.type === "image" && (
          <img
            src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${privewFile.file}`}
            alt="positionLogo"
            style={{ width: "100%", height: "700px" }}
          />
        )}
        {privewFile.type === "video" && (
          <video
            src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${privewFile.file}`}
            alt="positionLogo"
            style={{ width: "100%", height: "700px" }}
            controls
          />
        )}
      </Modal>
    </div>
  );
}

export default EmployeePortfolioDetails;

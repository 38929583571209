import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getCurrency } from "../../../utils/static/getCurrency";
import "./job_post_style.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { Colors } from "../../../constants/Colors";
import { Popconfirm } from "antd";
import { jobDelete } from "../../../services/jobServices";
import SmallLoading from "../../Shared/SmallLoading";
import { Toast } from "../../../utils/toast";
import { getUserCurrencyByCountry } from "../../../utils/commonFunctions";
import { isRtlCountry } from "../../../utils/country";

export default function JobPostCard({
  job,
  jwtDecode,
  isAllJob,
  refetchMyJob,
}) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true); // Set loading to true when delete starts
    // console.log("Delete-started");
    const response = await jobDelete(job?._id);
    // console.log("delete-response~~~", response);
    if (response?.data?.status === "success") {
      await refetchMyJob();
      setLoading(false);
      Toast.success("Deleted successfully");
    }
    // console.log("Delete-end");
    setLoading(false);
  };
  // console.log("isAllJob", isAllJob);
  return (
    <div className="job_post_card_wrapper">
      {!isAllJob && (
        <div
          className="position-absolute"
          style={{
            top: "10px",
            right: "10px",
          }}
        >
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
            placement="topRight"
            okButtonProps={{
              style: { backgroundColor: "#ff4d4f", borderColor: "#ff4d4f" },
            }}
            cancelButtonProps={{
              style: {
                backgroundColor: Colors.primary,
                borderColor: Colors.primary,
                color: "white",
              },
            }}
          >
            <DeleteOutlined
              style={{
                fontSize: "20px",
                color: Colors.primary,
                cursor: "pointer",
              }}
            />
          </Popconfirm>
        </div>
      )}

      <div className="cardHeader">
        <img
          className="icon"
          src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${job?.positionId?.logo}`}
          alt={job?.positionId?.name}
        />
        <h3 className="cardTitle">{job.positionId?.name}</h3>
      </div>
      <div className="cardBody">
        <p className="cardText">
          <span role="img" aria-label="rate" className="emoji">
            {" "}
            💰{" "}
          </span>
          <span className="me-2" style={{ color: "#7B7B7B" }}>
            Salary Range:{" "}
          </span>

          {isRtlCountry(job?.country) ? (
            <span>
              {getUserCurrencyByCountry(job?.country)}
              {job.maxRatePerHour}- {getUserCurrencyByCountry(job?.country)}{" "}
              {job.minRatePerHour}
            </span>
          ) : (
            <span>
              {job.minRatePerHour}
              {getUserCurrencyByCountry(job?.country)} - {job.maxRatePerHour}
              {getUserCurrencyByCountry(job?.country)}
            </span>
          )}
        </p>
        <p className="cardText">
          <span role="img" aria-label="experience" className="emoji">
            💼
          </span>
          <span className="me-2" style={{ color: "#7B7B7B" }}>
            Experience:
          </span>{" "}
          <spn>
            {job.minExperience} - {job?.maxExperience} Yr.
          </spn>
        </p>
        <p className="cardText">
          <span role="img" aria-label="date" className="emoji">
            🗓️
          </span>
          <span className="me-2" style={{ color: "#7B7B7B" }}>
            Date:
          </span>{" "}
          <span>
            {moment(job.publishedDate).format("DD-MM-YYYY")} -{" "}
            {moment(job.endDate).format("DD-MM-YYYY")}
          </span>
        </p>
      </div>
      <Link
        className="view_details_btn"
        to={
          isAllJob
            ? jwtDecode?.role === "EMPLOYEE"
              ? `/candidate/job-details/${job?._id}`
              : `/job-details/${job?._id} `
            : `/edit-job/${job?._id}`
        }
        // jwtDecode?.role == "CLIENT"
        //     ? `/edit-job/${job?._id}`
        //     : `/candidate/job-details/${job?._id}`
      >
        View Job Details
      </Link>
    </div>
  );
}

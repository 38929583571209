import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Form } from "antd";
import {
  resetPasswordHandler,
  sendOtpHandler,
  verifyOtpHandler,
} from "../../../api/auth";
import { responseNotification } from "../../../utils/notifcation";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [form] = Form.useForm();

  const sendOtp = async () => {
    await form.validateFields(["email"]);
    const values = form.getFieldsValue();
    const regex = /@/;
    if (!regex.test(values.email)) {
      responseNotification("Please enter a valid email", "error");
      return;
    }
    if (values.email) {
      setLoading(true);
      await sendOtpHandler({
        email: values.email,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(res?.message, "success");
            if (res?.user === "CLIENT") {
              setStep(2);
              setLoading(false);
            } else {
              setLoading(false);
              navigate("/login");
            }
          } else {
            responseNotification(res?.message, "error");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };
  const verifyOtp = async () => {
    const values = form.getFieldsValue(true);
    await form.validateFields(["email", "otp"]);
    console.log(values);

    if (values?.otp?.length !== 6) {
      responseNotification("Please enter a valid OTP", "error");
      return;
    }
    if (values.otp) {
      setLoading(true);
      await verifyOtpHandler({
        email: values?.email,
        otpNumber: values?.otp,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(res?.message, "success");
            setLoading(false);
            setStep(3);
          } else {
            responseNotification(res?.message, "error");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const resetPassword = async () => {
    const values = form.getFieldsValue(true);
    await form.validateFields(["email", "password", "confirmPassword"]);
    if (values.password !== values.confirmPassword) {
      responseNotification(
        "Password and confirm password does not match",
        "error"
      );
      return;
    }
    if (values.password) {
      setLoading(true);
      await resetPasswordHandler({
        email: values?.email,
        password: values?.password,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(res?.message, "success");
            setLoading(false);
            navigate("/login");
          } else {
            responseNotification(res?.message, "error");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  return (
    <section className="total_wrapper">
      <Helmet>
        <meta
          name="description"
          content=" Access the PLAGIT portal. Log in to connect with top-tier talent, manage job postings, and ensure your company's recruitment success."
        />
        <title>
          Forget Password | PLAGIT: Your Gateway to Talent Excellence
        </title>
      </Helmet>

      <div className="container-fluid">
        <div className="row">
          {/*leftpart start*/}
          {/* <div className="col-lg-6 col-md-6 Login_Page_left_part">
                        <div className="col-lg-12">
                            <div
                                className="button_wrapper"
                                onClick={() => {
                                    if (step !== 1) {
                                        setStep(step - 1);
                                    }
                                }}
                            >
                                {step === 1 ? (
                                    <Link to="/login">
                                        <img
                                            src="assets/frontend/images/login_page_images/Group 685.png"
                                            className="img-fluid"
                                            alt="Group"
                                        />
                                    </Link>
                                ) : (
                                    <img
                                        src="assets/frontend/images/login_page_images/Group 685.png"
                                        className="img-fluid"
                                        alt="Group"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row left_part_wrapper">
                            <div className="logo_wrapper text-center">
                                <img
                                    className="img-fluid"
                                    src="assets/frontend/images/login_page_images/Logo.png"
                                    alt="Logo"
                                />
                            </div>
                            <div className="premier_stuffing_wrapper text-center">
                                <h1>PLAGIT</h1>
                            </div>
                            <div className="mh_premier_wrapping text-center">
                                <p>
                                    PLAGIT helps you hire great &amp; experienced workers at a
                                    moment's notice
                                </p>
                            </div>
                        </div>
                    </div> */}

          <div className="col-lg-6 col-md-6 Login_Page_left_part Registration_left_part">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Link to="/login">
                    <img
                      src="assets/frontend/images/registrationFormImages/clientAndEmployee/Group 685.png"
                      className="img-fluid"
                      alt="image"
                    />
                  </Link>

                  <Link className="top_logo_left" to="/">
                    <img
                      src={"/assets/plagit_logo.png"}
                      className="img-fluid"
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="col-lg-12 helps_you_hire">
                  <h4>
                    PLAGIT {t("helps_you_hire_great")} &amp;{" "}
                    {t("experienced_workers_at_a_moment's_notice")}
                  </h4>
                </div>

                <div className="col-lg-12 text-center">
                  <img
                    className="img-fluid cover_image"
                    src={"/assets/banner_image.png"}
                    alt="cover"
                  />
                </div>
              </div>
              <div className="row footer_apps_download_link_btn">
                <div className="col-lg-7">
                  <img
                    className="img-fluid apps_download_btn_image_text"
                    src={"/assets/apps_download_text.png"}
                    alt=""
                  />
                  {/* <div className="download_app_message_div">
                                        <div className="plagit_app">
                                            plagit app
                                        </div>
                                        <div className="available_now_text">
                                            Is available now on android and ios
                                        </div>
                                    </div> */}
                </div>
                <div className="col-lg-5 apps_download_brn_group">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.invain.mh&pli=1"
                    target="_blank"
                    className="play_store_btn"
                  >
                    <img
                      className="img-fluid"
                      src={"/assets/play_store_btn.png"}
                      alt="cover"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/plagit/id6446052294"
                    target="_blank"
                    className="apple_store_btn"
                  >
                    <img
                      className="img-fluid"
                      src={"/assets/apple_store_btn.png"}
                      alt="cover"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/*Right Part Start*/}
          <div className="col-lg-6 col-md-6 Login_page_right_part">
            <div className="container custom_for_320">
              <div className="row right_part_wrapper ">
                <div className="first_eclips_img">
                  <img
                    className="img-fluid"
                    src="assets/frontend/images/login_page_images/Ellipse 70.png"
                    alt="Ellipse"
                  />
                </div>
                {/* Form part start */}
                <div className="form_part ">
                  <div className="card custom_card " style={{ width: "88%" }}>
                    <div className="card-body card-body_custom">
                      <h5 className="card-title-custom text-center">
                        {t("welcome_back")}
                      </h5>
                      <p
                        className="text-center"
                        style={{
                          marginTop: "-10px",
                          paddingBottom: "15px",
                        }}
                      >
                        {step === 1
                          ? t("please_enter_your_valid_email")
                          : step === 2
                          ? t("please_enter_otp")
                          : t("please_enter_your_new_password")}
                      </p>
                      <div className="form_wrapper">
                        <Form
                          className="ant-form ant-form-vertical"
                          layout="vertical"
                          form={form}
                        >
                          {step === 1 ? (
                            <div className="mb-5">
                              <Form.Item
                                name="email"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: t("please_enter_your_email"),
                                  },
                                  {
                                    type: "email",
                                    message: "Please enter a valid email",
                                  },
                                ]}
                              >
                                <div>
                                  <div className="contact_logo_img">
                                    <img
                                      src="assets/frontend/images/login_page_images/Profile.png"
                                      className="img-fluid "
                                      alt="Profile"
                                    />
                                  </div>
                                  <input
                                    placeholder={t("enter_email")}
                                    type="text"
                                    className="form-control form-control-custom"
                                  />
                                </div>
                              </Form.Item>
                            </div>
                          ) : step === 2 ? (
                            <div className="mb-5">
                              <Form.Item
                                name="otp"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter OTP",
                                  },
                                ]}
                              >
                                <div>
                                  <div className="inputLogoWrapper">
                                    <img
                                      className="img-fluid"
                                      src="assets/frontend/images/registrationFormImages/clientFormPictures/phone.png"
                                      alt="image"
                                    />
                                  </div>
                                  <input
                                    placeholder={t("enter_otp")}
                                    type="number"
                                    aria-controls="otp"
                                    className="form-control custom_client_input_for_registration_page text-center"
                                  />
                                </div>
                              </Form.Item>
                            </div>
                          ) : step === 3 ? (
                            <div className="mb-5">
                              <Form.Item
                                name="password"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: t("please_enter_your_password"),
                                  },
                                ]}
                              >
                                <div>
                                  <div className="password_logo_img">
                                    <img
                                      src="assets/frontend/images/login_page_images/Icon.png"
                                      className="img-fluid password_logo"
                                      alt="Password Icon"
                                    />
                                  </div>
                                  <input
                                    placeholder={t("enter_new_password")}
                                    type="password"
                                    className="form-control form-control-custom"
                                  />
                                </div>
                              </Form.Item>
                              <Form.Item
                                name="confirmPassword"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: t("please_enter_your_password"),
                                  },
                                ]}
                              >
                                <div>
                                  <div className="password_logo_img">
                                    <img
                                      src="assets/frontend/images/login_page_images/Icon.png"
                                      className="img-fluid password_logo"
                                      alt="Password Icon"
                                    />
                                  </div>
                                  <input
                                    placeholder={t("confirm_password")}
                                    type="password"
                                    className="form-control form-control-custom"
                                  />
                                </div>
                              </Form.Item>
                            </div>
                          ) : null}
                          <Form.Item>
                            <button
                              disabled={loading}
                              className="btn login_button"
                              type="button"
                              onClick={() => {
                                if (step === 1) {
                                  sendOtp();
                                } else if (step === 2) {
                                  verifyOtp();
                                } else if (step === 3) {
                                  resetPassword();
                                }
                              }}
                            >
                              {!loading && step === 1
                                ? t("submit")
                                : !loading && step === 2
                                ? t("verify")
                                : !loading && t("submit")}
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  {t("please_wait...")}
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Register Section start*/}
                <div className="accountandregisterwrapper text-center">
                  <span className="donthaveaccounttext">
                    {t("don’t_have_an_account?")}
                  </span>
                  <Link to="/client-register">{t("register")}</Link>
                </div>
                <div className="last_eclips_img_wrapper">
                  <img
                    src="assets/frontend/images/login_page_images/Ellipse 69.png"
                    alt="Ellipse"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Right Part End*/}
      </div>
    </section>
  );
}

export default Login;

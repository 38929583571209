import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axiosInstance from "../../../utils/API";
import { Toast } from "../../../utils/toast";
import { Colors } from "../../../constants/Colors";

const UploadCVButton = ({
  id,
  getSingleEmployeeDetails,
  fetchEmployeeDetails,
}) => {
  const [fileList, setFileList] = useState([]);

  const [loading, setLoading] = useState(false);

  const uploadCV = async (file) => {
    const formData = new FormData();
    formData.append("cv", file);
    formData.append("id", id);
    console.log("Preparing to upload file:", file);

    try {
      setLoading(true);
      const response = await axiosInstance.put(
        "/users/update-employee",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload response:", response);

      if (response?.status === 200) {
        Toast.success("Cv Uploaded successfully");
        await fetchEmployeeDetails();
      }
    } catch (error) {
      console.error("Upload error:", error);
      message.error(
        `Upload failed: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      message.error("Please select a CV to upload.");
      return;
    }

    await uploadCV(fileList[0].originFileObj); // Upload the first file in the list
    setFileList([]); // Clear the file list after successful upload
  };

  const props = {
    accept: ".pdf,.doc,.docx",
    fileList,
    multiple: false, // Set to false to only allow one file
    beforeUpload: (file) => {
      const isValidFileType =
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

      if (!isValidFileType) {
        message.error(`${file.name} is not a valid CV file`);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange: (info) => {
      setFileList(info.fileList.slice(-1));
    },
    onRemove: (file) => {
      setFileList((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );
    },
    itemRender: (_, file) => {
      return (
        <div>
          <span>{file.name}</span>
          <span style={{ marginLeft: 8 }}>
            size: {file.size ? `${(file.size / 1024).toFixed(2)} KB` : null}
          </span>
        </div>
      );
    },
  };

  return (
    <div className="row mb-3  w-100">
      <div className="col">
        <div className="d-flex align-items-start">
          <Upload
            className={`w-100 ${fileList.length > 0 ? "selected" : ""}`}
            {...props}
          >
            <Button icon={<UploadOutlined />} loading={loading}>
              {loading ? "Uploading..." : "Upload CV"}
            </Button>
          </Upload>
          <Button
            type="primary"
            className=""
            onClick={handleSubmit}
            disabled={loading}
            style={{
              backgroundColor: Colors.primary,
            }}
          >
            Submit
          </Button>
        </div>

        {getSingleEmployeeDetails?.cv && (
          <div className="mt-1">
            <a
              href={`${process.env.REACT_APP_ASSETs_BASE_URL}/${getSingleEmployeeDetails?.cv}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Uploaded CV
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadCVButton;

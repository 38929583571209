import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../utils/API.js";
import { useQuery } from "react-query";
import { jwtTokenDecode } from "../utils/jwtDecode.jsx";

const CurrentUserContext = createContext();

// Function to fetch employee data
const fetchSingleEmployeeData = async (id) => {
  if (!id) {
    return {};
  }
  const res = await axiosInstance.get(`/users/${id}`);
  // console.log("fetchSingleEmployeeData", res);
  return res?.data?.details;
};

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  // const jwt_decode = jwtTokenDecode();
  // const id = jwt_decode?._id;
  // const {
  //   data: currentUser = {},
  //   error,
  //   isLoading,
  //   refetch: fetchEmployeeDetails,
  // } = useQuery(
  //   ["singleEmployee", id],
  //   () => fetchSingleEmployeeData(id), // Fetching function
  //   {
  //     enabled: !!id,
  //   }
  // );
  // const values = {
  //   currentUser,
  //   currentUserError: error,
  //   currentUserRefetch: fetchEmployeeDetails,
  //   currentUserLoading: isLoading,
  // };
  const values = {
    currentUser,
    setCurrentUser,
  };
  return (
    <CurrentUserContext.Provider value={values}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(CurrentUserContext);
  return context;
};

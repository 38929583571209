import { Skeleton } from "antd";

function MultiChatSkeleton() {
  return (
    <div className="multi_chat_wrapper">
      <div className="left-container">
        {/* Header Skeleton */}
        <div className="header">
          <Skeleton title={{ width: "30%" }} active />
        </div>

        {/* Chat List Skeleton */}
        <div className="chat-list">
          {Array(3)
            .fill()
            .map((_, index) => (
              <div key={index} className="chat-box">
                <Skeleton.Avatar size="small" shape="circle" active />
                <div className="chat-details">
                  <Skeleton
                    paragraph={{ rows: 1, width: "50%" }}
                    title={false}
                    active
                  />
                  <Skeleton
                    paragraph={{ rows: 1, width: "80%" }}
                    title={false}
                    active
                  />
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="right-container">
        {/* Right Container Header Skeleton */}
        <div className="header py-2">
          <Skeleton.Avatar size="large" shape="circle" active />
          <div className="nav-icons">
            <Skeleton.Button shape="circle" active />
            <Skeleton.Button shape="circle" active />
          </div>
        </div>

        {/* Chat Messages Skeleton */}
        <div className="chat-container">
          {Array(3)
            .fill()
            .map((_, index) => (
              <div key={index} className="message-box">
                <Skeleton paragraph={{ rows: 2, width: "60%" }} active />
              </div>
            ))}
        </div>

        {/* Chatbox Input Skeleton */}
        <div className="chatbox-input">
          <Skeleton.Avatar size="small" shape="circle" active />
          <Skeleton.Input style={{ width: "80%" }} active />
          <Skeleton.Button shape="circle" active />
        </div>
      </div>
    </div>
  );
}

export default MultiChatSkeleton;

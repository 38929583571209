import {
    Button,
    DatePicker,
    Input,
    Modal,
    Select,
    Space,
    Switch,
    Table,
} from "antd";
import axios from "axios";
import _, { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    fetchClientListHandler,
    useFetchClientList,
    userDeleteHandler,
} from "../../../api/employee";
import { token } from "../../../utils/authentication";
import { getUserInfo } from "../../../utils/commonFunctions.js";
import { getPage } from "../../../utils/getPage";
import { responseNotification } from "../../../utils/notifcation";
import { donwloadCSV } from "../../../utils/static/donwloadCSV.js";
import Loader from "../../../components/loadar/Loader";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import ChatWithAdmin from "../chat/ChatWithAdmin.js";
const { Search } = Input;
const { Option } = Select;

function ClientList() {
    const { t } = useTranslation();
    const loc = useLocation();
    const userInfo = getUserInfo();
    const [limit] = useState(10);
    const [getClient, setClient] = useState([]);
    const [loading, setLoading] = useState(false);
    const [getName, setName] = useState(undefined);
    const [getStatus, setStatus] = useState(undefined);
    const [getCountryName, setCountryName] = useState(undefined);
    const [getFilterFromDate, setFilterFromDate] = useState(undefined);
    const [getFilterToDate, setFilterToDate] = useState(undefined);
    const [pageSize, setpageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedEmployee, setSelectedEmployee] = useState(false);
    const [total, setTotal] = useState(0);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [sender, setSender] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [filterPosition, setFilterPosition] = useState(undefined);
    const navigate = useNavigate();

    const columns = [
        {
            title: "#",
            dataIndex: "key",
        },
        {
            title: t("registration_date"),
            dataIndex: "createdAt", // Add the Registration Date (createdAt) here
            render: (text) => {
                return moment(text)?.format("YYYY-MM-DD");
            },
        },
        {
            title: t("restaurant_name"),
            dataIndex: "restaurantName",
            sorter: (a, b) => a.restaurantName.length - b.restaurantName.length,
        },
        {
            title: t("email"),
            dataIndex: "email",
        },
        {
            title: t("phone_number"),
            dataIndex: "phoneNumber",
        },
        {
            title: t("client_id"),
            dataIndex: "userIdNumber",
        },
        {
            title: t("country_name"),
            dataIndex: "countryName",
        },
        {
            title: t("restaurant_address"),
            dataIndex: "restaurantAddress",
        },
        {
            title: t("discount(%)"),
            dataIndex: "clientDiscount",
        },
        {
            title: t("active"),
            dataIndex: "active",
            sorter: (a, b) => a.active.length - b.active.length,
        },
        {
            title: t("status"),
            dataIndex: "status",
        },
        {
            title: t("action"),
            dataIndex: "action",
        },
    ];

    // useEffect(() => {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const myParam = urlParams.get("page");
    //   const myParam2 = urlParams.get("limit");
    //   // console.log(myParam, myParam2);
    //   if (myParam === null || myParam2 === null) {
    //     navigate(`/admin/client-list?page=1&limit=10`);
    //     window.location.reload();
    //   }
    //   setpageSize(myParam2);
    //   setCurrentPage(myParam);
    // }, [navigate]);
    // const fetchClient = useCallback(async () => {
    //   setLoading(true);

    //   await fetchClientListHandler(
    //     limit,
    //     getName,
    //     getStatus,
    //     getCountryName,
    //     loc?.search,
    //     currentPage,
    //   ).then((res) => {
    //     if (res?.status === 200) {
    //       setLoading(false);
    //       setClient(res?.data?.users);
    //       setTotal(res?.data?.total);
    //     } else {
    //       setLoading(false);
    //     }
    //   });
    // }, [limit, loc?.search, getName, getStatus, getCountryName, currentPage]);
    // useEffect(() => {
    //   fetchClient();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fetchClient, getPage(loc.search)]);

    const handleChangeStatus = (value) => {
        setStatus(value);
    };

    const {
        isLoading,
        data: responseData,
        error,
    } = useFetchClientList(
        limit,
        getName,
        getStatus,
        getCountryName,
        loc?.search,
        filterPosition,
        currentPage
    );

    useEffect(() => {
        if (!isLoading && responseData) {
            setLoading(false);
            setClient(responseData?.users);
            setTotal(responseData?.total);
        }
    }, [isLoading, responseData]);

    const handleChangeCountryName = (value) => {
        setCountryName(value);
    };

    //search
    const handleSearchkeywordOnChange = (e) => {
        debounce(() => {
            setName(e?.target?.value);
        }, 1000)();
    };

    const data1 = [];
    _.map(getClient, (item, index) => {
        let countryName = "";

        if (item?.countryName === "United Kingdom") {
            countryName = "United Kingdom";
        } else if (item?.countryName === "United Arab Emirates") {
            countryName = "United Arab Emirates";
        } else if (item?.countryName === "Italy") {
            countryName = "Italy";
        } else {
            countryName = "Others";
        }

        data1.push({
            key: index + 1,
            restaurantName: item?.restaurantName,
            email: item?.email,
            phoneNumber: item?.phoneNumber,
            userIdNumber: item?.userIdNumber,
            countryName: countryName,
            restaurantAddress: item?.restaurantAddress,
            clientDiscount: item?.clientDiscount,
            active: item.active ? "YES" : "NO",
            createdAt: item?.createdAt, // Add the createdAt field here
            status: (
                <>
                    <Switch
                        size="small"
                        defaultChecked={item?.active === true}
                        onChange={(e) => {
                            onClientStatusChange(item?._id, e);
                        }}
                    />
                </>
            ),
            action: (
                <>
                    <div className="action_btn_group">
                        <Link
                            to={`/admin/client-details/${item._id}`}
                            className="btn btn-sm btn_edit"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Edit"
                        >
                            <i class="far fa-edit"></i>
                        </Link>
                        <Button
                            onClick={() => {
                                setSelectedEmployee(item?._id);
                            }}
                            className="btn btn-sm btn_delete"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete"
                        >
                            {/* {t("delete")} */}
                            <i class="far fa-trash-alt"></i>
                        </Button>

                        <Link
                            className="btn btn-sm btn_chat"
                            onClick={() => {
                                setToggleSidebar(true);
                                setSender(item?._id); // special case when admin sends message to employee
                                setIsAdmin(true);
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Message"
                        >
                            <BsFillChatLeftTextFill />
                        </Link> 
                    </div>
                </>
            ),
        });
    });

    const handlePaginationChange = (page) => {
        // console.log(page);
    };

    const onClientStatusChange = useCallback(
        async (value, e) => {
            const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;
            const status = e === true ? true : false;
            const id = value;

            if (true) {
                await fetch(`${unicodeUri}/users/update-status`, {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${token()}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id,
                        active: status,
                    }),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res?.statusCode === 200) {
                            responseNotification(
                                "Client status updated successfully",
                                "success"
                            );
                            // fetchClient();
                        } else if (res?.statusCode === 400) {
                            responseNotification("Bad request", "danger");
                        }
                    });
            }
        },
        // [fetchClient],
        []
    );

    const handleExportData = async () => {
        try {
            const responseData = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/users?skipLimit=YES&requestType=CLIENT` +
                (getFilterFromDate ? `&fromDate=${getFilterFromDate}` : ``) +
                (getFilterToDate ? `&toDate=${getFilterToDate}` : ``),
                {
                    headers: {
                        Authorization: `Bearer ${token()}`,
                    },
                }
            );

            const data = responseData?.data?.users?.map((item) => {
                return {
                    RestaurantName: item?.restaurantName,
                    RestaurantAddress: item?.restaurantAddress,
                    Email: item?.email,
                    PhoneNumber: item?.phoneNumber,
                    ClientIDNumber: item?.userIdNumber,
                    Discount: item?.clientDiscount,
                };
            });

            donwloadCSV(data, "Client List");
        } catch (error) { }
    };

    return (
        <div className="container-fluid px-4">
            <div className="row mt-4">
                <div className="d-flex justify-content-between">
                    <h3 className="title mb-4">{t("client_list")}</h3>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-10">
                                <div className="d-flex justify-content-start">
                                    <Space>
                                        <Search
                                            placeholder={t("search")}
                                            allowClear
                                            size="large"
                                            onChange={handleSearchkeywordOnChange}
                                            style={{
                                                width: 300,
                                                marginLeft: "10px",
                                            }}
                                        />
                                        {userInfo?.role === "SUPER_ADMIN" ? (
                                            <Select
                                                size="large"
                                                allowClear
                                                showSearch={true}
                                                placeholder={t("select_country_name")}
                                                onChange={handleChangeCountryName}
                                            >
                                                <Option value="United Kingdom">United Kingdom</Option>
                                                <Option value="United Arab Emirates">
                                                    United Arab Emirates
                                                </Option>
                                                <Option value="OTHERS">OTHERS</Option>
                                            </Select>
                                        ) : null}

                                        <Select
                                            size="large"
                                            allowClear
                                            showSearch={true}
                                            placeholder={t("active")}
                                            onChange={handleChangeStatus}
                                        >
                                            <Option value="YES">YES</Option>
                                            <Option value="NO">NO</Option>
                                        </Select>

                                        <DatePicker
                                            size="large"
                                            style={{ width: "12" }}
                                            id="fromDate"
                                            placeholder={t("from_date")}
                                            onChange={(value) => {
                                                setFilterFromDate(
                                                    moment(value).format("YYYY-MM-DD").valueOf()
                                                );
                                            }}
                                        />
                                        <DatePicker
                                            size="large"
                                            style={{ width: "12" }}
                                            id="toDate"
                                            placeholder={t("to_date")}
                                            onChange={(value) => {
                                                setFilterToDate(
                                                    moment(value).format("YYYY-MM-DD").valueOf()
                                                );
                                            }}
                                        />
                                    </Space>
                                </div>
                            </div>
                            <div className="col-2">
                                <button
                                    style={{ background: "#58c8c8", color: "white" }}
                                    onClick={handleExportData}
                                    className="btn float-end"
                                >
                                   <i class="fas fa-file-export"></i> {t("export")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="m-2">
                        {" "}
                        <Table
                            columns={columns}
                            dataSource={data1}
                            pagination={{
                                pageSize: Number(pageSize),
                                current: Number(currentPage),
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                    setpageSize(pageSize);
                                    navigate(`?page=${page}&limit=${pageSize}`);
                                },
                                total: total,
                            }}
                        />
                    </div>
                )}
            </div>

            <Modal
                title={t("are_you_sure_to_delete_this_client")}
                open={selectedEmployee}
                cancelText={t("no")}
                okText={t("yes")}
                onOk={async () => {
                    try {
                        await userDeleteHandler(selectedEmployee).then((res) => {
                            if (res?.status === 200) {
                                responseNotification(
                                    t("client_deleted_successfully"),
                                    "success"
                                );
                                setSelectedEmployee(false);
                                // fetchClient();
                            } else {
                                setSelectedEmployee(false);
                                responseNotification(t("something_went_wrong"), "danger");
                            }
                        });
                    } catch (error) {
                        setSelectedEmployee(false);
                    }
                }}
                onCancel={() => {
                    setSelectedEmployee(false);
                }}
            >
                <p>{t("this_will_delete_the_client_permanently")}</p>
            </Modal>
            <ChatWithAdmin
                setOpen={setToggleSidebar}
                open={toggleSidebar}
                sender={sender}
                isAdmin={isAdmin}
                receiver={""} // for this case, the receiver is empty
            />
        </div>
    );
}

export default ClientList;

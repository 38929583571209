import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import JobPostCard from "../../../components/Cards/JobPost";
import JobPostSkeleton from "../../../components/Cards/JobPost/JobPostSkeleton";
import NotFound from "../../../components/frontend/employee/notFound/notFound";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import * as Action from "../_redux/candidateActions";
import { Pagination } from "antd";

export default function JobPostSection() {
  const dispatch = useDispatch();
  const jwtDecode = jwtTokenDecode();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const { jobsList, totalPages, loading, totalCount } = useSelector(
    ({ candidate }) => ({
      loading: candidate.listLoading,
      jobsList: candidate.job.entities,
      totalCount: candidate.job.totalCount,
      totalPages: candidate.job.totalPages,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(Action.getJobList(limit, page));
  }, [dispatch, page, limit]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // console.log({ jwtDecode });

  return (
    <div style={styles.container}>
      <Section
        title="All Job Posts"
        jobs={jobsList}
        jwtDecode={jwtDecode}
        loading={loading}
        isAllJob={true}
      />
      {jobsList?.length > 0 && (
        <section className="mt-5 mb-4 d-flex mx-auto justify-content-center">
          <Pagination
            current={page}
            total={totalCount}
            pageSize={limit}
            size="default"
            align="center"
            showSizeChanger={false}
            onChange={(page, limit) => {
              setPage(page);
            }}
          />
        </section>
      )}
    </div>
  );
}

function Section({ title, jobs, jwtDecode, loading, isAllJob }) {
  // console.log("isAllJob-section~", isAllJob);
  return (
    <div style={styles.section}>
      <h2 className="mt-3" style={styles.sectionTitle}>
        {title}
      </h2>
      {loading ? (
        <>
          <div className="row g-3">
            <div className="col-md-4">
              <JobPostSkeleton />
            </div>
            <div className="col-md-4">
              <JobPostSkeleton />
            </div>
            <div className="col-md-4">
              <JobPostSkeleton />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md-4">
              <JobPostSkeleton />
            </div>
            <div className="col-md-4">
              <JobPostSkeleton />
            </div>
            <div className="col-md-4">
              <JobPostSkeleton />
            </div>
          </div>
        </>
      ) : // <div style={{ paddingBottom: "100px" }}>
      //     <Loader />
      // </div>
      jobs?.length > 0 ? (
        <div style={styles.jobGrid}>
          {jobs?.map((job, index) => (
            <JobPostCard
              isAllJob={isAllJob}
              key={index}
              job={job}
              jwtDecode={jwtDecode}
            />
          ))}
        </div>
      ) : (
        <NotFound
          title={"No Job Available"}
          // description={t("you_have_not_been_booked.")}
        />
      )}
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    borderRadius: "15px",
    border: "1px solid #E0E0E0",
    // maxWidth: "1200px", // Adjusted for wider containers
    margin: "auto",
    backgroundColor: "white",
    marginTop: "20px",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "15px",
    color: "#333",
  },
  jobGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: "20px",
  },
  card: {
    borderRadius: "15px",
    border: "1px solid #E0E0E0", // Added border to match the original design
    padding: "20px",
    backgroundColor: "#fff",
    position: "relative", // For positioning button inside card
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  icon: {
    width: "40px", // Adjusted size for better visual
    height: "40px",
    marginRight: "10px",
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#333",
  },
  cardBody: {
    fontSize: "14px",
    color: "#555",
    marginBottom: "40px",
  },
  cardText: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    color: "#333", // Adjusted text color
  },
  emoji: {
    marginRight: "8px",
    marginLeft: "7px",
  },
  button: {
    background: "linear-gradient(to right, #58C8C8, #3F8E9B)",
    color: "#fff",
    border: "none",
    borderRadius: "15px 0px 15px 0px", // Adjusted border radius for custom corners
    padding: "5px 15px", // Adjusted padding for better spacing
    cursor: "pointer",
    textAlign: "center",
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
};

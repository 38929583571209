import { Col, DatePicker, Modal, Popconfirm, Row, Select, Table } from "antd";

import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FcCalendar } from "react-icons/fc";
import { token } from "../../../utils/authentication";
import {
  exactDate,
  getUserCurrencyByCountry,
  paramsToQueryString,
} from "../../../utils/commonFunctions";
import { donwloadCSV } from "../../../utils/static/donwloadCSV";
import Loader from "../../../components/loadar/Loader";
const BookingHistory = () => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [employeeName, setEmployeeName] = useState(undefined);
  const [allowed, setAllowed] = useState("ALL");
  const [totalEmployee, setTotalEmployee] = useState([]);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookedDates, setBookedDates] = useState([]);
  const columns = [
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    // },
    {
      title: t("restaurant_name"),
      dataIndex: "restaurantName",
      key: "restaurantName",
    },
    {
      title: t("employee_name"),
      dataIndex: "employeeName",
      key: "employeeName",
    },
    {
      title: t("position"),
      dataIndex: "position",
      key: "position",
    },
    {
      title: t("restaurant_rate"),
      dataIndex: "restaurantRate",
      key: "restaurantRate",
    },
    {
      title: t("contractor_rate"),
      dataIndex: "contractorRate",
      key: "contractorRate",
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return record.status === true ? "Active" : "Closed";
      },
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      // width: 100,
      render: (text, record) => (
        <div className="flex">
          <button
            className="btn"
            style={{ background: "#58c8c8", color: "white" }}
            onClick={() => {
              setIsModalVisible(true);
              setBookedDates(record?.bookedDate);
            }}
          >
            {t("view")}
          </button>
          <Popconfirm
            title={t("Are you sure you want to delete this booking?")}
            onConfirm={() => {
              console.log(record);
              axios
                .delete(
                  `${process.env.REACT_APP_API_BASE_URL}/book-history/${record._id}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token()}`,
                    },
                  }
                )
                .then((res) => {
                  setData(data.filter((item) => item._id !== record._id));
                  if (res.data.status === "success") {
                    toast.success("Booking deleted successfully");
                  }
                });
            }}
            okText={t("Yes")}
            cancelText={t("No")}
          >
            <button
              className="btn"
              style={{ background: "red", color: "white", marginLeft: "2px" }}
            >
              {t("Delete")}
            </button>
          </Popconfirm>
        </div>
      ),
    },
    // {
    //   title: "Start time",
    //   dataIndex: "startTime",
    //   key: "startTime",
    // },
    // {
    //   title: "End time",
    //   dataIndex: "endTime",
    //   key: "endTime",
    // },
  ];
  // client list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users?requestType=CLIENT&skipLimit=YES
      `,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setClients(
          res.data.users.map((item) => ({
            ...item,
            label: item.restaurantName,
            value: item._id,
          }))
        );
      });
  }, []);
  // booking history
  useEffect(() => {
    setLoader(true);
    const query = paramsToQueryString({
      startDate: startDate ? startDate : undefined,
      endDate: endDate ? endDate : undefined,
      hiredStatus: allowed === "ALL" ? undefined : allowed,
      restaurantName: selectedClient ? selectedClient : undefined,
      employeeName: employeeName ? employeeName : undefined,
    });
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/book-history?${query}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        setLoader(false);
        setData(
          res.data?.data?.map((item) => ({
            ...item,
            key: item._id,
            date: exactDate(item?.bookedDate[0]?.startDate),
            restaurantName: item?.restaurantDetails?.restaurantName,
            employeeName: item?.employeeDetails?.name,
            position: item?.employeeDetails?.positionName,
            restaurantRate:
              item?.employeeDetails?.hourlyRate +
              " " +
              getUserCurrencyByCountry(item?.employeeDetails?.countryName),
            contractorRate:
              item?.employeeDetails?.contractorHourlyRate +
              " " +
              getUserCurrencyByCountry(item?.employeeDetails?.countryName),
            startTime: item?.bookedDate[0]?.startTime,
            endTime: item?.bookedDate[0]?.endTime,
          }))
        );
      });
  }, [selectedClient, startDate, endDate, employeeName, allowed]);

  // employee list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users?requestType=EMPLOYEE&skipLimit=YES`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setTotalEmployee(
          res.data.users.map((item) => ({
            ...item,
            label: item.name,
            value: item._id,
          }))
        );
      });
  }, []);
  const countDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start.toDateString() === end.toDateString()) {
      return "1";
    }

    const timeDifference = Math.abs(end - start);
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1; // Add 1 to include both start and end dates

    return `${dayDifference}`;
  };

  const handleExportData = () => {
    const excelData = data.map((item) => {
      console.log(item?.finalCheckIn);
      return {
        "Restaurant Name": item?.restaurantName,
        "Employee Name": item?.employeeName,
        Position: item?.position,
        "Restaurant Rate": item?.restaurantRate,
        "Contractor Rate": item?.contractorRate,
      };
    });
    donwloadCSV(excelData, `Booking History (${startDate} - ${endDate})`);
  };

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="d-flex justify-content-between">
          <h3 className="mb-4 title">{t("booking_history")}</h3>
        </div>
      </div>
      {/* table here */}
      <div className="card">
        <div className="card-header">
          <div className="col-12">
            <div className="row">
              <div className="col-10">
                <Row gutter={[8, 8]}>
                  {/* <Col sm={24} md={12} lg={6}>
                                        <DatePicker
                                            size="large"
                                            onChange={(date, dateString) => {
                                                setStartDate(dateString);
                                                setEndDate(dateString);

                                            }}
                                            style={{ width: "100%" }}
                                            allowClear
                                        />
                                    </Col> */}
                  <Col sm={24} md={12} lg={8}>
                    <RangePicker
                      size="large"
                      onChange={(date, dateString) => {
                        setStartDate(dateString[0]);
                        setEndDate(dateString[1]);
                      }}
                      value={[dayjs(startDate), dayjs(endDate)]}
                      style={{ width: "100%" }}
                      allowClear={false}
                    />
                  </Col>
                  <Col sm={24} md={12} lg={6}>
                    <Select
                      showSearch={true}
                      placeholder={t("restaurant")}
                      optionFilterProp="children"
                      size="large"
                      allowClear
                      style={{ width: "100%" }}
                      onChange={(value, option) => {
                        setSelectedClient(option?.children);
                      }}
                    >
                      {clients?.map((item, index) => (
                        <Select key={index} value={item?._id}>
                          {item?.label}
                        </Select>
                      ))}
                    </Select>
                  </Col>
                  <Col sm={24} md={12} lg={6}>
                    <Select
                      showSearch={true}
                      placeholder={t("employee")}
                      optionFilterProp="children"
                      size="large"
                      allowClear
                      style={{ width: "100%" }}
                      onChange={(value, option) => {
                        setEmployeeName(option?.children);
                      }}
                    >
                      {totalEmployee?.map((item, index) => (
                        <Select key={index} value={item?._id}>
                          {item?.label}
                        </Select>
                      ))}
                    </Select>
                  </Col>
                  <Col sm={24} md={12} lg={6}>
                    <Select
                      showSearch={true}
                      placeholder={t("Type")}
                      optionFilterProp="children"
                      size="large"
                      // allowClear
                      style={{ width: "50%" }}
                      onChange={(value, option) => {
                        setAllowed(option?.children);
                      }}
                      value={allowed}
                    >
                      <Select value="ALL">ALL</Select>
                      <Select value="ALLOW">Active</Select>
                      <Select value="DENY">Closed</Select>
                    </Select>
                  </Col>
                </Row>
              </div>
              <div className="col-2">
                <button
                  style={{ background: "#58c8c8", color: "white" }}
                  onClick={handleExportData}
                  className="btn float-end"
                >
                  {t("export")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {loader ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
            }}
          />
        )}
      </div>
      <Modal
        title={t("booked_dates")}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
      >
        {bookedDates?.map((item, index) => (
          <>
            <Row
              key={index}
              style={{
                background: "#FAFAFA",
                padding: "10px",
                margin: "10px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Col style={{}}>
                <div>
                  <FcCalendar
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  {item?.startDate} - {item?.endDate}
                </div>
                <div
                  style={{
                    color: "#58c8c8",
                    fontWeight: "bold",
                  }}
                >
                  {countDates(item?.startDate, item?.endDate)} {t("days")}
                </div>
                <div>
                  {item?.startTime} - {item?.endTime}
                </div>
              </Col>
            </Row>
          </>
        ))}
      </Modal>
    </div>
  );
};

export default BookingHistory;

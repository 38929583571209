import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../../routes/PrivateRoute";
import MasterLayout from "../../layouts/admin/MasterLayout";
import Dashboard from "./Dashboard";
import Position from "./position/Position";
import Skill from "./skill/Skill";  
import Source from "./source/Source";
import EmployeePortfolio from "./employee/EmployeePortfolio";
import EmployeePortfolioDetails from "./employee/EmployeePortfolioDetails";
import EmployeeList from "./employee/EmployeeList";
import OthersEmployeeList from "./employee/OthersEmployeeList";
import ViewEmployeeDetails from "./employee/ViewEmployeeDetails";
import CourseSchedule from "./course/CourseSchedule";
import CourseList from "./course/CourseList";
import EmployeeDetails from "./employee/EmployeeDetails";
import ViewCertificate from "./employee/ViewCertificate";
import ClientList from "./client/ClientList";
import SubscriptionPlan from "./subscription/SubscriptionPlan";
import ClientDetails from "./client/ClientDetails";
import AddMeet from "./meet/AddMeet";
import ViewMeet from "./meet/ViewMeet";
import SingleMeet from "./meet/SingleMeet";
import BookingHistory from "./bookingHistory/BookingHistory";
import BookForClient from "./bookForClient/BookForClient";
import EmployeePaymentHistory from "./employeePayment/EmployeePaymentHistory";
import ManageReview from "./review/ManageReview";
import AddMHEmployee from "./mhEmployee/AddMHEmployee";
import MHEmployeeList from "./mhEmployee/MHEmployeeList";
import EditMHEmployee from "./mhEmployee/EditMHEmployee";
import ContactList from "./contact/ContactList";
import InvoiceList from "./invoice/InvoiceList";
import CourseCategory from "./course/CourseCategory";
import CoursePayment from "./course/CoursePayment";
import CoursePromotion from "./course/CoursePromotion";
import CreateAbout from "./about/CreateAbout";
import AboutTable from "./about/AboutTable";
import EditAbout from "./about/EditAbout";
import CreateTermCondition from "./termsconditions/CreateTermsConditions";
import TermsTable from "./termsconditions/TermsTable";
import EditTerms from "./termsconditions/editTerms";
import CreateRole from "./Roles/CreateRole";
import RoleList from "./Roles/AllRoles";
import CreateService from "./Service/CreateService";
import CreateMenu from "./Menu/CreateMenu";
import ServiceList from "./Service/ServiceList";
import MenuLists from "./Menu/MenuLists";
import MenuPermission from "./MenuPermission/MenuPermission";
import MenuPermissionList from "./MenuPermission/MenuPermissionList";
import ComplainList from "./complain/ComplainList";
import SupportList from "./support/Support";
import CreatePermission from "./Permission/CreatePermission";
import PermissionList from "./Permission/PermissionList";
import AddInvoice from "./invoice/AddInvoice";
import NotificationList from "./notification/NotificationList";
import ChangePassword from "./ChangePassword";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route
          index
          path="dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="position"
          element={
            <PrivateRoute>
              <Position />
            </PrivateRoute>
          }
        />
        <Route
          path="skill"
          element={
            <PrivateRoute>
              <Skill />
            </PrivateRoute>
          }
        />
        <Route
          path="source"
          element={
            <PrivateRoute>
              <Source />
            </PrivateRoute>
          }
        />
        <Route
          path="employee-portfolio"
          element={
            <PrivateRoute>
              <EmployeePortfolio />
            </PrivateRoute>
          }
        />
        <Route
          path="employee-portfolio-details/:id"
          element={
            <PrivateRoute>
              <EmployeePortfolioDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="employee-list"
          element={
            <PrivateRoute>
              <EmployeeList />
            </PrivateRoute>
          }
        />
        <Route
          path="others-employee-list"
          element={
            <PrivateRoute>
              <OthersEmployeeList />
            </PrivateRoute>
          }
        />
        <Route
          path="course-schedule"
          element={
            <PrivateRoute>
              <CourseSchedule />
            </PrivateRoute>
          }
        />
        <Route
          path="employee-details/:id"
          element={
            <PrivateRoute>
              <EmployeeDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="view-employee-details/:id"
          element={
            <PrivateRoute>
              <ViewEmployeeDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="view-certificate/:id"
          element={
            <PrivateRoute>
              <ViewCertificate />
            </PrivateRoute>
          }
        />
        <Route
          path="client-list"
          element={
            <PrivateRoute>
              <ClientList />
            </PrivateRoute>
          }
        />

        <Route
          path="subscription-plan"
          element={
            <PrivateRoute>
              <SubscriptionPlan />
            </PrivateRoute>
          }
        />

        <Route
          path="client-details/:id"
          element={
            <PrivateRoute>
              <ClientDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="create-meet"
          element={
            <PrivateRoute>
              <AddMeet />
            </PrivateRoute>
          }
        />
        <Route
          path="get-meets"
          element={
            <PrivateRoute>
              <ViewMeet />
            </PrivateRoute>
          }
        />
        <Route
          path="view-meet/:id"
          element={
            <PrivateRoute>
              <SingleMeet />
            </PrivateRoute>
          }
        />
        <Route
          path="booking-history"
          element={
            <PrivateRoute>
              <BookingHistory />
            </PrivateRoute>
          }
        />
        <Route
          path="book-for-client"
          element={
            <PrivateRoute>
              <BookForClient />
            </PrivateRoute>
          }
        />
        <Route
          path="employee-payment-history"
          element={
            <PrivateRoute>
              <EmployeePaymentHistory />
            </PrivateRoute>
          }
        />
        <Route
          path="manage-review"
          element={
            <PrivateRoute>
              <ManageReview />
            </PrivateRoute>
          }
        />
        {/* mh employee register from admin route here */}
        <Route
          path="add-mh-employee"
          element={
            <PrivateRoute>
              <AddMHEmployee />
            </PrivateRoute>
          }
        />
        <Route
          path="mh-employee-list"
          element={
            <PrivateRoute>
              <MHEmployeeList />
            </PrivateRoute>
          }
        />
        <Route
          path="edit-mh-employee/:id"
          element={
            <PrivateRoute>
              <EditMHEmployee />
            </PrivateRoute>
          }
        />

        <Route
          path="contact-list"
          element={
            <PrivateRoute>
              <ContactList />
            </PrivateRoute>
          }
        />
        <Route
          path="invoice-list"
          element={
            <PrivateRoute>
              <InvoiceList />
            </PrivateRoute>
          }
        />
        <Route
          path="course-list"
          element={
            <PrivateRoute>
              <CourseList />
            </PrivateRoute>
          }
        />
        <Route
          path="course-category"
          element={
            <PrivateRoute>
              <CourseCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="course-payment"
          element={
            <PrivateRoute>
              <CoursePayment />
            </PrivateRoute>
          }
        />

        <Route
          path="course-promotion"
          element={
            <PrivateRoute>
              <CoursePromotion />
            </PrivateRoute>
          }
        />
        <Route
          path="create-about"
          element={
            <PrivateRoute>
              <CreateAbout />
            </PrivateRoute>
          }
        />
        <Route
          path="about-table"
          element={
            <PrivateRoute>
              <AboutTable />
            </PrivateRoute>
          }
        />
        <Route
          path="edit-about/:slug"
          element={
            <PrivateRoute>
              <EditAbout />
            </PrivateRoute>
          }
        />
        <Route
          path="create-terms"
          element={
            <PrivateRoute>
              <CreateTermCondition />
            </PrivateRoute>
          }
        />
        <Route
          path="terms-table"
          element={
            <PrivateRoute>
              <TermsTable />
            </PrivateRoute>
          }
        />
        <Route
          path="terms-edit/:id"
          element={
            <PrivateRoute>
              <EditTerms />
            </PrivateRoute>
          }
        />
        <Route
          path="create-role"
          element={
            <PrivateRoute>
              <CreateRole />
            </PrivateRoute>
          }
        />
        <Route
          path="role-list"
          element={
            <PrivateRoute>
              <RoleList />
            </PrivateRoute>
          }
        />
        <Route
          path="create-service"
          element={
            <PrivateRoute>
              <CreateService />
            </PrivateRoute>
          }
        />
        <Route
          path="create-menu"
          element={
            <PrivateRoute>
              <CreateMenu />
            </PrivateRoute>
          }
        />
        <Route
          path="service-list"
          element={
            <PrivateRoute>
              <ServiceList />
            </PrivateRoute>
          }
        />
        <Route
          path="menu-list"
          element={
            <PrivateRoute>
              <MenuLists />
            </PrivateRoute>
          }
        />
        <Route
          path="add-menu-permission"
          element={
            <PrivateRoute>
              <MenuPermission />
            </PrivateRoute>
          }
        />
        <Route
          path="complain"
          element={
            <PrivateRoute>
              <ComplainList />
            </PrivateRoute>
          }
        />
        <Route
          path="support"
          element={
            <PrivateRoute>
              <SupportList />
            </PrivateRoute>
          }
        />
        <Route
          path="menu-permission-list"
          element={
            <PrivateRoute>
              <MenuPermissionList />
            </PrivateRoute>
          }
        />
        <Route
          path="create-permission"
          element={
            <PrivateRoute>
              <CreatePermission />
            </PrivateRoute>
          }
        />
        <Route
          path="permission-list"
          element={
            <PrivateRoute>
              <PermissionList />
            </PrivateRoute>
          }
        />

        <Route
          path="add-invoice"
          element={
            <PrivateRoute>
              <AddInvoice />
            </PrivateRoute>
          }
        />
        <Route
          path="notification-list"
          element={
            <PrivateRoute>
              <NotificationList />
            </PrivateRoute>
          }
        />
        <Route
          path="change-password"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { BiCamera } from "react-icons/bi";
import { token } from "../../../utils/authentication";
import default_profile from "../../../assets/images/Client.jpg";
import * as Actions from "../../../pages/Client/_redux/clientActions";
import { clientSlice } from "../../../pages/Client/_redux/clientSlice";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Image, Spin, Upload } from "antd";
import { useUser } from "../../../contexts/user-context";
import { createSlug } from "../../../utils/slug";
import { Toast } from "../../../utils/toast";
import { getFormateName } from "../../../utils/commonFunctions";

const { actions } = clientSlice;

export default function CreatePost({
  setCurrentPage,
  edit,
  selectedPost,
  setSelectedPost,
  handleUpdatePost,
  post,
  fetchPostListData,
}) {
  const jwtDecode = jwtTokenDecode();
  const { currentUser } = useUser();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [postCreateLoading, setPostCreateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // console.log("files~~", files);
  const { actionLoading, isCreatePostSuccess } = useSelector(({ client }) => ({
    actionLoading: client.actionsLoading,
    isCreatePostSuccess: client.isCreatePostSuccess,
  }));

  useEffect(() => {
    if (isCreatePostSuccess) {
      toast.success("Post Uploaded");
      setSelectedPost({});
      setContent("");
      setFiles([]);
      setSelectedFiles([]);
      dispatch(actions.postCreatedSuccess(false));
    }
  }, [dispatch, isCreatePostSuccess, setSelectedPost]);

  // console.log("isCreatePostSuccess~~", isCreatePostSuccess);

  function getFileType(file) {
    if (!file) {
      return "unknown";
    }

    const fileType = file.type;

    if (fileType.startsWith("image/")) {
      return "image";
    } else if (fileType.startsWith("video/")) {
      return "video";
    }

    return "unknown";
  }

  const handleOnSubmitPost = async () => {
    // console.log("handleOnSubmitPost", files);
    setPostCreateLoading(true);
    if (selectedPost && Object.keys(selectedPost).length > 0) {
      // update post
      dispatch(Actions.updatePost(selectedPost?._id, content, files));
      setFileList([]);
      setFiles([]);
    } else {
      // create a new post
      dispatch(Actions.createPost(content, files, currentUser));
      setFileList([]);
      setFiles([]);
    }
    setPostCreateLoading(false);
  };

  useEffect(() => {
    if (selectedPost && Object.keys(selectedPost).length > 0) {
      setContent(selectedPost?.content);
      setFiles(selectedPost?.media);
      setSelectedFiles(selectedPost?.media);
    } else {
      setContent("");
      setFiles([]);
    }
  }, [selectedPost]);

  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleUpload = async (file) => {
    setUploadLoading(true);
    // console.log("handle-upload~~", file);
    const formData = new FormData();
    formData.append("file", file);

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/commons/media-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        if (res.data?.status === "success") {
          setFiles((prev) => [
            ...prev,
            {
              url: res.data?.data?.file,
              type: getFileType(file),
              uid: file.uid,
            },
          ]);
          setUploadLoading(false);
        } else {
          alert("Invalid File Type");
        }
      })
      .catch((err) => alert("Invalid File Type"));

    setUploadLoading(false);
  };

  const unsupportedImageTypes = ["image/x-icon", "image/gif"];

  const beforeUpload = (file) => {
    if (unsupportedImageTypes.includes(file.type)) {
      Toast.error("Unsupported Image Type");
      return false;
    } else {
      handleUpload(file);
      return false;
    }

    // return false;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const gotoProfileHandler = (id = "") => {
    // console.log("id~~", id);
    if (jwtDecode?.role === "EMPLOYEE") {
      window.open(`/candidate/profile/${id}`, "_blank");
    } else {
      window.open(`/profile/${id}`, "_blank");
    }
  };

  const uploadButton = (
    <div>
      <UploadOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleRemove = (file) => {
    if (!file) {
      return;
    }

    const {
      originFileObj: { name },
    } = file;

    console.log({ file });

    const updatedFileList = fileList.filter((item) => item.name !== name);

    const updatedFiles = files.filter((item) => {
      return item.uid !== file.uid;
    });
    // console.log("updatedFileList", updatedFileList);
    // console.log("updatedFiles", updatedFiles);
    setFileList(updatedFileList);
    setFiles(updatedFiles);
  };

  const profilePicture = currentUser?.profilePicture
    ? currentUser?.profilePicture.includes("lh3.googleusercontent.com")
      ? currentUser?.profilePicture
      : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${currentUser?.profilePicture}`
    : jwtDecode?.profilePicture
    ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`
    : jwtDecode?.role === "CLIENT"
    ? "/assets/Client.jpg"
    : "/assets/Employee.jpg";

  // console.log("selectedPost~~", selectedPost);
  // console.log("fileList~~", fileList);
  // console.log("files~~", files);

  return (
    <div className="create-post-card">
      <div className="create-post-header">
        <div>
          <img
            src={profilePicture}
            alt={
              currentUser?.role === "CLIENT"
                ? currentUser?.restaurantName
                : currentUser?.name
            }
            className="user-image"
          />
        </div>

        <span
          to=""
          className="user_name_title"
          onClick={() => gotoProfileHandler(jwtDecode?._id)}
        >
          {jwtDecode?.role === "CLIENT"
            ? currentUser?.restaurantName || jwtDecode?.restaurantName
            : currentUser?.name || jwtDecode?.name}
        </span>
      </div>

      <div className="post_body">
        <textarea
          className="textarea"
          placeholder="Type here..."
          onChange={(e) => setContent(e.target.value)}
          value={content}
        />

        <div className="create-post-actions">
          <Upload
            listType="picture-card"
            fileList={fileList}
            multiple
            onPreview={handlePreview}
            onChange={handleChange}
            accept="image/*,video/*"
            onRemove={handleRemove}
            beforeUpload={beforeUpload} // Use custom upload handler
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>

          {previewImage && (
            <Image
              wrapperStyle={{
                display: "none",
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
          {selectedFiles?.length > 0 && (
            <p className="text-left ml-0">Previous</p>
          )}

          <div className="d-flex gap-3 mb-3">
            {selectedFiles?.map((item, i) => (
              <div key={item?.url} style={{ position: "relative" }}>
                <Image
                  width={100}
                  src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item?.url}`}
                />
                <Button
                  type="text"
                  danger
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    const updatedFiles = selectedFiles.filter(
                      (_, index) => index !== i
                    );
                    setSelectedFiles(updatedFiles);
                    setFiles(updatedFiles);
                  }}
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>

          {/* <div className="d-flex gap-3 mb-3">
            {selectedFiles?.map((item, i) => (
              <Image
                width={100}
                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item?.url}`}
              />
            ))}
          </div> */}

          {uploadLoading && (
            <div style={{ textAlign: "center", marginTop: 16 }}>
              <Spin size="small" />
            </div>
          )}

          {!uploadLoading && (content.trim() !== "" || files.length > 0) && (
            <Button
              className="publish-button p-0 px-4"
              type="primary"
              onClick={() => handleOnSubmitPost()}
              loading={loading}
              disabled={
                loading &&
                (fileList.length === 0 ||
                  content?.trim() === "" ||
                  !uploadLoading)
              }
            >
              {selectedPost && Object.keys(selectedPost).length > 0
                ? actionLoading
                  ? "Updating"
                  : "Update Now"
                : actionLoading
                ? "Publishing.."
                : "Publish Now"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./SocialChat.css";
import { FaChevronDown, FaChevronUp, FaPlus, FaMinus } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { jwtTokenDecode } from "../../utils/jwtDecode";
import axiosInstance from "../../utils/API";
import { useUser } from "../../contexts/user-context";
import { getProfilePicture } from "../Shared/getProfilePictureUrl";
import { formatDateForUnreadMsg } from "../../utils/date";
import { Colors } from "../../constants/Colors";
import SocialConversation from "./_components/SocialConversation";
import { useSearchParams } from "react-router-dom";
import { Skeleton } from "antd";
import { useCommon } from "../../contexts/common-context";
import { getFormateName, getUserId } from "../../utils/commonFunctions";
import { useSocket } from "../../hooks/useSocket";
const messageList = [
  {
    name: "Brian Smith",
    date: "Jan 5",
    text: "You: Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  },
];

const fetchConversionList = async () => {
  const response = await axiosInstance.get("/conversations");
  return response?.data;
};

const SocialChat = () => {
  const { getNewMessages } = useSocket();
  const jwtDecode = jwtTokenDecode();
  const { isChatBoxListVisible, setChatBoxListVisible } = useCommon();
  // const [isChatBoxListVisible, setChatBoxListVisible] = useState(true);
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isMessageInputVisible, setMessageInputVisible] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [socketData, setSocketData] = useState({});
  const [previousMessages, setPreviousMessages] = useState(false);
  const { currentUser } = useUser();
  const queryClient = useQueryClient();
  const { data, isLoading: chatListLoading } = useQuery({
    queryKey: ["chat", jwtDecode?._id],
    queryFn: async () => await fetchConversionList(),
    enabled: !!jwtDecode?._id,
  });

  const toggleChatBoxList = () => {
    setChatBoxListVisible((prev) => !prev);
  };

  const toggleMessageInput = () => {
    setMessageInputVisible((prev) => !prev);
  };

  const selectedUserHandler = (conversation) => {
    if (!conversation?._id) {
      return;
    }
    setSelectedUser(null);
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("conversationId", conversation?._id);
      return params;
    });
    setPage(1);
    setMessages([]);
    setSocketData({});
    setPreviousMessages(false);
    setSelectedUser(conversation);
    queryClient.invalidateQueries(["chat", jwtDecode?._id]);
  };

  useEffect(() => {
    if (getNewMessages) {
      if (
        getNewMessages?.message?.receiverDetails?.find(
          (item) => item?.receiverId == getUserId()
        )
      ) {
        queryClient.invalidateQueries(["chat", jwtDecode?._id]);
      }
    }
  }, [getNewMessages]);

  // console.log("current-user", currentUser);
  return (
    <>
      <div className="chat__box">
        <div
          className="d-flex align-items-center justify-content-between p-1"
          onClick={toggleChatBoxList} // Toggle chat list on click
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex align-items-center">
            <img
              src={getProfilePicture(
                currentUser?.profilePicture || jwtDecode?.profilePicture,
                jwtDecode?.role
              )}
              alt="Profile"
              className="profile_thumb rounded-50"
            />
            <div className="fs-14 ms-2">Messages</div>
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            {/* <i className="fa-solid fa-ellipsis"></i>
            <i className="fa-solid fa-pen-to-square"></i> */}
            {isChatBoxListVisible ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>

        {/* Conditionally render chatBoxList based on visibility state */}
        {isChatBoxListVisible && (
          <div className="border-top">
            <div className="px-2 mt-2 message_search_box position-relative">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input type="text" placeholder="Search Messages" />
            </div>
            <ul className="mt-4 p-0 chat_list">
              {chatListLoading ? (
                Array(5)
                  .fill()
                  .map((_, index) => (
                    <li key={index} className="d-flex align-items-center mb-3">
                      <div className="me-2">
                        <Skeleton.Avatar active size="large" />
                      </div>
                      <div className="flex-grow-1">
                        <Skeleton.Input active size="small" className="mb-2" />
                        <Skeleton.Input active size="small" />
                      </div>
                    </li>
                  ))
              ) : data?.conversations.length > 0 ? (
                data?.conversations
                  .slice(0)
                  .sort((a, b) => {
                    const dateA = a.latestMessage?.createdAt
                      ? new Date(a.latestMessage.createdAt)
                      : new Date(a.createdAt);
                    const dateB = b.latestMessage?.createdAt
                      ? new Date(b.latestMessage.createdAt)
                      : new Date(b.createdAt);
                    return dateB - dateA; // Descending order
                  })
                  .map((conversation, index) => {
                    // console.log({ conversation });
                    const { members, isAdmin } = conversation;
                    if (members?.length === 0) {
                      return <div></div>;
                    }
                    //   console.log("members~~", members);
                    const member = members[0];
                    const { role } = member;
                    const name = isAdmin
                      ? "Plagit Support"
                      : role === "CLIENT"
                      ? member?.restaurantName
                      : member?.name;
                    const profilePicture = getProfilePicture(
                      member?.profilePicture,
                      role
                    );
                    return (
                      <li
                        key={index}
                        className="d-flex conversation-list fs-14 cursor-pointer align-items-center py-2 px-2"
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          backgroundColor:
                            selectedUser &&
                            selectedUser?._id === conversation?._id
                              ? "#f5f5f5"
                              : "#ffff",
                        }}
                        onClick={() => selectedUserHandler(conversation)}
                      >
                        <div className="me-2">
                          <img
                            src={profilePicture}
                            alt="Profile"
                            className="profile_thumb rounded-50"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </div>
                        <div className="flex-grow-1">
                          <div className="d-flex justify-content-between mb-1">
                            <div>{getFormateName(name)}</div>
                            <div style={{ fontSize: "12px", color: "#999" }}>
                              {formatDateForUnreadMsg(
                                conversation?.latestMessage?.dateTime
                              )}
                            </div>
                          </div>
                          <div style={{ fontSize: "12px", color: "#999" }}>
                            {conversation?.latestMessage?.text?.length > 30
                              ? `${conversation.latestMessage.text.slice(
                                  0,
                                  30
                                )}...`
                              : conversation?.latestMessage?.text}
                          </div>
                        </div>
                        {conversation?.unreadMsg > 0 &&
                          jwtDecode?._id !=
                            conversation?.latestMessage?.createdBy && (
                            <div
                              style={{ bottom: "5px" }}
                              className="unread-msg-count position-absolute"
                            >
                              <b>{conversation?.unreadMsg}</b>
                            </div>
                          )}
                      </li>
                    );
                  })
              ) : (
                <div>
                  <h3
                    className="h3 fw-semibold"
                    style={{
                      color: Colors.primary,
                    }}
                  >
                    There is no conversation
                  </h3>
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
      {selectedUser && (
        <SocialConversation
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setMessages={setMessages}
          messages={messages}
          setPreviousMessages={setPreviousMessages}
          previousMessages={previousMessages}
          socketData={socketData}
          setSocketData={setSocketData}
          page={page}
          setPage={setPage}
          onMessageSent={() =>
            queryClient.invalidateQueries(["chat", jwtDecode?._id])
          } // Refresh conversations
        />
      )}
    </>
  );
};

export default SocialChat;

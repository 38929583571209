import { combineReducers } from "redux";
import { authSlice } from "../pages/Auth/_redux/authSlicer";
import { candidateSlice } from "../pages/Candidate/_redux/candidateSlice";
import { clientSlice } from "../pages/Client/_redux/clientSlice";

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  client: clientSlice.reducer,
  candidate: candidateSlice.reducer,
});

import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import EmployeeNav from "./EmployeeNav";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import Footer from "../../../layouts/frontend/Footer";

export default function EmployeeLayout() {
  const jwt_decode = jwtTokenDecode();
  const navigate = useNavigate();

  useEffect(() => {
    if (jwt_decode?.role == "EMPLOYEE") {
      return;
    } else {
      navigate("/login");
    }
  }, [jwt_decode, navigate]);

  return (
    <>
      <EmployeeNav />
      <Outlet />
      <Footer />
    </>
  );
}

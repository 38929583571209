/* eslint-disable react/prop-types */
import "react-multi-carousel/lib/styles.css";
import EventCard from "./EventCard";
import CarouselTemplate from "./CarouselTemplate";

const EventCardCarousel = ({ events }) => {
  return (
    <CarouselTemplate>
      {events.map((event, index) => (
        <div key={index} className="mx-3 mt-5 mb-5">
          <EventCard
            key={index}
            title={event.title}
            location={event.location}
            date={event.date}
            time={event.time}
            entryFee={event.entryFee}
            imageUrl={event.imageUrl}
            detailsUrl={event.detailsUrl}
          /> 
        </div>
      ))}
    </CarouselTemplate>
  );
};

export default EventCardCarousel;

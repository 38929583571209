import React from "react";
import { Route, Routes } from "react-router-dom";
import EmployeeLayout from "./Layout/EmployeeLayout";
import EmployeeHome from "./Home/EmployeeHome";
import EmployeeProfile from "./Profile/EmployeeProfile";
import EmployeeCalendar from "./Calender/EmployeeCalendar";
import EmployeeDashboard from "./Dashboard/EmployeeDashboard";
import ComingSoonPage from "./ComingSoon";
import PlagitPlusPage from "./PlagitPlus";
import EmployeeBookedHistory from "./BookHistory/EmployeeBookedHistory";
import EmployeeHiredHistory from "./HiredHistory/EmployeeHiredHistory";
import EmployeePaymentHistoryForProfile from "./Payment/EmployeePaymentHistoryForProfile";
import MySocialPost from "../Client/ClientProfile/MySocialPost";
import CandidateChat from "./Chat/CandidateChat";
import UserProfile from "../Client/UserProfile/UserProfile";
import JobDetails from "../../components/frontend/employee/jobs/JobDetails";

const CandidateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EmployeeLayout />}>
        <Route path="home" element={<EmployeeHome />} />
        <Route path="profile" element={<EmployeeProfile />} />
        <Route path="profile/:id" element={<UserProfile />} />
        <Route path="my-social-post" element={<MySocialPost />} />
        {/* <Route path="social-post-details/:id" element={<ViewMediaPost />} /> */}
        <Route path="my-calender" element={<EmployeeCalendar />} />
        <Route path="dashboard" element={<EmployeeDashboard />} />
        <Route path="coming-soon" element={<ComingSoonPage />} />
        <Route path="plagit-plus" element={<PlagitPlusPage />} />
        <Route path="job-details/:id" element={<JobDetails />} />
        <Route
          path="employee-booked-history"
          element={<EmployeeBookedHistory />}
        />
        <Route
          path="employee-hired-history"
          element={<EmployeeHiredHistory />}
        />
        <Route
          path="payment-history"
          element={<EmployeePaymentHistoryForProfile />}
        />
        <Route path="/help-support" element={<CandidateChat />} />
      </Route>
    </Routes>
  );
};

export default CandidateRoutes;

import { io } from "socket.io-client";

const socketURL = process.env.REACT_APP_SOCKET_URL;

// Initialize a singleton socket connection
const socket = io(socketURL, {
  withCredentials: false,
  transports: ["websocket"],
});

// Connect only once
socket.connect();

// Listen for reconnection events if needed
socket.on("connect", () => {
  console.log("Socket connected:", socket.id);
});

socket.on("disconnect", () => {
  console.log("Socket disconnected");
});

export default socket;

import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { createContext, useEffect, useState } from "react";
import { token } from "../utils/authentication";

export const ContextApi = createContext();

const ContextProvider = ({ children }) => {
  const [getShortList, setShortListCount] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [loadingShortList, setLoadingShortList] = useState(false);
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [shouldRefetchShortList, setShouldRefetchShortList] = useState(false);
  const [shouldRefetchNotificationList, setShouldRefetchNotificationList] =
    useState(false);

  const token1 = token(); // Replace this with your logic to get the token
  let loggedInCountryName = "United Kingdom"; // Default country name
  let currencyType = "£"; // Default currency symbol
  const currencySymbols = {
    "united kingdom": "£",
    "united arab emirates": "د.إ",
  };

  if (token1) {
    const decodedUserData = jwtDecode(token1);
    // console.log("decodedUserData: ", decodedUserData);
    if (decodedUserData.countryName) {
      currencyType =
        currencySymbols[decodedUserData.countryName.toLowerCase()] || "£";
      loggedInCountryName = decodedUserData.countryName;
    }
  }

  // console.log("decodedUserData: ", currencyType);
  // Fetch notifications
  useEffect(() => {
    const fetchNotifications = async () => {
      setLoadingNotification(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/notifications/list?readStatus=false`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setNotificationCount(res.data.total);
      } catch (error) {
        console.log("error~~", error);
      }
      setLoadingNotification(false);
    };
    fetchNotifications();
  }, [notificationCount, shouldRefetchNotificationList]);

  // Fetch shortlist
  useEffect(() => {
    const fetchShortListData = async () => {
      setLoadingShortList(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/short-list/list`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (res?.status === 201) {
        setShortListCount(res.data);
      }
      setLoadingShortList(false);
    };
    fetchShortListData();
  }, [shouldRefetchShortList]); // Add shouldRefetchShortList to the dependency array

  return (
    <ContextApi.Provider
      value={{
        notificationCount,
        setNotificationCount,
        getShortList,
        setShortListCount,
        loadingShortList,
        loadingNotification,
        shouldRefetchShortList,
        setShouldRefetchShortList,
        shouldRefetchNotificationList,
        setShouldRefetchNotificationList,
        currencySymbols,
        currencyType,
        loggedInCountryName,
      }}
    >
      {children}
    </ContextApi.Provider>
  );
};

export default ContextProvider;



const CouponCard = ({
    imageUrl,
    restaurantLogo,
    restaurantName,
    discount,
    offerDetails,
    expirationDate,
}) => {
    return (
        <div className='ticket-shape-bg'>
            <div className='card-body coupon_body ticket-shape'>
                <div className="coupon-card">
                    <img
                        src={imageUrl}
                        alt="Coupon"
                        className="coupon-image"
                    />

                    <div className="coupon-content">
                        <div className="restaurant-name">
                            <img
                                src={restaurantLogo}
                                alt="Restaurant Logo"
                                className="restaurant-logo"
                            />
                            {restaurantName}
                        </div>
                        <div className="coupon-discount">{discount}</div>
                        <div className="coupon-condition"> {offerDetails} </div>

                        <div className='card_footer'>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="coupon-expiry">
                                    Before <br />
                                    {expirationDate}
                                </div>
                                <button className="coupon-button">Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CouponCard;

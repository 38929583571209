import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../components/Profile/my_profile_style.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as Action from "../_redux/clientActions";
import PostFeedCardMulti from "../../../components/frontend/dashboard/SocialFeed/PostFeedCardMulti";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import SkeletonPostCard from "../../../components/Cards/SocialFeeds/SkeletonPostCard";
import { FaBuilding, FaMapMarkerAlt, FaBriefcase } from "react-icons/fa";
import { useQuery } from "react-query";
import { useUser } from "../../../contexts/user-context";
import { fetchSingleEmployeeData } from "../../../services/userServices";
import EmployeeDetailsLeftCard from "../../../components/EmployeeDetailsLeftCard/EmployeeDetailsLeftCard";
import UserProfileSkeleton from "./UserProfileSkeleton";
import {
  getFlagByCountry,
  getProfilePicture,
} from "../../../utils/commonFunctions";
export default function UserProfile() {
  const dispatch = useDispatch();
  const jwtDecode = jwtTokenDecode();
  const [currentPage, setCurrentPage] = useState(1);
  const [edit, setEdit] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  //   const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedPost, setSelectedPost] = useState({});
  const { user } = useSelector((state) => state.auth);
  const { currentUser } = useUser();
  const { id } = useParams();
  const [posts, setPosts] = useState([]);

  // current user details
  const { data: selectedUser = {}, isLoading } = useQuery(
    ["singleEmployee", id],
    () => fetchSingleEmployeeData(id), // Fetching function
    {
      enabled: !!id,
    }
  );
  // assign to user context
  // useEffect(() => {
  //   if (getSingleEmployeeDetails && userId) {
  //     setCurrentUser(getSingleEmployeeDetails);
  //   }
  // }, [getSingleEmployeeDetails, userId, setCurrentUser]);

  const { myPostList, totalPostPages, postLoading, totalPages } = useSelector(
    ({ client }) => ({
      postLoading: client?.listLoading,
      postList: client?.postData.entities ?? [],
      totalPostPages: client?.postData.totalPages,
      myPostList: client?.myPostData?.entities ?? [],
      totalPages: client.myPostData?.totalPages,
    }),
    shallowEqual
  );

  useEffect(() => {
    setPosts(myPostList);
  }, [myPostList]);

  // Fetch posts using Redux
  const fetchPostListData = useCallback(() => {
    dispatch(Action.fetchPosts("mypost", pageSize, currentPage, id));
  }, [currentPage, dispatch, id, pageSize]);

  useEffect(() => {
    fetchPostListData();
  }, [currentPage, fetchPostListData]);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     const user = await axiosInstance.get(`/users/${id}`);
  //     if (user?.data) {
  //       const { details } = user.data;
  //       setSelectedUser(details);
  //     }
  //   };
  //   fetchUser();
  // }, [id]);

  const [isTabSticky, setIsTabSticky] = useState(false);

  // Function to handle post updates
  const handleUpdatePost = async (updatedPost) => {
    try {
      // Update the local state with the updated post
      setPosts(
        posts.map((post) => (post._id === updatedPost._id ? updatedPost : post))
      );
    } catch (error) {
      console.error("Failed to update post:", error);
    }
  };

  const fetchMorePosts = () => {
    const temp = Math.ceil(posts.length / pageSize);
    if (currentPage < totalPages) {
      if (posts.length > 0 && pageSize > 0 && temp !== currentPage) {
        setCurrentPage(temp + 1);
      } else {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    } else {
      setHasMore(false);
    }
  };

  // Function to handle post updates
  const handleDeletePost = async (post) => {
    // console.log("post~~", post);
    // try {
    //   // Update the local state with the updated post
    //   const allpost = [...posts];
    //   const data = allpost.filter((item) => item?._id != post?._id);
    //   console.log({ data });
    //   setPosts(data);
    // } catch (error) {
    //   console.error("Failed to update post:", error);
    // }
  };

  useEffect(() => {
    if (currentPage <= totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    const handleScrollPage = () => {
      if (window.scrollY > 400) {
        setIsTabSticky(true);
      } else {
        setIsTabSticky(false);
      }
    };

    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, []);

  // if (isLoading) {
  //   return <Loader />;
  // }

  const role = selectedUser?.role;

  // console.log("selectedUser~~", selectedUser);

  return (
    <div className="container p-0">
      {isLoading ? (
        <UserProfileSkeleton />
      ) : (
        <>
          <div
            className="my_profile_card bg-white pt-0 pb-3"
            style={{
              borderBottomRadius: "18px",
            }}
          >
            <header className="profile_card_header">
              <div className="cover_bg_section">
                {/* <div onClick={backHandler} className="mt-3 back_button">
            <BsFillArrowLeftCircleFill
              style={{ color: "#58c8c8", fontSize: "30px" }}
            />{" "}
            Back
          </div> */}
              </div>
              <div className="container profile_image">
                {selectedUser && (
                  <div className="images">
                    <img
                      src={getProfilePicture(
                        selectedUser?.profilePicture,
                        selectedUser?.role
                      )}
                      alt={selectedUser?.restaurantName}
                    />
                  </div>
                )}

                {selectedUser && (
                  <div className="heading_info_box">
                    {role === "EMPLOYEE" ? (
                      <>
                        <h2 className="h2">{selectedUser?.name}</h2>
                        <div className=" mt-3 user-profile-position">
                          <FaBriefcase
                            style={{
                              color: "#58c8c8",
                            }}
                          />{" "}
                          <p>{selectedUser?.positionName}</p>
                        </div>
                        <div className="h6 user-profile-countryname">
                          <FaMapMarkerAlt
                            style={{
                              color: "#58c8c8",
                            }}
                          />{" "}
                          <p>{selectedUser?.countryName}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <h2 className="mb-2">
                          <FaBuilding /> {selectedUser?.restaurantName}
                        </h2>
                        <div className="h6 pt-3 user-profile-countryname">
                          <FaMapMarkerAlt
                            style={{
                              color: "#58c8c8",
                            }}
                          />{" "}
                          <p>{selectedUser?.restaurantAddress} </p>
                        </div>
                        {selectedUser?.countryName && (
                          <div className="d-flex align-items-center">
                            {selectedUser?.countryName}{" "}
                            <img
                              src={getFlagByCountry(selectedUser?.countryName)}
                              alt={selectedUser?.countryName}
                              style={{
                                width: "30px",
                                height: "20px",
                              }}
                              className="ms-1"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </header>
          </div>

          <div className="container">
            <div className="row mt-4 ">
              {role === "EMPLOYEE" && (
                <div className="col-lg-5 ">
                  <EmployeeDetailsLeftCard employee={selectedUser} />
                </div>
              )}

              <div
                className={
                  role === "EMPLOYEE" ? `col-lg-7` : `col-lg-8 mx-auto`
                }
              >
                <InfiniteScroll
                  dataLength={posts.length ?? 0}
                  next={fetchMorePosts}
                  hasMore={hasMore}
                  loader={postLoading ? <SkeletonPostCard /> : null}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      You have seen all post.
                    </p>
                  }
                >
                  {posts.map(
                    (item, i) =>
                      item?.active && (
                        <PostFeedCardMulti
                          key={i}
                          userName={
                            item?.user?.role === "CLIENT"
                              ? item?.user?.restaurantName
                              : item?.user?.name
                          }
                          userImage={
                            item?.user?.profilePicture &&
                            item?.user?.profilePicture !== undefined
                              ? item?.user?.profilePicture.includes(
                                  "lh3.googleusercontent.com"
                                )
                                ? item?.user?.profilePicture
                                : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item?.user?.profilePicture}`
                              : item?.user?.role === "CLIENT"
                              ? "/assets/Client.jpg"
                              : item?.user?._id === user?._id
                              ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${currentUser?.profilePicture}`
                              : "/assets/Employee.jpg"
                          }
                          // userImage={
                          //   item?.user?.profilePicture &&
                          //   item?.user?.profilePicture !== "undefined"
                          //     ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item?.user?.profilePicture}`
                          //     : item?.user?.role === "CLIENT"
                          //     ? "/assets/Client.jpg"
                          //     : item?.user === user?._id
                          //     ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${user?.profilePicture}`
                          //     : "/assets/Employee.jpg"
                          // }
                          postTime={item?.createdAt}
                          content={item?.content}
                          postContentImg={item?.media}
                          post={item}
                          handleUpdatePost={handleUpdatePost}
                          setEdit={setEdit}
                          setSelectedPost={setSelectedPost}
                          setCurrentPage={setCurrentPage}
                          handleDeletePosts={handleDeletePost}
                        />
                      )
                  )}

                  {postLoading && (
                    <>
                      <SkeletonPostCard />
                      <SkeletonPostCard />
                      <SkeletonPostCard />
                      <SkeletonPostCard />
                      <SkeletonPostCard />
                      <SkeletonPostCard />
                    </>
                  )}
                </InfiniteScroll>
              </div>

              {/* <div className="col-lg-5 order-lg-2 order-1 mb-4 position-relative">
          <div className={`${isTabSticky ? "sticky_create_post" : ""}`}>
            <CreatePost
              setCurrentPage={setCurrentPage}
              edit={edit}
              selectedPost={selectedPost}
              setSelectedPost={setSelectedPost}
              handleUpdatePost={handleUpdatePost}
              // fetchPostListData={fetchPostListData}
              post={posts}
            />
          </div>
        </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

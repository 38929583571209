import { Card, Row, Col, Skeleton } from "antd";

const JobRequestSkeleton = ({ count = 3 }) => (
  <div>
    {Array.from({ length: count }).map((_, index) => (
      <Card
        key={index}
        style={{
          border: "1px solid #A6A6A6",
          borderRadius: "16px",
          backgroundColor: "#f5f5f5",
          marginBottom: "16px",
          padding: "15px",
          position: "relative",
        }}
      >
        {/* View Job Details Tag */}
        <div style={{ position: "absolute", top: "4px", right: "-10px" }}>
          <Skeleton.Button
            active
            style={{
              width: 90,
              height: 30,
              borderRadius: "0px 15px 0px 15px",
              backgroundColor: "#dfe0e5",
            }}
          />
        </div>

        <Row gutter={[16, 8]}>
          {/* Job Position and Logo */}
          <Col xs={20} sm={20} md={8} lg={8} xl={8}>
            <div className="d-flex align-items-center">
              <Skeleton.Avatar active size={60} shape="circle" />
              <div style={{ marginLeft: 10 }}>
                <Skeleton.Input active size="small" style={{ width: 120 }} />
                <Skeleton.Input
                  active
                  size="small"
                  style={{
                    width: 40,
                    backgroundColor: "#58c8c8",
                    borderRadius: "5px",
                    marginTop: "6px",
                  }}
                />
              </div>
            </div>
          </Col>

          {/* Experience Info */}
          <Col xs={20} sm={20} md={6} lg={6} xl={6}>
            <Skeleton.Input active size="small" style={{ width: 100 }} />
          </Col>

          {/* Date Range */}
          <Col xs={20} sm={20} md={7} lg={7} xl={7}>
            <Skeleton.Input active size="small" style={{ width: 130 }} />
          </Col>

          {/* Status */}
          <Col xs={20} sm={20} md={3} lg={3} xl={3}>
            <Skeleton.Input
              active
              size="small"
              style={{ width: 70, backgroundColor: "#FFEB3B" }}
            />
          </Col>
        </Row>

        {/* Action Buttons */}
        <Row justify="end" style={{ paddingTop: "10px", gap: "10px" }}>
          <Skeleton.Button
            active
            shape="circle"
            style={{ width: 24, height: 24 }}
          />
          <Skeleton.Button
            active
            shape="circle"
            style={{ width: 24, height: 24 }}
          />
        </Row>
      </Card>
    ))}
  </div>
);

export default JobRequestSkeleton;

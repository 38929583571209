import React, { createContext, useContext, useState } from "react";

const CommonContext = createContext();

export const CommonProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPligitPlusModalOpen, setIsPligitPlusModalOpen] = useState(false);
  const [isVisibleNotifition, setIsVisibleNotifition] = useState(false);
  const [isVisibleNotification, setIsVisibleNotification] = useState(false);
  const [isChatBoxListVisible, setChatBoxListVisible] = useState(false);
  const value = {
    isModalOpen,
    setIsModalOpen,
    isPligitPlusModalOpen,
    setIsPligitPlusModalOpen,
    isVisibleNotifition,
    setIsVisibleNotifition,
    isVisibleNotification,
    setIsVisibleNotification,
    isChatBoxListVisible,
    setChatBoxListVisible,
  };
  return (
    <CommonContext.Provider value={value}>{children}</CommonContext.Provider>
  );
};

export const useCommon = () => {
  const context = useContext(CommonContext);
  return context;
};

export function isValidDate(dateString) {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date.getTime());
}

export const minutestoHours = (secs) => {
  const isMinus = secs < 0 ? true : false;
  const sec = isMinus ? secs * -1 : secs;
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = (sec - hours * 3600 - minutes * 60).toFixed(0);
  return (
    (isMinus ? "-" : "") +
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds +
    " H"
  );
};

export const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

export function groupDates(dates) {
  if (dates.length === 0) {
    return [];
  }

  if (dates.length === 1) {
    return [
      {
        startDate: dates[0],
        endDate: dates[0],
      },
    ];
  }
  // Sort the dates in ascending order
  dates.sort((a, b) => new Date(a) - new Date(b));

  const result = [];
  let startDate = dates[0];
  let endDate = dates[0];

  for (let i = 1; i < dates.length; i++) {
    const currentDate = dates[i];

    // Convert to Date objects
    const prevDate = new Date(endDate + "-01");
    const nextDate = new Date(currentDate + "-01");

    // Check if the current date is the next month
    const isNextMonth =
      nextDate.getFullYear() === prevDate.getFullYear() &&
      nextDate.getMonth() === prevDate.getMonth() + 1;

    const isNewYear =
      nextDate.getFullYear() === prevDate.getFullYear() + 1 &&
      nextDate.getMonth() === 0 &&
      prevDate.getMonth() === 11;

    if (isNextMonth || isNewYear) {
      endDate = currentDate; // Update the end date if consecutive
    } else {
      // Push the current range to the result
      result.push({ startDate, endDate });

      // Start a new range
      startDate = currentDate;
      endDate = currentDate;
    }
  }

  // Push the last range
  result.push({ startDate, endDate });

  return result;
}

export function ungroupDates(groupedDates) {
  const ungrouped = [];
  if (groupedDates.length === 0) {
    return [];
  }

  // if (groupDates.length === 1) {
  //   return [groupedDates[0].startDate];
  // }
  groupedDates.forEach(({ startDate, endDate }) => {
    const start = new Date(startDate + "-01");
    const end = new Date(endDate + "-01");

    // Loop through each month from start to end
    while (start <= end) {
      // Format the date as 'YYYY-MM' and push to the ungrouped array
      ungrouped.push(start.toISOString().slice(0, 7));
      // Move to the next month
      start.setMonth(start.getMonth() + 1);
    }
  });

  return ungrouped;
}

export const formatDateForUnreadMsg = (dateString) => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  const now = new Date();

  // Check if the date is today
  const isToday = date.toDateString() === now.toDateString();

  // Check if the date is yesterday
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const isYesterday = date.toDateString() === yesterday.toDateString();

  if (isToday) {
    // Format as HH:MM for today
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  } else if (isYesterday) {
    // Display 'Yesterday' if it was yesterday
    return "Yesterday";
  } else {
    // Format as DD MMM YY for other days
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  }
};

import i18n from "../i18n";
import { jwtTokenDecode } from "./jwtDecode";

const currencyList = {
  afghanistan: "؋",
  albania: "L",
  algeria: "دج",
  argentina: "$",
  armenia: "֏",
  australia: "$",
  austria: "€",
  azerbaijan: "₼",
  bahrain: ".د.ب",
  bangladesh: "৳",
  belarus: "Br",
  belgium: "€",
  brazil: "R$",
  bulgaria: "лв",
  canada: "$",
  chile: "$",
  china: "¥",
  colombia: "$",
  croatia: "kn",
  "czech republic": "Kč",
  denmark: "kr",
  egypt: "£",
  eurozone: "€",
  finland: "€",
  france: "€",
  germany: "€",
  greece: "€",
  "hong kong": "$",
  hungary: "Ft",
  iceland: "kr",
  india: "₹",
  indonesia: "Rp",
  iran: "﷼",
  iraq: "ع.د",
  ireland: "€",
  israel: "₪",
  italy: "€",
  japan: "¥",
  jordan: "د.ا",
  kazakhstan: "₸",
  kenya: "Sh",
  kuwait: "د.ك",
  lebanon: "ل.ل",
  malaysia: "RM",
  mexico: "$",
  morocco: "د.م.",
  netherlands: "€",
  "new zealand": "$",
  nigeria: "₦",
  norway: "kr",
  oman: "ر.ع.",
  pakistan: "₨",
  philippines: "₱",
  poland: "zł",
  portugal: "€",
  qatar: "ر.ق",
  romania: "lei",
  russia: "₽",
  "saudi arabia": "﷼",
  singapore: "$",
  "south africa": "R",
  "south korea": "₩",
  spain: "€",
  sweden: "kr",
  switzerland: "CHF",
  taiwan: "NT$",
  thailand: "฿",
  turkey: "₺",
  "united arab emirates": "د.إ",
  "united kingdom": "£",
  "united states": "$",
  vietnam: "₫",
  default: "$",
};

// Full Country-to-flag image URL mapping with lowercase country names
const countryFlags = {
  afghanistan: "https://flagcdn.com/af.svg",
  albania: "https://flagcdn.com/al.svg",
  algeria: "https://flagcdn.com/dz.svg",
  andorra: "https://flagcdn.com/ad.svg",
  angola: "https://flagcdn.com/ao.svg",
  argentina: "https://flagcdn.com/ar.svg",
  armenia: "https://flagcdn.com/am.svg",
  australia: "https://flagcdn.com/au.svg",
  austria: "https://flagcdn.com/at.svg",
  azerbaijan: "https://flagcdn.com/az.svg",
  bahamas: "https://flagcdn.com/bs.svg",
  bahrain: "https://flagcdn.com/bh.svg",
  bangladesh: "https://flagcdn.com/bd.svg",
  barbados: "https://flagcdn.com/bb.svg",
  belarus: "https://flagcdn.com/by.svg",
  belgium: "https://flagcdn.com/be.svg",
  belize: "https://flagcdn.com/bz.svg",
  benin: "https://flagcdn.com/bj.svg",
  bhutan: "https://flagcdn.com/bt.svg",
  bolivia: "https://flagcdn.com/bo.svg",
  "bosnia and herzegovina": "https://flagcdn.com/ba.svg",
  botswana: "https://flagcdn.com/bw.svg",
  brazil: "https://flagcdn.com/br.svg",
  brunei: "https://flagcdn.com/bn.svg",
  bulgaria: "https://flagcdn.com/bg.svg",
  "burkina faso": "https://flagcdn.com/bf.svg",
  burundi: "https://flagcdn.com/bi.svg",
  "cabo verde": "https://flagcdn.com/cv.svg",
  cambodia: "https://flagcdn.com/kh.svg",
  cameroon: "https://flagcdn.com/cm.svg",
  canada: "https://flagcdn.com/ca.svg",
  "central african republic": "https://flagcdn.com/cf.svg",
  chad: "https://flagcdn.com/td.svg",
  chile: "https://flagcdn.com/cl.svg",
  china: "https://flagcdn.com/cn.svg",
  colombia: "https://flagcdn.com/co.svg",
  comoros: "https://flagcdn.com/km.svg",
  "congo (congo-brazzaville)": "https://flagcdn.com/cg.svg",
  "costa rica": "https://flagcdn.com/cr.svg",
  croatia: "https://flagcdn.com/hr.svg",
  cuba: "https://flagcdn.com/cu.svg",
  cyprus: "https://flagcdn.com/cy.svg",
  "czech republic": "https://flagcdn.com/cz.svg",
  denmark: "https://flagcdn.com/dk.svg",
  djibouti: "https://flagcdn.com/dj.svg",
  "dominican republic": "https://flagcdn.com/do.svg",
  ecuador: "https://flagcdn.com/ec.svg",
  egypt: "https://flagcdn.com/eg.svg",
  "el salvador": "https://flagcdn.com/sv.svg",
  "equatorial guinea": "https://flagcdn.com/gq.svg",
  eritrea: "https://flagcdn.com/er.svg",
  estonia: "https://flagcdn.com/ee.svg",
  eswatini: "https://flagcdn.com/sz.svg",
  ethiopia: "https://flagcdn.com/et.svg",
  finland: "https://flagcdn.com/fi.svg",
  france: "https://flagcdn.com/fr.svg",
  gabon: "https://flagcdn.com/ga.svg",
  gambia: "https://flagcdn.com/gm.svg",
  georgia: "https://flagcdn.com/ge.svg",
  germany: "https://flagcdn.com/de.svg",
  ghana: "https://flagcdn.com/gh.svg",
  greece: "https://flagcdn.com/gr.svg",
  grenada: "https://flagcdn.com/gd.svg",
  guatemala: "https://flagcdn.com/gt.svg",
  guinea: "https://flagcdn.com/gn.svg",
  guyana: "https://flagcdn.com/gy.svg",
  honduras: "https://flagcdn.com/hn.svg",
  hungary: "https://flagcdn.com/hu.svg",
  iceland: "https://flagcdn.com/is.svg",
  india: "https://flagcdn.com/in.svg",
  indonesia: "https://flagcdn.com/id.svg",
  iran: "https://flagcdn.com/ir.svg",
  iraq: "https://flagcdn.com/iq.svg",
  ireland: "https://flagcdn.com/ie.svg",
  israel: "https://flagcdn.com/il.svg",
  italy: "https://flagcdn.com/it.svg",
  jamaica: "https://flagcdn.com/jm.svg",
  japan: "https://flagcdn.com/jp.svg",
  jordan: "https://flagcdn.com/jo.svg",
  kazakhstan: "https://flagcdn.com/kz.svg",
  kenya: "https://flagcdn.com/ke.svg",
  kuwait: "https://flagcdn.com/kw.svg",
  kyrgyzstan: "https://flagcdn.com/kg.svg",
  laos: "https://flagcdn.com/la.svg",
  latvia: "https://flagcdn.com/lv.svg",
  lebanon: "https://flagcdn.com/lb.svg",
  lesotho: "https://flagcdn.com/ls.svg",
  liberia: "https://flagcdn.com/lr.svg",
  libya: "https://flagcdn.com/ly.svg",
  lithuania: "https://flagcdn.com/lt.svg",
  luxembourg: "https://flagcdn.com/lu.svg",
  madagascar: "https://flagcdn.com/mg.svg",
  malawi: "https://flagcdn.com/mw.svg",
  malaysia: "https://flagcdn.com/my.svg",
  maldives: "https://flagcdn.com/mv.svg",
  mali: "https://flagcdn.com/ml.svg",
  malta: "https://flagcdn.com/mt.svg",
  mexico: "https://flagcdn.com/mx.svg",
  mongolia: "https://flagcdn.com/mn.svg",
  morocco: "https://flagcdn.com/ma.svg",
  mozambique: "https://flagcdn.com/mz.svg",
  namibia: "https://flagcdn.com/na.svg",
  nepal: "https://flagcdn.com/np.svg",
  netherlands: "https://flagcdn.com/nl.svg",
  "new zealand": "https://flagcdn.com/nz.svg",
  nigeria: "https://flagcdn.com/ng.svg",
  "north korea": "https://flagcdn.com/kp.svg",
  norway: "https://flagcdn.com/no.svg",
  oman: "https://flagcdn.com/om.svg",
  pakistan: "https://flagcdn.com/pk.svg",
  panama: "https://flagcdn.com/pa.svg",
  paraguay: "https://flagcdn.com/py.svg",
  peru: "https://flagcdn.com/pe.svg",
  philippines: "https://flagcdn.com/ph.svg",
  poland: "https://flagcdn.com/pl.svg",
  portugal: "https://flagcdn.com/pt.svg",
  qatar: "https://flagcdn.com/qa.svg",
  romania: "https://flagcdn.com/ro.svg",
  russia: "https://flagcdn.com/ru.svg",
  rwanda: "https://flagcdn.com/rw.svg",
  "saudi arabia": "https://flagcdn.com/sa.svg",
  senegal: "https://flagcdn.com/sn.svg",
  serbia: "https://flagcdn.com/rs.svg",
  singapore: "https://flagcdn.com/sg.svg",
  slovakia: "https://flagcdn.com/sk.svg",
  slovenia: "https://flagcdn.com/si.svg",
  somalia: "https://flagcdn.com/so.svg",
  "south africa": "https://flagcdn.com/za.svg",
  "south korea": "https://flagcdn.com/kr.svg",
  spain: "https://flagcdn.com/es.svg",
  "sri lanka": "https://flagcdn.com/lk.svg",
  sudan: "https://flagcdn.com/sd.svg",
  suriname: "https://flagcdn.com/sr.svg",
  sweden: "https://flagcdn.com/se.svg",
  switzerland: "https://flagcdn.com/ch.svg",
  syria: "https://flagcdn.com/sy.svg",
  tajikistan: "https://flagcdn.com/tj.svg",
  tanzania: "https://flagcdn.com/tz.svg",
  thailand: "https://flagcdn.com/th.svg",
  togo: "https://flagcdn.com/tg.svg",
  "trinidad and tobago": "https://flagcdn.com/tt.svg",
  tunisia: "https://flagcdn.com/tn.svg",
  turkey: "https://flagcdn.com/tr.svg",
  uganda: "https://flagcdn.com/ug.svg",
  ukraine: "https://flagcdn.com/ua.svg",
  "united arab emirates": "https://flagcdn.com/ae.svg",
  "united kingdom": "https://flagcdn.com/gb.svg",
  "united states": "https://flagcdn.com/us.svg",
  uruguay: "https://flagcdn.com/uy.svg",
  uzbekistan: "https://flagcdn.com/uz.svg",
  venezuela: "https://flagcdn.com/ve.svg",
  vietnam: "https://flagcdn.com/vn.svg",
  yemen: "https://flagcdn.com/ye.svg",
  zambia: "https://flagcdn.com/zm.svg",
  zimbabwe: "https://flagcdn.com/zw.svg",
};

// Function to get flag image URL by country name
export function getFlagByCountry(country) {
  return countryFlags[country.toLowerCase()] || "https://flagcdn.com/un.svg";
}

export function getUserInfo() {
  const userInfo = jwtTokenDecode();
  if (userInfo) {
    return userInfo;
  }
  return null;
}

export function getUserCurrency() {
  const userInfo = getUserInfo();
  const country = userInfo.countryName?.toLowerCase();
  if (userInfo) {
    if (currencyList[country]) {
      return currencyList[country];
    }
    return currencyList["default"];
  }
  return null;
}

export function getUserCountry() {
  const userInfo = getUserInfo();
  if (userInfo) {
    return userInfo.countryName;
  }
  return null;
}

export function getUserCurrencyByCountry(country) {
  const countryName = country?.toLowerCase();
  if (currencyList[countryName]) {
    return currencyList[countryName];
  }
  return currencyList["default"];
}

export function getUserId() {
  const userInfo = getUserInfo();
  if (userInfo) {
    return userInfo._id;
  }
  return null;
}
export const getProfilePicture = (url, role) => {
  // Check if url is a valid string and not "undefined" or an empty string
  const isUrlValid = url && url !== "undefined" && url !== undefined;

  // Determine the profile picture URL based on conditions
  const profilePicture = isUrlValid
    ? url.includes("lh3.googleusercontent.com")
      ? url
      : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${url}`
    : role === "CLIENT"
    ? "/assets/Client.jpg"
    : "/assets/Employee.jpg";

  return profilePicture;
};

export const getFormateName = (name = "", length = 12) => {
  if (!name) {
    return;
  }
  const temp = name.length > length ? name.substring(0, length) + "..." : name;
  return temp;
};

export function paramsToQueryString(params) {
  const parmasToQuery = Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  return parmasToQuery;
}
export function paramsToQueryString2(params) {
  const parmasToQuery = Object.keys(params)
    .filter(
      (key) =>
        params[key] !== undefined && params[key] !== "" && params[key] !== null
    )
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  return parmasToQuery;
}

export function exactDate(checkInTime) {
  let dateObj = new Date(checkInTime);
  let year = dateObj.getUTCFullYear();
  let month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // months start from 0
  let day = String(dateObj.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const changeLanguageFunc = (e) => {
  const lang = e === "GB" ? "en" : e === "AE" ? "ar" : "it";
  localStorage.setItem("countryCode", e);
  localStorage.setItem("langSelect", lang);
  return i18n.changeLanguage(lang);
};

import React from "react";
import { Link } from "react-router-dom";
import VideoPlayer from "../../frontend/dashboard/SocialFeed/VideoPlayer";
import VideoPlayerShow from "../../frontend/dashboard/SocialFeed/VideoPlayerShow";

const PostMedia = ({
  postContentImg,
  handleMediaClick,
  post,
  setSelectedPostId,
}) => {
  const renderImage = (item, index, size = "w-100") => (
    <img
      key={index}
      className={`img-fluid ${size} multi_post_image`}
      src={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${item.url}`}
      alt="post media"
      onClick={() => {
        handleMediaClick(item, index);
        setSelectedPostId(post?._id);
      }} // Open modal on image click
    />
  );

  const renderVideo = (item, index, size = "w-100") => (
    <div
      key={index}
      className={`media-thumbnail-wrapper multi_post_image ${size}`}
      onClick={() => {
        handleMediaClick(item, index);
        setSelectedPostId(post?._id);
      }} // Open modal on video click
      style={{ cursor: "pointer" }}
    >
      <VideoPlayerShow data={item.url} post={post} />
    </div>
  );

  const renderMedia = (items, limit) =>
    items
      .slice(0, limit)
      .map((item, index) =>
        item.type === "image"
          ? renderImage(item, index, "w-50")
          : renderVideo(item, index, "w-50")
      );

  return (
    postContentImg && (
      <div className="multiple_images">
        {/* Single Image */}
        {postContentImg.length === 1 && (
          <div className="single_image">
            {postContentImg
              .slice(0, 1)
              .map((item, index) =>
                item.type === "image"
                  ? renderImage(item, index)
                  : renderVideo(item, index)
              )}
          </div>
        )}

        {/* Two Images */}
        {postContentImg.length === 2 && renderMedia(postContentImg, 2)}

        {/* Three Images */}
        {postContentImg.length === 3 && (
          <>
            {renderMedia(postContentImg.slice(0, 1), 1)}
            {renderMedia(postContentImg.slice(1, 3), 2)}
          </>
        )}

        {/* Four Images */}
        {postContentImg.length === 4 && renderMedia(postContentImg, 4)}

        {/* More than Four Images */}
        {postContentImg.length > 4 && (
          <>
            {renderMedia(postContentImg.slice(0, 3), 3)}
            <div className="multi_post_image_wrapper more_view_card">
              {renderMedia(postContentImg.slice(3, 4), 1)}
              <div className="view_more_overlay">View More</div>
            </div>
          </>
        )}
      </div>
    )
  );
};

export default PostMedia;

import React, { useState, useEffect } from "react";
import { Form, Input, Button, Upload, Row, Col, Popconfirm, Card } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../utils/API";
import { Toast } from "../../../utils/toast";
import { Colors } from "../../../constants/Colors";
import {
  BankOutlined,
  NumberOutlined,
  TransactionOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const AddAndViewBankInfo = ({
  fetchEmployeeDetails,
  getSingleEmployeeDetails,
  isEdit,
  setIsEdit,
}) => {
  const { t } = useTranslation();
  const [bankform] = Form.useForm();
  const [bankUpdateLoading, setBankUpdateLoading] = useState(false);

  // console.log("getSingleEmployeeDetails~~", getSingleEmployeeDetails);

  useEffect(() => {
    if (getSingleEmployeeDetails) {
      bankform.setFieldsValue({
        bankName: getSingleEmployeeDetails.bankName,
        accountNumber: getSingleEmployeeDetails.accountNumber,
        routingNumber: getSingleEmployeeDetails.routingNumber,
        dressSize: getSingleEmployeeDetails.dressSize,
        additionalOne: getSingleEmployeeDetails.additionalOne,
        additionalTwo: getSingleEmployeeDetails.additionalTwo,
        // companyName: getSingleEmployeeDetails.companyName,
        // additionalThree: getSingleEmployeeDetails.additionalThree,
      });
    }
  }, [bankform, getSingleEmployeeDetails]);

  const onBankFinish = async (values) => {
    // console.log("values~", values);
    setBankUpdateLoading(true);
    const response = await axiosInstance.put("/users/update-bank-dress", {
      ...values,
      id: getSingleEmployeeDetails._id,
    });

    // console.log("response~", response);
    setBankUpdateLoading(false);
    if (response?.data?.status === "success") {
      fetchEmployeeDetails();
      setIsEdit(false);
      Toast.success("Saved successfully!", "bottom-left");
    }
  };

  return (
    <>
      {isEdit ? (
        <div>
          <Form
            className="ant-form ant-form-vertical"
            layout="vertical"
            onFinish={onBankFinish}
            form={bankform}
          >
            <div className="col-12">
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label={t("bank_name")}
                    name="bankName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter bank name",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_bank_name")}
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    label={t("account_number")}
                    name="accountNumber"
                    // hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter account number",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_bank_account_number")}
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    label={t("sort_code")}
                    name="routingNumber"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("enter_sort_code"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_sort_code")}
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </div>
                {/* <div className="col-md-4">
                  <Form.Item
                    label={t("company_name")}
                    name="companyName"
                    hasFeedback
                    rules={[
                      {
                        message: "Please enter Company Name",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_company_name")}
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </div> */}

                <div className="col-md-4">
                  <Form.Item
                    label={t("additional_one")}
                    name="additionalOne"
                    // hasFeedback
                    // rules={[
                    //   {
                    //     message: "Please enter Company Name",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder={t("enter_additional_one")}
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    label={t("additional_two")}
                    name="additionalTwo"
                    // hasFeedback
                    // rules={[
                    //   {
                    //     message: "Please enter additional one",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder={t("enter_additional_two")}
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </div>
                {/* <div className="col-md-4">
                  <Form.Item
                    label={t("additional_three")}
                    name="additionalThree"
                    // hasFeedback
                    // rules={[
                    //   {
                    //     message: "Please enter additional two",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder={t("enter_additional_three")}
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </div> */}
              </div>
            </div>

            <div className="col-md-6">
              <Form.Item>
                <button
                  disabled={bankUpdateLoading}
                  className="btn"
                  style={{ background: "#58c8c8", color: "white" }}
                  type="submit"
                >
                  {!bankUpdateLoading && t("update_bank")}
                  {bankUpdateLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {t("please_wait...")}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </Form.Item>
            </div>
          </Form>
        </div>
      ) : (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BankOutlined style={{ fontSize: "20px", color: "#1890ff" }} />
              <span style={{ marginLeft: 8 }}>{t("bank_name")}:</span>{" "}
              {getSingleEmployeeDetails?.bankName}
            </Col>
            {/* <Col span={12}>
              <BankOutlined style={{ fontSize: "20px", color: "#1890ff" }} />
              <span style={{ marginLeft: 8 }}>{t("company_name")}:</span>{" "}
              {getSingleEmployeeDetails?.companyName}
            </Col> */}
            <Col span={12}>
              <NumberOutlined style={{ fontSize: "20px", color: "#1890ff" }} />
              <span style={{ marginLeft: 8 }}>{t("account_number")}:</span>{" "}
              {getSingleEmployeeDetails?.accountNumber}
            </Col>
            <Col span={12}>
              <TransactionOutlined
                style={{ fontSize: "20px", color: "#1890ff" }}
              />
              <span style={{ marginLeft: 8 }}>{t("sort_code")}:</span>{" "}
              {getSingleEmployeeDetails?.routingNumber}
            </Col>
            <Col span={12}>
              <InfoCircleOutlined
                style={{ fontSize: "20px", color: "#1890ff" }}
              />
              <span style={{ marginLeft: 8 }}>{t("additional_one")}:</span>{" "}
              {getSingleEmployeeDetails?.additionalOne}
            </Col>
            <Col span={12}>
              <InfoCircleOutlined
                style={{ fontSize: "20px", color: "#1890ff" }}
              />
              <span style={{ marginLeft: 8 }}>{t("additional_two")}:</span>{" "}
              {getSingleEmployeeDetails?.additionalTwo}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AddAndViewBankInfo;

import React, { useContext, useEffect, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { AiOutlineMessage } from "react-icons/ai";
import { IoMdCard, IoMdNotificationsOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { changeLanguageFunc, getUserId } from "../../../utils/commonFunctions";
import { Dropdown, Tooltip } from "antd";
import Notification from "../../../layouts/frontend/dashboard/Notification/Notification";
import notificationSound from "./notification-sound.mp3";
import { ContextApi } from "../../../hooks/ContextApi";
import { responseNotification } from "../../../utils/notifcation";
import { FaCalendarAlt } from "react-icons/fa";
import { MdBusinessCenter, MdSpaceDashboard } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { HiHome } from "react-icons/hi";
import { useDispatch } from "react-redux";
import * as Action from "../../Auth/_redux/authActions";
import * as CandidateAction from "../../Candidate/_redux/candidateActions";
import { useCommon } from "../../../contexts/common-context";
import { useUser } from "../../../contexts/user-context";
import { useLocation } from "react-router-dom";
import { useSocket } from "../../../hooks/useSocket";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { fetchSingleEmployeeData } from "../../../services/userServices";
import { useQuery } from "react-query";
export default function EmployeeNav() {
  const { setIsPligitPlusModalOpen, setChatBoxListVisible } = useCommon();
  const { getNotifications, getNewMessages } = useSocket();
  const dispatch = useDispatch();
  const { setCurrentUser } = useUser();
  const [selected, setSelected] = useState(
    localStorage.getItem("countryCode") || "GB"
  );
  const jwtDecode = jwtTokenDecode();
  const id = jwtDecode?._id;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    notificationCount,
    setNotificationCount,
    setShouldRefetchNotificationList,
  } = useContext(ContextApi);

  // const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  //   withCredentials: false,
  //   transports: ["websocket", "polling", "flashsocket"],
  // });

  const { notificationList, unreadCount } = useSelector(
    ({ auth }) => ({
      notificationList: auth?.notifications,
      unreadCount: auth?.unreadCount,
    }),
    shallowEqual
  );

  const { data: getSingleEmployeeDetails = {} } = useQuery(
    ["singleEmployee", id],
    () => fetchSingleEmployeeData(id),
    {
      enabled: !!id,
    }
  );
  useEffect(() => {
    // Use `window.history` explicitly to avoid restricted globals error
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  useEffect(() => {
    setShouldRefetchNotificationList(true);
  }, []);

  useEffect(() => {
    if (
      getSingleEmployeeDetails &&
      Object.keys(getSingleEmployeeDetails).length
    ) {
      setCurrentUser(getSingleEmployeeDetails);
    }
  }, [getSingleEmployeeDetails]);

  //assign notification value which come from socket connection
  useEffect(() => {
    console.log("socket--getNotifications~~", getNotifications);

    if (getNotifications && Object.keys(getNotifications).length > 0) {
      const loggedInUserId = getUserId();
      if (getNotifications?.userIfo?._id === loggedInUserId) {
        setNotificationCount(notificationCount + 1);
        responseNotification(getNotifications.body, "success");
        new Audio(notificationSound).play();
      }
    }
  }, [getNotifications]);

  // assign new msg value which come from socket connection
  useEffect(() => {
    if (getNewMessages && Object.keys(getNewMessages).length > 0) {
      console.log("getNewMessages~~", getNewMessages);
      var currentUrl = window.location.href;
      // CHECK IF THE MESSAGE IS FROM THE ADMIN
      if (
        getNewMessages.message.receiverDetails?.length > 1 &&
        getNewMessages.message.receiverDetails
          .map((item) => item.receiverId)
          .includes(getUserId()) &&
        !currentUrl.includes("/client-help-support")
      ) {
        const message = `Admin: ${getNewMessages.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
        return;
      }

      // CHECK IF THE MESSAGE IS FROM THE USER
      if (
        getNewMessages.message.receiverDetails[0].receiverId === getUserId()
      ) {
        const message = `${getNewMessages.message.senderDetails.name} : ${getNewMessages.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
      }
    }
  }, [getNewMessages]);

  // useEffect(() => {
  //   console.log("WebSocket connection initiated.");

  //   socket.on("notification", (dataFromServer) => {
  //     console.log("Received notification:", dataFromServer);

  //     const logedInUserId = getUserId();
  //     if (dataFromServer.userInfo._id === logedInUserId) {
  //       setNotificationCount(notificationCount + 1);
  //       responseNotification(dataFromServer.body, "success");
  //       new Audio(notificationSound).play();
  //     }
  //   });

  //   socket.on("new_message", (data) => {
  //     var currentUrl = window.location.href;
  //     // CHECK IF THE MESSAGE IS FROM THE ADMIN
  //     if (
  //       data.message.receiverDetails?.length > 1 &&
  //       data.message.receiverDetails
  //         .map((item) => item.receiverId)
  //         .includes(getUserId()) &&
  //       !currentUrl.includes("/client-help-support")
  //     ) {
  //       const message = `Admin: ${data.message.text}`;
  //       responseNotification(message, "success");
  //       new Audio(notificationSound).play();
  //       return;
  //     }

  //     // CHECK IF THE MESSAGE IS FROM THE USER
  //     if (data.message.receiverDetails[0].receiverId === getUserId()) {
  //       const message = `${data.message.senderDetails.name} : ${data.message.text}`;
  //       responseNotification(message, "success");
  //       new Audio(notificationSound).play();
  //     }
  //   });

  //   return () => {
  //     socket.off("notification");
  //     socket.off("new_message");
  //     console.log("WebSocket connection closed.");
  //   };
  // }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginData");
    navigate("/");
    window.location.reload();
  };

  const [message, setMessage] = useState("");

  const notificationMenu = (
    <Notification setNotificationCount={setNotificationCount} />
  );

  const [prevCount, setPrevCount] = useState(0);
  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (notificationCount > prevCount) {
      setShake(true);
      const timer = setTimeout(() => setShake(false), 500); // 500ms for the shaking duration
      return () => clearTimeout(timer);
    }
    setPrevCount(notificationCount);
  }, [notificationCount, prevCount]);

  const shakingStyle = {
    animation: shake ? "shake 0.5s cubic-bezier(.36,.07,.19,.97) both" : "",
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    console.log("notifcation open~~", isOpen);
    setIsOpen(!isOpen);
  };

  const [isVisibleNotifition, setIsVisibleNotifition] = useState(false);

  const handleNotifitionClick = () => {
    setIsVisibleNotifition(!isVisibleNotifition);
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(Action.getNotificationList(1));
    dispatch(Action.getNotificationUnreadCount());
    dispatch(CandidateAction.getBookedList());
  }, [notificationCount, dispatch]);

  const notificationRef = useRef(null);

  // Close notification when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsVisibleNotifition(false); // Close notification
      }
    };

    if (isVisibleNotifition) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Cleanup event listener when component unmounts or when notification is closed
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisibleNotifition]);

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg plagit_pro_navbar ${
          isSticky ? "sticky" : ""
        }`}
      >
        <div className="container">
          <div className="nav_main_wrapper">
            <div className="">
              <Link className="mobile_header_open navbar-brand" to={"/"}>
                <img
                  className="img-fluid Dashboard_nav_logo"
                  style={{
                    height: "38px",
                  }}
                  src="/assets/frontend/images/Dashboardimages/logo_full.png"
                  alt="Default"
                />
              </Link>
            </div>

            <div className="mobile_header_open">
              <ul className="navbar-nav d-flex flex-row">
                <li className="nav-item pe-2">
                  <div
                    className="notification_icon_btn nav-link"
                    // href="#"
                    onClick={handleNotifitionClick}
                    // onBlur={handleNotifitionClick}
                  >
                    <div className="navLinkImageTextWraper position-relative">
                      <IoMdNotificationsOutline
                        style={{
                          fontSize: "30px",
                          ...shakingStyle,
                        }}
                      />
                      {unreadCount > 0 && (
                        <span className="bage_count">
                          {unreadCount > 20 ? "20+" : unreadCount}
                        </span>
                      )}
                    </div>
                  </div>

                  {isVisibleNotifition && (
                    <div ref={notificationRef}>
                      <Notification
                        setNotificationCount={setNotificationCount}
                        // isSubscriber={isSubscriber}
                      />
                    </div>
                  )}
                </li>

                <li className="nav-item">
                  <ReactFlagsSelect
                    defaultCountry="GB"
                    countries={["GB", "AE", "IT"]}
                    showSelectedLabel={false}
                    selected={selected || "GB"}
                    customLabels={{
                      US: "Eng",
                      GB: "Eng",
                      AE: "Ara",
                      IT: "Ita",
                    }}
                    onSelect={(e) => {
                      setSelected(e);
                      changeLanguageFunc(e);
                    }}
                  />
                </li>

                {/* <li className="nav-item">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample11"
                    aria-controls="navbarsExample11"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={toggleSidebar}
                  >
                    <CiMenuBurger />
                  </button>
                </li> */}
              </ul>
            </div>
          </div>

          <Sidebar
            toggleSidebar={toggleSidebar}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />

          {!isOpen && (
            <div
              className="collapse navbar-collapse d-lg-flex"
              id="navbarsExample11"
            >
              <Link className="navbar-brand col-lg-3 me-0" to={"/"}>
                <img
                  className="img-fluid Dashboard_nav_logo"
                  style={{
                    height: "38px",
                  }}
                  src="/assets/frontend/images/Dashboardimages/logo_full.png"
                  alt="Default"
                />
              </Link>

              <ul
                className={`navbar-nav col-lg-5 justify-content-lg-around ${
                  isSticky ? "visible" : "invisible"
                }`}
              >
                <li className="nav-item">
                  <Tooltip placement="bottom" title={t("home")}>
                    <Link
                      className={`nav-link nav_link_hover rounded_btn  ${
                        currentPath.includes("candidate/home") ? "nav_link" : ""
                      }`}
                      onClick={async (e) => {
                        e.preventDefault();
                        await localStorage.setItem("client_tab", "social");
                        navigate("/candidate/home");
                        window.scrollTo(0, 0);
                        if ("scrollRestoration" in window.history) {
                          window.history.scrollRestoration = "manual";
                        }
                        setTimeout(() => {
                          window.location.reload();
                        }, 0);
                      }}
                    >
                      <HiHome
                        className={`${
                          currentPath.includes("candidate/home")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>
                <li className="nav-item">
                  <Tooltip placement="bottom" title={t("dashboard")}>
                    <Link
                      className={`nav-link nav_link_hover rounded_btn  ${
                        currentPath.includes("candidate/dashboard")
                          ? "nav_link"
                          : ""
                      }`}
                      to={"/candidate/dashboard"}
                    >
                      <MdSpaceDashboard
                        className={`${
                          currentPath.includes("candidate/dashboard")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>
                <li className="nav-item">
                  <Tooltip placement="bottom" title={t("my_calender")}>
                    <Link
                      className={`nav-link nav_link_hover rounded_btn  ${
                        currentPath.includes("candidate/my-calender")
                          ? "nav_link"
                          : ""
                      }`}
                      to={"/candidate/my-calender"}
                    >
                      <FaCalendarAlt
                        className={`${
                          currentPath.includes("candidate/my-calender")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>
                <li classN ame="nav-item">
                  <Tooltip placement="bottom" title={t("payments")}>
                    <Link
                      className={`nav-link nav_link_hover rounded_btn  ${
                        currentPath.includes("candidate/payment-history")
                          ? "nav_link"
                          : ""
                      }`}
                      to={"/candidate/payment-history"}
                    >
                      <IoMdCard
                        className={`${
                          currentPath.includes("candidate/payment-history")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>

                {/* <li className="nav-item">
                  <Tooltip placement="bottom" title={t("help_support")}>
                    <div
                      onClick={() => setIsModalOpen(true)}
                      className=" rounded_btn"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <MdOutlineSupportAgent />
                    </div>
                  </Tooltip>
                </li> */}

                {/* <li className="nav-item">
                  <Tooltip placement="bottom" title={"Plagit Plus"}>
                    <div
                      onClick={() => setIsPligitPlusModalOpen(true)}
                      style={{
                        cursor: "pointer",
                      }}
                      className="rounded_btn"
                    >
                      <MdBusinessCenter />
                    </div>
                  </Tooltip>
                </li> */}
              </ul>

              <div className="d-lg-flex col-lg-4 justify-content-lg-end">
                <ul className="Dashboard_navbar_custom navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item position-relative ps-2">
                    {/* <Dropdown overlay={notificationMenu} trigger={["click"]}> */}
                    <Tooltip placement="bottom" title={"Notifications"}>
                      <div
                        // href="#"
                        onClick={handleNotifitionClick}
                        className={`notification_icon_btn nav-link nav_link_hover ${
                          isVisibleNotifition ? "nav_link" : ""
                        }`}
                        // onBlur={handleNotifitionClick}
                      >
                        <div className="navLinkImageTextWraper position-relative">
                          <IoMdNotificationsOutline
                            style={{
                              fontSize: "25px",
                              ...shakingStyle,
                            }}
                            className={`${
                              isVisibleNotifition ? "nav_link_text" : ""
                            }`}
                          />
                          {unreadCount > 0 && (
                            <span className="bage_count">
                              {unreadCount > 20 ? "20+" : unreadCount}
                            </span>
                          )}
                        </div>
                      </div>
                    </Tooltip>

                    {isVisibleNotifition && (
                      <div ref={notificationRef}>
                        <Notification
                          setNotificationCount={setNotificationCount}
                        />
                      </div>
                    )}
                    {/* </Dropdown> */}
                  </li>

                  <li className="nav-item ps-2">
                    <Tooltip placement="bottom" title={"Messages"}>
                      <div
                        className="notification_icon_btn nav-link nav_link_hover"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setChatBoxListVisible(true);
                          const url = "/candidate/home";
                          navigate(url);
                        }}
                        // to="/candidate/help-support"
                      >
                        <div className="navLinkImageTextWraper position-relative">
                          <AiOutlineMessage
                            style={{
                              fontSize: "25px",
                              ...shakingStyle,
                            }}
                          />

                          <span className="bage_count">
                            {message == "" ? null : message > 0 && message}
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                  </li>

                  <li className="nav-item ps-2">
                    <ReactFlagsSelect
                      defaultCountry="GB"
                      countries={["GB", "AE", "IT"]}
                      showSelectedLabel={false}
                      selected={selected || "GB"}
                      customLabels={{
                        US: "Eng",
                        GB: "Eng",
                        AE: "Ara",
                        IT: "Ita",
                      }}
                      onSelect={(e) => {
                        setSelected(e);
                        changeLanguageFunc(e);
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </nav>

      <div className={`d-none bg-white`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand " href="/">
                  <img
                    className="img-fluid Dashboard_nav_logo"
                    style={{
                      height: "38px",
                    }}
                    src="/assets/frontend/images/Dashboardimages/logo_full.png"
                    alt="Default"
                  />
                </a>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggleSidebar}
                >
                  <span className="navbar-toggler-icon" />
                </button>

                {!isOpen && (
                  <div
                    className="Dash_navb_custom collapse  navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="Dashboard_navbar_custom navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <Dropdown
                          overlay={notificationMenu}
                          trigger={["click"]}
                        >
                          <a
                            className="Dashboard_navbar_custom_hover nav-link"
                            href="#"
                          >
                            <div
                              className="navLinkImageTextWraper"
                              style={{ position: "relative" }}
                            >
                              <IoMdNotificationsOutline
                                style={{
                                  fontSize: "30px",
                                }}
                              />
                              {unreadCount > 0 && (
                                <span className="bage_count">
                                  {unreadCount > 20 ? "20+" : unreadCount}
                                </span>
                              )}
                            </div>
                          </a>
                        </Dropdown>
                      </li>

                      <li className="nav-item">
                        <div
                          className="Dashboard_navbar_custom_hover nav-link"
                          // to="/candidate/help-support"
                          style={{ cursor: "pointer" }}
                          onClick={() => setChatBoxListVisible((prev) => !prev)}
                        >
                          <div
                            className="navLinkImageTextWraper"
                            style={{ position: "relative" }}
                          >
                            <AiOutlineMessage
                              style={{
                                fontSize: "30px",
                              }}
                            />

                            <span className="bage_count">
                              {message == "" ? null : message}
                            </span>
                          </div>
                        </div>
                      </li>

                      <li>
                        <ReactFlagsSelect
                          defaultCountry="GB"
                          countries={["GB", "AE", "IT"]}
                          selected={selected || "GB"}
                          showSelectedLabel={false}
                          customLabels={{
                            US: "Eng",
                            GB: "Eng",
                            AE: "Ara",
                            IT: "Ita",
                          }}
                          onSelect={(e) => {
                            setSelected(e);
                            changeLanguageFunc(e);
                          }}
                        />
                      </li>

                      {/* <li className="nav-item">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                }}
                                                trigger={["click"]}
                                            >
                                                <a
                                                    className="profile-icon-menu"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        src={profilePictureUrl}
                                                        alt="Profile"
                                                        className="rounded-circle user-icon-btn"
                                                    />
                                                    <DownOutlined className="down-arrow" />
                                                </a>
                                            </Dropdown>
                                        </li> */}
                    </ul>
                  </div>
                )}
              </nav>
            </div>
          </div>

          {/* <div className="">
                        <Link to="/">
                            <img
                                src={`assets/frontend/images/indexImages/logo_full.png`}
                                className="img-fluid"
                                style={{
                                    height: windowWidth < 576 ? "30px" : "50px",
                                    width: windowWidth < 576 ? "100px" : "150px",
                                }}
                                alt="Plagit Logo"
                            />
                        </Link>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">

                        <IoMdNotificationsOutline
                            style={{
                                fontSize: "20px",
                                color: "#000",
                                cursor: "pointer",
                            }}
                        />

                        <AiOutlineMessage
                            style={{
                                fontSize: "17px",
                                color: "#000",
                                cursor: "pointer",
                                marginLeft: "20px",
                            }}
                        />
                        <ReactFlagsSelect
                            defaultCountry="GB"
                            className="mt-1"
                            countries={["GB", "AE", "IT"]}
                            selected={selected || "GB"}
                            showSelectedLabel={false}
                            customLabels={{
                                US: "Eng",
                                GB: "Eng",
                                AE: "Ara",
                                IT: "Ita",
                            }}
                            onSelect={(e) => {
                                setSelected(e);
                                changeLanguageFunc(e);
                            }}
                        />
                    </div> */}
        </div>
      </div>
    </>
  );
}

import React, { useRef } from "react";
import ReactPlayer from "react-player"; // Import ReactPlayer

const VideoPlayer = ({ data }) => {
  const playerRef = useRef(null); // Ref for ReactPlayer

  return (
    <div className="popup_image_view d-flex align-items-center">
      <ReactPlayer
        url={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${data}`}
        controls
        playing
        muted
        className="social_player_view"
        // playing={false}
        ref={playerRef} // Ref to the ReactPlayer
      />
    </div>
  );
};

export default VideoPlayer;

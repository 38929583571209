import {
  Modal,
  Input,
  Button,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  InputNumber,
  Upload,
} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiEdit3, FiPhoneOutgoing } from "react-icons/fi";
import { IoChevronBackCircleOutline, IoCloseSharp } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { token } from "../../../utils/authentication";
import {
  getFlagByCountry,
  getUserCurrencyByCountry,
  getUserId,
  getUserInfo,
} from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import Loader from "../../../components/loadar/Loader";
import "./EmployeeProfile.scss";
import { useTranslation } from "react-i18next";
import MyCard from "../../../components/frontend/profile/Card";
import useCustomConfirm from "../../../hooks/useCustomConfirm";
import { FaRegUser, FaStar } from "react-icons/fa6";
import EditCardHeader from "../../../components/Cards/Common/EditCardHeader";
import {
  fetchPositionListForDropdownHandler,
  fetchSinglePosition,
} from "../../../api/position";
import axiosInstance from "../../../utils/API";
import { useQuery } from "react-query";
import { Toast } from "../../../utils/toast";
import AddAndViewCertification from "./AddAndViewCertification";
import { Colors } from "../../../constants/Colors";
import { FaWeight } from "react-icons/fa";
import { EditOutlined } from "@ant-design/icons";
import { getCurrencySymbol } from "../../../utils/country";
import MapModal from "../../../components/frontend/profile/MapModal";
import UploadCVButton from "./UploadCvButton";
import ProfilePercentageCard from "../../../components/Shared/ProfilePercentageCard";
import AddAndViewBankInfo from "./AddAndViewBankInfo";
import {
  PhoneOutlined,
  VerticalAlignMiddleOutlined,
  SkinOutlined,
} from "@ant-design/icons";
import { MdEmail } from "react-icons/md";
import { fetchSkills } from "../../../services/commonServices";
import { isValidPhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
// Function to fetch employee data
const fetchSingleEmployeeData = async (id) => {
  const res = await axiosInstance.get(`/users/${id}`);
  // console.log("fetchSingleEmployeeData", res);
  return res?.data?.details;
};
const fetchNationalities = async (id) => {
  const res = await axiosInstance.get("/commons/nationality");
  // console.log("fetchSingleEmployeeData", res);
  return res?.data?.nationalities;
};
const fetchAllLanguages = async (id) => {
  const res = await fetch("/data/languages.json");
  const data = await res.json();
  return data;
};

const EmployeeProfile = () => {
  const { t } = useTranslation();
  const jwt_decode = jwtTokenDecode();
  const [bankform] = Form.useForm();
  const id = jwt_decode?._id;
  //   const [getSingleEmployeeDetails, setSingleEmployeeDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProfileImageLoading, setUploadProfileImageLoading] =
    useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("€");
  const [fileList, setFileList] = useState([]);
  const [meetData, setMeetData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBioModelOpen, setIsBioModelOpem] = useState(false);
  const [cardInfo, setCardInfo] = useState({});
  const [cardLoading, setCardLoading] = useState(false);
  const [form] = Form.useForm();
  const [additionalForm] = Form.useForm();
  const [personalForm] = Form.useForm();
  const [educationForm] = Form.useForm();
  const [bioForm] = Form.useForm();
  const [languageForm] = Form.useForm();
  const [previewImage, setPreviewImage] = useState(null);
  const { confirm, CustomConfirmDialog } = useCustomConfirm();
  const [positions, setPositions] = useState([]);
  const [editLoading, setEditLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [name, setName] = useState("");
  const [myLocation, setMyLocation] = useState("");
  const [mapModal, setMapModal] = useState(false);
  const [skills, setSkills] = useState([{ name: "", active: true }]);
  const { Option } = Select;
  //   console.log("getSingleEmployeeDetails~~", getSingleEmployeeDetails);
  const validateBio = (_, value) => {
    // console.log("value", value);
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const phoneRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/;
    const consecutiveNumbersRegex = /\b\d\s?\d\s?\d\s?\d\s?\d\b/;
    const spaceRegex = /\s{2,}/;
    const digitRegex = /\d/g;
    const digitCount = (value.match(digitRegex) || []).length;
    // max length will be 100 characters
    if (value.length > 100) {
      return Promise.reject(
        "Please ensure that the bio is at most 100 characters."
      );
    }

    if (emailRegex.test(value) || phoneRegex.test(value)) {
      return Promise.reject(
        "Please avoid including your email or phone number in the bio."
      );
    }

    if (consecutiveNumbersRegex.test(value)) {
      return Promise.reject(
        "Please avoid including consecutive numbers in the bio."
      );
    }

    if (spaceRegex.test(value)) {
      return Promise.reject(
        "Please avoid including consecutive spaces in bio."
      );
    }

    if (digitCount > 5) {
      return Promise.reject(
        "Please ensure that the bio contains at most five numbers."
      );
    }

    return Promise.resolve();
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isEditEducation, setIsEditEducation] = useState(false);
  const [isEditLanguage, setIsEditLanguage] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isBankInfo, setIsBankInfo] = useState(false);
  const [isEditAdditional, setIsEditAdditional] = useState(false);

  // Use useQuery to fetch employee details
  const {
    data: getSingleEmployeeDetails,
    error,
    isLoading,
    refetch: fetchEmployeeDetails,
  } = useQuery(
    ["singleEmployee", id],
    () => fetchSingleEmployeeData(id), // Fetching function
    {
      enabled: !!id,
    }
  );
  // Use useQuery to fetch position name using  id data
  const { data: position } = useQuery(
    ["position", getSingleEmployeeDetails?.positionId],
    () => fetchSinglePosition(getSingleEmployeeDetails?.positionId),
    {
      enabled: !getSingleEmployeeDetails?.positionName,
    }
  );

  const [country, setCountry] = useState(getSingleEmployeeDetails?.countryName);

  // console.log("getSingleEmployeeDetails~~", getSingleEmployeeDetails);

  const { data: nationalities, isLoading: nationalityLoading } = useQuery(
    ["nationalities"],
    () => fetchNationalities()
  );
  const { data: languagesData = [], isLoading: languagesLoading } = useQuery(
    ["languages"],
    () => fetchAllLanguages()
  );

  const { data: skillsData = [], isLoading: skillsLoading } = useQuery(
    ["skills"],
    () => fetchSkills()
  );

  //   console.log("singleEmployeeDetails~~", getSingleEmployeeDetails);
  // console.log("nationalities~~", nationalities);
  // console.log("languages~~", languagesData);
  // console.log("languages~~", skillsData);
  console.log("position~~", position);

  //   fetch position data
  useEffect(() => {
    const fetchPositionData = async () => {
      const { data } = await fetchPositionListForDropdownHandler();
      //   console.log("data~~", data);
      setPositions(
        data?.positions?.map((item) => {
          return { value: item._id, label: item.name };
        })
      );
    };
    fetchPositionData();
  }, []);

  useEffect(() => {
    // console.log("country~~", country);
    if (country) {
      setCurrencySymbol(getUserCurrencyByCountry(country));
    }
    if (getSingleEmployeeDetails?.countryName) {
      setCurrencySymbol(
        getUserCurrencyByCountry(getSingleEmployeeDetails?.countryName)
      );
    }
  }, [country, getSingleEmployeeDetails?.countryName]);

  // update name and location data
  useEffect(() => {
    // if (getSingleEmployeeDetails?.presentAddress) {
    //   setName(getSingleEmployeeDetails.presentAddress);
    // }
    // if (getSingleEmployeeDetails?.lat && getSingleEmployeeDetails?.long) {
    //   setMyLocation({
    //     lat: getSingleEmployeeDetails.lat,
    //     lng: getSingleEmployeeDetails.long,
    //   });
    // }
  }, [
    getSingleEmployeeDetails?.presentAddress,
    getSingleEmployeeDetails?.lat,
    getSingleEmployeeDetails?.long,
  ]);

  //   update card info
  useEffect(() => {
    if (getSingleEmployeeDetails?.sourceOfFunds) {
      setCardInfo(JSON.parse(getSingleEmployeeDetails.sourceOfFunds));
    }
    if (getSingleEmployeeDetails?.languages) {
      setLanguages(getSingleEmployeeDetails.languages);
    }
    if (getSingleEmployeeDetails?.skills) {
      setSkills(getSingleEmployeeDetails.skills);
    }
  }, [getSingleEmployeeDetails]);

  const handleBioCancel = () => {
    setIsBioModelOpem(false);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/candidate/home");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleFileChange = ({ fileList }) => {
    // console.log("fileList~~", fileList);
    const unsupportedImageTypes = ["image/x-icon", "image/gif"];

    // Check if a file is selected
    if (fileList.length > 0) {
      const file = fileList[fileList.length - 1].originFileObj;

      // Check if `originFileObj` is present to avoid potential errors
      if (file && unsupportedImageTypes.includes(file.type)) {
        Toast.error("Unsupported Image Type");
        return;
      }

      setFileList([fileList[fileList.length - 1]]);
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
    } else {
      setPreviewImage(null);
    }
  };

  const handleUpload = async () => {
    if (fileList.length === 0) return;
    const formData = new FormData();
    formData.append("id", getSingleEmployeeDetails?._id);
    formData.append("profilePicture", fileList[0]?.originFileObj);

    try {
      setUploadProfileImageLoading(true);
      const response = await axiosInstance.put(
        "/users/profile-picture/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("uploadimage~~", response);
      if (response?.data?.status === "success") {
        fetchEmployeeDetails(); // Refresh the employee details after upload
      } else {
        console.error("Failed to update profile picture.");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      setUploadProfileImageLoading(false);
      setFileList([]);
    }
  };

  const handleBioSave = async (values) => {
    console.log("values", values);
    setEditLoading(true);
    const response = await axiosInstance.put("/users/update-employee", {
      ...values,
      long: myLocation?.lng || getSingleEmployeeDetails?.long,
      lat: myLocation?.lat || getSingleEmployeeDetails?.lat,
      presentAddress: name || getSingleEmployeeDetails?.presentAddress,
      id: getSingleEmployeeDetails._id,
    });
    setEditLoading(false);
    // console.log("handleBioSave response~~", response);
    if (response?.data?.status === "success") {
      fetchEmployeeDetails();
      setIsEditEducation(false);
      setIsEditAdditional(false);
      Toast.success("Saved successfully!", "bottom-left");
    }

    // form
    //   .validateFields()
    //   .then(async (values) => {
    //     console.log("bio~~", values);
    //     setIsBioModelOpem(false);
    //     setIsEditAdditional(false);
    //     setEditedBio(values.bio);
    //     await API.put("/users/update-employee-bio", {
    //       id: jwt_decode?._id,
    //       bio: values.bio,
    //     }).then(() => {
    //       //   fetchSingleEmployeeData();
    //     });
    //   })
    //   .catch((errorInfo) => {
    //     // Handle validation errors if any
    //     console.log("Validation failed:", errorInfo);
    //   });
  };

  const onCardAdd = async () => {
    setCardLoading(true);

    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/users/get-session`, {
          email: getUserInfo().email,
          returnUrl: window.location.href,
        })
        .then((res) => {
          navigate("/card-verify?sessionId=" + res.data.details.id);
        });
    } catch (error) {
      setCardLoading(false);
      console.error("Error fetching session ID:", error);
    }
  };
  const onCardDelete = async () => {
    const userConfirmed = await confirm(
      "Are you sure you want to remove this card?"
    );

    if (!userConfirmed) {
      return;
    }
    // console.log("confirm~~", userConfirmed);

    setCardLoading(true);
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/users/remove-token`, {
          id: getUserId(),
        })
        .then((res) => {
          // setTrigger(!trigger);
          setCardInfo(undefined);
          setCardLoading(false);
        });
    } catch (error) {
      setCardLoading(false);
      console.error("Error deleting card:", error);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/meet/get-my-meets`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        // console.log(res.data[0]);
        setMeetData(res.data[0]);
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <h1>
        <Loader />
      </h1>
    );
  }

  const saveEmployeePersonalInfo = async (values) => {
    // if (values?.contractorHourlyRate) {
    //   const contractorHourlyRate = parseFloat(values.contractorHourlyRate);

    //   if (!isNaN(contractorHourlyRate)) {
    //     values["hourlyRate"] = contractorHourlyRate * 1.2;
    //   } else {
    //     console.error(
    //       "Invalid contractorHourlyRate:",
    //       values.contractorHourlyRate
    //     );
    //   }
    // }

    setEditLoading(true);

    const response = await axiosInstance.put("/users/update-employee", {
      ...values,
      id: getSingleEmployeeDetails._id,
    });

    if (response?.data?.status === "success") {
      setIsEdit(false);
      setIsEditing(false);
      fetchEmployeeDetails();
      Toast.success("Saved successfully!", "bottom-left");
    }
    setEditLoading(false);

    // console.log("response-edit-personal-info~~", response);
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };

  // console.log(
  //   " getSingleEmployeeDetails?.profilePicture~~",
  //   getSingleEmployeeDetails?.profilePicture
  // );

  // console.log("getSingleEmployeeDetails~~", getSingleEmployeeDetails);

  return (
    <div className="container px-4 mt-5">
      {getSingleEmployeeDetails &&
        getSingleEmployeeDetails?.profileCompleted !== 100 && (
          <ProfilePercentageCard
            percent={getSingleEmployeeDetails?.profileCompleted}
            name={getSingleEmployeeDetails?.name}
          />
        )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "10px",
          height: "50px",
          color: "#58c8c8",
          cursor: "pointer",
        }}
        onClick={goBack}
      >
        <IoChevronBackCircleOutline
          size={25}
          backgroundColor="#A6A6A6"
          color="#58c8c8"
        />
        <span style={{ marginLeft: "10px" }}>{t("go_back")}</span>
      </div>
      <section className="">
        {/* <div className="row">
          <div
            className="col-md-3 card"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "left",
              borderRadius: "14.8px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              backgroundColor: " #58c8c87f",
            }}
          >
            <p>HI {getSingleEmployeeDetails?.name},</p>
            <p>Explore the features of Plagit App here</p>
          </div>
          <div
            className="col-md-9"
            style={{
              marginLeft: "",
            }}
          >
            <div
              className="row row-cols-1 row-cols-md-2 row-cols-lg-3 p-3"
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "14.8px",
                marginLeft: "10px",
              }}
            >
              <div className="col-lg-2">
                <Link to={`/employee-dashboard`} className="customCardLink">
                  <div className="customCard">
                    <img src={dashboard} alt="dashboard" />
                    <p>Dashboard</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link
                  to={`/employee-booked-history`}
                  className="customCardLink"
                >
                  <div className="customCard">
                    <img src={booked} alt="booked" />
                    <p>Booked History</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link to={`/employee-hired-history`} className="customCardLink">
                  <div className="customCard">
                    <img src={hired} alt="" />
                    <p>Hired History</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link to={`/my-calender`} className="customCardLink">
                  <div className="customCard">
                    <img src={calendar} alt="calendar" />
                    <p>Calendar</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <Link
                  to={`/employee-payment-history`}
                  className="customCardLink"
                >
                  <div className="customCard">
                    <img src={payment} alt="payment" />
                    <p>Payment History</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-2">
                <div onClick={showModal} className="customCardLink">
                  <div className="customCard">
                    <img src={help} alt="help" />
                    <p>Help & Support</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {loading ? (
          <div className="card-body">
            <Loader /> <br />
          </div>
        ) : (
          <div className="row">
            <div className="col-xl-4 col-md-5">
              <div className="row">
                <div
                  className="selectedEmpProfilePic"
                  style={{
                    height: "400px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      borderRadius: 14,
                      backgroundSize: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                    src={
                      previewImage
                        ? previewImage
                        : getSingleEmployeeDetails?.profilePicture
                        ? getSingleEmployeeDetails?.profilePicture.includes(
                            "lh3.googleusercontent.com"
                          )
                          ? getSingleEmployeeDetails?.profilePicture
                          : process.env.REACT_APP_ASSETs_BASE_URL +
                            "/" +
                            getSingleEmployeeDetails?.profilePicture
                        : "/assets/Employee.jpg"
                    }
                    className="img-fluid"
                    alt={
                      getSingleEmployeeDetails?.profilePicture &&
                      getSingleEmployeeDetails?.profilePicture.includes(
                        "lh3.googleusercontent.com"
                      )
                        ? "Image from google"
                        : "Profile Picture"
                    }
                  />
                  {/* Edit icon overlay */}
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <Upload
                      accept="image/*"
                      fileList={fileList}
                      onChange={handleFileChange}
                      beforeUpload={() => false} // Prevents auto-upload, we will upload manually
                      showUploadList={false} // Hide default file list
                    >
                      <Button
                        icon={
                          <EditOutlined
                            style={{ fontSize: "24px", color: "#fff" }}
                          />
                        }
                        shape="circle"
                        style={{
                          background: Colors.primary,
                          border: "none",
                        }}
                      />
                    </Upload>
                  </div>
                </div>
                <div className="text-center">
                  {/* Upload button */}
                  {fileList.length > 0 && (
                    <Button
                      type="primary"
                      onClick={handleUpload}
                      loading={loading}
                      className="text-center"
                      style={{
                        marginTop: "10px",
                        width: "80%",
                        margin: "10px",
                        backgroundColor: Colors.primary,
                      }}
                    >
                      {uploadProfileImageLoading
                        ? "Uploading..."
                        : "Upload New Picture"}
                    </Button>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="candidate_personal_info selectProfileRowForMargin">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="d-flex"> {t("personal_information")} </h5>
                      {isEditing ? (
                        <button
                          className="close_btn"
                          onClick={() => setIsEditing(false)}
                        >
                          <IoCloseSharp />
                          {t("close")}
                        </button>
                      ) : (
                        <button
                          className="edit_btn"
                          onClick={() => setIsEditing(true)}
                        >
                          <FiEdit3 />
                          {t("edit")}
                        </button>
                      )}
                    </div>
                    <div className="card-body" id="personal-info-data">
                      {isEditing ? (
                        <div className="edit_div">
                          <Form
                            name="wrap"
                            labelCol={{
                              flex: "110px",
                            }}
                            labelAlign="left"
                            labelWrap
                            wrapperCol={{
                              flex: 1,
                            }}
                            colon={false}
                            style={{
                              maxWidth: 600,
                            }}
                            form={personalForm}
                            initialValues={{
                              firstName: getSingleEmployeeDetails?.firstName,
                              lastName: getSingleEmployeeDetails?.lastName,
                              email: getSingleEmployeeDetails?.email,
                              postCode: getSingleEmployeeDetails?.postCode,
                              nationality:
                                getSingleEmployeeDetails?.nationality,
                              positionId: getSingleEmployeeDetails?.positionId,
                              employeeExperience:
                                getSingleEmployeeDetails?.employeeExperience,
                              gender: getSingleEmployeeDetails?.gender,
                              dateOfBirth: getSingleEmployeeDetails?.dateOfBirth
                                ? moment(getSingleEmployeeDetails?.dateOfBirth)
                                : null,
                              contractorHourlyRate:
                                getSingleEmployeeDetails?.contractorHourlyRate,
                              phoneNumber:
                                getSingleEmployeeDetails?.phoneNumber,
                              presentAddress:
                                getSingleEmployeeDetails?.presentAddress,
                              countryName:
                                getSingleEmployeeDetails?.countryName,
                            }}
                            onFinish={saveEmployeePersonalInfo}
                          >
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label={t("first_name")}
                                  name="firstName"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label={t("last_name")}
                                  name="lastName"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label={t("date_of_birth")}
                                  name="dateOfBirth"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    disabledDate={disabledDate}
                                    needConfirm
                                    className="w-100"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label={t("gender")}
                                  name="gender"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    defaultValue={t("select_gender")}
                                    className="w-100"
                                    options={[
                                      {
                                        value: "Male",
                                        label: "Male",
                                      },
                                      {
                                        value: "Female",
                                        label: "Female",
                                      },
                                      {
                                        value: "Other",
                                        label: "Other",
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label={t("per_hour_rate")}
                                  name="contractorHourlyRate"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    addonBefore={getUserCurrencyByCountry(
                                      country ||
                                        getSingleEmployeeDetails?.countryName
                                    )}
                                    className="w-100"
                                    min={0}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label={t("experience")}
                                  name="employeeExperience"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <InputNumber className="w-100" min={0} />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label={t("nationality")}
                                  name="nationality"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    className="w-100"
                                    showSearch
                                    optionFilterProp="label"
                                    placeholder={t("select_nationality")}
                                    options={nationalities?.map((item) => {
                                      return {
                                        value: item.nationality,
                                        label: item.nationality,
                                      };
                                    })}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label={t("position")}
                                  name="positionId"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    // filterOption={(input, option) =>
                                    //   option.label
                                    //     .toLowerCase()
                                    //     .includes(input.toLowerCase())
                                    // }
                                    placeholder={t("select_position")}
                                    optionFilterProp="label"
                                    options={positions}
                                    className="w-100"
                                    allowClear
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label={t("country_name")}
                                  name="countryName"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    className="w-100"
                                    showSearch
                                    optionFilterProp="label"
                                    placeholder={t("select_country")}
                                    onChange={(value) => {
                                      setCountry(value);
                                    }}
                                    options={nationalities?.map((item) => {
                                      return {
                                        value: item.country,
                                        label: item.country,
                                      };
                                    })}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label={t("post_code")}
                                  name="postCode"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={24}>
                                <Form.Item
                                  name="phoneNumber"
                                  hasFeedback
                                  // rules={[
                                  //   {
                                  //     required: false,
                                  //     message: "",
                                  //   },
                                  //   {
                                  //     validator: (_, value) =>
                                  //       value && isValidPhoneNumber(value)
                                  //         ? Promise.resolve()
                                  //         : Promise.reject(
                                  //             new Error(
                                  //               t(
                                  //                 "please_enter_valid_phone_number_for_selected_country"
                                  //               )
                                  //             )
                                  //           ),
                                  //   },
                                  // ]}
                                  label={t("phone_number")}
                                >
                                  <PhoneInput
                                    placeholder="Enter phone number"
                                    isValid
                                    inputProps={{
                                      name: "phoneNumber",
                                      required: false,
                                    }}
                                    value={
                                      personalForm.getFieldValue().phoneNumber
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.Item
                              wrapperCol={{
                                span: 24, // Take full width
                              }}
                            >
                              <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                  float: "right",
                                  marginTop: "1rem",
                                  backgroundColor: Colors.primary,
                                }}
                                // onClick={() => setIsEditing(false)}
                              >
                                {editLoading ? (
                                  <div
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                  >
                                    <span class="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  t("save")
                                )}
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      ) : (
                        <>
                          <h5 className="d-flex align-items-center">
                            <span className="user_icon_react">
                              <FaRegUser />
                            </span>
                            {getSingleEmployeeDetails?.name}
                            {getSingleEmployeeDetails?.certified && (
                              <img
                                src={"/assets/employee/verified.png"}
                                alt="verified"
                                className="ms-2"
                                style={{ width: "25px", height: "25px" }}
                              />
                            )}

                            <span className="rating_span">
                              <FaStar className="text-warning me-1 ms-3" />
                              {getSingleEmployeeDetails?.rating}
                            </span>
                          </h5>

                          <div className="col-xl-12 col-md-12">
                            <div className="selectEmployeeTotalHours">
                              <MdEmail />
                              &nbsp; {t("email")}:{" "}
                              <small className="selectEmployeeTotalHoursValue">
                                {getSingleEmployeeDetails?.email}{" "}
                              </small>
                            </div>
                          </div>
                          {getSingleEmployeeDetails?.phoneNumber && (
                            <div className="col-xl-12 col-md-12">
                              <div className="selectEmployeeTotalHours">
                                <PhoneOutlined />
                                &nbsp; {t("phone_number")} :{" "}
                                <small className="selectEmployeeTotalHoursValue">
                                  {"+"}
                                  {getSingleEmployeeDetails?.phoneNumber}{" "}
                                </small>
                              </div>
                            </div>
                          )}

                          <div className="col-xl-12 col-md-12">
                            <div className="selectEmployeeTotalHours">
                              <img
                                src="/assets/frontend/images/selectedEmployee/job.png"
                                alt="alt-hours"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              &nbsp; {t("position")}:{" "}
                              <small className="selectEmployeeTotalHoursValue">
                                {getSingleEmployeeDetails?.positionName ||
                                  position?.details?.name}{" "}
                              </small>
                            </div>
                          </div>

                          <div className="col-xl-12 col-md-12">
                            <div className="selectEmployeeTotalHours">
                              <img
                                src="/assets/frontend/images/selectedEmployee/clock.png"
                                alt="alt-hours"
                              />
                              &nbsp; {t("total_hour")}:{" "}
                              <small className="selectEmployeeTotalHoursValue">
                                {getSingleEmployeeDetails?.totalWorkingHour}{" "}
                                {t("h")}
                              </small>
                            </div>
                          </div>

                          <div className="col-xl-12 col-md-12">
                            <div className="sctProfileAge">
                              <img
                                src={"/assets/employee/calander.png"}
                                style={{ height: "18px" }}
                                alt="calender"
                              />
                              &nbsp;{t("date_of_birth")}: &nbsp;
                              <span className="slctprofilePerHourPrice">
                                {getSingleEmployeeDetails?.dateOfBirth
                                  ? moment(
                                      getSingleEmployeeDetails?.dateOfBirth
                                    )
                                      .format("YYYY-MM-DD")
                                      .valueOf()
                                  : null}
                              </span>
                            </div>
                            <span className="slctProfileRateName">
                              <img
                                className="img-fluid SlctProrateIcon"
                                src="/assets/frontend/images/selectedEmployee/rate.png"
                                alt="alt-rate"
                              />
                              {t("rate")} :&nbsp;
                            </span>
                            <span className="slctprofilePerHourPrice">
                              {getSingleEmployeeDetails?.contractorHourlyRate?.toFixed(
                                2
                              )}{" "}
                              {currencySymbol ||
                                getUserCurrencyByCountry(
                                  getSingleEmployeeDetails?.countryName
                                )}{" "}
                              {t("per_hour")}
                            </span>
                          </div>

                          <div className="col-xl-12 col-md-12">
                            <span className="slctProfileExpName">
                              <img
                                src="/assets/frontend/images/selectedEmployee/experience.png"
                                alt="alt-exp"
                              />
                              &nbsp; {t("exp")}
                              {" : "}
                            </span>
                            <span className="slctProfileExpValue">
                              {getSingleEmployeeDetails?.employeeExperience &&
                                getSingleEmployeeDetails?.employeeExperience +
                                  " Years"}
                            </span>
                          </div>
                          <div className="col-xl-12 col-md-12">
                            <span className="slctProfileExpName">
                              <img
                                src="/assets/frontend/images/selectedEmployee/location.png"
                                alt="alt-exp"
                              />
                              &nbsp; {t("country")}
                              {" : "}
                            </span>
                            <span className="slctProfileExpValue">
                              {getSingleEmployeeDetails?.countryName}{" "}
                              <img
                                src={getFlagByCountry(
                                  getSingleEmployeeDetails?.countryName
                                )}
                                alt={getSingleEmployeeDetails?.countryName}
                                style={{
                                  width: "20px",
                                  height: "15px",
                                }}
                              />
                            </span>
                          </div>

                          {/* <div className="col-xl-12 col-md-12">
                            <span className="slctProfileExpName">
                              <img
                                src={"/assets/employee/license.png"}
                                style={{ height: "20px" }}
                                alt=""
                              />
                              &nbsp;{t("license_no")}
                              {" : "}
                            </span>
                            <span className="slctProfileExpValue">
                              {getSingleEmployeeDetails?.licensesNo}
                            </span>
                          </div> */}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="card mt-4">
                    <div className="card-header">{t("card_information")}</div>
                    <div className="card-body">
                      <div>
                        <Form
                          className="ant-form ant-form-vertical"
                          layout="vertical"
                          onFinish={
                            cardInfo?.provided ? onCardDelete : onCardAdd
                          }
                          form={bankform}
                        >
                          {cardInfo?.provided ? (
                            <MyCard cardInfo={cardInfo} />
                          ) : null}
                          {!cardInfo?.provided ? (
                            <div className="col-md-6">
                              <Form.Item>
                                <button
                                  disabled={cardLoading}
                                  className="btn"
                                  style={{
                                    background: "#58c8c8",
                                    color: "white",
                                  }}
                                  type="submit"
                                >
                                  {!cardLoading && "Add Card"}
                                  {cardLoading && (
                                    <span
                                      className="indicator-progress"
                                      style={{ display: "block" }}
                                    >
                                      {t("please_wait...")}
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  )}
                                </button>
                              </Form.Item>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              {
                                <Form.Item>
                                  <button
                                    disabled={cardLoading}
                                    className="btn"
                                    style={{
                                      background: "#58c8c8",
                                      color: "white",
                                    }}
                                    type="submit"
                                  >
                                    {!cardLoading && "Remove Card"}
                                    {cardLoading && (
                                      <span
                                        className="indicator-progress"
                                        style={{ display: "block" }}
                                      >
                                        {t("please_wait...")}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span>
                                    )}
                                  </button>
                                </Form.Item>
                              }
                            </div>
                          )}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7">
              <EditCardHeader
                title={t("additional_information")}
                isEdit={isEditAdditional}
                setIsEdit={setIsEditAdditional}
              >
                {isEditAdditional ? (
                  <div className="edit_div">
                    <Form
                      form={additionalForm}
                      layout="vertical"
                      autoComplete="off"
                      onFinish={handleBioSave}
                      initialValues={{
                        bio: getSingleEmployeeDetails?.bio,
                        skills:
                          getSingleEmployeeDetails?.skills?.length > 0
                            ? getSingleEmployeeDetails?.skills?.map(
                                (skill) => skill.skillId
                              )
                            : [],
                        currentOrganisation:
                          getSingleEmployeeDetails?.currentOrganisation,
                        higherEducation:
                          getSingleEmployeeDetails?.higherEducation,
                        languages: getSingleEmployeeDetails?.languages,
                        dressSize: getSingleEmployeeDetails?.dressSize,
                        emmergencyContact:
                          getSingleEmployeeDetails?.emmergencyContact,
                        height: getSingleEmployeeDetails?.height,
                        weight: getSingleEmployeeDetails?.weight,
                      }}
                    >
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            label="Bio"
                            name="bio"
                            initialValue={getSingleEmployeeDetails?.bio}
                            rules={[
                              {
                                required: true,
                                message: "Please enter your bio",
                              },
                              { validator: validateBio },
                            ]}
                          >
                            <Input.TextArea rows={4} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="emmergencyContact"
                            label={t("emergency_contact")}
                            rules={[
                              {
                                required: true,
                                message: t("enter_emergency_contact"),
                              },
                            ]}
                          >
                            <Input placeholder={t("enter_emergency_contact")} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="height"
                            label={<span>{t("height")} (cm)</span>}
                            rules={[
                              {
                                required: true,
                                message: t("please_enter_height"),
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder={t("enter_height")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="weight"
                            label={t("weight_label")}
                            rules={[
                              {
                                required: true,
                                message: t("please_enter_weight"),
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder={t("enter_weight_placeholder")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="dressSize"
                            label={t("dress_size")}
                            rules={[
                              {
                                required: true,
                                message: t("please_enter_dress_size"),
                              },
                            ]}
                          >
                            <Select placeholder={t("select_dress_size")}>
                              {["XS", "S", "M", "L", "XL", "XXL"].map(
                                (size) => (
                                  <Option key={size} value={size}>
                                    {size}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <UploadCVButton
                        id={getSingleEmployeeDetails?._id}
                        getSingleEmployeeDetails={getSingleEmployeeDetails}
                        fetchEmployeeDetails={fetchEmployeeDetails}
                      />

                      <Form.Item
                        label={t("higher_education")}
                        name="higherEducation"
                        rules={[
                          {
                            message: t("please_enter_higher_education"),
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder={t("enter_higher_education")} />
                      </Form.Item>

                      <Form.Item
                        label={t("languages")}
                        name="languages"
                        rules={[
                          {
                            required: true,
                            message: t("please_input_your_preferred_language"),
                          },
                        ]}
                      >
                        <Select
                          className="w-100"
                          mode="multiple" // Enable multiple select mode
                          showSearch
                          optionFilterProp="label"
                          placeholder={t("preferred_language")}
                          options={languagesData?.map((item) => {
                            return {
                              value: item.name,
                              label: item.name,
                            };
                          })}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("skills")}
                        name="skills"
                        rules={[
                          {
                            required: true,
                            message: t("please_select_your_skills"),
                          },
                        ]}
                      >
                        <Select
                          className="w-100"
                          mode="multiple" // Enable multiple select mode
                          showSearch
                          optionFilterProp="label"
                          placeholder={t("select_skills")}
                          options={
                            skillsData?.length > 0
                              ? skillsData.map((item) => {
                                  return {
                                    value: item?._id || 1,
                                    label: item.name,
                                  };
                                })
                              : []
                          }
                        />
                      </Form.Item>
                      {/* <Form.Item
                        label={t("skills")}
                        name="skills"
                        rules={[
                          {
                            // required: true,
                            // messages: t("please_select_your_skills"),
                          },
                        ]}
                      >
                        <Select
                          className="w-100"
                          mode="multiple" // Enable multiple select mode
                          showSearch
                          optionFilterProp="label"
                          onChange={(value) => console.log("value", value)}
                          // placeholder={t("select_skills")}
                          options={
                            skillsData && skillsData.length > 0
                              ? skillsData.map((item) => ({
                                  value: item._id,
                                  label: item.name,
                                }))
                              : []
                          }
                        />
                      </Form.Item> */}

                      <Form.Item
                        name="presentAddress"
                        label={t("present_address")}
                        hasFeedback
                        rules={[
                          {
                            required: false,
                            message: t("please_enter_present_address"),
                          },
                        ]}
                      >
                        <div>
                          <div className="inputLogoWrapper">
                            <img
                              className="img-fluid"
                              src="/assets/frontend/images/registrationFormImages/clientFormPictures/Subtract.png"
                              alt="image"
                            />
                          </div>
                          <input
                            onClick={() => {
                              setMapModal(true);
                            }}
                            autoComplete="off"
                            placeholder={t("enter_present_address")}
                            type="text"
                            value={
                              additionalForm?.getFieldValue("presentAddress") ||
                              name ||
                              getSingleEmployeeDetails?.presentAddress
                            }
                            className="form-control custom_client_input_for_registration_page"
                          />
                        </div>
                      </Form.Item>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            label={t("current_organization")}
                            name="currentOrganisation"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            float: "right",
                            marginTop: "1rem",
                            backgroundColor: Colors.primary,
                          }}
                        >
                          {editLoading ? (
                            <div
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          ) : (
                            t("save")
                          )}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                ) : (
                  <>
                    <div>
                      <h6 className="h6 employee-header-profile">Bio:</h6>
                      <hr />
                      <p>{getSingleEmployeeDetails?.bio}</p>
                    </div>

                    <Col className="mt-2" span={12}>
                      <VerticalAlignMiddleOutlined
                        style={{ fontSize: "20px", color: Colors.primary }}
                      />
                      <span style={{ marginLeft: 8 }}>Height (cm):</span>{" "}
                      {getSingleEmployeeDetails?.height}
                    </Col>
                    <Col span={12}>
                      <FaWeight
                        style={{ fontSize: "20px", color: Colors.primary }}
                      />
                      <span style={{ marginLeft: 8 }}>Weight (kg):</span>{" "}
                      {getSingleEmployeeDetails?.weight}
                    </Col>

                    <Col className="mt-2" span={12}>
                      <SkinOutlined
                        style={{ fontSize: "20px", color: Colors.primary }}
                      />
                      <span style={{ marginLeft: 8 }}>Dress Size:</span>{" "}
                      {getSingleEmployeeDetails?.dressSize}
                    </Col>
                    <Col className="mt-2" span={12}>
                      <PhoneOutlined
                        style={{ fontSize: "20px", color: Colors.primary }}
                      />
                      <span style={{ marginLeft: 8 }}>Emergency Contact:</span>{" "}
                      {getSingleEmployeeDetails?.emmergencyContact}
                    </Col>
                    <div className="mb-3 mt-3">
                      <h6 className="h6 employee-header-profile">Resume:</h6>
                      <hr />
                      {getSingleEmployeeDetails?.cv && (
                        <div className="mt-1">
                          <a
                            href={`${process.env.REACT_APP_ASSETs_BASE_URL}/${getSingleEmployeeDetails?.cv}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Uploaded CV
                          </a>
                        </div>
                      )}
                    </div>
                    <div>
                      <h6 className="h6 employee-header-profile">
                        {t("higher_education")} :
                      </h6>
                      <hr />
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/frontend/images/selectedEmployee/education (2).png"
                          alt="custom-image1"
                        />
                        <p
                          style={{
                            textTransform: "capitalize",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          &nbsp;{getSingleEmployeeDetails?.higherEducation}
                        </p>
                      </div>
                      <div className="mt-4">
                        <h6 className="h6 employee-header-profile">
                          {t("skills")}:
                        </h6>
                        <hr />
                        <div className="row">
                          <div className="col">
                            {getSingleEmployeeDetails?.skills
                              ?.slice(0, 5)
                              .map((item, index) => (
                                <div key={index}>
                                  <span>
                                    <img
                                      src={"/assets/employee/requirements.png"}
                                      style={{ height: "25px", width: "25px" }}
                                      alt="custom-language"
                                    />
                                    &nbsp;{item.skillName}
                                  </span>
                                </div>
                              ))}
                          </div>
                          <div className="col">
                            {getSingleEmployeeDetails?.skills
                              ?.slice(5)
                              .map((item, index) => (
                                <div key={index}>
                                  <span>
                                    <img
                                      src={"/assets/employee/requirements.png"}
                                      style={{ height: "25px", width: "25px" }}
                                      alt="custom-language"
                                    />
                                    &nbsp;{item.skillName}
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <h6 className="h6 employee-header-profile">
                          {t("languages")}:
                        </h6>
                        <hr />
                        <div>
                          {getSingleEmployeeDetails?.languages?.map(
                            (item, index) => (
                              <div className="row" key={index}>
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <img
                                    src="/assets/frontend/images/selectedEmployee/googleTranslate.png"
                                    alt="custom-image1"
                                  />
                                  &nbsp; {item}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      {getSingleEmployeeDetails?.lat &&
                        getSingleEmployeeDetails?.long && (
                          <div className="mt-4">
                            <h6 className="h6 employee-header-profile">
                              {t("present_address")}:
                            </h6>
                            <hr />
                            {/* <MapView
                              lat={+getSingleEmployeeDetails.lat}
                              lng={+getSingleEmployeeDetails.long}
                            /> */}
                            <div>
                              <img
                                src="/assets/frontend/images/selectedEmployee/location.png"
                                alt="custom-image1"
                              />
                              &nbsp;
                              {getSingleEmployeeDetails?.presentAddress}
                            </div>
                          </div>
                        )}
                    </div>
                  </>
                )}
              </EditCardHeader>

              <EditCardHeader
                title={t("documents")}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
              >
                <AddAndViewCertification
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  getSingleEmployeeDetails={getSingleEmployeeDetails}
                  fetchEmployeeDetails={fetchEmployeeDetails}
                />
              </EditCardHeader>
              <EditCardHeader
                title={t("bank_information")}
                isEdit={isBankInfo}
                setIsEdit={setIsBankInfo}
              >
                <AddAndViewBankInfo
                  isEdit={isBankInfo}
                  setIsEdit={setIsBankInfo}
                  getSingleEmployeeDetails={getSingleEmployeeDetails}
                  fetchEmployeeDetails={fetchEmployeeDetails}
                />
              </EditCardHeader>
            </div>
          </div>
        )}
      </section>

      <Modal
        title="Edit Bio"
        open={isBioModelOpen}
        onCancel={handleBioCancel}
        footer={null}
      >
        <Form form={bioForm} onFinish={handleBioSave}>
          <Form.Item
            name="bio"
            initialValue={getSingleEmployeeDetails?.bio}
            rules={[
              { required: true, message: "Please enter your bio" },
              { validator: validateBio },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <button
            className="btn btn-secondary "
            style={{
              background: "#58c8c8",
              border: "none",
            }}
            type="submit"
          >
            Update
          </button>
        </Form>
      </Modal>

      <Modal
        title={t("help_support")}
        okButtonProps={{ style: { display: "none" } }}
        open={isModalOpen}
        onCancel={handleCancel}
        cancelText={t("close")}
        okText={t("ok")}
        cancelButtonProps={{
          style: { backgroundColor: "#58c8c8", color: "white" },
        }}
      >
        <div className="col-lg-12 mb-4">
          {/* <div className="row"></div> */}
          <div className="socialMediaIcons">
            <a
              target="_blank"
              href="https://www.facebook.com/RecruitmentMirkoHospitality/"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116168.png"
                alt="image1"
              />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/recruitmentmirkohospitality/"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116169.png"
                alt="image1"
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/mirko-hospitality/mycompany/?viewAsMember=true"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116170.png"
                alt="image1"
              />
            </a>
            <a
              href="https://vm.tiktok.com/ZGJmndX98/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116171.png"
                alt="image1"
              />
            </a>
          </div>
          <div>
            <div className="d-flex mt-4">
              <a href="tel:+4407500146699">
                <FiPhoneOutgoing
                  style={{
                    fontSize: "45px",
                    color: "#8e6d45",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>Reservation</p>
                <h5 style={{ fontSize: "20px", color: "#8e6d45" }}>
                  {t("privacy_notice_des_8_4")}
                </h5>
              </div>
            </div>
            <div className="d-flex mt-4 align-items-center">
              <a href="mailto:info@plagit.com">
                <TfiEmail
                  style={{
                    fontSize: "40px",
                    color: "#8e6d45",
                    marginTop: "2px",
                  }}
                />
              </a>
              <div className="mx-3">
                <p className="m-0 p-0">Email Info</p>
                <h5
                  className="m-0 p-0"
                  style={{ fontSize: "15px", color: "#8e6d45" }}
                >
                  info@plagit.com
                </h5>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <MapModal
        isModalOpen={mapModal}
        setIsModalOpen={setMapModal}
        myLocation={myLocation}
        setMyLocation={setMyLocation}
        name={name}
        setName={setName}
        form={additionalForm}
      />
      <CustomConfirmDialog message="Are you sure you want to remove this card?" />
    </div>
  );
};

export default EmployeeProfile;

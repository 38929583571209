import React, { useRef, useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa";
import ReactPlayer from "react-player";

const VideoPlayerShow = ({ data, post }) => {
  const playerWrapperRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // Start muted initially

  useEffect(() => {
    const multiVideo = post?.media?.filter((item) => item?.type == "video");

    if (multiVideo?.length == 1) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsPlaying(true);
              setIsMuted(false); // Unmute when it starts playing
            } else {
              setIsPlaying(false);
              setIsMuted(true); // Mute when it's not in view
            }
          });
        },
        { threshold: 0.75 }
      );

      if (playerWrapperRef?.current) {
        observer.observe(playerWrapperRef.current);
      }

      return () => {
        if (playerWrapperRef?.current) {
          observer.unobserve(playerWrapperRef.current);
        }
      };
    } else {
      setIsPlaying(false);
    }
  }, [post?.media]);

  return (
    <div
      ref={playerWrapperRef}
      className="d-flex align-items-center mx-auto"
      style={{ position: "relative" }}
    >
      <ReactPlayer
        url={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${data}`}
        playing={isPlaying}
        muted={isMuted} // Use isMuted state for muting control
        className="social_player_view"
        width="100%"
        height="300px"
        controls
      />
      {!isPlaying && (
        <div
          className="play-icon-overlay"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
            zIndex: 10,
            color: "#fff",
            fontSize: "50px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "50%",
            padding: "15px",
          }}
          onClick={() => setIsPlaying(true)}
        >
          <FaPlay />
        </div>
      )}
    </div>
  );
};

export default VideoPlayerShow;

// import React from "react";
// import { FaPlay } from "react-icons/fa"; // Play icon from react-icons
// import ReactPlayer from "react-player"; // Import ReactPlayer

// const VideoPlayerShow = ({ data }) => {
//   return (
//     <div
//       className="d-flex align-items-center mx-auto"
//       style={{ position: "relative" }}
//     >
//       <ReactPlayer
//         url={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${data}`}
//         // controls
//         // playing
//         muted
//         className="social_player_view"
//         wrapper={"div"}
//         width="100%"
//         height="300px"
//         playing={false}
//       />
//       <div
//         className="play-icon-overlay"
//         style={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           cursor: "pointer",
//           zIndex: 10,
//           color: "#fff",
//           fontSize: "50px",
//           backgroundColor: "rgba(0, 0, 0, 0.5)",
//           borderRadius: "50%",
//           padding: "15px",
//         }}
//       >
//         <FaPlay />
//       </div>
//     </div>
//   );
// };

// export default VideoPlayerShow;

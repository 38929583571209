import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  TimePicker,
  Tooltip,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { token } from "../../../utils/authentication";
import { FcInfo } from "react-icons/fc";
import {
  getUserCurrencyByCountry,
  getUserInfo,
} from "../../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import toast from "react-hot-toast";
import { donwloadCSV } from "../../../utils/static/donwloadCSV";
import { ContextApi } from "../../../hooks/ContextApi";
import { minutestoHours } from "../../../utils/date";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as Action from "../_redux/clientActions";
import axiosInstance from "../../../utils/API";
import { buildCheckInOutHistoryUrl } from "../../../utils/url";
import { useQuery } from "react-query";
import { formattedCheckInCheckOutData } from "../../../utils/checkInCheckOut";
import SmallLoading from "../../../components/Shared/SmallLoading";
import { InvoiceDownloadButton } from "../../../components/frontend/client/_components/InvoiceDownload";
import { Colors } from "../../../constants/Colors";
const fetchCheckInOutHistories = async ({ queryKey }) => {
  const [
    _key,
    { clientId, currentPage, pageSize, dateFilter, getName, status },
  ] = queryKey;
  const url = buildCheckInOutHistoryUrl({
    clientId,
    currentPage,
    pageSize,
    dateFilter,
    getName,
    status,
  });
  const response = await axiosInstance.get(url);
  return response.data;
};

const useCheckInOutHistories = ({
  clientId,
  currentPage,
  pageSize,
  dateFilter,
  getName,
  status,
}) => {
  return useQuery(
    [
      "checkInOutHistories",
      { clientId, currentPage, pageSize, dateFilter, getName, status },
    ],
    fetchCheckInOutHistories,
    {
      keepPreviousData: true,
    }
  );
};

function ClientPaymentHistoryForProfile() {
  const { t } = useTranslation();
  const jwtDecode = jwtTokenDecode();
  const userInfo = getUserInfo();
  const [form] = Form.useForm();
  const [getEmployee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [modal, setModal] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const { currencyType } = useContext(ContextApi);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedClientsData, setselectedClientsData] = useState(null);
  const [pageSize, setpageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  //Set filter data
  const [getName, setName] = useState(undefined);
  const dispatch = useDispatch();
  const [dateFilter, setDateFilter] = useState(null);
  const userId = jwtDecode?._id || userInfo?._id;

  const { historyPaymentList, totalPages, listLoading, totalCount } =
    useSelector(
      ({ client }) => ({
        listLoading: client?.listLoading,
        historyPaymentList: client?.clientPaymentData?.entities ?? [],
        totalPages: client?.clientPaymentData?.totalPages,
        totalCount: client?.clientPaymentData?.totalCount,
      }),
      shallowEqual
    );

  const { data, isLoading, error, refetch } = useCheckInOutHistories({
    clientId: userId,
    currentPage,
    pageSize,
    dateFilter,
    getName,
    status,
  });

  // useEffect(() => {
  //   if (currentPage && pageSize && userId) {
  //     dispatch(
  //       Action.fetchClientPaymentHistoryList(
  //         userId,
  //         currentPage,
  //         pageSize,
  //         dateFilter,
  //         getName,
  //         status
  //       )
  //     );
  //   }
  // }, [dispatch, currentPage, pageSize, dateFilter, getName, status]);

  useEffect(() => {
    // setEmployee(historyPaymentList || []);
    // setTotalData(totalCount);

    if (data && Object.keys(data).length > 0) {
      const employees = formattedCheckInCheckOutData(data);
      // console.log(employees);
      setEmployee(employees);
      setTotalData(data?.total);
    }
  }, [data]);

  // console.log("historyPaymentList~~", historyPaymentList);

  const handleDateFilter = (date, dateString) => {
    setDateFilter(dateString);
    console.log("date", dateString);
  };

  // const isEditable = (checkInTime) => {
  //   const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000); // 12 hours ago
  //   return new Date(checkInTime) >= twelveHoursAgo;
  // };

  function isEditable(pastTime) {
    const twelveHoursInMs = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
    const currentTime = new Date();
    const timeDifference = currentTime - new Date(pastTime);

    return timeDifference < twelveHoursInMs;
  }

  const showModal = (_id, data) => {
    setModalVisible(true);
    // console.log(_id);
    setSelectedItemId(data.currentHiredEmployeeId);
    setselectedClientsData(data.checkInCheckOutDetails);
    // console.log("data", data);
  };

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedItemId(null);
    setselectedClientsData(null);
  };

  // const fetchEmployees = useCallback(async () => {
  //   setLoading(true);

  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories?clientId=${jwtDecode._id}&page=${currentPage}&limit=${pageSize}&status=${status}` +
  //         `${dateFilter ? `&filterDate=${dateFilter}` : ""}` +
  //         `${getName ? `&employeeName=${getName}` : ""}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token()}`,
  //         },
  //       }
  //     );

  //     if (res && res?.data.statusCode === 200) {
  //       const checkInCheckOutHistories = res.data.result.map((item) => {
  //         const finalCheckIn =
  //           item?.checkInCheckOutDetails?.clientCheckInTime ||
  //           item?.checkInCheckOutDetails?.checkInTime;
  //         const finalCheckOut =
  //           item?.checkInCheckOutDetails?.clientCheckOutTime ||
  //           item?.checkInCheckOutDetails?.checkOutTime;
  //         const finalBreakTime =
  //           item?.checkInCheckOutDetails?.clientBreakTime ||
  //           item?.checkInCheckOutDetails?.breakTime;
  //         const isEditCheckIn = item?.checkInCheckOutDetails?.clientCheckInTime
  //           ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             ) ===
  //             dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             )
  //             ? false
  //             : true
  //           : false;
  //         const isEditCheckOut = item?.checkInCheckOutDetails
  //           ?.clientCheckOutTime
  //           ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             ) ===
  //             dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             )
  //             ? false
  //             : true
  //           : false;
  //         const isEditBreakTime = item?.checkInCheckOutDetails?.clientBreakTime
  //           ? item?.checkInCheckOutDetails?.breakTime ===
  //             item?.checkInCheckOutDetails?.clientBreakTime
  //             ? false
  //             : true
  //           : false;
  //         console.log(item?.checkInCheckOutDetails?.checkInTime);
  //         return {
  //           date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
  //           id: item?._id,
  //           country: item?.restaurantDetails?.countryName,
  //           restaurantName: item?.restaurantDetails?.restaurantName,
  //           employeeName: item?.employeeDetails?.name,
  //           position: item?.employeeDetails?.positionName,
  //           contractorHourlyRate: item?.employeeDetails?.contractorHourlyRate,
  //           restaurantRate:
  //             item?.employeeDetails?.hourlyRate +
  //             " " +
  //             getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
  //             "/Hour",
  //           customerRate:
  //             item?.employeeDetails?.contractorHourlyRate +
  //             " " +
  //             getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
  //             "/Hour",
  //           checkIn: item?.checkInCheckOutDetails?.checkInTime
  //             ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
  //                 .format("hh:mm:ss A")
  //                 .toString()
  //             : null,
  //           finalCheckIn: finalCheckIn
  //             ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
  //             : null,
  //           isEditCheckIn: isEditCheckIn,

  //           checkOut: item?.checkInCheckOutDetails?.checkOutTime
  //             ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
  //                 .format("hh:mm:ss A")
  //                 .toString()
  //             : null,
  //           finalCheckOut: finalCheckOut
  //             ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
  //             : null,
  //           isEditCheckOut: isEditCheckOut,

  //           break: item?.checkInCheckOutDetails?.breakTime,
  //           finalBreakTime: finalBreakTime ? finalBreakTime : 0,
  //           isEditBreakTime: isEditBreakTime,

  //           totalHours: finalCheckOut
  //             ? minutestoHours(
  //                 dayjs(finalCheckOut)
  //                   .diff(dayjs(finalCheckIn), "seconds", true)
  //                   .toFixed(0) -
  //                   finalBreakTime * 60
  //               )
  //             : null,

  //           totalAmount: finalCheckOut
  //             ? (
  //                 (dayjs(finalCheckOut)
  //                   .diff(dayjs(finalCheckIn), "seconds", true)
  //                   .toFixed(0) -
  //                   finalBreakTime * 60) *
  //                 (item?.employeeDetails?.hourlyRate / 3600)
  //               ).toFixed(2) +
  //               " " +
  //               getUserCurrencyByCountry(item?.restaurantDetails?.countryName)
  //             : null,
  //           clientComment: item?.checkInCheckOutDetails?.clientComment,
  //           vat: item?.vat,
  //           vatAmount: item?.vatAmount,
  //           totalAmountWithVat: item?.totalAmount,
  //           employeeAmount: item?.employeeAmount,
  //           status: item?.status,
  //           workedHour: item?.workedHour,
  //           mainItem: item,
  //         };
  //       });

  //       setEmployee(checkInCheckOutHistories || []);
  //       setTotalData(res.data.total);
  //       setLoading(false);
  //     } else if (res && res?.data.statusCode === 400) {
  //       setError(res.errors);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setError(error);
  //     setLoading(true);
  //     console.log(error);
  //   }
  // }, [currentPage, pageSize, dateFilter, getName, status]);

  // useEffect(() => {
  //   fetchEmployees();
  // }, [fetchEmployees]);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const myParam = urlParams.get("page");
  //   const myParam2 = urlParams.get("limit");
  //   // console.log(myParam, myParam2);
  //   if (myParam === null || myParam2 === null) {
  //     navigate(`/employee-payment-history?page=1&limit=10`);
  //     window.location.reload();
  //   }
  //   setpageSize(myParam2);
  //   setCurrentPage(myParam);
  // }, [navigate]);

  const handleExportData = () => {
    const excelData = getEmployee.map((item) => {
      return {
        Date: item?.date,
        Country: item?.country,
        RestaurantName: item?.restaurantName,
        EmployeeName: item?.employeeName,
        Position: item?.position,
        RestaurantRate: item?.restaurantRate,
        CustomerRate: item?.customerRate,
        CheckIn: item?.finalCheckIn,
        CheckOut: item?.finalCheckOut,
        Break: item?.finalBreakTime,
        TotalHours: item?.totalHours,
        TotalAmount: item?.totalAmount,
        ClientComment: item?.clientComment,
      };
    });
    donwloadCSV(excelData, "check-in-check-out-histories");
  };
  const dynamicInvoiceLinkGenerator = (country) => {
    console.log(country);
    if (country === "United Kingdom") {
      return "payment-invoice-details";
    } else if (country === "United Arab Emirates") {
      return "payment-invoice-details2";
    } else {
      return "payment-invoice-details3";
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      // render upto totalData
      render: (value, record, index) => {
        return (
          <span>
            {(currentPage - 1) * pageSize + (index + 1)}
            {/* {index + 1} */}
          </span>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: t("contractor"),
      dataIndex: "employeeName",
    },
    {
      title: t("position"),
      dataIndex: "position",
      // sorter: (a, b) => a.active.length - b.active.length,
    },
    {
      title: t("contract_per_hour_rate"),
      dataIndex: "customerRate",
    },
    {
      title: t("check_in"),
      dataIndex: "checkIn",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckIn && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkIn}
              </div>
            )}
            <div
              style={
                record?.isEditCheckIn
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckIn}
            </div>
          </div>
        );
      },
    },
    {
      title: t("check_out"),
      dataIndex: "checkOut",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckOut && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkOut}
              </div>
            )}
            <div
              style={
                record?.isEditCheckOut
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckOut}
            </div>
          </div>
        );
      },
    },

    {
      title: t("break"),
      dataIndex: "break",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditBreakTime && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.break} min
              </div>
            )}
            <div
              style={
                record?.isEditBreakTime
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalBreakTime} min
            </div>
          </div>
        );
      },
    },
    {
      title: t("total_hours"),
      dataIndex: "workedHour",
    },
    {
      title: t("total_amount"),
      dataIndex: "totalAmount",
      // dataIndex: "employeeAmount",
      // render: (employeeAmount) => {
      //   return `${currencyType}${employeeAmount?.toFixed(2)}`;
      // },
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text, record) => {
        return (
          <div>
            {record?.status === "PAID" ? (
              <div
                style={{
                  color: "green",
                }}
              >
                {record?.status}
              </div>
            ) : (
              <div
                style={{
                  color: "red",
                }}
              >
                {record?.status}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("Tips"),
      dataIndex: "tips",
      render: (id, record) => {
        return (
          <div>
            {getUserCurrencyByCountry(record.country)}
            {record?.tips}
          </div>
        );
      },
    },
    {
      title: t("Travel Cost"),
      dataIndex: "travel_cost",
      render: (id, record) => {
        return (
          <div>
            {getUserCurrencyByCountry(record.country)}
            {record?.travel_cost}
          </div>
        );
      },
    },
    {
      title: t("client_comment"),
      dataIndex: "clientComment",
    },

    {
      title: t("action"),
      dataIndex: "_id",
      fixed: "right",
      render: (_id, record) => (
        <div className="text-center">
          {isEditable(record?.mainItem?.checkInCheckOutDetails?.checkInTime) &&
          record?.status === "DUE" ? (
            <>
              {!record?.hasReview && (
                <Button
                  type="button"
                  style={{
                    cursor: "pointer",
                    backgroundColor: Colors.primary,
                    color: "#fff",
                  }}
                  onClick={async () => {
                    try {
                      setLoading(record.currentHiredEmployeeId);
                      const { data } = await axiosInstance.put(
                        "/check-in-check-out-histories/review-employee",
                        {
                          hasReview: true,
                          currentHiredEmployeeId: record.currentHiredEmployeeId,
                        }
                      );
                      setLoading(null);

                      // console.log("data",data)

                      if (data?.status === "success") {
                        toast.success("Reviewed successfully");
                        refetch();
                      }
                    } catch (error) {
                      setLoading(null);
                      console.error("API Error:", error);
                    }
                  }}
                >
                  {loading === record.currentHiredEmployeeId ? (
                    <SmallLoading />
                  ) : (
                    "Pay Now"
                  )}
                </Button>
              )}

              {/* <Button
                className="InnerTableButton ms-2"
                onClick={() => {
                  console.log("Editing record", record);
                  setModal(true);
                  form.setFieldsValue({
                    currentHiredEmployeeId: record.currentHiredEmployeeId,
                    travelCost: record.travel_cost,
                    tips: record.tips,
                    checkInTime: record.checkIn
                      ? dayjs(
                          record?.mainItem?.checkInCheckOutDetails?.checkInTime
                        )
                      : null,
                    checkOutTime: record.checkOut
                      ? dayjs(
                          record?.mainItem?.checkInCheckOutDetails?.checkOutTime
                        )
                      : null,
                    breakTime: record.break,
                    clientComment: record.clientComment,
                    prevCheckInTime:
                      record?.mainItem?.checkInCheckOutDetails?.checkInTime,
                    prevCheckOutTime:
                      record?.mainItem?.checkInCheckOutDetails?.checkOutTime,
                  });
                }}
              >
                Edit
              </Button> */}
            </>
          ) : record?.status === "PAID" ? (
            <InvoiceDownloadButton country={record?.country} id={record?.id} />
          ) : (
            <Tooltip title="Can't edit after 12 hours">
              <span style={{ color: "red" }}>
                <FcInfo />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const saveHandler = () => {
    const values = form.getFieldsValue(true);
    const payload = {
      id: values.currentHiredEmployeeId,
      checkIn: !dayjs(values.checkInTime).isSame(values.prevCheckInTime),
      checkOut: !dayjs(values.checkOutTime).isSame(values.prevCheckOutTime),
      clientComment: values.clientComment ? values.clientComment : "",
      clientCheckInTime: dayjs(values.checkInTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      clientCheckOutTime: dayjs(values.checkOutTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      clientBreakTime: values.breakTime ? values.breakTime : 0,
      tips: values.tips,
      travel_cost: values.travelCost,
    };
    setModalLoader(true);
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/current-hired-employees/update-status`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((response) => {
        setModalLoader(false);
        if (response.data.statusCode === 200) {
          setModal(false);
          // fetchEmployees();
          toast.success("Updated successfullly");
          refetch();
        }
      })
      .catch((error) => {
        setModalLoader(false);
        console.error("API request error:", error);
      });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // console.log("Form values:", values);
        const payload = {
          id: selectedItemId,
          checkIn: selectedClientsData.checkIn,
          checkOut: selectedClientsData.checkOut,
          clientComment: values.complainComment,
          clientCheckInTime: selectedClientsData.checkInTime,
          clientCheckOutTime: selectedClientsData.checkOutTime,
        };
        if (values.complainOption === "checkInBefore") {
          payload.clientCheckInTime = Number(values.complainMinutes) * -1;
          payload.clientCheckOutTime = 0;
          payload.clientBreakTime = 0;
        } else if (values.complainOption === "checkInAfter") {
          payload.clientCheckOutTime = 0;
          payload.clientCheckInTime = Number(values.complainMinutes);
          payload.clientBreakTime = 0;
        } else if (values.complainOption === "checkOutBefore") {
          payload.clientCheckInTime = 0;
          payload.clientCheckOutTime = Number(values.complainMinutes) * -1;
          payload.clientBreakTime = 0;
        } else if (values.complainOption === "checkOutAfter") {
          payload.clientCheckInTime = 0;
          payload.clientCheckOutTime = Number(values.complainMinutes);
          payload.clientBreakTime = 0;
        } else if (values.complainOption === "breaktime") {
          payload.clientCheckInTime = 0;
          payload.clientCheckOutTime = 0;
          payload.clientBreakTime = Number(values.complainMinutes);
        }

        console.log("Payload:", payload); // Add this line to log the payload

        axios
          .put(
            `${process.env.REACT_APP_API_BASE_URL}/current-hired-employees/update-status`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token()}`,
              },
            }
          )
          .then((response) => {
            console.log("API response:", response.data);
            if (response.data.statusCode === 200) {
              // fetchEmployees();
              toast.success("Updated successfullly");
            }
            // fetchEmployees();
          })
          .catch((error) => {
            console.error("API request error:", error);
          });

        setModalVisible(false);
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.log("Form validation failed:", errorInfo);
      });
  };
  return (
    <div>
      {/* Inner Dashboard Search Part Start */}
      <section className="InnnerDashboardSearch">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="innerDashSearchItems d-flex align-items-center">
                <Link to="/client-dashboard">
                  <button className="innerdashboardBackButton">
                    <img
                      src="assets/frontend/images/InnerDashboard/arrow.png"
                      className="img-fluid"
                      alt="arrow"
                    />
                  </button>
                </Link>
                <img
                  src="assets/employee/payment.png"
                  className="img-fluid"
                  alt="paymentImage"
                />
                <span className="innerDashSearchItemsSpan">
                  {t("payment_history")}
                </span>
                <div
                  className="d-flex"
                  style={{
                    width: "350px",
                    marginLeft: "10px",
                    // border: "1px solid #58c8c8",
                  }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder={t("select_date")}
                    size="large"
                    onChange={handleDateFilter}
                  />
                  {/* radio button PAID and DUE  */}
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <Select
                      defaultValue=""
                      style={{ width: 120 }}
                      size="large"
                      onChange={(value) => {
                        setStatus(value);
                      }}
                    >
                      <Select.Option value="">ALL</Select.Option>
                      <Select.Option value="PAID">PAID</Select.Option>
                      <Select.Option value="DUE">DUE</Select.Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="InnnerDashboardTable">
        <div className="container">
          <Table
            columns={columns}
            dataSource={getEmployee}
            loading={isLoading}
            scroll={{ x: "max-content" }}
            pagination={{
              pageSize: Number(pageSize),
              current: Number(currentPage),
              showSizeChanger: false,
              onChange: (page, pageSize) => {
                setCurrentPage(page);
                setpageSize(pageSize);
                navigate(`?page=${page}&limit=${pageSize}`);
              },
              total: totalData,
            }}
          />
        </div>
      </section>
      <Modal
        title="Update Check In Check Out"
        visible={modal}
        width={1000}
        onCancel={() => setModal(false)}
        footer={
          <>
            <button
              className="btn btn-primary"
              disabled={modalLoader}
              style={{ marginRight: "10px" }}
              onClick={() => saveHandler()}
            >
              {modalLoader ? "Loading..." : "Update"}
            </button>
            <button
              className="btn btn-danger"
              onClick={() => setModal(false)}
              disabled={modalLoader}
            >
              Cancel
            </button>
          </>
        }
      >
        <Form layout="vertical" form={form}>
          <Row gutter={[10, 8]} className="mt-5 mb-4">
            <Col sm={24} md={12} lg={6}>
              <label>Check In Time</label>
              <Form.Item name="checkInTime">
                <TimePicker
                  onChange={(value) =>
                    form.setFieldsValue({ checkInTime: value })
                  }
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  showTime={true}
                  format={"HH:mm:ss"}
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={6}>
              <label>Check Out Time</label>
              <Form.Item name="checkOutTime">
                <TimePicker
                  onChange={(value) =>
                    form.setFieldsValue({ checkOutTime: value })
                  }
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  showTime={true}
                  format={"HH:mm:ss"}
                  disabled={!form.getFieldValue("checkOutTime")}
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={6}>
              <label>Break Time (Min)</label>
              <Form.Item name="breakTime">
                <InputNumber
                  type="number"
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Break Time"
                  onChange={(e) => form.setFieldsValue({ breakTime: e })}
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={6}>
              <label>Client Comment</label>
              <Form.Item name="clientComment">
                <Input
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Client Comment"
                  onChange={(e) =>
                    form.setFieldsValue({ clientComment: e.target.value })
                  }
                />
              </Form.Item>
            </Col>

            {/* Travel Cost */}
            <Col sm={24} md={12} lg={6}>
              <label>Travel Cost</label>
              <Form.Item name="travelCost">
                <InputNumber
                  type="number"
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Travel Cost"
                  onChange={(e) => form.setFieldsValue({ travelCost: e })}
                />
              </Form.Item>
            </Col>

            {/* Tips */}
            <Col sm={24} md={12} lg={6}>
              <label>Tips</label>
              <Form.Item name="tips">
                <InputNumber
                  type="number"
                  size="large"
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Tips"
                  onChange={(e) => form.setFieldsValue({ tips: e })}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default ClientPaymentHistoryForProfile;

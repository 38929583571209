import { notification } from "antd";

export const responseNotification = (message, type) => {
  notification[
    type === "warning" || type === "success" || type === "error"
      ? type
      : "success"
  ]({
    message: message?.code === 200 ? "Operation successful" : message,
  });
};

export const saveSuccessNotification = (Model) => {
  notification.success({
    message: `${Model}`,
  });
};

export const errorNotification = (errorMessage) => {
  notification.error({
    message: `${errorMessage} Error!!`,
  });
};
export const customErrorNotification = (errorMessage) => {
  notification.error({
    message: errorMessage,
  });
};

export function formatNotificationDate(date) {
  if (!date) {
    return null;
  }
  const now = new Date();
  const givenDate = new Date(date);
  const timeDiff = Math.abs(now - givenDate);

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) return years === 1 ? "1 year ago" : `${years} years ago`;
  if (months > 0) return months === 1 ? "1 month ago" : `${months} months ago`;
  if (days > 0) return days === 1 ? "1 day ago" : `${days} days ago`;
  if (hours > 0) return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  if (minutes > 0)
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  if (seconds > 0)
    return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;

  return "Just now";
}

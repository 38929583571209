import { Skeleton } from "antd";
import React from "react";

const MessageSkeleton = () => {
  return (
    <div>
      {/* User Message Skeleton */}
      <div className="message-box my-message">
        <p>
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
          <br />
          <span>
            <Skeleton.Input style={{ width: 50 }} active size="small" />
          </span>
        </p>
      </div>

      {/* Friend Message Skeleton */}
      <div className="message-box friend-message">
        <p>
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
          <br />
          <span>
            <Skeleton.Input style={{ width: 50 }} active size="small" />
          </span>
        </p>
      </div>
    </div>
  );
};

export default MessageSkeleton;

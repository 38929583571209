import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Switch } from "antd";
import axios from "axios";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import Loader from "../../../common/Loader";
import { token } from "../../../utils/authentication";
import GoBack from "../../../utils/goBack";
import NotFound from "../../../components/frontend/employee/notFound/notFound";
import FileUpload from "./FileUpload";

const { Option } = Select;

export default function EditAbout() {
  const { slug } = useParams();
  const [aboutDetails, setAboutDetails] = useState(null);
  const baseUrl = process.env.REACT_APP_ASSETs_BASE_URL;
  const fetchAboutDetails = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/about-us/${slug}`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (response.data.status === "success") {
        setAboutDetails(response.data.details);
        console.log("aboutDetails", response.data.details);
      } else {
        // Handle error
        console.error("Error fetching about details:", response.data);
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAboutDetails();
  }, [slug]);

  const initialValues = aboutDetails ? { ...aboutDetails } : null;
  console.log("initialValues", initialValues);

  const handleUpdate = async (updatedValues, { resetForm }) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/about-us/update`;
      const { _id: id, ...rest } = updatedValues;
      const updatedObject = { id, ...rest };
      // console.log("updatedObject", updatedObject);
      const response = await axios.put(apiUrl, updatedObject, {
        headers: {
          Authorization: `Bearer ${token()}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === "success") {
        // Handle success
        // console.log("AboutUs updated successfully!");
        toast.success("AboutUs updated successfully!");
        resetForm();
        window.location.href = "/admin/about-table";
        // Redirect or perform any other action upon successful update
      } else {
        // Handle other success cases or unexpected responses
        console.error("Unexpected response:", response.data);
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      toast.error("Error updating AboutUs:", error);
    }
  };
  const [generatedSlug, setGeneratedSlug] = useState(slug || "");
  const handleTitleChange = (event, setFieldValue) => {
    const title = event.target.value;
    const slug = title.toLowerCase().replace(/[^a-z-]+/g, "");
    setGeneratedSlug(slug || ""); // Set default value if slug is not provided
    setFieldValue("slug", slug);
    setFieldValue("title", title);
  };

  const handleSlugChange = (event, setFieldValue) => {
    const updatedSlug = event.target.value
      .toLowerCase()
      .replace(/[^a-z-]+/g, "");
    setGeneratedSlug(updatedSlug || ""); // Set default value if slug is not provided
    setFieldValue("slug", updatedSlug);
  };

  if (aboutDetails === undefined) {
    return (
      <>
        <NotFound title={"About Us Not Found"} description={""} />
      </>
    );
  }
  if (!aboutDetails) {
    return <Loader />;
  }

  return (
    <div>
      <GoBack />
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpdate}
        // validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              className="fw-bold"
              style={{
                fontSize: "1.5rem",
                marginBottom: "20px",
                textAlign: "center",
                color: "#C6A34E",
              }}
            >
              About US Page Builder
            </p>
            <Space direction="vertical" size={20}>
              <div
                className="form-container"
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Country Name
                </label>

                <Field
                  name="countryName"
                  as={Select}
                  placeholder="Select Country Name"
                  style={{ width: "100%", marginBottom: "20px" }}
                  value={values.countryName}
                  onChange={(value) => setFieldValue("countryName", value)}
                >
                  <Option value="Dubai">Dubai</Option>
                  <Option value="Italy">Italy</Option>
                  <Option value="United Kingdom">United Kingdom</Option>
                </Field>

                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Title
                </label>
                <Field
                  name="title"
                  as={Input}
                  placeholder="Enter Title"
                  style={{ width: "100%", marginBottom: "16px" }}
                  onChange={(e) => handleTitleChange(e, setFieldValue)}
                />
                {/* Slug Field */}
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Slug
                </label>
                <Field
                  name="slug"
                  as={Input}
                  placeholder="Slug will be generated automatically based on the title"
                  style={{ width: "100%", marginBottom: "16px" }}
                  value={generatedSlug}
                  onChange={(e) => handleSlugChange(e, setFieldValue)}
                />
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Sub Title
                </label>
                <Field
                  name="subTitle"
                  as={Input}
                  placeholder="Enter Sub Title"
                  style={{ width: "100%", marginBottom: "16px" }}
                />

                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Content Type
                </label>
                <Field
                  name="contentType"
                  as={Select}
                  placeholder="Select Content Type"
                  style={{ width: "100%", marginBottom: "16px" }}
                  value={values.contentType}
                  onChange={(value) => setFieldValue("contentType", value)}
                >
                  <Option value="image">Image</Option>
                  <Option value="video">Video</Option>
                </Field>

                {values.contentType === "image" && (
                  <FileUpload
                    contentType="image"
                    cropRatio={16 / 9}
                    onUpload={(fileUrl) => setFieldValue(`url`, fileUrl)}
                  />
                )}
                {values.contentType === "video" && (
                  <FileUpload
                    contentType="video"
                    onUpload={(fileUrl) => setFieldValue(`url`, fileUrl)}
                  />
                )}

                {values.contentType === "image" && (
                  <img
                    src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${values.url}`}
                    alt=""
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                )}
                {values.contentType === "video" && (
                  <video
                    src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${values.url}`}
                    alt=""
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                )}

                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Active/Inactive
                </label>
                <Field
                  name="active"
                  as={Switch}
                  checked={values.active}
                  onChange={(checked) => setFieldValue("active", checked)}
                />
              </div>

              {/* Branch Section */}
              <div
                className="form-container"
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Branch
                </label>
                <FieldArray name="branch">
                  {({ remove, push }) => (
                    <>
                      {values.branch.map((branch, index) => (
                        <div key={index} style={{ marginBottom: "16px" }}>
                          <Space
                            style={{ display: "flex", marginBottom: "8px" }}
                            align="baseline"
                          >
                            <Field
                              name={`branch.${index}.title`}
                              as={Input}
                              placeholder="Enter Branch Title"
                              style={{ width: "200px", marginRight: "8px" }}
                            />
                            <FileUpload
                              contentType="image"
                              cropRatio={16 / 9}
                              onUpload={(fileUrl) =>
                                setFieldValue(`branch.${index}.url`, fileUrl)
                              }
                            />

                            {values.branch[index].url && (
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                }}
                              >
                                <img
                                  src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${values.branch[index].url}`}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                            )}

                            <Field
                              name={`branch.${index}.subTitle`}
                              as={Input}
                              placeholder="Enter Branch Sub Title"
                              style={{ width: "200px", marginRight: "8px" }}
                            />
                            <MinusCircleOutlined
                              onClick={() => remove(index)}
                            />
                          </Space>
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() =>
                          push({ title: "", url: "", subTitle: "" })
                        }
                        icon={<PlusCircleOutlined />}
                      >
                        Add Branch
                      </Button>
                    </>
                  )}
                </FieldArray>
              </div>

              {/* Leadership Section */}
              <div
                className="form-container"
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Leadership
                </label>
                <Field
                  name="leadership.title"
                  as={Input}
                  placeholder="Enter Leadership Title"
                  style={{ marginBottom: "8px" }}
                />
                <Field
                  name="leadership.subTitle"
                  as={Input}
                  placeholder="Enter Leadership Sub Title"
                  style={{ marginBottom: "8px" }}
                />
                <FieldArray name="leadership.list">
                  {({ remove, push }) => (
                    <>
                      {values.leadership.list.map((leader, index) => (
                        <div key={index} style={{ marginBottom: "16px" }}>
                          <Space
                            style={{ display: "flex", marginBottom: "8px" }}
                            align="baseline"
                          >
                            <Field
                              name={`leadership.list.${index}.name`}
                              as={Input}
                              placeholder="Enter Leader Name"
                              style={{ width: "200px", marginRight: "8px" }}
                            />
                            <FileUpload
                              contentType="image"
                              cropRatio={16 / 9}
                              onUpload={(fileUrl) =>
                                setFieldValue(
                                  `leadership.list.${index}.url`,
                                  fileUrl
                                )
                              }
                            />
                            {values.leadership.list[index].url && (
                              <div style={{ width: "50px", height: "50px" }}>
                                <img
                                  src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${values.leadership.list[index].url}`}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            )}

                            <Field
                              name={`leadership.list.${index}.occupation`}
                              as={Input}
                              placeholder="Enter Leader Occupation"
                              style={{ width: "200px", marginRight: "8px" }}
                            />
                            <MinusCircleOutlined
                              onClick={() => remove(index)}
                            />
                          </Space>
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() =>
                          push({ name: "", url: "", occupation: "" })
                        }
                        icon={<PlusCircleOutlined />}
                      >
                        Add Leader
                      </Button>
                    </>
                  )}
                </FieldArray>
              </div>

              {/* Mission & Vision Section */}
              <div
                className="form-container"
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                {/* Mission & Vision Section */}
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Mission & Vision
                </label>
                <Field
                  name="missionAndVision.title"
                  as={Input}
                  placeholder="Enter Mission & Vision Title"
                  style={{ marginBottom: "8px" }}
                />
                <Field
                  name="missionAndVision.subTitle"
                  as={Input}
                  placeholder="Enter Mission & Vision Sub Title"
                  style={{ marginBottom: "8px" }}
                />
                <Field
                  name="missionAndVision.contentType"
                  as={Select}
                  placeholder="Select Content Type"
                  style={{ width: "100%", marginBottom: "16px" }}
                  value={values.missionAndVision.contentType}
                  onChange={(value) =>
                    setFieldValue("missionAndVision.contentType", value)
                  }
                >
                  <Option value="image">Image</Option>
                  <Option value="video">Video</Option>
                </Field>

                {values.missionAndVision.contentType === "image" && (
                  <FileUpload
                    contentType="image"
                    cropRatio={16 / 9}
                    onUpload={(fileUrl) =>
                      setFieldValue("missionAndVision.url", fileUrl)
                    }
                  />
                )}
                {values.missionAndVision.contentType === "video" && (
                  <FileUpload
                    contentType="video"
                    onUpload={(fileUrl) =>
                      setFieldValue("missionAndVision.url", fileUrl)
                    }
                  />
                )}
                {values.missionAndVision.contentType === "image" && (
                  <img
                    src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${values.missionAndVision.url}`}
                    alt=""
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                )}
                {values.missionAndVision.contentType === "video" && (
                  <video
                    src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${values.missionAndVision.url}`}
                    alt=""
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <div>
                  <FieldArray name="missionAndVision.list">
                    {({ push, remove }) => (
                      <>
                        {values.missionAndVision.list.map((item, index) => (
                          <div key={index} style={{ marginBottom: "16px" }}>
                            <Space
                              style={{ display: "flex", marginBottom: "8px" }}
                              align="baseline"
                            >
                              <Field
                                name={`missionAndVision.list.${index}`}
                                as={Input}
                                placeholder="Enter Mission & Vision Item"
                                style={{ width: "300px", marginRight: "8px" }}
                              />

                              <MinusCircleOutlined
                                onClick={() => remove(index)}
                              />
                            </Space>
                          </div>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() => push("")}
                          icon={<PlusCircleOutlined />}
                        >
                          Add Item
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>

              {/* Testimonials Section */}
              <div
                className="form-container"
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Testimonials
                </label>
                <Field
                  name="testimonials.title"
                  as={Input}
                  placeholder="Enter Testimonials Title"
                  style={{ marginBottom: "8px" }}
                />
                <Field
                  name="testimonials.subTitle"
                  as={Input}
                  placeholder="Enter Testimonials Sub Title"
                  style={{ marginBottom: "8px" }}
                />
                <FieldArray name="testimonials.list">
                  {({ remove, push }) => (
                    <>
                      {values.testimonials.list.map((testimonial, index) => (
                        <div key={index} style={{ marginBottom: "16px" }}>
                          <Space
                            style={{ display: "flex", marginBottom: "8px" }}
                            align="baseline"
                          >
                            <Field
                              name={`testimonials.list.${index}.title`}
                              as={Input}
                              placeholder="Enter Testimonial Title"
                              style={{ width: "200px", marginRight: "8px" }}
                            />
                            <Field
                              name={`testimonials.list.${index}.subTitle`}
                              as={Input}
                              placeholder="Enter Testimonial Sub Title"
                              style={{ width: "200px", marginRight: "8px" }}
                            />
                            <FileUpload
                              contentType="image"
                              cropRatio={16 / 9}
                              onUpload={(fileUrl) =>
                                setFieldValue(
                                  `testimonials.list.${index}.url`,
                                  fileUrl
                                )
                              }
                            />
                            {values.testimonials.list[index].url && (
                              <div style={{ width: "50px", height: "50px" }}>
                                <img
                                  src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${values.testimonials.list[index].url}`}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            )}

                            <MinusCircleOutlined
                              onClick={() => remove(index)}
                            />
                          </Space>
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() =>
                          push({ title: "", subTitle: "", url: "" })
                        }
                        icon={<PlusCircleOutlined />}
                      >
                        Add Testimonial
                      </Button>
                    </>
                  )}
                </FieldArray>
              </div>

              {/* Partners Section */}
              <div
                className="form-container"
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                {/* Partners Section */}
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Partners
                </label>
                <Field
                  name="partners.title"
                  as={Input}
                  placeholder="Enter Partners Title"
                  style={{ marginBottom: "8px" }}
                />
                <Field
                  name="partners.subTitle"
                  as={Input}
                  placeholder="Enter Partners Sub Title"
                  style={{ marginBottom: "8px" }}
                />
                <FieldArray name="partners.list">
                  {({ remove, push }) => (
                    <>
                      {values.partners.list.map((partner, index) => (
                        <div key={index} style={{ marginBottom: "16px" }}>
                          <Space
                            style={{ display: "flex", marginBottom: "8px" }}
                            align="baseline"
                          >
                            <Field
                              name={`partners.list.${index}.title`}
                              as={Input}
                              placeholder="Enter Partner"
                              style={{ width: "200px", marginRight: "8px" }}
                            />
                            <FileUpload
                              contentType="image"
                              cropRatio={16 / 9}
                              onUpload={(fileUrl) =>
                                setFieldValue(
                                  `partners.list.${index}.url`,
                                  fileUrl
                                )
                              }
                            />
                            {values.partners.list[index].url && (
                              <div style={{ width: "50px", height: "50px" }}>
                                <img
                                  src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${values.partners.list[index].url}`}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            )}
                            <MinusCircleOutlined
                              onClick={() => remove(index)}
                            />
                          </Space>
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() => push("")}
                        icon={<PlusCircleOutlined />}
                      >
                        Add Partner
                      </Button>
                    </>
                  )}
                </FieldArray>
              </div>

              <Button
                style={{
                  width: "200px",
                  marginBottom: "20px",
                  backgroundColor: "#C6A34E",
                }}
                type="primary"
                htmlType="submit"
              >
                Update
              </Button>
            </Space>
          </Form>
        )}
      </Formik>
    </div>
  );
}

import React from "react";
import { Progress } from "antd";
import { Colors } from "../../constants/Colors";
import { getFormateName } from "../../utils/commonFunctions";

const ProfilePercentageCard = ({
  percent = 0,
  name = "",
  className = "",
  width = 40,
}) => {
  return (
    <div
      className={`container-fluid p-3 w-full d-flex mb-2 rounded-3 shadow justify-content-center  text-white ${className}`}
      style={{ backgroundColor: "#000" }}
    >
      <div className=" d-flex align-items-center mx-auto justify-content-center text-white">
        <div>
          <p className="mb-0 percentage-card-header  text-white">
            Hey {getFormateName(name, 15)}, Your Profile is{" "}
            <span style={{ color: Colors.primary }}>{percent}%</span> Done.
            <br className="profile-percentage-break" />
            Please, complete your profile to Proceed Next!
          </p>
        </div>
        <div className="ms-2">
          <Progress
            type="circle"
            percent={percent}
            width={width}
            strokeColor={Colors.primary}
            trailColor="#d9d9d9"
            format={(percent) => `${percent}%`}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilePercentageCard;

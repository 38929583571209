import { token } from "../../utils/authentication";

//client register api call
export const loginHandler = async (receivedLoginFields) => {
  console.log(
    "process.env.REACT_APP_API_BASE_URL~~",
    process.env.REACT_APP_API_BASE_URL
  );
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(receivedLoginFields),
  });
  return res;
};

//update api call
export const updatePasswordHandler = async (receivedMhEmployeeFields) => {
  let res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/update-password`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(receivedMhEmployeeFields),
    }
  );
  return res;
};

//forgot password api call
export const sendOtpHandler = async (receivedForgotPasswordFields) => {
  let res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/forgot-password`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(receivedForgotPasswordFields),
    }
  );
  return res;
};

export const verifyOtpHandler = async (receivedVerifyOtpFields) => {
  let res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/otp-check`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(receivedVerifyOtpFields),
    }
  );
  return res;
};

export const resetPasswordHandler = async (receivedResetPasswordFields) => {
  let res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/reset-password`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(receivedResetPasswordFields),
    }
  );
  return res;
};

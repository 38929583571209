import ReactGA from "react-ga4";

ReactGA.initialize("G-9CYG79SDEF", {
  debug_mode: true,
});

export const SIGN_IN = "user_signin";
export const SIGN_UP = "user_signup";
export const PLAY_STORE = "play_store";
export const APP_STORE = "app_store";

export const eventTracker = (
  event,
  category = "",
  action = "click",
  label,
  custom_data
) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event, // Define the event name
      event_category: category,
      event_action: action,
      event_label: label,
      custom_data: JSON.stringify(custom_data),
      ...custom_data,
    });
    // Send event with ReactGA (GA4)
    ReactGA.gtag("event", event, {
      event_category: category,
      event_action: action,
      event_label: label,
      email: custom_data.email,
      // name: "rashed@gmail.com", // Add your custom parameter here
      ...custom_data, // Spread additional custom parameters
    });
  } catch (error) {
    console.error(error);
  }
};

import API from "../../../utils/API";

export function jobList(limit, page) {
  return API.get(`/job?status=PUBLISHED&limit=${limit}&page=${page}`);
}

export function mySocialPostList(user, limit, page) {
  return API.get(`/social-feed??user=${user}&limit=${limit}&page=${page}`);
}

export function getBookedList() {
  return API.get(`/notifications/details`);
}

export function getHiredList() {
  return API.get(`/users/hired-history`);
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Tooltip } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { AiOutlineFileAdd, AiOutlineMessage } from "react-icons/ai";
import { HiHome, HiOutlineBookmark } from "react-icons/hi";
import { IoMdCard, IoMdNotificationsOutline } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Action from "../../../pages/Auth/_redux/authActions";
import * as ClientAction from "../../../pages/Client/_redux/clientActions";
import { ContextApi } from "../../../hooks/ContextApi";
import { changeLanguageFunc, getUserId } from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { responseNotification } from "../../../utils/notifcation";
import Notification from "./Notification/Notification";
import notificationSound from "./Notification/notification-sound.mp3";
import { DownOutlined } from "@ant-design/icons";
import { FaRegUserCircle } from "react-icons/fa";
import {
  MdBusinessCenter,
  MdOutlinePassword,
  MdSpaceDashboard,
} from "react-icons/md";
import { FiPower } from "react-icons/fi";
import axios from "axios";
import { token } from "../../../utils/authentication";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { FaUser } from "react-icons/fa6";
import Sidebar from "../../../pages/Candidate/Layout/Sidebar";
import { useDispatch } from "react-redux";
import { useCommon } from "../../../contexts/common-context";
import { useUser } from "../../../contexts/user-context";
import { useSocket } from "../../../hooks/useSocket";
import { fetchSingleEmployeeData } from "../../../services/userServices";
import { useQuery } from "react-query";

function Header() {
  const { getNotifications, getNewMessages } = useSocket();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useUser();
  const [isHovered, setIsHovered] = useState(false);
  const { setChatBoxListVisible } = useCommon();
  const jwtDecode = jwtTokenDecode();
  const id = jwtDecode?._id;
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(
    localStorage.getItem("countryCode") || "GB"
  );
  const location = useLocation();
  const currentPath = location.pathname;
  // const isClientDashboard2 = currentPath === "/client-dashboard";

  const { notificationList, unreadCount } = useSelector(
    ({ auth }) => ({
      notificationList: auth?.notifications,
      unreadCount: auth?.unreadCount,
    }),
    shallowEqual
  );
  const { data: getSingleEmployeeDetails = {} } = useQuery(
    ["singleEmployee", id],
    () => fetchSingleEmployeeData(id),
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    dispatch(ClientAction.fetchShortlist(12, 1));
  }, [dispatch]);

  useEffect(() => {
    // Use `window.history` explicitly to avoid restricted globals error
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"; // Disable automatic scroll restoration
    }

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto"; // Reset behavior when component unmounts
      }
    };
  }, []);

  const profilePictureUrl =
    currentUser?.profilePicture &&
    currentUser.profilePicture !== "" &&
    !currentUser.profilePicture.includes("lh3.googleusercontent.com")
      ? `${process.env.REACT_APP_ASSETs_BASE_URL}/${currentUser.profilePicture}`
      : "/assets/Client.jpg";

  // const profilePictureUrl =
  //   userInfo.profilePicture && userInfo.profilePicture != ""
  //     ? `${process.env.REACT_APP_ASSETs_BASE_URL}/${userInfo.profilePicture}`
  //     : "/assets/Client.jpg";

  const {
    getShortList,
    notificationCount,
    setNotificationCount,
    loadingShortList,
  } = useContext(ContextApi);
  // const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  //   withCredentials: false,
  //   transports: ["websocket", "polling", "flashsocket"],
  // });

  const { totalCount } = useSelector(
    ({ client }) => ({
      totalCount: client.shortlistData?.totalCount,
    }),
    shallowEqual
  );

  const [message, setMessage] = useState("");
  useEffect(() => {
    const payload = {
      senderId: getUserId(), // sender id
      isAdmin: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        const conversationId = res.data.details._id;
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/messages/unread-msg?conversationId=${conversationId}`,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          )
          .then((res) => {
            setMessage(res.data.details.count);
          });
      });
  }, []);

  useEffect(() => {
    if (
      getSingleEmployeeDetails &&
      Object.keys(getSingleEmployeeDetails).length
    ) {
      setCurrentUser(getSingleEmployeeDetails);
    }
  }, [getSingleEmployeeDetails]);

  // useEffect(() => {
  //   const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  //     withCredentials: false,
  //     transports: ["websocket"],
  //   });

  //   socket.on("notification", (dataFromServer) => {
  //     console.log("Received notification:", dataFromServer);

  //     const logedInUserId = getUserId();
  //     if (dataFromServer.userInfo._id === logedInUserId) {
  //       setNotificationCount(notificationCount + 1);
  //       responseNotification(dataFromServer.body, "success");
  //       new Audio(notificationSound).play();
  //     }
  //   });

  //   socket.on("new_message", (data) => {
  //     var currentUrl = window.location.href;
  //     // CHECK IF THE MESSAGE IS FROM THE ADMIN
  //     if (
  //       data.message.receiverDetails?.length > 1 &&
  //       data.message.receiverDetails
  //         .map((item) => item.receiverId)
  //         .includes(getUserId()) &&
  //       !currentUrl.includes("/client-help-support")
  //     ) {
  //       const message = `Admin: ${data.message.text}`;
  //       responseNotification(message, "success");
  //       new Audio(notificationSound).play();
  //       return;
  //     }

  //     // CHECK IF THE MESSAGE IS FROM THE USER
  //     if (data.message.receiverDetails[0].receiverId === getUserId()) {
  //       const message = `${data.message.senderDetails.name} : ${data.message.text}`;
  //       responseNotification(message, "success");
  //       new Audio(notificationSound).play();
  //     }
  //   });

  //   return () => {
  //     socket.off("notification");
  //     socket.off("new_message");
  //     console.log("WebSocket connection closed.");
  //   };
  // }, []);

  useEffect(() => {
    if (getNotifications) {
      console.log({ getNotifications });
      const logedInUserId = getUserId();
      if (getNotifications.userInfo._id === logedInUserId) {
        setNotificationCount(notificationCount + 1);
        responseNotification(getNotifications.body, "success");
        new Audio(notificationSound).play();
      }
    }

    if (getNewMessages) {
      // var currentUrl = window.location.href;
      // // CHECK IF THE MESSAGE IS FROM THE ADMIN
      // if (
      //   getNewMessages.message.receiverDetails?.length > 1 &&
      //   getNewMessages.message.receiverDetails
      //     .map((item) => item.receiverId)
      //     .includes(getUserId()) &&
      //   !currentUrl.includes("/client-help-support")
      // ) {
      //   const message = `Admin: ${getNewMessages.message.text}`;
      //   responseNotification(message, "success");
      //   new Audio(notificationSound).play();
      //   return;
      // }

      // CHECK IF THE MESSAGE IS FROM THE USER
      if (
        getNewMessages?.message?.receiverDetails?.find(
          (item) => item?.receiverId == getUserId()
        )
      ) {
        const message = `${getNewMessages.message.senderDetails.name} : ${getNewMessages.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
      }
    }
  }, [getNewMessages, getNotifications]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginData");
    navigate("/");
    window.location.reload();
  };

  const notificationMenu = (
    <Notification
      setNotificationCount={setNotificationCount}
      isSubscriber={true}
    />
  );

  const [prevCount, setPrevCount] = useState(0);
  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (notificationCount > prevCount) {
      setShake(true);
      const timer = setTimeout(() => setShake(false), 500); // 500ms for the shaking duration
      return () => clearTimeout(timer);
    }
    setPrevCount(notificationCount);
  }, [notificationCount, prevCount]);

  const shakingStyle = {
    animation: shake ? "shake 0.5s cubic-bezier(.36,.07,.19,.97) both" : "",
  };
  // console.log(notificationCount);

  const items = [
    {
      label: (
        <Link to="/profile" className="profile-item">
          <FaRegUserCircle className="pro-icons" />
          Edit Profile
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to="/setting" className="profile-item">
          <MdOutlinePassword className="change-password" />
          Setting
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <a href="" onClick={handleLogout} className="profile-item">
          <FiPower className="close-btn" />
          Logout
        </a>
      ),
      key: "2",
    },
  ];

  //   console.log("notificationCount~~", notificationCount);

  const [tabs, setTabs] = useState("social");

  const handleTabs = (tab) => {
    localStorage.setItem("client_tab", tab);
    setTabs(tab);
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(Action.getNotificationList(1));
    dispatch(Action.getNotificationUnreadCount());
  }, [dispatch]);

  const [isVisibleNotifition, setIsVisibleNotifition] = useState(false);

  const handleNotifitionClick = () => {
    console.log("handleNotifitionClick~~", handleNotifitionClick);
    setIsVisibleNotifition(!isVisibleNotifition);
  };

  const notificationRef = useRef(null);

  // Close notification when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsVisibleNotifition(false); // Close notification
      }
    };

    if (isVisibleNotifition) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Cleanup event listener when component unmounts or when notification is closed
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisibleNotifition]);

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg plagit_pro_navbar ${
          isSticky ? "sticky" : ""
        }`}
      >
        <div className="container">
          <div className="nav_main_wrapper">
            <div className="">
              <Link className="mobile_header_open navbar-brand" to={"/"}>
                <img
                  className="img-fluid Dashboard_nav_logo"
                  style={{
                    height: "38px",
                  }}
                  src="/assets/frontend/images/Dashboardimages/logo_full.png"
                  alt="Default"
                />
              </Link>
            </div>

            <div className="mobile_header_open">
              <ul className="navbar-nav d-flex flex-row">
                <li className="nav-item pe-2">
                  <a
                    className="notification_icon_btn nav-link"
                    href="#"
                    onClick={handleNotifitionClick}
                  >
                    <div className="navLinkImageTextWraper position-relative">
                      <IoMdNotificationsOutline
                        style={{
                          fontSize: "30px",
                          ...shakingStyle,
                        }}
                      />
                      {unreadCount > 0 && (
                        <span className="bage_count">
                          {unreadCount > 20 ? "20+" : unreadCount}
                        </span>
                      )}
                    </div>
                  </a>

                  {isVisibleNotifition && (
                    <div ref={notificationRef}>
                      <Notification
                        setNotificationCount={setNotificationCount}
                        isSubscriber={true}
                      />
                    </div>
                  )}
                </li>

                <li className="nav-item">
                  <ReactFlagsSelect
                    defaultCountry="GB"
                    countries={["GB", "AE", "IT"]}
                    showSelectedLabel={false}
                    selected={selected || "GB"}
                    customLabels={{
                      US: "Eng",
                      GB: "Eng",
                      AE: "Ara",
                      IT: "Ita",
                    }}
                    onSelect={(e) => {
                      setSelected(e);
                      changeLanguageFunc(e);
                    }}
                  />
                </li>

                {/* <li className="nav-item">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample11"
                    aria-controls="navbarsExample11"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={toggleSidebar}
                  >
                    <CiMenuBurger />
                  </button>
                </li> */}
              </ul>
            </div>
          </div>

          <Sidebar
            toggleSidebar={toggleSidebar}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />

          {!isOpen && (
            <div
              className="collapse navbar-collapse d-lg-flex"
              id="navbarsExample11"
            >
              <Link className="navbar-brand col-lg-3 me-0" to={"/"}>
                <img
                  className="img-fluid Dashboard_nav_logo"
                  style={{
                    height: "38px",
                  }}
                  src="/assets/frontend/images/Dashboardimages/logo_full.png"
                  alt="Default"
                />
              </Link>

              <ul
                className={`navbar-nav col-lg-5 justify-content-lg-around ${
                  isSticky ? "visible" : "invisible"
                }`}
              >
                <li className="nav-item">
                  <Tooltip placement="bottom" title={t("home")}>
                    <a
                      className={`nav-link nav_link_hover rounded_btn  ${
                        currentPath.includes("client-dashboard")
                          ? "nav_link"
                          : ""
                      }`}
                      href="#"
                      onClick={async (e) => {
                        e.preventDefault();
                        console.log("anis molla!!!");
                        await localStorage.setItem("client_tab", "social");
                        navigate("/client-dashboard");
                        window.scrollTo(0, 0);
                        if ("scrollRestoration" in window.history) {
                          window.history.scrollRestoration = "manual";
                        }
                        setTimeout(() => {
                          window.location.reload();
                        }, 0);
                      }}
                    >
                      <HiHome
                        className={`${
                          currentPath.includes("client-dashboard")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </a>
                  </Tooltip>
                </li>
                <li className="nav-item">
                  <Tooltip placement="bottom" title={t("dashboard")}>
                    <Link
                      className={`nav-link nav_link_hover rounded_btn  ${
                        currentPath.includes("dashboard-history")
                          ? "nav_link"
                          : ""
                      }`}
                      to={"/dashboard-history"}
                    >
                      <MdSpaceDashboard
                        className={` nav_link_hover_icon ${
                          currentPath.includes("dashboard-history")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>
                <li className="nav-item">
                  <Tooltip placement="bottom" title={"Candidates"}>
                    <Link
                      className={`nav-link nav_link_hover rounded_btn  ${
                        currentPath.includes("client-myemployee")
                          ? "nav_link"
                          : ""
                      }`}
                      to={"/client-myemployee"}
                    >
                      <FaUser
                        className={` nav_link_hover_icon ${
                          currentPath.includes("client-myemployee")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>
                <li className="nav-item">
                  <Tooltip placement="bottom" title={"Create Post"}>
                    <Link
                      className={`nav-link nav_link_hover rounded_btn  ${
                        currentPath.includes("create-job") ? "nav_link" : ""
                      }`}
                      to={"/create-job"}
                    >
                      <AiOutlineFileAdd
                        className={` nav_link_hover_icon ${
                          currentPath.includes("create-job")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>

                <li className="nav-item">
                  <Tooltip placement="bottom" title={"Job Requests"}>
                    <Link
                      className={`nav-link rounded_btn nav_link_hover  ${
                        currentPath.includes("job-requests") ? "nav_link" : ""
                      } `}
                      to={"/job-requests"}
                    >
                      <MdBusinessCenter
                        className={`nav_link_hover_icon ${
                          currentPath.includes("job-requests")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>

                <li className="nav-item">
                  <Tooltip placement="bottom" title={t("payments")}>
                    <Link
                      className={`nav-link rounded_btn nav_link_hover  ${
                        currentPath.includes("client-payment-history")
                          ? "nav_link"
                          : ""
                      } `}
                      to={"/client-payment-history"}
                    >
                      <IoMdCard
                        className={`nav_link_hover_icon ${
                          currentPath.includes("client-payment-history")
                            ? "nav_link_text"
                            : ""
                        }`}
                      />
                    </Link>
                  </Tooltip>
                </li>
              </ul>

              <div className="d-lg-flex col-lg-4 justify-content-lg-end">
                <ul className="Dashboard_navbar_custom navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Tooltip placement="bottom" title={"Short List"}>
                      <Link
                        className={`Dashboard_navbar_custom_hover nav_link_hover nav-link ${
                          currentPath.includes("short-list") ? "nav_link" : ""
                        }`}
                        to="/short-list"
                      >
                        <div
                          className="navLinkImageTextWraper"
                          style={{ position: "relative" }}
                        >
                          <HiOutlineBookmark
                            style={{
                              fontSize: "30px",
                              fontWeight: "bold",
                            }}
                            className={`${
                              currentPath.includes("short-list")
                                ? "nav_link_text"
                                : ""
                            }`}
                          />
                          {totalCount > 0 && (
                            <span className="bage_count">{totalCount}</span>
                          )}
                        </div>
                      </Link>
                    </Tooltip>
                  </li>

                  <li className="nav-item position-relative">
                    <Tooltip placement="bottom" title={"Notifications"}>
                      <div
                        className={`Dashboard_navbar_custom_hover nav-link nav_link_hover ${
                          isVisibleNotifition ? "nav_link" : ""
                        }`}
                        // href="#"
                        onClick={handleNotifitionClick}
                      >
                        <div
                          className="navLinkImageTextWraper"
                          style={{ position: "relative" }}
                        >
                          <IoMdNotificationsOutline
                            style={{
                              fontSize: "30px",
                              ...shakingStyle,
                            }}
                            className={`${
                              isVisibleNotifition ? "nav_link_text" : ""
                            }`}
                          />
                          {unreadCount > 0 && (
                            <span className="bage_count">
                              {unreadCount > 20 ? "20+" : unreadCount}
                            </span>
                          )}
                        </div>
                      </div>
                    </Tooltip>

                    {isVisibleNotifition && (
                      <div ref={notificationRef}>
                        <Notification
                          setNotificationCount={setNotificationCount}
                          isSubscriber={true}
                        />
                      </div>
                    )}
                  </li>

                  <li className="nav-item">
                    <Tooltip placement="bottom" title={"Messages"}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setChatBoxListVisible(true);
                          const url =
                            jwtDecode?.role === "CLIENT"
                              ? "/client-dashboard"
                              : "/candidate/home";
                          navigate(url);
                        }}
                        className="Dashboard_navbar_custom_hover nav-link nav_link_hover"
                        // to="/client-help-support"
                      >
                        <div
                          className="navLinkImageTextWraper"
                          style={{ position: "relative" }}
                        >
                          <AiOutlineMessage
                            style={{
                              fontSize: "30px",
                              ...shakingStyle,
                            }}
                          />

                          <span className="bage_count">
                            {message === "" ? null : message > 0 && message}
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                  </li>

                  <li
                    onMouseEnter={() => setIsHovered(true)} // Track hover state
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <ReactFlagsSelect
                      defaultCountry="GB"
                      countries={["GB", "AE", "IT"]}
                      showSelectedLabel={false}
                      selected={selected || "GB"}
                      customLabels={{
                        US: "Eng",
                        GB: "Eng",
                        AE: "Ara",
                        IT: "Ita",
                      }}
                      onSelect={(e) => {
                        setSelected(e);
                        changeLanguageFunc(e);
                      }}
                    />
                  </li>

                  {!loading && (
                    <li className="nav-item ms-2">
                      <Dropdown
                        menu={{
                          items,
                        }}
                        trigger={["click"]}
                      >
                        <a
                          href="#!"
                          className="profile-icon-menu"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            src={profilePictureUrl}
                            alt="Profile"
                            className="rounded-circle user-icon-btn"
                          />
                          <DownOutlined className="down-arrow" />
                        </a>
                      </Dropdown>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </nav>

      <section className="Dashboardheader  d-none">
        <div className="container header_container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar DashboardNavban navbar-expand-md navbar-light">
                <a className="navbar-brand " href="/">
                  <img
                    className="img-fluid Dashboard_nav_logo"
                    style={{
                      height: "38px",
                    }}
                    src="/assets/frontend/images/Dashboardimages/logo_full.png"
                    alt="Default"
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="Dash_navb_custom collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="Dashboard_navbar_custom navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link
                        className="Dashboard_navbar_custom_hover nav-link"
                        to="/short-list"
                      >
                        <div
                          className="navLinkImageTextWraper"
                          style={{ position: "relative" }}
                        >
                          <HiOutlineBookmark
                            style={{
                              fontSize: "30px",
                              fontWeight: "bold",
                            }}
                          />
                          {totalCount > 0 && (
                            <span className="bage_count">{totalCount}</span>
                          )}
                        </div>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Dropdown overlay={notificationMenu} trigger={["click"]}>
                        <a
                          className="Dashboard_navbar_custom_hover nav-link"
                          href="#"
                        >
                          <div
                            className="navLinkImageTextWraper"
                            style={{ position: "relative" }}
                          >
                            <IoMdNotificationsOutline
                              style={{
                                fontSize: "30px",
                                ...shakingStyle,
                              }}
                            />
                            {unreadCount > 0 && (
                              <span className="bage_count">
                                {unreadCount > 20 ? "20+" : unreadCount}
                              </span>
                            )}
                          </div>
                        </a>
                      </Dropdown>
                    </li>

                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setChatBoxListVisible((prev) => !prev);
                          const url =
                            jwtDecode?.role === "CLIENT"
                              ? "/client-dashboard"
                              : "/candidate/home";
                          navigate(url);
                        }}
                        className="Dashboard_navbar_custom_hover nav-link"
                        // to="/client-help-support"
                      >
                        <div
                          className="navLinkImageTextWraper"
                          style={{ position: "relative" }}
                        >
                          <AiOutlineMessage
                            style={{
                              fontSize: "30px",
                              ...shakingStyle,
                            }}
                          />
                          <span className="bage_count">
                            {message == "" ? null : message > 0 && message}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <ReactFlagsSelect
                        defaultCountry="GB"
                        countries={["GB", "AE", "IT"]}
                        selected={selected || "GB"}
                        showSelectedLabel={false}
                        customLabels={{
                          US: "Eng",
                          GB: "Eng",
                          AE: "Ara",
                          IT: "Ita",
                        }}
                        onSelect={(e) => {
                          setSelected(e);
                          changeLanguageFunc(e);
                        }}
                      />
                    </li>

                    {/*!isSubscriber && (
                      <li className="nav-item">
                        <Dropdown
                          menu={{
                            items,
                          }}
                          trigger={["click"]}
                        >
                          <a
                            href="#!"
                            className="profile-icon-menu"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              src={profilePictureUrl}
                              alt="Profile"
                              className="rounded-circle user-icon-btn"
                            />
                            <DownOutlined className="down-arrow" />
                          </a>
                        </Dropdown>
                      </li>
                    )*/}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;

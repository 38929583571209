import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMail, AiOutlinePlayCircle } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import { BsTelephoneFill } from "react-icons/bs";
import ReactPlayer from "react-player";
import Courses from "./Courses";
import "./academy.css";
import Calendar from "./calender/Calender";
import pi2 from "./images/PERTICLE2.png";
import pi1 from "./images/Pertical1.png";
import f2 from "./images/f2.png";
import f3 from "./images/f3.png";
import g1 from "./images/g1.png";
import g3 from "./images/g3.png";
import g4 from "./images/g4.png";
import g5 from "./images/g5.png";
import g6 from "./images/g6.png";
import r3 from "./images/r3.png";
import sb from "./images/sb.png";
import bg from "./images/school-bg.png";
import st from "./images/st.png";
// import MyAcademyVideo from "./my_academy_video.mp4";
import "./style.scss";

const MHAcademy = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const programms = [
    {
      title: `${t("bartender_essentials")}`,
      subTitle: `${t("bartender_essentials_details")}`,
      image: "/assets/path134.png",
    },
    {
      title: `${t("foh_essentials")}`,
      subTitle: `${t("foh_essentials_details")}`,
      image: "/assets/path135.png",
    },
    {
      title: `${t("barista_essentials")}`,
      subTitle: `${t("barista_essentials_details")}`,
      image: "/assets/path138.png",
    },
  ];

  const service = [
    {
      title: `${t("customized_program")}`,
      subTitle: `${t("customized_program_details")}`,
      image: "/assets/path133.png",
    },
    {
      title: `${t("masterclasses")}`,
      subTitle: `${t("masterclasses_details")}`,
      image: "/assets/training.png",
    },
    {
      title: `${t("business_consultancy")}`,
      subTitle: `${t("business_consultancy_details")}`,
      image: "/assets/Group117731.png",
    },
  ];

  const handleEmailClick = () => {
    if (validateEmail(email)) {
      window.location.href = `mailto:info@plagit.com?subject=Hello%20MH%20Team`;
    }
  };

  //   console.log("validateEmail(email)", validateEmail(email));
  return (
    <div className="">
      {/*<div className="d-lg-flex  justify-space-between align-items-center  container mb-2 py-5">
        <div
          style={{
            height: "440px",
          }}
        >
          <img
            src={p1}
            alt="the"
            width={3000}
            height={"100%"}
            className="my-3 img-fluid"
          />
        </div>
        <div className="mx-5">
          <button
            style={{
              border: "1px solid #58c8c8",
              color: "#58c8c8",
              borderRadius: "5px",
              background: "white",
            }}
            className="my-3 px-4 py-2"
          >
            Plagit Academy
          </button>
          <h3
            style={{
              color: "#252525",
              fontWeight: 600,
              fontSize: "30px",
              font: "Montserrat",
            }}
          >
            THE HOSPITALITY ACADEMY
          </h3>
          <p
            style={{
              textAlign: "justify",
              // paddingRight: "40px",
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "38px",
              letterSpacing: "0em",
            }}
          >
            In response to the increasing demand for skilled professionals in
            the hospitality industry, we have developed innovative services for
            our valued customers and individuals passionate about this field.
            Inspired by renowned Italian high schools, our education and
            training academy distinguishes itself by providing expedited
            training programs that focus on the key skills required for various
            roles, such as chefs, waiters, baristas, and more
          </p>
        </div>
          </div>*/}

      <div className="container">
        <div className="row justify-space-between align-items-center  container mb-2">
          <div className="col-md-6 position-relative">
            <ReactPlayer
              className="react-player"
              url="https://mh-user-bucket.s3.amazonaws.com/public/users/profile/03AFazB8U-my-academy-video.mp4"
              // url={MyAcademyVideo}
              width="100%"
              height="100%"
              loop={true}
              muted
              playing={true}
              controls={true}
              playIcon={
                <div>
                  <AiOutlinePlayCircle
                    style={{
                      color: "#EEE8DD",
                      fontSize: "100px",
                      position: "absolute",
                      top: "38%",
                      left: "40%",
                      right: "40%",
                    }}
                  />
                </div>
              }

              // light={
              //   <img
              //     src={p1}
              //     alt="Thumbnail"
              //     width={"100%"}
              //     height={"100%"}
              //     className="my-3 img-fluid"
              //   />
              // }
            />
          </div>
          <div className="col-md-6">
            <div className="mx-5 pb-3">
              <button
                style={{
                  border: "1px solid #58c8c8",
                  color: "#58c8c8",
                  borderRadius: "5px",
                }}
                className="my-3 px-4 py-2"
              >
                {`${t("mh_academy")}`}
              </button>
              <h5
                style={{
                  color: "#252525",
                  fontWeight: 600,
                  fontSize: "30px",
                  font: "Montserrat",
                }}
              >
                {t("who_are_we")}
              </h5>
              <p
                style={{
                  textAlign: "justify",
                }}
              >
                {t("who_are_we_details")}
              </p>
              <h3
                style={{
                  color: "#252525",
                  fontWeight: 600,
                  fontSize: "30px",
                  font: "Montserrat",
                }}
              >
                {t("what_we_offer")}
              </h3>
              <p
                style={{
                  textAlign: "justify",
                  // paddingRight: "40px",
                }}
              >
                {t("what_we_offer_details")}
              </p>

              <div>
                <a
                  href="https://www.linkedin.com/company/plagit/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={f3} alt="the" height={50} width={50} />
                </a>
                <a
                  href="https://www.instagram.com/plagit.ae/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={f2}
                    alt="the"
                    className="mx-4"
                    height={50}
                    width={50}
                  />
                </a>
                {/* <a
              href="https://www.linkedin.com/company/mhpremierstaffingsolutions/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={f3} alt="the" height={50} width={50} />
            </a> */}
                {/* <a
              href="https://www.tiktok.com/@mhpremierstaffing"
              target="_blank"
              rel="noreferrer"
            >
              <img src={f4} alt="the" className="ms-4" height={50} width={50} />
            </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5
                style={{ color: "#252525", fontWeight: 700, fontSize: "30px" }}
                className="text-center text-uppercase"
              >
                {t("training_school")}
                <button
                  className="text-uppercase px-4 ms-3"
                  style={{
                    fontWeight: 700,
                    backgroundColor: "#58c8c8",
                    color: "white",
                    border: "1px solid #58c8c8",
                    borderRadius: "10px",
                  }}
                >
                  {t("courses")}
                </button>
              </h5>
            </div>
          </div>
        </div>

        <Courses />
      </div>

      <div>
        <div className="">
          <Calendar />
        </div>
      </div>
      <div
        className=""
        style={{
          position: "relative",
          width: "100 %",
          height: "auto",
        }}
      >
        <div className="">
          <div
            style={{
              backgroundColor: "#E5F0F0",
            }}
            className=" img-fluid "
          >
            <h5
              style={{ color: "#252525", fontWeight: 700, fontSize: "30px" }}
              className="text-center pt-5"
            >
              {t("introducing")}

              <span
                style={{ color: " #58c8c8" }}
                className="  rounded text-center "
              >
                {" "}
                {t("our_course")}
              </span>
            </h5>
            <div
              className="d-lg-flex container"
              style={{
                marginTop: "-60px",
              }}
            >
              <div className="my-5">
                <div className=" m-5 d-flex ">
                  <img
                    src={g1}
                    alt="the"
                    className="mt-1 p-2"
                    height={60}
                    width={70}
                    style={{
                      backgroundColor: "white",
                      borderLeft: "3px solid #58c8c8",
                      borderRadius: "13px",
                    }}
                  />
                  <p className="mt-3 mx-3">{t("course_card1")}</p>
                </div>

                <div className=" m-5 d-flex ">
                  <img
                    src={r3}
                    alt="the"
                    className="mt-1 p-2"
                    height={60}
                    width={70}
                    style={{
                      backgroundColor: "white",
                      borderLeft: "3px solid #58c8c8",
                      borderRadius: "13px",
                    }}
                  />
                  <p className="mt-3 mx-3">{t("course_card2")}</p>
                </div>
                <div className=" m-5 d-flex ">
                  <img
                    src={g3}
                    alt="the"
                    className="mt-1 p-2"
                    height={60}
                    width={70}
                    style={{
                      backgroundColor: "white",
                      borderLeft: "3px solid #58c8c8",
                      borderRadius: "13px",
                    }}
                  />
                  <p className="mt-3 mx-3">{t("course_card3")}</p>
                </div>
              </div>

              <div className="my-5">
                <div className=" m-5 d-flex">
                  <img
                    src={"/assets/frontend/images/indexImages/footerLogo.png"}
                    alt="the"
                    className="mt-1 p-2"
                    height={60}
                    width={70}
                    style={{
                      backgroundColor: "white",
                      borderLeft: "3px solid #58c8c8",
                      borderRadius: "13px",
                    }}
                  />
                  <p className="mt-3 mx-3">
                    {t("course_card4")}
                    <span
                      className="fw-bold"
                      style={{
                        color: "#58c8c8",
                      }}
                    >
                      {" "}
                      "Plagit"
                    </span>
                    family
                  </p>
                </div>
                <div className=" m-5 d-flex">
                  <img
                    src={g5}
                    alt="the"
                    className="mt-1 p-2"
                    height={60}
                    width={70}
                    style={{
                      backgroundColor: "white",
                      borderLeft: "3px solid #58c8c8",
                      borderRadius: "13px",
                    }}
                  />
                  <p className="mt-3 mx-3">{t("course_card5")}</p>
                </div>
                <div className=" m-5 d-flex">
                  <img
                    src={g6}
                    alt="the"
                    className="mt-1 p-2"
                    height={60}
                    width={70}
                    style={{
                      backgroundColor: "white",
                      borderLeft: "3px solid #58c8c8",
                      borderRadius: "13px",
                    }}
                  />
                  <p className="mt-3 mx-3">{t("course_card6")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          src={pi2}
          alt="Left Bottom"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxHeight: "50%",
          }}
        />
        <img
          src={pi1}
          alt="Right Top"
          className="right-top"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            maxHeight: "50%",
          }}
        />
      </div>

      {/* // */}

      <div
        className="container"
        style={{
          position: "relative",
          width: "100 %",
          height: "auto",
        }}
      >
        <div
          className="d-lg-flex justify-content-between"
          style={{ marginBottom: "80px" }}
        >
          <div style={{ marginTop: "10%" }}>
            <h5
              style={{ color: "#252525", fontWeight: 700, fontSize: "30px" }}
              className="text-uppercase"
            >
              {t("our_mission")}
            </h5>
            <p
              style={{
                lineHeight: "36px",
                textAlign: "justify",
              }}
            >
              {t("our_mission_details")}
            </p>
          </div>

          <img
            src={"/assets/mh_academy.png"}
            alt="the"
            className=" mt-5 img-fluid "
            style={{ width: "561px", height: "396px" }}
          />
        </div>
        <img
          src={sb}
          alt="Left Bottom"
          style={{
            position: "absolute",
            bottom: 80,
            left: -170,
            maxHeight: "35%",
          }}
        />
        <img
          src={st}
          alt="Right Top"
          className="right-top"
          style={{
            position: "absolute",
            top: 20,
            right: -80,
            maxHeight: "50%",
          }}
        />
      </div>

      <div style={{ background: "#FFF" }}>
        {" "}
        <div className="container pt-5">
          <h5
            style={{
              color: "#252525",
              fontWeight: 700,
              fontSize: "30px",
              paddingTop: "40px",
              marginTop: "25px",
            }}
            className="text-center text-uppercase"
          >
            {t("programs_offered")}
          </h5>
          <section className="mt-5">
            <div className="row">
              {programms &&
                programms.length > 0 &&
                programms.map((item, i) => (
                  <div className="col-md-6 mb-5" key={i}>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          width:
                            item.title == "Bartender Essentials"
                              ? "106px"
                              : "130px",
                          height: "95px",
                          padding: "30px",
                          background: "#58c8c8",
                          borderRadius: "100%",
                        }}
                        className="me-4"
                      >
                        <img
                          src={item.image}
                          alt=""
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                      <div>
                        <h6
                          style={{
                            fontSizze: "20px",
                            fontWeight: "600",
                            lineHeight: "30.12px",
                            color: "#111111",
                          }}
                        >
                          {item.title}
                        </h6>
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#7B7B7B",
                            fontWeight: "400",
                            lineHeight: "25px",
                          }}
                        >
                          {item.subTitle}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        </div>
        <div className="container pb-5">
          <h5
            style={{
              color: "#252525",
              fontWeight: 700,
              fontSize: "30px",
              paddingTop: "40px",
              marginTop: "25px",
            }}
            className="text-center text-uppercase"
          >
            {`${t("service_offered")}`}
          </h5>
          <section className="mt-5">
            <div className="row">
              {service &&
                service.length > 0 &&
                service.map((item, i) => (
                  <div className="col-md-6 mb-5" key={i}>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          width: "180px",
                          height: "100px",
                          padding: "30px",
                          background: "#58c8c8",
                          borderRadius: "100%",
                        }}
                        className="me-4"
                      >
                        <img
                          src={item.image}
                          alt=""
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                      <div>
                        <h6
                          style={{
                            fontSizze: "20px",
                            fontWeight: "600",
                            lineHeight: "30.12px",
                            color: "#111111",
                          }}
                        >
                          {item.title}
                        </h6>
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#7B7B7B",
                            fontWeight: "400",
                            lineHeight: "25px",
                          }}
                        >
                          {item.subTitle}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        </div>
      </div>

      {/* <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="my-5 img-fluid"
      >
        <h5
          style={{
            color: "#252525",
            fontWeight: 700,
            fontSize: "30px",
            paddingTop: "40px",
          }}
          className="text-center "
        >
          Why Choose{" "}
          <button
            style={{
              backgroundColor: "#58c8c8",
              color: "white",
              border: "1px solid #58c8c8",
              borderRadius: "15px",
              fontWeight: 600,
            }}
            className="px-4 py-1 mt-1  text-center "
          >
            Plagit Academy
          </button>
        </h5>

        <div className="container py-5">
          <div className="row g-2">
            <div className="col-lg-3 col-md-6">
              <div>
                <img src={c1} alt="the" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div>
                <img src={c2} alt="the" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div>
                <img src={c3} alt="the" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div>
                <img src={c4} alt="the" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container">
        <h5
          style={{
            color: "#252525",
            fontWeight: 700,
            fontSize: "30px",
            paddingTop: "40px",
            marginTop: "25px",
          }}
          className="text-center text-uppercase"
        >
          {`${t("contact_us")}`}
        </h5>

        <div className="d-flex justify-content-center">
          <div
            className="border"
            style={{ borderRadius: "25px", marginLeft: "0%" }}
          >
            <h5
              style={{ color: "#252525", fontWeight: 500, fontSize: "20px" }}
              className="text-center px-4"
            >
              {" "}
              <button
                style={{
                  backgroundColor: " #58c8c8",
                  border: "1px solid #58c8c8",
                  borderRadius: "20px",
                }}
                className=" py-1 mt-1 px-4 text-center text-white"
              >
                {`${t("mh_academy")}`}
              </button>{" "}
              {`${t("is_happy_to_assist_you")}`}
            </h5>
          </div>
          <p></p>
        </div>
        <div className="mt-4">
          {/* <div className="me-5 mt-4">
                        <div className="mt-4 d-flex">
                            <BsTelephoneFill style={{ marginTop: "4px" }} />
                            <p
                                className="ms-3"
                                style={{ color: "#252525", fontWeight: 500, fontSize: "15px" }}
                            >
                                {t("privacy_notice_des_8_4")}
                            </p>
                        </div>
                        <div className="mt-4 d-flex">
                            <AiOutlineMail style={{ marginTop: "4px" }} />
                            <p
                                className="ms-3"
                                style={{ color: "#252525", fontWeight: 500, fontSize: "15px" }}
                            >
                                info@plagit.com
                            </p>
                        </div>
                        <div className="mt-4 d-flex">
                            <BiCurrentLocation style={{ marginTop: "4px" }} />
                            <p
                                className="ms-3"
                                style={{ color: "#252525", fontWeight: 500, fontSize: "15px" }}
                            >
                                202 Souk Al Bahar Saaha C, Downtown Dubai
                            </p>
                        </div>
                    </div> */}
          <div className="">
            {/* <img src={map} alt="the" className="img-fluid" /> */}
            {/* <iframe
              style={{
                border: "0",
                borderRadius: "20px",
                width: "650px",
                height: "300px",
              }}
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=5 Holborn Circus, London, London, EC1N 2HB&t=&z=14&ie=UTF8&iwloc=&output=embed"
            ></iframe> */}

            <iframe
              className="img-fluid"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.280882565924!2d55.27389507483741!3d25.193748481841432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f68299c6b1377%3A0x9ac95c9c94112b25!2sSouk%20Al%20Bahar%20-%20Downtown%20Dubai%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sbd!4v1713608771903!5m2!1sen!2sbd"
              style={{
                border: "2px solid #58c8c8",
                borderRadius: "20px",
                width: "100%",
                height: "340px",
              }}
            ></iframe>
          </div>
        </div>
      </div>

      <div
        className="container my-5  pb-3 d-lg-flex justify-content-around"
        style={{ backgroundColor: "black", borderRadius: "20px" }}
      >
        <div className="py-5">
          <h3
            className="text-white text-center "
            style={{
              marginTop: "35px",
            }}
          >
            {`${t("stay_with_us")}`}
          </h3>
        </div>

        <div
          className=" "
          style={{
            borderRadius: "15px",
            backgroundColor: "white",
            margin: "5%",
          }}
        >
          <input
            className="px-5 py-3 custom-input"
            style={{
              borderRadius: "15px",
              backgroundColor: "white",
              border: "1px solid white",
            }}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("enter_email_address")}
          />
          <button
            href="mailto:info@plagit.com?subject=Hello%20MH%20Team"
            style={{
              backgroundColor: "#58c8c8",
              color: "white",
              border: "1px solid #58c8c8",
              marginLeft: "-15px",
              borderRadius: "15px",
              textDecoration: "none",
              cursor: validateEmail(email) ? "pointer" : "not-allowed",
            }}
            disabled={!validateEmail(email)}
            onClick={handleEmailClick}
            className="px-4 mx-3 py-1 mt-3 text-center mb-3"
          >
            {t("continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MHAcademy;


const EventCard = ({
  title,
  location,
  date,
  time,
  entryFee,
  imageUrl,
  detailsUrl,
}) => {
  return (
    <div className="event-card">
      <img
        src={imageUrl}
        alt="Event"
        className="event-image"
      />

      <div className="event-content">
        <h2 className="event_title">{title}</h2>

        <div className="d-flex align-items-center mb-2">
          <i className="fas fa-map-marker-alt event-icon"></i>
          <span className="event-location">{location.address}</span>
        </div>

        <div className="d-flex align-items-center mb-2">
          <div>
            <i className="fas fa-calendar-alt event-icon"></i>
            <span className="event-date-time">{date}</span>
          </div>
          <div>
            <i className="fas fa-clock event-icon"></i>
            <span className="event-date-time">From {time.start}</span>
          </div>
        </div>

        <div className='d-flex align-items-center justify-content-between'>
          <h3 className="event-free-entry">{entryFee}</h3>
          <button className="event-button">Details</button>
        </div>
      </div>
    </div>
  );
};

export default EventCard;

import { Card, Skeleton } from "antd";
import React from "react";

function SkeletonPostCard() {
  return (
    <Card className="post_feed_card bg-light">
      <div className="d-flex">
        <Skeleton.Avatar loading={true} active size={50} circle />
        <Skeleton
          loading={true}
          active
          paragraph={{
            rows: 1,
            width: ["100%"],
          }}
        />
      </div>

      <div className="skeleton_content_body">
        <Skeleton
          loading={true}
          active
          paragraph={{
            rows: 3,
            width: ["100%"],
          }}
        />
      </div>
      <div className="w-100 mt-3">
        <Skeleton.Image className="w-100" style={{ height: 220 }} />
      </div>

      <div className="d-flex justify-content-between mt-4">
        <Skeleton.Button
          style={{ width: "100px" }}
          active
          size="small"
          shape="round"
        />
        <Skeleton.Button
          style={{ width: "70px" }}
          active
          size="small"
          shape="round"
        />
        <Skeleton.Button
          style={{ width: "100px" }}
          active
          size="small"
          shape="round"
        />
      </div>
    </Card>
  );
}

export default SkeletonPostCard;

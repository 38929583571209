import { PiLinkSimpleBold } from "react-icons/pi";
import { Colors } from "../../constants/Colors";
import { getCurrencySymbol } from "../../utils/country";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EmployeeDetailsLeftCard = ({ employee }) => {
  const { bio, languages, certificates, skills } = employee;
  const { t } = useTranslation();

  return (
    <div
      className="p-4 bg-white mb-lg-2 mb-5 "
      style={{
        borderRadius: "18px",
      }}
    >
      <p
        className="text-center fw-semibold mb-4 pt-2 mx-auto"
        style={{
          maxWidth: "600px",
          fontSize: "1.1rem",
          lineHeight: "1.5",
          color: "#333",
        }}
      >
        {bio}
      </p>

      <div className="col-xl-12 col-md-12">
        <div className="sctProfileAge">
          <img
            src={"/assets/employee/calander.png"}
            style={{ height: "18px" }}
            alt="calender"
          />
          &nbsp;{t("date_of_birth")} : &nbsp;
          <span className="slctprofilePerHourPrice">
            {employee?.dateOfBirth
              ? moment(employee?.dateOfBirth).format("YYYY-MM-DD").valueOf()
              : null}
          </span>
        </div>
        <span className="slctProfileRateName">
          <img
            className="img-fluid SlctProrateIcon"
            src="/assets/frontend/images/selectedEmployee/rate.png"
            alt="alt-rate"
          />
          &nbsp; {t("rate")} : &nbsp;
        </span>
        <span className="slctprofilePerHourPrice">
          {employee?.contractorHourlyRate?.toFixed(2)}{" "}
          {getCurrencySymbol(employee?.countryName)} {t("per_hour")}
        </span>
      </div>

      <div className="col-xl-12 col-md-12">
        <span className="slctProfileExpName">
          <img
            src="/assets/frontend/images/selectedEmployee/experience.png"
            alt="alt-exp"
          />
          &nbsp; {t("exp")} &nbsp;
          {" : "}
        </span>
        <span className="slctProfileExpValue">
          {employee?.employeeExperience &&
            employee?.employeeExperience + " Years"}
        </span>
      </div>

      {employee?.higherEducation && (
        <div className="my-1 row">
          <div className="d-flex align-items-center">
            <img
              src="/assets/frontend/images/selectedEmployee/education (2).png"
              alt="custom-image1"
            />
            <p
              style={{
                textTransform: "capitalize",
                margin: 0,
                padding: 0,
              }}
              className="text-black"
            >
              &nbsp;{employee?.higherEducation}
            </p>
          </div>
        </div>
      )}

      {employee?.cv && (
        <div>
          <PiLinkSimpleBold
            style={{
              color: Colors.primary,
            }}
          />
          &nbsp;
          <a
            href={`${process.env.REACT_APP_ASSETs_BASE_URL}/${employee.cv}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              textTransform: "lowercase",
              fontSize: "18px",
            }}
            className="link-content"
          >
            CV
          </a>
        </div>
      )}

      {skills?.length > 0 ? (
        <div className="mt-3 row">
          <h5 className=" employee-header-profile">{t("skills")}</h5>
          <div>
            {skills?.map((item, index) => {
              return <div key={index}></div>;
            })}
            <div className="col">
              {skills?.slice(0, 5).map((item, index) => (
                <div key={index}>
                  <span>
                    <img
                      src={"/assets/employee/requirements.png"}
                      style={{ height: "25px", width: "25px" }}
                      alt="custom-language"
                    />
                    &nbsp;{item.skillName}
                  </span>
                </div>
              ))}
            </div>

            <div className="col">
              {skills?.slice(5).map((item, index) => (
                <div key={index}>
                  <span>
                    <img
                      src={"/assets/employee/requirements.png"}
                      style={{ height: "25px", width: "25px" }}
                      alt="custom-language"
                    />
                    &nbsp;{item.skillName}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}

      {languages?.length > 0 ? (
        <div className="mt-2">
          <h5 className=" employee-header-profile">{t("languages")}</h5>
          <div>
            {languages?.map((item, index) => {
              return (
                <div key={index} className="list-unstyled">
                  <img
                    src="/assets/frontend/images/selectedEmployee/googleTranslate.png"
                    alt="custom-image1"
                  />
                  &nbsp;{item}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {certificates?.length > 0 ? (
        <div className="mt-3">
          {/* <h5>{t("documents")}</h5> */}
          <div>
            {certificates.map((item, index) => {
              return (
                <div>
                  <PiLinkSimpleBold
                    style={{
                      color: Colors.primary,
                    }}
                  />
                  &nbsp;
                  <a
                    key={index}
                    href={`${process.env.REACT_APP_ASSETs_BASE_URL}/${item.attachment}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      textTransform: "lowercase",
                      fontSize: "14px",
                    }}
                    className="link-content"
                  >
                    {item.certificateName}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EmployeeDetailsLeftCard;

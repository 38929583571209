import { Skeleton } from 'antd'
import React from 'react'

export default function EmployeeSkeletonCard() {
    return (
        <div className="w-100 mt-3 mb-3">
            <Skeleton.Image className="w-100" style={{ height: 250 }} />
            <Skeleton
                className='mt-2'
                loading={true}
                active
                paragraph={false}
                title={{ width: "100%" }}
            />
            <Skeleton
                className='mt-2'
                loading={true}
                active
                paragraph={false}
                title={{ width: "100%" }}
            />
            <Skeleton
                className='mt-2'
                loading={true}
                active
                paragraph={false}
                title={{ width: "100%" }}
            />
            <Skeleton
                className='mt-2'
                loading={true}
                active
                paragraph={false}
                title={{ width: "100%" }}
            /> 
        </div>
    )
} 

import { Button, Modal, Row, TimePicker, message } from "antd";
import { addMonths, isSameDay } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FcCalendar } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import Calendar from "../../../pages/Candidate/Calender/Newcalendar";
import "./daycss.css";
import nodate from "./nodate.png";
export default function BookForJob({
  id,
  setSelectedDateTime,
  setCalenderModal,
}) {
  const [selectedDays, setSelectedDays] = useState([]);
  /* const [ranges, setRanges] = useState([
    {
      from: "2023-12-21T18:00:00.000Z",
      to: "2023-12-22T18:00:00.000Z",
    },
    {
      from: "2023-12-23T18:00:00.000Z",
      to: "2023-12-23T18:00:00.000Z",
    },
  ]); */
  const [ranges, setRanges] = useState([]);
  const [rangeStart, setRangeStart] = useState(null);
  /* const [timeRanges, setTimeRanges] = useState({
    "2023-12-21T18:00:00.000Z": {
      startTime: "2023-12-20T01:38:36.200Z",
      endTime: "2023-12-20T01:38:37.680Z",
    },
    "2023-12-22T18:00:00.000Z": {},
    "2023-12-23T18:00:00.000Z": {
      startTime: "2023-12-20T01:38:39.768Z",
      endTime: "2023-12-20T01:38:41.216Z",
    },
  }); */
  const [timeRanges, setTimeRanges] = useState({});
  const [isTimeSelected, setIsTimeSelected] = useState(true);
  const [timerShow, setTimerShow] = useState(true);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  // Initialize state variables
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [dateAndTimeRanges, setDateAndTimeRanges] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    console.log("timeRanges:", timeRanges);
    if (typeof timeRanges === "object" && !Array.isArray(timeRanges)) {
      let showButton = true;

      // Check if any object has a missing endTime
      const hasMissingEndTime = Object.keys(timeRanges).some((key) => {
        const item = timeRanges[key];
        return item && item.startTime && !item.endTime;
      });

      if (hasMissingEndTime) {
        showButton = false;
      }

      setShowSubmitButton(showButton);
    } else {
      console.log("timeRanges is not an object or is not defined.");
      setShowSubmitButton(false);
    }
  }, [timeRanges]);

  useEffect(() => {
    let isDateToFound = false;

    ranges.forEach((range) => {
      if (range.date && range.date.to) {
        isDateToFound = true;
      }
    });

    selectedDays.forEach((day) => {
      if (day.date && day.date.to) {
        isDateToFound = true;
      }
    });

    setShowSubmitButton(isDateToFound);
  }, [ranges, selectedDays]);

  const handleTimeChange = (time, type, date) => {
    if (date instanceof Date) {
      const dateKey = date.toISOString();
      setTimeRanges({
        ...timeRanges,
        [dateKey]: { ...timeRanges[dateKey], [type]: time },
      });
      setIsTimeSelected(true);
    } else {
      console.warn("Invalid date object passed to handleTimeChange");
    }
  };

  const handleSubmit = async () => {
    const requestData = {
      requestDate: [
        ...ranges.map((range) => ({
          startDate: moment(range.from).format("YYYY-MM-DD"),
          endDate: moment(range.to).format("YYYY-MM-DD"),
          startTime: timeRanges[range.from.toISOString()]?.startTime
            ? timeRanges[range.from.toISOString()].startTime.format("hh:mm A")
            : null, // Use null for missing time
          endTime: timeRanges[range.from.toISOString()]?.endTime
            ? timeRanges[range.from.toISOString()].endTime.format("hh:mm A")
            : null, // Use null for missing time
        })),
        ...selectedDays.map((day) => ({
          startDate: moment(day).format("YYYY-MM-DD"),
          endDate: moment(day).format("YYYY-MM-DD"),
          startTime: timeRanges[day.toISOString()]?.startTime
            ? timeRanges[day.toISOString()].startTime.format("hh:mm A")
            : null, // Use null for missing time
          endTime: timeRanges[day.toISOString()]?.endTime
            ? timeRanges[day.toISOString()].endTime.format("hh:mm A")
            : null, // Use null for missing time
        })),
      ],
    };
    const isValid = requestData.requestDate.every(
      (item) =>
        item.startDate &&
        item.endDate &&
        item.startTime !== null &&
        item.endTime !== null
    );

    if (!isValid) {
      Modal.warning({
        title: "Incomplete Data",
        content:
          "Some data is missing, please complete all fields before submitting.",
        style: {
          marginTop: "20vh",
        },
      });
      return;
    } else {
      setSelectedDateTime(requestData.requestDate);
      setCalenderModal(false);
    }
  };

  const handleDayClick = (day, { selected }) => {
    if (selectedStartDate && !selectedEndDate) {
      // Set the end date if start date is selected
      setSelectedEndDate(day);
    } else {
      // Set the start date if no start date is selected
      setSelectedStartDate(day);
      setSelectedEndDate(null);
    }

    // Reset the time picker for the selected date
    const selectedDateKey = day.toISOString();
    setTimeRanges({ ...timeRanges, [selectedDateKey]: {} });
    // setShowSubmitButton(false);

    // Set the selected date and its time ranges in the dictionary
    setDateAndTimeRanges({
      ...dateAndTimeRanges,
      [selectedDateKey]: {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        timeRanges: {},
      },
    });

    if (isDaySelected(day) && !rangeStart) {
      const rangeToRemove = ranges.find((range) =>
        isDateWithinRange(day, range)
      );
      if (rangeToRemove) {
        deselectDateRange(rangeToRemove);
      }
      deselectDay(day);
      return; // Return early, since the day is now deselected
    }

    if (rangeStart) {
      const sortedDates = [rangeStart, day].sort(
        (a, b) => a.getTime() - b.getTime()
      );
      const newRange = { from: sortedDates[0], to: sortedDates[1] };
      // Check if the new range overlaps with any existing range
      const isRangeOverlapping = ranges.some((range) => {
        return newRange.from <= range.to && newRange.to >= range.from;
      });

      if (isRangeOverlapping) {
        // console.log("Range partially overlaps with an existing selection.");
        message.error(
          "This range partially overlaps with an existing selection."
        );
        return;
      }
      let dateCursor = new Date(newRange.from);
      let isAnyDateUnavailable = false;

      while (dateCursor <= newRange.to) {
        dateCursor.setDate(dateCursor.getDate() + 1);
      }

      if (isAnyDateUnavailable) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      // Check if the range includes disabled dates
      if (isRangeIncludingDisabledDates(newRange)) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      // Assuming `isRangeIncludingDisabledDates` is a function that returns true or false
      if (!isRangeIncludingDisabledDates(newRange)) {
        setRanges((prev) => [...prev, newRange]);

        // Remove individual days that are now part of a range
        setSelectedDays((prev) =>
          prev.filter((d) => !isDateWithinRange(d, newRange))
        );
      }
      setRangeStart(null);
    } else {
      // Existing logic for handling individual days

      // Check if the clicked day is within a disabled date range
      if (isRangeIncludingDisabledDates({ from: day, to: day })) {
        message.error("One or more dates in the range are unavailable.");
        return;
      }

      // If the clicked day is already selected, remove it from selectedDays
      if (selected) {
        setSelectedDays((prev) => prev.filter((d) => !isSameDay(d, day)));
      } else {
        setSelectedDays((prev) => [...prev, day]);
      }
      setRangeStart(day);
    }
    const selectedDateElement = document.getElementById(day.toString());
    if (selectedDateElement) {
      selectedDateElement.classList.add("selected-date");
    }
  };

  const deselectDateRange = (dateRange) => {
    console.log("dateRange", dateRange);
    if (id) {
      setRanges((prev) =>
        prev.filter(
          (range) =>
            !(
              isSameDay(
                moment(range.from).toDate(),
                moment(dateRange.from).toDate()
              ) &&
              isSameDay(
                moment(range.to).toDate(),
                moment(dateRange.to).toDate()
              )
            )
        )
      );
    } else {
      setRanges((prev) =>
        prev.filter(
          (range) =>
            !(
              isSameDay(range.from, dateRange.from) &&
              isSameDay(range.to, dateRange.to)
            )
        )
      );
    }
  };

  const deselectDay = (dayToRemove) => {
    setSelectedDays((prevSelectedDays) => {
      return prevSelectedDays.filter(
        (day) => day.getTime() !== dayToRemove.getTime()
      );
    });
    if (selectedDays?.length) {
      setSelectedStartDate([]); // it contains first date only
      setRangeStart(null);
    }
  };

  const isRangeIncludingDisabledDates = ({ from, to }) => {
    return false;
  };

  const isDateWithinRange = (date, { from, to }) => {
    // Get the start and end of the day for the 'from' and 'to' dates
    const startOfDayFrom = new Date(from);
    const endOfDayTo = new Date(to);
    // Set the time for the start of the day (00:00:00.000)
    startOfDayFrom.setHours(0, 0, 0, 0);

    // Set the time for the end of the day (23:59:59.999)
    endOfDayTo.setHours(23, 59, 59, 999);

    // Check if the 'date' falls within the adjusted range
    const withinRange =
      date.getTime() >= startOfDayFrom.getTime() &&
      date.getTime() <= endOfDayTo.getTime();

    return withinRange;
  };

  // Get the current month
  const currentMonth = new Date();
  currentMonth.setDate(1);
  currentMonth.setHours(0, 0, 0, 0);

  // Get the next month
  const nextMonth = addMonths(currentMonth, 1);

  const isDaySelected = (day) => {
    for (let range of ranges) {
      if (isDateWithinRange(day, range)) {
        return true;
      }
    }
    return selectedDays.some((selectedDay) => isSameDay(day, selectedDay));
  };
  const today = moment().startOf("day").toDate();

  const goBack = () => {
    navigate(-1);
  };

  const deleteAllDates = () => {
    Modal.confirm({
      title: "Are you sure you want to delete all selected dates?",
      content: "This action cannot be undone.",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        // Reset the selectedDays and ranges back to empty arrays
        setSelectedDays([]);
        setRanges([]);
        setTimeRanges({});
        setSelectedStartDate([]); // it contains first date only
        setRangeStart(null);
      },
    });
  };

  return (
    <>
      <Row
        justify="space-around"
        className="container mx-auto"
        style={{ margin: "1rem" }}
      >
        {/* First Part: Calendar */}

        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <Calendar
                bookedDates={[]}
                pendingDates={[]}
                unavailableDates={[]}
                selectedDays={selectedDays}
                ranges={ranges}
                timeRanges={timeRanges}
                handleDayClick={handleDayClick}
                deselectDateRange={deselectDateRange}
                deselectDay={deselectDay}
                handleTimeChange={handleTimeChange}
                handleCreateShortList={false}
                handleBookUser={false}
                estimatedAmount={0}
                deleteAllDates={deleteAllDates}
                isTimeSelected={isTimeSelected}
                usersData={{}}
                goBack={goBack}
                isSameDay={isSameDay}
                isDateWithinRange={isDateWithinRange}
                today={today}
                nextMonth={nextMonth}
                state={null}
                jobEdit={true}
              />
            </div>
          </div>
        </div>

        {/* Second Part: Selected Dates and Ranges */}
        <div className="selected-date-style">
          {/* Your JSX to display selected dates and ranges */}
          <div className="text-center">
            {ranges.length || selectedDays?.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <h5>Days Selected</h5>
                <button
                  onClick={
                    selectedDays.length === 0 && ranges.length === 0
                      ? null
                      : deleteAllDates
                  }
                  style={{
                    backgroundColor:
                      selectedDays.length === 0 && ranges.length === 0
                        ? "#ccc"
                        : "red",
                    color: "#fff",
                    cursor:
                      selectedDays.length === 0 && ranges.length === 0
                        ? "not-allowed"
                        : "pointer",
                    outline: "none",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                  }}
                >
                  Clear All
                </button>
              </div>
            ) : null}
            <div>
              {ranges.length === 0 && selectedDays.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "445px",
                  }}
                >
                  <img src={nodate} alt="No Date" />
                </div>
              ) : (
                <>
                  {ranges.map((range, idx, dates) => (
                    <DateTimeComponent
                      date={range}
                      idx={idx}
                      type="range"
                      deselect={deselectDateRange}
                      timeRanges={timeRanges}
                      setTimeRanges={setTimeRanges}
                      handleTimeChange={handleTimeChange}
                      setTimerShow={setTimerShow}
                      timerShow={timerShow}
                      dates={dates}
                    />
                  ))}

                  {selectedDays.map((day, idx) => (
                    <DateTimeComponent
                      key={`day-${idx}`}
                      date={day}
                      idx={idx}
                      type="day"
                      deselect={deselectDay}
                      timeRanges={timeRanges}
                      setTimeRanges={setTimeRanges}
                      handleTimeChange={handleTimeChange}
                      setTimerShow={setTimerShow}
                      timerShow={timerShow}
                    />
                  ))}

                  {showSubmitButton && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          id
                            ? Modal.confirm({
                                title: "Important Notice",
                                content: "Your previous dates will be deleted",
                                onOk: () => {
                                  handleSubmit();
                                },
                                onCancel: () => {
                                  return;
                                },
                                style: {
                                  marginTop: "20vh",
                                },
                              })
                            : handleSubmit();
                        }
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#58c8c8",
                          color: "#fff",
                          cursor: "pointer",
                          outline: "none",
                          border: "none",
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

// TimePicker Component
const TimePickers = ({ timeRanges, timeKey, handleTimeChange, index }) => {
  console.log("timeRanges", timeKey);
  console.log("timeRanges", timeRanges[timeKey]?.startTime);
  return (
    <div style={{}}>
      <TimePicker
        value={timeRanges[timeKey]?.startTime}
        placeholder="Start Time"
        onChange={(time) =>
          handleTimeChange(time, "startTime", new Date(timeKey), index)
        }
        format="HH:mm"
      />
      -
      <TimePicker
        value={timeRanges[timeKey]?.endTime}
        placeholder="End Time"
        onChange={(time) =>
          handleTimeChange(time, "endTime", new Date(timeKey), index)
        }
        format="HH:mm"
      />
      {/* <TimePicker.RangePicker format="HH:mm" /> */}
    </div>
  );
};

// Function to format individual dates
const formatDate = (date) => {
  return date.toLocaleDateString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

// Function to format date ranges
const formatDateRange = (from, to) => {
  if (from && !to) {
    return `${formatDate(new Date(from))} - Select endDate`;
  }

  const fromDate = formatDate(new Date(from));
  const toDate = formatDate(new Date(to));

  return fromDate === toDate
    ? `${fromDate} - ${fromDate} `
    : `${fromDate} - ${toDate}`;
};

const DateTimeComponent = ({
  date,
  idx,
  type,
  deselect,
  timeRanges,
  setTimeRanges,
  handleTimeChange,
  setTimerShow,
  timerShow,
  dates = false,
}) => {
  console.log("index in date time component", idx);
  let dateKey;
  if (type === "range") {
    if (date.from instanceof Date) {
      dateKey = date.from.toISOString();
    }
  } else {
    if (date instanceof Date) {
      dateKey = date.toISOString();
    }
  }
  const countDates = (startDate, endDate) => {
    if (!startDate || !endDate) {
      setTimerShow(false);
      return "Select End Date";
    }

    setTimerShow(true);

    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");

    if (start.isSame(end, "day")) {
      return `1 Day`;
    }

    const dayDifference = end.diff(start, "days") + 1; // Add 1 to include both start and end dates

    return `${dayDifference}
    ${dayDifference > 1 ? "Days" : "Day"}
    `;
  };

  // Make sure formatDateRange is defined or imported
  const displayDate =
    type === "range"
      ? formatDateRange(date.from, date.to)
      : formatDate(new Date(date));

  return (
    <div
      key={`${type}-${idx}`}
      style={{
        backgroundColor: "#FAFAFA",
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "10px",
        width: "350px",
      }}
    >
      <p>
        <FcCalendar
          style={{
            marginRight: "5px",
          }}
        />
        {displayDate}
        <span
          className="text-red-500 ml-2 cursor-pointer"
          onClick={() => deselect(date)}
        >
          <AiFillDelete
            style={{
              color: "red",
              marginLeft: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          />
        </span>
      </p>

      <div
        style={{
          color: "#58c8c8",
          fontWeight: "bold",
        }}
      >
        <p
          style={{
            color:
              countDates(date.from, date.to) === "Select End Date"
                ? "red"
                : "#58c8c8",
          }}
        >
          {countDates(date.from, date.to)}
        </p>
      </div>

      {/* Pass the necessary props to the TimePickers component */}
      {date.to && (
        <TimePickers
          timeKey={dateKey} // Use the dateKey here
          timeRanges={timeRanges}
          setTimeRanges={setTimeRanges}
          index={idx}
          handleTimeChange={handleTimeChange} // Pass down the handleTimeChange function
        />
      )}
    </div>
  );
};

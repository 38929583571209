export const getProfilePicture = (url, role) => {
  // Check if url is a valid string and not "undefined" or an empty string
  const isUrlValid = url && url !== "undefined" && url !== undefined;

  // Determine the profile picture URL based on conditions
  const profilePicture = isUrlValid
    ? url.includes("lh3.googleusercontent.com")
      ? url
      : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${url}`
    : role === "CLIENT"
    ? "/assets/Client.jpg"
    : "/assets/Employee.jpg";

  return profilePicture;
};

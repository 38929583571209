import React, { useState, useEffect } from "react";
import { Modal, Avatar, Button, Typography, Row, Col, Space } from "antd";
import { UserOutlined, CheckCircleOutlined } from "@ant-design/icons";
import axiosInstance from "../../../../utils/API";
import { useQuery } from "react-query";
import { getTodayDate } from "../../../../utils/date";
import { getProfilePicture } from "../../../../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
const { Title, Text } = Typography;

const fetchCandidates = async ({ queryKey }) => {
  const [, { startDate, endDate }] = queryKey;
  const { data } = await axiosInstance.get(
    `/check-in-check-out-histories/today-employee`,
    {
      params: { startDate, endDate },
    }
  );
  return data;
};

const CheckoutModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const todayDate = getTodayDate();
  const navigate = useNavigate();

  useEffect(() => {
    const hasModalOpened = localStorage.getItem("hasModalOpened");
    if (hasModalOpened === "true") {
      const timer = setTimeout(() => {
        setIsModalVisible(true);
        localStorage.setItem("hasModalOpened", "false");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  const {
    data: candidatesData,
    isLoading,
    isError,
  } = useQuery(
    [
      "candidates-checkin-check_out",
      // { startDate: "2024-11-01", endDate: "2024-11-08" },
      { startDate: todayDate, endDate: todayDate },
    ],
    fetchCandidates
  );

  // console.log("data", candidatesData);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const candidates = candidatesData?.result || [];
  const visibleCandidates = candidates.slice(0, 4);
  const extraCount = candidates.length - visibleCandidates.length;

  return (
    candidates.length > 0 && (
      <Modal
        title={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={400}
        bodyStyle={{ textAlign: "center", padding: "24px" }}
      >
        <div className="d-flex flex-row align-items-center justify-content-center">
          <Avatar
            size={64}
            icon={<CheckCircleOutlined />}
            style={{ backgroundColor: "#E6FAF5", color: "#5BC6A8" }}
            className="d-flex flex-row align-items-center justify-content-center"
          />
        </div>
        <Title level={4}>
          {candidates.length === 0
            ? "No candidates have checked out"
            : `${candidates.length} Candidate${
                candidates.length > 1 ? "s" : ""
              } have checked out`}
        </Title>
        {candidates.length > 0 && (
          <Row justify="center" gutter={8} style={{ margin: "16px 0" }}>
            {visibleCandidates.map((candidate, index) => (
              <Col key={index}>
                <Avatar
                  size="large"
                  src={getProfilePicture(candidate.profilePicture, "EMPLOYEE")}
                />
              </Col>
            ))}
            {extraCount > 0 && (
              <Col>
                <Avatar
                  size="large"
                  style={{ backgroundColor: "#5BC6A8", color: "#fff" }}
                >
                  +{extraCount}
                </Avatar>
              </Col>
            )}
          </Row>
        )}
        {candidates.length > 0 && (
          <Text type="danger" style={{ display: "block", marginBottom: 24 }}>
            Please check the details properly. After 12 hours these candidates
            checkouts will be automatically confirmed.
          </Text>
        )}

        <Space size="middle">
          {/* View Details Button */}
          <Button
            style={{
              color: "#009688",
              borderColor: "#009688",
              borderWidth: 2,
              borderRadius: "0px",
              borderTopLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              padding: "4px 16px",
              fontWeight: "500",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              navigate("/dashboard-history");
            }}
          >
            View Details
          </Button>

          {/* Confirm Button */}
          <Button
            style={{
              color: "#ffffff",
              border: "none",
              padding: "4px 16px",
              fontWeight: "500",
              background: "linear-gradient(90deg, #009688, #00BCD4)",
              borderRadius: "0px",
              borderTopLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Confirm
          </Button>
        </Space>
      </Modal>
    )
  );
};

export default CheckoutModal;

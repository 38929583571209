import Swal from 'sweetalert2';

const useSweetAlert = () => {
    const showAlert = (title, icon, autoClose = false) => {
        Swal.fire({
            title,
            icon,
            showConfirmButton: !autoClose,  // Hides OK button if autoClose is true
            timer: autoClose ? 3000 : null, // Closes automatically after 3 seconds if autoClose is true
            timerProgressBar: autoClose,    // Show progress bar when auto-closing
        });
    };
    return { showAlert };
};

export default useSweetAlert;

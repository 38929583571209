import React from "react";
import { Skeleton } from "antd";

const UserProfileSkeleton = () => (
  <div className="container p-0">
    <div className="my_profile_card bg-white pt-0 pb-3">
      <header className="profile_card_header">
        <div className="cover_bg_section">
          <Skeleton.Avatar
            active
            shape="square"
            size="large"
            style={{ width: "100%", height: "120px" }}
          />
        </div>
        <div className="container profile_image">
          <Skeleton.Avatar active size={100} shape="circle" />
          <div className="heading_info_box">
            <Skeleton.Input
              active
              size="large"
              style={{ width: 150, marginTop: 16 }}
            />
            <div className="user-profile-position mt-3">
              <Skeleton.Input active size="small" style={{ width: 100 }} />
            </div>
            <div className="user-profile-countryname">
              <Skeleton.Input active size="small" style={{ width: 120 }} />
            </div>
          </div>
        </div>
      </header>
    </div>
    <div className="container mt-4">
      <div className="row">
        <div className="col-lg-5">
          <Skeleton active paragraph={{ rows: 4 }} />
        </div>
        <div className="col-lg-7">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="skeleton-post-card mb-4">
              <Skeleton active avatar paragraph={{ rows: 2 }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default UserProfileSkeleton;

import { Skeleton, Row, Col, Card } from "antd";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { FcCalendar, FcBusinessman } from "react-icons/fc";

const JobDetailsSkeleton = () => (
  <div
    className="container mt-4 mx-auto"
    style={{
      borderRadius: "10px",
      borderColor: "#FFFFFF",
      border: "1px solid #FFFFFF",
      minHeight: "70vh",
      marginBottom: "20px",
      width: "100%",
    }}
  >
    <div className="row mt-4">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "10px",
          height: "50px",
        }}
      >
        <IoChevronBackCircleOutline
          size={25}
          backgroundColor="#A6A6A6"
          color="#58c8c8"
        />
        <Skeleton.Avatar active style={{ marginLeft: "10px" }} size={35} />
        <Skeleton.Input style={{ width: "200px", marginLeft: "10px" }} active />
      </div>
    </div>

    <Row gutter={[16, 16]} justify="center">
      {/* Left Column - Job Details Skeleton */}
      <Col
        style={{ flex: "0 0 40%" }} // Set left column to take 40%
      >
        <Card
          style={{
            backgroundColor: "#FFFFFF",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Skeleton.Avatar
            shape="square"
            size={85}
            active
            style={{ margin: "20px auto" }}
          />
          <Skeleton
            title={false}
            paragraph={{ rows: 1 }}
            active
            style={{ textAlign: "center" }}
          />
          <Skeleton.Input
            style={{ width: "80%", margin: "10px auto" }}
            active
          />
          <Skeleton paragraph={{ rows: 4 }} active />
        </Card>
      </Col>

      {/* Right Column - Client Information and Job Details */}
      <Col
        style={{ flex: "0 0 60%" }} // Set right column to take 60%
      >
        <div
          style={{ borderRadius: "10px", width: "100%" }}
          className="bg-white rounded-4 p-3 mb-3"
        >
          <h5 className="fw-bold d-flex align-items-center">
            <FcBusinessman style={{ marginRight: "5px" }} />
            <Skeleton.Input style={{ width: "150px" }} active />
          </h5>
          <div className="d-flex mt-4 align-items-start">
            <Skeleton.Avatar
              shape="square"
              size={120}
              active
              style={{ borderRadius: "5px" }}
            />
            <div className="ms-3">
              <Skeleton.Input style={{ width: "200px" }} active />
              <Skeleton paragraph={{ rows: 2, width: "100%" }} active />
            </div>
          </div>
        </div>

        {/* Date Section Skeleton */}
        <div className="dates detailsCard">
          <h5 className="fw-bold d-flex align-items-center">
            <FcCalendar style={{ marginRight: "5px" }} />
            <Skeleton.Input style={{ width: "100px" }} active />
          </h5>
          <Skeleton paragraph={{ rows: 2, width: "80%" }} active />
        </div>

        {/* Skills Section Skeleton */}
        <div className="skills detailsCard">
          <h5 className="fw-bold d-flex align-items-center">
            <Skeleton.Avatar
              shape="square"
              size={20}
              active
              style={{ marginRight: "5px" }}
            />
            <Skeleton.Input style={{ width: "150px" }} active />
          </h5>
          <Skeleton paragraph={{ rows: 4, width: "80%" }} active />
        </div>

        {/* Comment Section Skeleton */}
        <div className="skills detailsCard">
          <h5 className="fw-bold d-flex align-items-center">
            <Skeleton.Avatar
              shape="square"
              size={20}
              active
              style={{ marginRight: "5px" }}
            />
            <Skeleton.Input style={{ width: "150px" }} active />
          </h5>
          <Skeleton paragraph={{ rows: 3, width: "90%" }} active />
        </div>
      </Col>
    </Row>
  </div>
);

export default JobDetailsSkeleton;

import React from "react";
import "./card.scss";
import { getUserInfo } from "../../../utils/commonFunctions";
import { Colors } from "../../../constants/Colors";

const MyCard = ({ cardInfo }) => {
  // console.log("cardInfo ========>>> ", cardInfo);
  const userInfo = getUserInfo();
  console.log("userInfo ========>>> ", userInfo);
  return (
    <div className="CardSection">
      <div className="container">
        <div className="card">
          <div className="card-inner">
            <div className="front">
              <img
                src="https://i.ibb.co/PYss3yv/map.png"
                alt="Map"
                className="map-img"
              />
              <div
                className="text-white"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "24px",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <p className="text-white">
                  {cardInfo?.provided?.card?.number.slice(0, 4)}
                </p>
                <p className="text-white">
                  {cardInfo?.provided?.card?.number.slice(4, 8)}
                </p>
                <p className="text-white">
                  {cardInfo?.provided?.card?.number.slice(8, 12)}
                </p>
                <p className="text-white">
                  {cardInfo?.provided?.card?.number.slice(12, 16)}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div className="row card-holder">
                    <p
                      style={{
                        color: Colors.primary,
                        fontWeight: "bold",
                      }}
                    >
                      CARD HOLDER
                    </p>
                  </div>
                  <div className="row name">
                    <p className="text-white">
                      {cardInfo?.provided?.card?.nameOnCard}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="row card-holder">
                    <p
                      className=""
                      style={{
                        color: Colors.primary,
                        fontWeight: "bold",
                      }}
                    >
                      VALID TILL
                    </p>
                  </div>
                  <div className="row name">
                    <p className="text-white">
                      {cardInfo?.provided?.card?.expiry?.month}/
                      {cardInfo?.provided?.card?.expiry?.year}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="back">
              <img
                src="https://i.ibb.co/PYss3yv/map.png"
                alt="Map"
                className="map-img"
              />
              <div className="bar"></div>
              <div className="row text-white card-cvv">
                <div>
                  <img
                    src="https://i.ibb.co/S6JG8px/pattern.png"
                    alt="Pattern"
                  />
                </div>
                <p>XXX</p>
              </div>
              <div className="row text-white card-text">
                <p className="text-white">
                  This is your card number. Please keep it safe and do not share
                </p>
              </div>
              <div className="row signature">
                <p className="text-white">CUSTOMER SIGNATURE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCard;

import { Checkbox, List, Modal, message } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FcCalendar } from "react-icons/fc";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import { responseNotification } from "../../../utils/notifcation";
import HireEmployeeCalender from "../../../components/frontend/calender/HireEmployeeCalender";
import { useTranslation } from "react-i18next";
import { getTermsCondition } from "../../../locals/terms_condition";
import { IoBookmark } from "react-icons/io5";
import Loader from "../../../components/loadar/Loader";
import NotFound from "../../../components/frontend/employee/notFound/notFound";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as Action from "../_redux/clientActions";
import {
  calculateEstimatedAmount,
  calculateTotalDays,
} from "../../../utils/shortlist";
import { Colors } from "../../../constants/Colors";

const ShortList = () => {
  const { t } = useTranslation();
  // const [getShortList, setShortList] = useState([]);
  const [getSelectedShortlist, setSelectedShortlist] = useState([]);
  const [getError, setError] = useState();
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [termsConditions, setTermsConditions] = useState([]);
  const [isDateModalOpen, setDateModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [shortListIds, setShortListIds] = useState([]);
  const [requestedData, setRequestedData] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { setShortListCount, currencyType } = useContext(ContextApi);
  // const { listLoading: shortlistLoading, shortlistData } = useSelector(
  //   (state) => state.client
  // );
  const { listLoading, getShortList, allShortListIds } = useSelector(
    ({ client }) => ({
      listLoading: client?.listLoading,
      getShortList: client?.shortlistData?.entities ?? [],
      totalPages: client.shortlistData?.totalPages,
      allShortListIds: client.shortlistData?.shortListIds,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  // console.log("shortListIds", shortListIds);
  // fetch short list data
  useEffect(() => {
    dispatch(Action.fetchShortlist(12, 1));
  }, [dispatch]);

  // useEffect(() => {
  //   if (listShort && listShort.length) {
  //     setShortList(listShort);
  //   }
  // }, [listShort]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/terms-conditions`)
      .then((response) => response.json())
      .then((data) => {
        setTermsConditions(data.termsConditions);
      })
      .catch((error) => {
        message.error(
          "There was an error fetching the terms and conditions:",
          error
        );
      });
  }, []);

  const handleModalClose = () => {
    window.location.reload();
  };
  const toggleDateModal = () => {
    setDateModalOpen(!isDateModalOpen);
  };

  const navigate = useNavigate();

  const showTermsModal = () => {
    setIsTermsModalVisible(true);
  };

  const handleTermsAccept = () => {
    setConfirmLoading(true);
    setIsTermsModalVisible(false);
    bookAllShortListInfo();
  };

  const handleTermsReject = () => {
    setIsTermsModalVisible(false);
  };

  // const fetchShortListData = useCallback(async () => {
  //   setLoading(true);
  //   await fetchShortListHandler().then((res) => {
  //     // console.log(`res`, res);
  //     if (res?.status === 201) {
  //       setSelectedShortlist(res?.data?.shortList);
  //       setShortList(res?.data?.shortList);
  //     } else {
  //       setLoading(false);
  //     }
  //   });

  //   setLoading(false);
  // }, []);

  // useEffect(() => {
  //   fetchShortListData();
  // }, [fetchShortListData]);

  //delete short list info here
  const deleteShortListInfo = useCallback(
    async (shortListId) => {
      const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;
      if (true) {
        await fetch(`${unicodeUri}/short-list/delete/${shortListId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token()}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            if (res?.statusCode === 200) {
              responseNotification(
                "Short list removed successfully",
                "success"
              );
              setShortListCount(res?.data?.shortList);
              window.location.reload();
            } else if (res?.statusCode === 400) {
              responseNotification("Bad request", "danger");
            }
          });
      }
    },
    [setShortListCount] // make sure to list fetchShortListData as a dependency
  );

  const bookAllShortListInfo = useCallback(async () => {
    console.log({ shortListIds });
    const unicodeUri = `${process.env.REACT_APP_API_BASE_URL}`;

    const receivedData = { selectedShortlist: shortListIds };
    // console.log(`receivedData`, receivedData);
    // console.log(`getSelectedShortlist as received`, getSelectedShortlist);
    if (_.size(shortListIds)) {
      await fetch(`${unicodeUri}/hired-histories/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(receivedData),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(`res`, res);
          if (res?.status === "success") {
            responseNotification(
              "Hired history created successfully",
              "success"
            );
            setConfirmLoading(false);
            // fetchShortListData();
            // window.location.reload();
            navigate("/success-message");
          } else if (res?.statusCode === 400) {
            setError(res?.message);
            responseNotification(res?.message, "error");
          }
        });
      setConfirmLoading(false);
    } else {
      responseNotification("Please shortlist added then hired", "error");
      setConfirmLoading(false);
    }
  }, [getSelectedShortlist, navigate, shortListIds]);

  return (
    <div>
      <section className="InnnerDashboardSearch">
        <div className="container px-0">
          <div className="innerDashSearchItems">
            <Link to="/client-dashboard">
              <button className="innerdashboardBackButton">
                <img
                  src="/assets/frontend/images/InnerDashboard/arrow.png"
                  className="img-fluid"
                  alt="back"
                />
              </button>
            </Link>
            <IoBookmark style={{ color: "#58C8C8", fontSize: "30px" }} />
            <span className="innerDashSearchItemsSpan">{t("short_lists")}</span>
          </div>
        </div>
      </section>

      <div className="my-5 mb-5">
        {listLoading ? (
          <div className="container px-0">
            <div className="mt-2 container-style mb-4">
              <div className="mb-3 d-flex align-items-center">
                <Loader />
              </div>
            </div>
          </div>
        ) : getShortList?.length ? (
          _.map(getShortList, (positionItem, positionIndex) => (
            <div className="container px-0" key={positionIndex}>
              <div className="mt-2 container-style mb-4">
                <div className="mb-3 d-flex align-items-center">
                  <img
                    src="/assets/frontend/images/shortList/icons/chef.png"
                    width={26}
                    height={26}
                    alt="item"
                  />
                  <span className="ms-2 fw-500" style={{ fontSize: "17px" }}>
                    {positionItem?.positionName}
                  </span>
                </div>
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-sm-2  g-4">
                  {positionItem?.employeeDetails?.map((item, index) => (
                    <div className="col" key={index}>
                      <div className="card h-100 short-list-card">
                        <div style={{ position: "relative" }}>
                          {calculateTotalDays(item?.requestDate) > 0 && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: "5px",
                              }}
                            >
                              <Checkbox
                                className="custom-checkbox"
                                // checked={shortListIds?.find(
                                //   (val) => val == item?._id
                                // )}
                                onChange={(e) => {
                                  const check = e.target.checked;
                                  if (check) {
                                    setShortListIds((prev) => [
                                      ...prev,
                                      item?._id,
                                    ]);
                                  } else {
                                    const data = [...shortListIds];
                                    const ids = data?.filter(
                                      (res) => res != item?._id
                                    );
                                    setShortListIds(ids);
                                  }
                                }}
                              ></Checkbox>
                            </div>
                          )}
                          <img
                            style={{
                              width: 272,
                              height: 360,
                              objectFit: "cover",
                            }}
                            src={
                              item?.employeeDetails?.profilePicture &&
                              item?.employeeDetails?.profilePicture !=
                                "undefined"
                                ? item?.employeeDetails?.profilePicture.includes(
                                    "lh3.googleusercontent.com"
                                  )
                                  ? item?.employeeDetails?.profilePicture
                                  : process.env.REACT_APP_ASSETs_BASE_URL +
                                    "/" +
                                    item?.employeeDetails?.profilePicture
                                : "/assets/Employee.jpg"
                            }
                            // src={
                            //   item?.employeeDetails?.profilePicture
                            //     ? process.env.REACT_APP_ASSETs_BASE_URL +
                            //       "/" +
                            //       item?.employeeDetails?.profilePicture
                            //     : "/assets/default.png"
                            // }
                            className="card-img-top"
                            alt="profile"
                          />
                        </div>

                        <div className="card-body">
                          <span className="card-title card-title-style fw-500">
                            {item?.employeeDetails?.name}
                          </span>

                          {/* new calender */}
                          <div className="mb-2">
                            <button
                              style={{
                                backgroundColor: "#F5F5F5",
                                colour: "#A6A6A6",
                                border: "none",
                                borderRadius: "5px",
                                padding: "5px",
                                width: "100%",
                                marginTop: "10px",
                              }}
                              onClick={() => {
                                toggleDateModal();
                                setSelectedId(item?.employeeId);
                                setShortListIds(item?._id);
                                setRequestedData(item?.requestDate);
                                setSelectedEmployee(item);
                              }}
                            >
                              <FcCalendar
                                style={{
                                  fontSize: "20px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              {calculateTotalDays(item?.requestDate) === 0
                                ? t("select_dates")
                                : `${calculateTotalDays(
                                    item?.requestDate
                                  )} days selected`}
                            </button>
                          </div>
                          {/* amount */}
                          <div className="mb-2">
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {t("total_estimate_amount")}: {currencyType}{" "}
                              {calculateEstimatedAmount(item)}{" "}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <button className="fvt-btn">
                              <IoBookmark
                                style={{ color: "#58C8C8", fontSize: "22px" }}
                              />
                            </button>
                            <button
                              onClick={() => deleteShortListInfo(item?._id)}
                              className="book-btn ms-2 fw-500"
                            >
                              {t("removed")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="">
            <NotFound
              title={t("no_short_list")}
              description={t("you_have_not_added_anyone_to_short_list.")}
            />
          </div>
        )}
      </div>

      {shortListIds?.length > 0 && (
        <div className="container px-0">
          <div className="container-style mb-4">
            <button
              // onClick={bookAllShortListInfo}
              onClick={showTermsModal}
              className="btn ms-2 fw-500"
              style={{ backgroundColor: "#58c8c8", color: "white" }}
            >
              {t("book_all")}
            </button>
          </div>
        </div>
      )}

      <Modal
        title={t("terms_&_conditions")}
        visible={isTermsModalVisible}
        onOk={handleTermsAccept}
        onCancel={handleTermsReject}
        confirmLoading={confirmLoading}
        okText="Accept"
        cancelText="Reject"
        okButtonProps={{
          style: {
            backgroundColor: Colors.primary,
            borderColor: Colors.primary,
            color: "white",
          },
        }}
      >
        <p className="mb-3">{t("do_you_accept_the_terms_&_conditions?")}</p>

        <List
          style={{
            height: "450px",
            overflowY: "scroll",
          }}
          // dataSource={termsConditions.filter((term) => term.active)}
          dataSource={getTermsCondition()}
          renderItem={(term, index) => (
            <List.Item>
              <p className="mb-2">
                <span style={{ fontWeight: "bold" }}>{index + 1}:</span> {term}
              </p>
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        // title="Select Date"
        visible={isDateModalOpen}
        onOk={() => setDateModalOpen(false)}
        onCancel={() => {
          handleModalClose();
          setDateModalOpen(false);
        }}
        footer={null}
        width={1200}
      >
        <HireEmployeeCalender
          selectedId={selectedId}
          shortListIds={shortListIds}
          requestedData={requestedData}
          selectedEmployee={selectedEmployee}
          setDateModalOpen={setDateModalOpen}
        />
      </Modal>
    </div>
  );
};

export default ShortList;

import React, { useState } from "react";
import "./chat_style.scss";
import axiosInstance from "../../utils/API";
import { useQuery } from "react-query";
import { jwtTokenDecode } from "../../utils/jwtDecode";
import MultiChatSkeleton from "./MultiChatSkeleton";
import { Colors } from "../../constants/Colors";
import { formatDateForUnreadMsg } from "../../utils/date";
import RightSide from "./_components/RightSide";
import { useSearchParams } from "react-router-dom";
import { getFormateName } from "../../utils/commonFunctions";
const fetchConversionList = async () => {
  const response = await axiosInstance.get("/conversations");
  return response?.data;
};

export default function MultiChat() {
  const jwtDecode = jwtTokenDecode();
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [socketData, setSocketData] = useState({});
  const [previousMessages, setPreviousMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const { data, isLoading: chatListLoading } = useQuery({
    queryKey: ["chat", jwtDecode?._id],
    queryFn: async () => await fetchConversionList(),
    refetchInterval: 30000,
  });

  const onClickHandler = (user) => {
    if (selectedUser && selectedUser?._id === user?._id) {
      return;
    }
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("conversationId", user?.latestMessage?.conversationId);
      return params;
    });
    setSelectedUser(null);
    setPreviousMessages(false);
    setPage(1);
    setMessages([]);
    setSocketData({});
    setSelectedUser(user);
  };

  // console.log("conversation-data~~", data);
  if (data?.conversations?.length === 1) {
    const { latestMessage } = data.conversations[0];
    if (!latestMessage) {
      return (
        <div>
          <h3
            className="h3 fw-semibold"
            style={{
              color: Colors.primary,
            }}
          >
            There is no conversation
          </h3>
        </div>
      );
    }
  }
  // console.log("conversationId~~", conversationId);

  return (
    <>
      <div className="background-green"></div>

      {chatListLoading ? (
        <MultiChatSkeleton />
      ) : (
        <div className="multi_chat_wrapper">
          <div className="left-container">
            {/* Header */}
            <div className="header">
              <h4 className="mb-0">Chats</h4>
            </div>
            {/* conversion list */}
            <div className="chat-list">
              {data?.conversations.length > 0 ? (
                data?.conversations.map((conversation) => {
                  const { members } = conversation;
                  if (members?.length === 0) {
                    return <div></div>;
                  }
                  //   console.log("members~~", members);
                  const member = members[0];
                  const { role } = member;
                  const name =
                    role === "CLIENT" ? member?.restaurantName : member?.name;
                  const profilePicture =
                    member?.profilePicture &&
                    member?.profilePicture != "undefined"
                      ? member?.profilePicture.includes(
                          "lh3.googleusercontent.com"
                        )
                        ? member?.profilePicture
                        : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${member?.profilePicture}`
                      : role === "CLIENT"
                      ? "/assets/Client.jpg"
                      : "/assets/Employee.jpg";
                  //   const { name } = members[0];
                  return (
                    conversation &&
                    conversation?.latestMessage?.conversationId && (
                      <div
                        className={`chat-box ${
                          selectedUser &&
                          selectedUser?._id === conversation?._id
                            ? " active"
                            : ""
                        }`}
                        onClick={() => onClickHandler(conversation)}
                        key={conversation._id}
                      >
                        <div className="img-box">
                          <img
                            className="img-cover"
                            src={profilePicture}
                            alt="img"
                          />
                        </div>

                        <div className="chat-details">
                          <div className="text-head">
                            {name && <h4>{getFormateName(name)}</h4>}
                            {conversation?.latestMessage?.dateTime && (
                              <p className="time unread">
                                {formatDateForUnreadMsg(
                                  conversation?.latestMessage?.dateTime
                                )}
                              </p>
                            )}
                          </div>
                          {conversation?.latestMessage && (
                            <div className="d-flex align-items-center justify-content-between">
                              <b>
                                {conversation?.latestMessage?.text?.length > 30
                                  ? `${conversation.latestMessage.text.slice(
                                      0,
                                      30
                                    )}...`
                                  : conversation?.latestMessage?.text}
                              </b>
                              {conversation?.unreadMsg > 0 &&
                                jwtDecode?._id !=
                                  conversation?.latestMessage?.createdBy && (
                                  <div className="unread-msg-count ">
                                    <b>{conversation?.unreadMsg}</b>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  );
                })
              ) : (
                <div>
                  <h3
                    style={{
                      color: Colors.primary,
                    }}
                    className="text-center fw-500 h3"
                  >
                    There is no conversation
                  </h3>
                </div>
              )}
            </div>
          </div>

          <div className="right-container">
            {selectedUser ? (
              <RightSide
                {...selectedUser}
                setMessages={setMessages}
                messages={messages}
                setPreviousMessages={setPreviousMessages}
                previousMessages={previousMessages}
                socketData={socketData}
                setSocketData={setSocketData}
                page={page}
                setPage={setPage}
              />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

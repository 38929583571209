import React from "react";
import { Route, Routes } from "react-router-dom";
import ClientMasterLayout from "../Client/Layout/ClientMasterLayout";
import GlobalHrAgency from "./Global-Hr-Agency/Global-Hr-Agency";
import DubaiTemporaryJobRecruitmentAgencies from "./dubai-temporary-job-recruitment-agencies/dubai-temporary-job-recruitment-agencies";
import LondonTemporaryJobRecruitmentAgencies from "./london-temporary-job-recruitment-agencies/london-temporary-job-recruitment-agencies";
import ItalyTemporaryJobRecruitmentAgencies from "./italy-temporary-job-recruitment-agencies/italy-temporary-job-recruitment-agencies";
import PartTimeJobRecruitmentConsultancy from "./part-time-job-recruitment-consultancy/part-time-job-recruitment-consultancy";
import HospitalityTemporaryStaffingAgency from "./hospitality-temporary-staffing-agency/hospitality-temporary-staffing-agency";
import "./seo.scss";
const SeoRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ClientMasterLayout />}>
        <Route path="/" element={<GlobalHrAgency />} />
        <Route
          path="/dubai-temporary-job-recruitment-agencies"
          element={<DubaiTemporaryJobRecruitmentAgencies />}
        />
        <Route
          path="/london-temporary-job-recruitment-agencies/"
          element={<LondonTemporaryJobRecruitmentAgencies />}
        />
        <Route
          path="/italy-temporary-job-recruitment-agencies"
          element={<ItalyTemporaryJobRecruitmentAgencies />}
        />
        <Route
          path="/part-time-job-recruitment-consultancy"
          element={<PartTimeJobRecruitmentConsultancy />}
        />
        <Route
          path="/hospitality-temporary-staffing-agency"
          element={<HospitalityTemporaryStaffingAgency />}
        />
      </Route>
    </Routes>
  );
};

export default SeoRoutes;

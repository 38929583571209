import React, { useContext } from 'react';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import AcademyCard from './AcademyCard';
import path1 from './images/path217.png';
import path2 from './images/path219.png';
import t2 from './images/t3.png';
import { ContextApi } from '../../../hooks/ContextApi';

const Waiter = () => {
  const { currencyType } = useContext(ContextApi);

  return (
    <div className="card  pb-5 pt-3 container my-5 " style={{ borderRadius: '20px' }} >

      <div className='d-flex justify-content-between my-5'>
        <Link to='/plagit-academy' style={{ textDecoration: 'none', color: 'gray' }}><p className='mt-3'><BsFillArrowLeftCircleFill style={{ color: '#58c8c8', fontSize: '30px' }} /> Back</p></Link>

        <div className='d-flex'>
          <img src={t2} alt='the' height={50} />
          <p className='mt-3 mx-1' style={{ fontSize: '20px', fontWeight: 500 }}>FRONT OF HOUSE ESSENTIALS</p>
        </div>
        <p></p>
      </div>

      <p style={{ color: '#7B7B7B', fontWeight:"bold" }} className='text-center'>Welcome to our comprehensive and dynamic FOH course! Whether you are an aspiring hospitality professional or looking to enhance your existing skills, our course is designed to equip you with the knowledge and expertise needed to excel in the exciting world of waitstaff and hospitality. Our FOH course is a carefully crafted program that covers a wide range of essential topics to prepare you for a successful career in the food and beverage industry.</p>

      <div class="row gy-5 mt-5">
        {/* <div class="col-lg-4 ">
          < AcademyCard comming={'Details Comming Soon'} course={'Basic Course'} />
        </div> */}
        <div class="col-lg-4 mx-auto">
          < AcademyCard comming={''} course={'FRONT OF HOUSE ESSENTIALS'}
            description={`Tailored to introduce aspiring waitstaff to the world of hospitality, cultivating their passion for the industry. Our students will have the opportunity to refine their core skills in providing exceptional service and creating memorable dining experience.`}
            price={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path2} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "8%",
                    marginRight: "10%",
                  }}
                >
                  Price: <strong>{currencyType}250</strong>
                </p>
              </div>
            }
            duration={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path1} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                >
                  Duration: <span style={{
                    fontWeight: 600,
                  }}>24H</span>
                  
                </p>
              </div>
            }
          />
        </div>
        {/* <div class="col-lg-4 ">
          < AcademyCard comming={'Details Comming Soon'} course={'Advance Course'} />
        </div> */}
      </div>
    </div>
  )
}

export default Waiter

import React, { Fragment, useRef, useState, useEffect } from "react";
import "./social_feed_style.scss";
import { Carousel, Dropdown, Modal } from "antd";
import CommentComponents from "./CommentComponents";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import { jwtTokenDecode } from "../../../../utils/jwtDecode";
import axios from "axios";
import { token } from "../../../../utils/authentication";
import toast from "react-hot-toast";
import { BsThreeDots } from "react-icons/bs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import PostMedia from "../../../Cards/SocialFeeds/PostMedia";
import MediaPostModal from "../../../Modals/SocialFeedModal/MediaPostModal";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as Actions from "../../../../pages/Client/_redux/clientActions";
import { clientSlice } from "../../../../pages/Client/_redux/clientSlice";
import API from "../../../../utils/API";
import { useLocation, useNavigate } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";

const { actions } = clientSlice;
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

export default function PostFeedCardMulti({
  userImage,
  userName,
  postTime,
  postContentImg,
  content,
  post,
  handleUpdatePost,
  setEdit,
  setSelectedPost,
  setCurrentPage,
  handleDeletePosts,
}) {
  const jwtDecode = jwtTokenDecode();
  const location = useLocation();
  // console.log(location);
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const type = params.get("type");
  const [showComments, setShowComments] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reportShowModal, setShowReportModal] = useState(false);
  const [remark, setRemark] = useState("");
  const [viewMore, setViewMore] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState();
  const [likes, setLikes] = useState(post.likes);
  const [likeLoading, setLikeLoading] = useState(false);
  const { actionLoading, isCreatePostSuccess } = useSelector(({ client }) => ({
    actionLoading: client.actionsLoading,
    isCreatePostSuccess: client.isCreatePostSuccess,
  }));
  const dispatch = useDispatch();
  const [postedData, setPostedData] = useState(postContentImg || []); // Set initial state

  // console.log("post~~~", post);
  // Toggle the view more state
  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  const handleRepostSubmit = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/social-feed/repost`,
        {
          postId:
            post?.repost && Object.keys(post?.repost).length > 0
              ? post?.repost?._id
              : post?._id,
          content: remark,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        if (res.data?.status == "success") {
          setShowModal(false);
          toast.success("Repost Successfully");
          setCurrentPage(1);
          setRemark("");
        }
      });
  };

  const handleReportSubmit = async () => {
    await API.post(`/social-feed/report`, {
      postId: post?._id,
      reason: remark,
    }).then((res) => {
      if (res.data?.status == "success") {
        setShowReportModal(false);
        toast.success("Report Successfully");
        setCurrentPage(1);
        setRemark("");
      }
    });
  };

  const likeUnlike = async () => {
    try {
      // first update the like or unlike
      const isLiked = likes.some((like) => like._id === jwtDecode?._id);
      let updatedLikes;

      if (isLiked) {
        // Remove like
        updatedLikes = likes.filter((like) => like._id !== jwtDecode?._id);
      } else {
        // Add like
        const user = { _id: jwtDecode?._id, name: jwtDecode?.name }; // Replace with actual user info
        updatedLikes = [...likes, user];
      }

      // Update state
      setLikes(updatedLikes);

      // Create a new post object with updated likes
      const updatedPost = { ...post, likes: updatedLikes };

      handleUpdatePost(updatedPost);

      setLikeLoading(true);
      //   after updating then call to backend
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/social-feed/like-unlike`,
        {
          postId: post?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
    } catch (error) {
      console.log("error~~", error);
    } finally {
      setLikeLoading(false);
    }
  };

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast((item) => num >= item.value);
    return item
      ? (num / item.value)
          .toFixed(digits)
          .replace(regexp, "")
          .concat(item.symbol)
      : "0";
  }

  const handleUploadPost = async (data) => {
    await axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/social-feed/update/${data?._id}`,
        {
          active: !data?.active,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then(async (res) => {
        if (res.data?.status == "success") {
          await toast.success("Post Updated");
          await setCurrentPage(1);
          const updatedPost = { ...post, active: res.data?.socialFeed?.active };

          handleUpdatePost(updatedPost);
        }
      })
      .catch((err) => toast.error("Invalid File Type"));
  };

  const handleDeletePost = async (data) => {
    if (data?._id) {
      dispatch(Actions.deletePost(data?._id));
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <button
          className="btn btn-sm btn-block p-0"
          type="button"
          onClick={() => {
            setSelectedPost(post);
          }}
        >
          Edit
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className="btn btn-sm btn-block p-0"
          onClick={() => {
            handleUploadPost(post);
          }}
        >
          {post?.active ? "Inactive" : "Active"}
        </button>
      ),
    },
    {
      key: "3",
      label: (
        <button
          className="btn btn-sm btn-block p-0"
          onClick={() => {
            handleDeletePost(post);
          }}
        >
          Delete
        </button>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(); // Reference to the Carousel component

  const handleMediaClick = (item, index) => {
    setCurrentSlide(index); // Set the clicked media index as the current slide
    setIsModalVisible(true); // Open the modal
  };

  const handleCancel = () => {
    setCurrentSlide(0); // Reset to the first slide
    setIsModalVisible(false); // Close the modal
    setPostedData(postContentImg || []);
    setSelectedPostId(null);
  };

  const prevSlide = () => {
    const newIndex =
      currentSlide === 0 ? postedData.length - 1 : currentSlide - 1;
    setCurrentSlide(newIndex);
    carouselRef.current.goTo(newIndex);
  };

  const nextSlide = () => {
    const newIndex =
      currentSlide === postedData.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(newIndex);
    carouselRef.current.goTo(newIndex);
  };

  function DetectLinks({ text }) {
    const detectAndMakeLinksClickable = (text) => {
      const urlRegex = /https?:\/\/[^\s]+/g;

      const parts = text.split(urlRegex);
      const links = [...text.matchAll(urlRegex)];

      return parts.reduce((acc, part, index) => {
        acc.push(<Fragment key={`part-${index}`}>{part}</Fragment>);
        if (links[index]) {
          acc.push(
            <a
              key={`link-${index}`}
              href={links[index][0]}
              target="_blank"
              rel="noopener noreferrer"
            >
              {links[index][0]}
            </a>
          );
        }
        return acc;
      }, []);
    };

    return <>{detectAndMakeLinksClickable(text)}</>;
  }

  const [isExpanded, setIsExpanded] = useState(false);
  const [isRepostExpanded, setIsRepostExpanded] = useState(false);
  const previewLength = 200; // Set preview length to 250 characters

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleRepostExpand = () => {
    setIsRepostExpanded(!isRepostExpanded);
  };
  function countItems(arr) {
    let count = 0;

    function countChildren(item) {
      count += 1;
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => countChildren(child));
      }
    }

    arr.forEach((item) => countChildren(item));
    return count;
  }

  const gotoProfileHandler = (id = "") => {
    // console.log("id~~", id);
    if (jwtDecode?.role === "EMPLOYEE") {
      window.open(`/candidate/profile/${id}`, "_blank");
    } else {
      window.open(`/profile/${id}`, "_blank");
    }
  };

  return (
    <>
      <div className="post_feed_card">
        <div className="post_feed_header">
          <div>
            <img className="post_user_img" src={userImage} alt="User" />
          </div>
          <div className="">
            <strong
              onClick={() => gotoProfileHandler(post.user?._id)}
              className="user_post_profile_name"
            >
              {userName}
            </strong>{" "}
            {" . "}
            <span className="post_time">
              {post?.active ? (
                <ReactTimeAgo date={postTime} locale="en-US" />
              ) : (
                "Hold"
              )}
            </span>
          </div>
          {(type === "mypost" ||
            location.pathname === "/candidate/my-social-post" ||
            location.pathname === "/my-social-post" ||
            post?.user?._id === jwtDecode?._id) && (
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
              className="ms-auto"
            >
              <button className="btn">
                <BsThreeDots />
              </button>
            </Dropdown>
          )}
        </div>

        <div className="post_feed_content">
          {content && content.length > 0 && (
            <span className="mb-5">
              {isExpanded ? (
                <DetectLinks text={content} />
              ) : (
                <DetectLinks
                  text={`${content.slice(0, previewLength)}${
                    content.length > previewLength ? "..." : ""
                  }`}
                />
              )}

              {content.length > previewLength && (
                <span onClick={toggleExpand} className="expanded_btn">
                  {isExpanded ? "See Less" : "See More"}
                </span>
              )}
            </span>
          )}

          {/* {console.log({ selectedPostId })} */}

          {postContentImg && (
            <PostMedia
              postContentImg={postContentImg}
              post={post}
              handleMediaClick={handleMediaClick}
              setSelectedPostId={setSelectedPostId}
            />
          )}

          {post?.repost && Object.keys(post?.repost).length > 0 && (
            <div className="border p-3">
              <div className="post_feed_header">
                <img
                  className="post_user_img"
                  src={
                    post?.repost?.user?.profilePicture &&
                    post?.repost?.user?.profilePicture != "undefined"
                      ? post?.repost?.user?.profilePicture?.includes(
                          "lh3.googleusercontent.com"
                        )
                        ? post?.repost?.user?.profilePicture
                        : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${post?.repost?.user?.profilePicture}`
                      : post?.repost?.user?.role === "CLIENT"
                      ? "/assets/Client.jpg"
                      : "/assets/Employee.jpg"
                  }
                  alt="User"
                />
                <div>
                  <strong
                    onClick={() => gotoProfileHandler(post?.repost?.user?._id)}
                    className="user_post_profile_name"
                  >
                    {post?.repost?.user?.role === "CLIENT"
                      ? post?.repost?.user?.restaurantName
                      : post?.repost?.user?.name}
                  </strong>
                </div>
              </div>

              {post?.repost?.content && post?.repost?.content.length > 0 && (
                <span className="mb-5">
                  {isRepostExpanded ? (
                    <DetectLinks text={post?.repost?.content} />
                  ) : (
                    <DetectLinks
                      text={`${post?.repost?.content.slice(0, previewLength)}${
                        content.length > previewLength ? "..." : ""
                      }`}
                    />
                  )}

                  {post?.repost?.content.length > previewLength && (
                    <span onClick={toggleRepostExpand} className="expanded_btn">
                      {isRepostExpanded ? "See Less" : "See More"}
                    </span>
                  )}
                </span>
              )}

              <PostMedia
                postContentImg={post?.repost?.media}
                handleMediaClick={handleMediaClick}
                post={post}
                setSelectedPostId={setSelectedPostId}
              />
            </div>
          )}

          {selectedPostId === post?._id && isModalVisible && (
            <Modal
              visible={isModalVisible}
              footer={null}
              onCancel={handleCancel}
              // closable={false}
              centered
              bodyStyle={{ overflow: "hidden" }}
              style={{
                top: 0,
              }}
              width="80%"
              maxHeight="80vh"
              wrapClassName="post_preview_modal"
              className="custom_socail_post_modal"
            >
              <div className="main_wrap_modal">
                <div className="row">
                  <div className="col-md-7 position-relative p-0">
                    {postedData.length === 1 ? (
                      <Fragment>
                        {postedData.map((item, i) => (
                          <div className="w-100 position-relative">
                            {item.type === "image" ? (
                              <Fragment>
                                <img
                                  src={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${item.url}`}
                                  className="popup_image_view"
                                  alt="post images"
                                />
                                <a
                                  href={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${item.url}`}
                                  download
                                  rel="noreferrer"
                                  className="download_media_post"
                                >
                                  <i class="fas fa-download"></i>
                                </a>
                              </Fragment>
                            ) : (
                              <VideoPlayer data={item.url} />
                            )}
                          </div>
                        ))}
                      </Fragment>
                    ) : (
                      <>
                        <LeftOutlined
                          onClick={prevSlide}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "20px",
                            zIndex: 10,
                            fontSize: "22px",
                            color: "#fff",
                            cursor: "pointer",
                            background: "rgba(0, 0, 0, 0.7)",
                            padding: "10px",
                            borderRadius: "50%",
                          }}
                        />

                        <RightOutlined
                          onClick={nextSlide}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "0",
                            zIndex: 10,
                            fontSize: "22px",
                            color: "#fff",
                            cursor: "pointer",
                            background: "rgba(0, 0, 0, 0.7)",
                            padding: "10px",
                            borderRadius: "50%",
                          }}
                        />

                        <Carousel
                          ref={carouselRef}
                          initialSlide={currentSlide}
                          dots={false}
                          afterChange={(index) => setCurrentSlide(index)}
                          adaptiveHeight={true}
                        >
                          {postedData.map((item, index) => (
                            <div className="position-relative" key={index}>
                              {item.type === "image" ? (
                                <Fragment>
                                  <img
                                    src={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${item.url}`}
                                    className="popup_image_view"
                                    alt="post images"
                                  />

                                  <a
                                    href={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${item.url}`}
                                    download
                                    rel="noreferrer"
                                    className="download_media_post"
                                  >
                                    <i class="fas fa-download"></i>
                                  </a>
                                </Fragment>
                              ) : (
                                <VideoPlayer data={item.url} />
                              )}
                            </div>
                          ))}

                          {post?.repost?.media?.map((item, index) => (
                            <div className="position-relative" key={index}>
                              {item.type === "image" ? (
                                <Fragment>
                                  <img
                                    src={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${item.url}`}
                                    className="popup_image_view"
                                    alt="post images"
                                  />

                                  <a
                                    href={`https://d1ew68mie4ej5v.cloudfront.net/public/users/profile/${item.url}`}
                                    target="_blank"
                                    download
                                    rel="noreferrer"
                                    className="download_media_post"
                                  >
                                    <i class="fas fa-download"></i>
                                  </a>
                                </Fragment>
                              ) : (
                                <VideoPlayer data={item.url} />
                              )}
                            </div>
                          ))}
                        </Carousel>
                      </>
                    )}
                  </div>

                  <div className="col-md-5 content_box_modal_right">
                    <div className="card-body">
                      <div className="user_info_card">
                        <div className="profile_image">
                          <img
                            className="post_user_img"
                            src={userImage}
                            alt="User"
                          />
                        </div>
                        <div className="user_info_content">
                          <h2
                            onClick={() => gotoProfileHandler(post.user?._id)}
                          >
                            {userName}
                          </h2>
                          <p>
                            <span className="post_time">
                              {post?.active ? (
                                <ReactTimeAgo date={postTime} locale="en-US" />
                              ) : (
                                "Hold"
                              )}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="post_content_div">
                        {content && content.length > 0 && (
                          <>
                            {isExpanded ? (
                              <DetectLinks text={content} />
                            ) : (
                              <DetectLinks
                                text={`${content.slice(0, previewLength)}${
                                  content.length > previewLength ? "..." : ""
                                }`}
                              />
                            )}

                            {content.length > previewLength && (
                              <span
                                onClick={toggleExpand}
                                className="expanded_btn"
                              >
                                {isExpanded ? "See Less" : "See More"}
                              </span>
                            )}
                          </>
                        )}
                      </div>

                      <hr
                        style={{
                          border: "1.21px solid rgba(104, 118, 132, 0.3)",
                        }}
                      />

                      <div className="post_feed_actions">
                        <button
                          className={`${
                            likes?.some((item) => item?._id === jwtDecode?._id)
                              ? "like-btn-active"
                              : ""
                          }
                            ${likeLoading ? "like-btn-disabled" : ""}`}
                          disabled={likeLoading}
                          onClick={() => {
                            likeUnlike();
                          }}
                        >
                          👍 {nFormatter(post?.likes?.length, 1)} Like
                        </button>
                        <button onClick={() => setShowComments(!showComments)}>
                          💬 {nFormatter(post?.comments?.length, 1)}
                        </button>
                        <button onClick={() => setShowModal(true)}>
                          🔄 Repost
                        </button>
                      </div>

                      <CommentComponents
                        comment={post?.comments}
                        handleUpdatePost={handleUpdatePost}
                        postId={post?._id}
                        post={post}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>

        <hr style={{ border: "1.21px solid rgba(104, 118, 132, 0.3)" }} />

        <div className="post_feed_actions">
          <button
            className={
              likes?.some((item) => item?._id === jwtDecode?._id)
                ? "like-btn-active"
                : ""
            }
            onClick={() => {
              likeUnlike();
            }}
          >
            👍 {nFormatter(post?.likes?.length, 1)} Like
          </button>
          <button onClick={() => setShowComments(!showComments)}>
            💬 {nFormatter(countItems(post?.comments, 1))}
          </button>
          {!post?.repost && (
            <button onClick={() => setShowModal(true)}>🔄 Repost</button>
          )}
          <button onClick={() => setShowReportModal(true)}>⛔️ Report</button>
        </div>

        {showComments && (
          <CommentComponents
            comment={post?.comments}
            handleUpdatePost={handleUpdatePost}
            postId={post?._id}
            post={post}
          />
        )}
      </div>

      <div
        className={`modal reason_modal fade ${showModal ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Repost </h5>
              <button
                type="button"
                className="btn-close text-light"
                onClick={() => setShowModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <textarea
                className="form-control"
                autoFocus
                rows="2"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder="Write message"
              />

              <div className="mt-3">
                {content && content.length > 0 && <p>{content} </p>}
                <PostMedia postContentImg={postContentImg} />
              </div>
              <button
                type="button"
                className="btn btn-primary reason_submit_btn"
                onClick={handleRepostSubmit}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal reason_modal fade ${reportShowModal ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: reportShowModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Report </h5>
              <button
                type="button"
                className="btn-close text-light"
                onClick={() => setShowReportModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <textarea
                className="form-control"
                autoFocus
                rows="2"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder="Write message"
              />

              <div className="mt-3">
                {content && content.length > 0 && <p>{content} </p>}
                <PostMedia postContentImg={postContentImg} />
              </div>
              <button
                type="button"
                className="btn btn-primary reason_submit_btn"
                onClick={handleReportSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

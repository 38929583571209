
import CouponCard from "./CouponCard";
import CarouselTemplate from "./CarouselTemplate";

const CouponCarousel = ({ coupons }) => {
    return (
        <CarouselTemplate>
            {coupons.map((coupon, index) => (
                <div key={index} className="mx-3 mt-5 mb-5">
                    <CouponCard
                        imageUrl={coupon.imageUrl}
                        restaurantLogo={coupon.restaurantLogo}
                        restaurantName={coupon.restaurantName}
                        discount={coupon.discount}
                        offerDetails={coupon.offerDetails}
                        expirationDate={coupon.expirationDate}
                    />
                </div>
            ))}
        </CarouselTemplate>
    );
};

export default CouponCarousel;

import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../../layouts/frontend/Footer";
import Navbar from "../../../layouts/frontend/Navbar";

function ClientMasterLayout() {
    return (
        <div>
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    );
}

export default ClientMasterLayout;

import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Menu } from "antd";
import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { BsPerson } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import io from "socket.io-client";
import useWindowWidth from "../../hooks/useWindowWidth";
import { token } from "../../utils/authentication";
import { changeLanguageFunc, getUserInfo } from "../../utils/commonFunctions";
import "./select.css";

function Navbar() {
  const [resturantDetails, setResturantDetails] = useState(null);
  const userInfo = token() && getUserInfo();
  const [selected, setSelected] = useState(
    localStorage.getItem("countryCode") || "GB"
  );
  const windowWidth = useWindowWidth();
  const [aboutUsList, setAboutUsList] = useState([]);
  const menuAboutItems = aboutUsList.map((aboutUs) => (
    <Menu.Item
      key={aboutUs._id}
      style={{
        // backgroundColor: "#58c8c8",
        // color: "white",
        fontWeight: "bold",
      }}
    >
      <NavLink
        className="navbar_custom_hover nav-link"
        to={`/about2/${aboutUs.slug}`}
      >
        {aboutUs.countryName}
      </NavLink>
    </Menu.Item>
  ));

  const aboutMenu = <Menu>{menuAboutItems}</Menu>;

  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  let localToken = token();
  let decodeData = null;
  if (localToken) {
    decodeData = jwtDecode(localToken);
  }

  async function handleLogout() {
    if (decodeData.role === "EMPLOYEE") {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        const updateLatLong = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/users/update-location`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${localToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: decodeData._id,
              lat,
              long,
            }),
          }
        );
        console.log(updateLatLong);
        if (updateLatLong.status === 201) {
          // debugger;
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        } else {
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      });
    } else {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    }
  }

  let prevLat = null;
  let prevLong = null;
  useEffect(() => {
    try {
      axios
        .get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/check-in-check-out-histories/today-work-place?currentDate=${moment().format(
            "YYYY-MM-DD"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          setResturantDetails(data.result?.restaurantDetails);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // function success(position) {
  //   const latitude = position.coords.latitude;
  //   const longitude = position.coords.longitude;
  //   console.log(prevLat, prevLong, latitude, longitude);
  //   if (
  //     latitude &&
  //     longitude &&
  //     userInfo &&
  //     resturantDetails &&
  //     prevLat !== latitude &&
  //     prevLong !== longitude
  //   ) {
  //     const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  //       withCredentials: false,
  //       transports: ["websocket", "polling", "flashsocket"],
  //     });

  //     // Event listener for when the connection is opened
  //     socket.on("connect", () => {
  //       console.log("Socket connected");
  //       const payload = {
  //         sender: userInfo?._id,
  //         reciever: resturantDetails?.hiredBy,
  //         cords: {
  //           latitude: latitude,
  //           longitude: longitude,
  //         },
  //       };
  //       // Emit the payload to the server
  //       socket.emit("location:move", payload);
  //       prevLat = latitude;
  //       prevLong = longitude;
  //     });

  //     // Event listener for handling incoming messages from the server
  //     socket.on("location:move", (data) => {
  //       // Handle the received data here
  //     });

  //     // Event listener for errors
  //     socket.on("error", (error) => {
  //       console.error("Socket error:", error);
  //     });

  //     // Event listener for when the connection is closed
  //     socket.on("disconnect", () => {
  //       console.log("Socket disconnected");
  //     });

  //     // Clean up the socket connection when the component unmounts
  //     return () => {
  //       console.log("Socket disconnecting");
  //       socket.disconnect();
  //     };
  //   }
  // }

  // function error() {
  //   console.log("Unable to retrieve your location");
  // }
  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(success, error);
  //   } else {
  //     console.log("Geolocation not supported");
  //   }
  // };
  // useEffect(() => {
  //   // getLocation();
  //   const intervalId = setInterval(() => {
  //     getLocation();
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, [resturantDetails]);

  useEffect(() => {
    const fetchAboutUsList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/about-us/list-for-dropdown`
        );
        setAboutUsList(response.data.aboutUss);
      } catch (error) {
        console.error("Error fetching about us list:", error);
      }
    };

    fetchAboutUsList();
  }, []);
  // console.log(aboutUsList);
  return (
    <section className="header sticky-top">
      <div className="container Index_header_container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                onClick={scrollToTop}
                className="navbar-brand customNav320"
                to="/"
              >
                <img
                  className="img-fluid nav_logo"
                  style={{
                    height: windowWidth < 576 ? "30px" : "50px",
                    width: windowWidth < 576 ? "100px" : "150px",
                  }}
                  src={`/assets/frontend/images/indexImages/logo_full.png`}
                  alt="images"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="home_navbar_custom collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li onClick={scrollToTop} className="nav-item">
                    <NavLink
                      className="navbar_custom_hover nav-link"
                      activeClassName="active" // Add this line
                      aria-current="page"
                      to="/"
                    >
                      {t("home_navbar_home")}
                    </NavLink>
                  </li>
                  <li onClick={scrollToTop} className="nav-item">
                    <NavLink
                      className="navbar_custom_hover nav-link"
                      aria-current="page"
                      to="/about"
                    >
                      {t("home_navbar_about_us")}
                    </NavLink>
                  </li>
                  {/* about us */}
                  {/* <li className="nav-item">
                    <Dropdown overlay={aboutMenu} placement="bottomLeft" arrow>
                      <NavLink className="navbar_custom_hover nav-link" to="#">
                        {t("home_navbar_about_us")}
                      </NavLink>
                    </Dropdown>
                  </li> */}
                  <li onClick={scrollToTop} className="nav-item">
                    <NavLink
                      className="navbar_custom_hover nav-link"
                      aria-current="page"
                      to="/plagit-academy"
                    >
                      {t("home_navbar_mh_academy")}
                    </NavLink>
                  </li>
                  <li onClick={scrollToTop} className="nav-item dropdown">
                    <NavLink
                      className="nav-link navbar_custom_hover"
                      to="/our-services"
                    >
                      {t("home_navbar_our_services")}
                    </NavLink>
                  </li>
                  <li onClick={scrollToTop} className="nav-item dropdown">
                    <NavLink
                      className="nav-link navbar_custom_hover"
                      to="/our-mission"
                    >
                      {t("home_navbar_our_mission_vision")}
                    </NavLink>
                  </li>
                  {decodeData && decodeData.role === "EMPLOYEE" ? (
                    <>
                      <li onClick={scrollToTop} className="nav-item dropdown">
                        <NavLink
                          className="nav-link navbar_custom_hover"
                          to="/candidate/home"
                        >
                          {t("home_navbar_profile")}
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <>
                      {decodeData && (
                        <li onClick={scrollToTop} className="nav-item dropdown">
                          <NavLink
                            className="nav-link navbar_custom_hover"
                            to={
                              decodeData?.role === "ADMIN" ||
                              decodeData?.role === "SUPER_ADMIN"
                                ? "/admin/dashboard"
                                : "/client-dashboard"
                            }
                          >
                            {decodeData?.role === "ADMIN" ||
                            decodeData?.role === "SUPER_ADMIN"
                              ? t("home_navbar_profile_admin")
                              : t("home_navbar_profile_client")}
                          </NavLink>
                        </li>
                      )}
                    </>
                  )}

                  {/* <li onClick={scrollToTop} className="nav-item">
                    <NavLink
                      className="nav-link navbar_custom_hover"
                      to="/career"
                    >
                      {t("home_navbar_career")}
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink
                      className="nav-link navbar_custom_hover"
                      to="/blog"
                    >
                      {t("home_navbar_blog")}
                    </NavLink>
                  </li> */}
                  <li>
                    <ReactFlagsSelect
                      defaultCountry="GB"
                      // className="menu-flags"
                      countries={["GB", "AE", "IT"]}
                      selected={selected || "GB"}
                      showSelectedLabel={false}
                      customLabels={{
                        US: "Eng",
                        GB: "Eng",
                        AE: "Ara",
                        IT: "Ita",
                      }}
                      onSelect={(e) => {
                        setSelected(e);
                        changeLanguageFunc(e);
                      }}
                    />
                  </li>
                  <li className="ms-2">
                    {localToken && decodeData.role === "EMPLOYEE" ? (
                      <>
                        <Dropdown
                          overlay={
                            <Menu>
                              <Link
                                to="/candidate/profile"
                                className="text-decoration-none"
                              >
                                <Menu.Item className="">
                                  {t("my_profile")}
                                </Menu.Item>
                              </Link>
                              <Menu.Item
                                className="bg-danger text-white"
                                onClick={handleLogout}
                              >
                                {t("logout")}
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Avatar
                            style={{
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Dropdown>
                      </>
                    ) : (
                      <>
                        {localToken ? (
                          <Button
                            onClick={handleLogout}
                            // className="navButton btn"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                            }}
                          >
                            Logout
                          </Button>
                        ) : (
                          <Link to="/login">
                            <button
                              type="button"
                              className="navButton btn"
                              style={{ color: "#58c8c8" }}
                            >
                              <BsPerson
                                style={{
                                  fontSize: "18px",
                                  marginRight: "5px",
                                  // color: "#58c8c8",
                                  marginTop: "-3px",
                                }}
                              />
                              {t("home_navbar_sign_in")}
                            </button>
                          </Link>
                        )}
                      </>
                    )}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Navbar;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  error: null,
  job: {
    totalCount: 0,
    totalPages: 0,
    entities: null,
  },
  bookedHistory: {
    totalCount: 0,
    entities: null,
  },
  hiredHistory: {
    totalCount: 0,
    entities: null,
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const candidateSlice = createSlice({
  name: "candidate",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // job list
    jobListFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.job.entities = action.payload.entities;
      state.job.totalCount = action.payload.totalCount;
      state.job.totalPages = action.payload.totalPages;
      state.error = null;
    },

    // booked list
    bookedListFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.bookedHistory.entities = action.payload.entities;
      state.bookedHistory.totalCount = action.payload.totalCount;
      state.error = null;
    },

    // hired list
    hiredListFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.hiredHistory.entities = action.payload.entities;
      state.hiredHistory.totalCount = action.payload.totalCount;
      state.error = null;
    },

    // my social post list
    mySocialPostFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.socialFeeds = action.payload.entities;
      state.socialFeeds.totalCount = action.payload.totalCount;
      state.socialFeeds.totalPages = action.payload.totalPages;
      state.error = null;
    },
  },
});

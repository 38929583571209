import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import axios from "axios";
import _, { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { userDeleteHandler } from "../../../api/employee";
import { token } from "../../../utils/authentication";
import { responseNotification } from "../../../utils/notifcation";
import Loader from "../../../components/loadar/Loader";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function SubscriptionPlan() {
  const { t } = useTranslation();
  const [getPlanData, setGetPlanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(false);
  const [addPlanModal, setAddPlanModal] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [featureValue, setFeatureValue] = useState("");
  const [editingPlan, setEditingPlan] = useState(null);
  const [rowId, setRowId] = useState(null);

  const columns = [
    {
      title: "#",
      dataIndex: "key",
    },
    {
      title: "Plan Name",
      dataIndex: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: "Monthly Charge",
      dataIndex: "monthlyCharge",
    },
    {
      title: "Discount Type",
      dataIndex: "discountType",
    },
    {
      title: "Discount",
      dataIndex: "discount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (status ? "Active" : "Inactive"),
    },
    {
      title: t("action"),
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              onEdit(record);
              setRowId(record._id);
            }}
          >
            Edit
          </Button>
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record._id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const [planData, setPlanData] = useState({
    name: "",
    role: "",
    keys: [],
    features: [],
    yearlyCharge: null,
    monthlyCharge: null,
    currency: "",
    discountType: "",
    discount: "",
    discountStartDate: null,
    discountEndDate: null,
  });

  const handleChange = (key, value) => {
    if (key === "monthlyCharge") {
      const monthlyCharge = Number(value);
      const yearlyCharge = monthlyCharge * 12;

      setPlanData({
        ...planData,
        monthlyCharge,
        yearlyCharge,
      });
    } else {
      setPlanData({
        ...planData,
        [key]: value,
      });
    }
  };

  const submitPlanData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/subscription-plan/create`,
        planData,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
            "Content-Type": "application/json",
          },
        }
      );
      responseNotification("Plan created successfully!", "success");
      setAddPlanModal(false);
      fetchSubscriptionPlan();
      setPlanData({
        name: "",
        role: "",
        keys: [],
        features: [],
        yearlyCharge: "",
        monthlyCharge: "",
        currency: "",
        discountType: "",
        discount: "",
        discountStartDate: null,
        discountEndDate: null,
      });
      setAddPlanModal(false); // Close the modal on success
    } catch (error) {
      console.error("Error creating plan:", error);
      // swalWarning("Failed to create plan. Please try again.");
    }
  };

  const handleKeyPress = (keyType) => {
    if (keyType === "keys" && inputValue) {
      setPlanData({
        ...planData,
        keys: [...planData.keys, inputValue],
      });
      setInputValue("");
    } else if (keyType === "features" && featureValue) {
      setPlanData({
        ...planData,
        features: [...planData.features, featureValue],
      });
      setFeatureValue("");
    }
  };

  const handleTagClose = (keyType, removedTag) => {
    const updatedList = planData[keyType].filter((tag) => tag !== removedTag);
    setPlanData({
      ...planData,
      [keyType]: updatedList,
    });
  };

  const fetchSubscriptionPlan = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/subscription-plan/all`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setGetPlanData(response.data.result);
    } catch (error) {
      console.error("Error fetching subscription plan:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionPlan();
  }, []);

  const handleEditSubmit = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/subscription-plan/update/${rowId}`,
        planData,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      responseNotification("Plan updated successfully!", "success");
      setAddPlanModal(false);
      fetchSubscriptionPlan();
      setPlanData({
        name: "",
        role: "",
        keys: [],
        features: [],
        yearlyCharge: "",
        monthlyCharge: "",
        currency: "",
        discountType: "",
        discount: "",
        discountStartDate: null,
        discountEndDate: null,
      });
    } catch (error) {
      console.error("Failed to update the plan:", error);
      responseNotification("Failed to update plan.", "error");
    }
  };

  const onEdit = (record) => {
    setPlanData(record);
    setEditingPlan(record);
    setAddPlanModal(true);
  };

  const onDelete = async (key) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/subscription-plan/delete/${key}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      responseNotification("Plan deleted successfully!", "success");
      // Optionally refresh the table data
      fetchSubscriptionPlan();
    } catch (error) {
      console.error("Failed to delete plan:", error);
      responseNotification("Failed to delete plan.", "error");
    }
  };

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="d-flex justify-content-between">
          <h3 className="title mb-4">{t("subscription_plan_list")}</h3>

          <button
            style={{ background: "#58c8c8", color: "white" }}
            className="btn btn-sm py-2 mb-3 float-end"
            onClick={() => setAddPlanModal(true)}
          >
            Add New Plan
          </button>
        </div>
      </div>
      <div className="card">
        {loading ? (
          <Loader />
        ) : (
          <div className="p-4">
            <Table columns={columns} dataSource={getPlanData} />
          </div>
        )}
      </div>

      <Modal
        title={editingPlan ? "Edit Plan" : "Add New Plan"}
        open={addPlanModal}
        onCancel={() => {
          setAddPlanModal(false);
          setPlanData({
            name: "",
            role: "",
            keys: [],
            features: [],
            yearlyCharge: "",
            monthlyCharge: "",
            currency: "",
            discountType: "",
            discount: "",
            discountStartDate: null,
            discountEndDate: null,
          });
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setAddPlanModal(false);
              setPlanData({
                name: "",
                role: "",
                keys: [],
                features: [],
                yearlyCharge: "",
                monthlyCharge: "",
                currency: "",
                discountType: "",
                discount: "",
                discountStartDate: null,
                discountEndDate: null,
              });
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              editingPlan ? handleEditSubmit() : submitPlanData();
            }}
          >
            {editingPlan ? "Update" : "Submit"}
          </Button>,
        ]}
      >
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Plan Name
          </label>
          <Input
            id="name"
            value={planData.name}
            onChange={(e) => handleChange("name", e.target.value)}
            placeholder="Enter plan name"
            required
          />
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="role" className="form-label">
                Role
              </label>
              <Select
                id="role"
                value={planData.role}
                onChange={(value) => handleChange("role", value)}
                style={{ width: "100%" }}
                required
              >
                <Option value="Client">Client</Option>
                <Option value="Employee">Employee</Option>
              </Select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="currency" className="form-label">
                Currency
              </label>
              <Select
                id="currency"
                value={planData.currency}
                onChange={(value) => handleChange("currency", value)}
                style={{ width: "100%" }}
                required
              >
                <Option value="AED">AED</Option>
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
                <Option value="GBP">GBP</Option>
              </Select>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="keys" className="form-label">
            Keys
          </label>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onPressEnter={() => handleKeyPress("keys")}
            placeholder="Add key and press Enter"
          />

          {planData.keys.map((tag) => (
            <Tag
              key={tag}
              closable
              onClose={() => handleTagClose("keys", tag)}
              className="mt-2"
            >
              {tag}
            </Tag>
          ))}
        </div>
        <div className="mb-3">
          <label htmlFor="features" className="form-label">
            Features
          </label>
          <Input
            value={featureValue}
            onChange={(e) => setFeatureValue(e.target.value)}
            onPressEnter={() => handleKeyPress("features")}
            placeholder="Add feature and press Enter"
          />
          {planData.features.map((tag) => (
            <Tag
              key={tag}
              closable
              onClose={() => handleTagClose("features", tag)}
              className="mt-2"
            >
              {tag}
            </Tag>
          ))}
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="monthlyCharge" className="form-label">
                Monthly Charge
              </label>
              <Input
                id="monthlyCharge"
                value={planData.monthlyCharge}
                onChange={(e) => handleChange("monthlyCharge", e.target.value)}
                placeholder="Enter Monthly Charge Amount"
                type="number"
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="yearlyCharge" className="form-label">
                Yearly Charge
              </label>
              <Input
                id="yearlyCharge"
                value={planData.yearlyCharge}
                onChange={(e) => handleChange("yearlyCharge", e.target.value)}
                placeholder="Enter Yearly Charge Amount"
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="discountType" className="form-label">
                Discount Type
              </label>
              <Select
                id="discountType"
                value={planData.discountType}
                onChange={(value) => handleChange("discountType", value)}
                style={{ width: "100%" }}
                required
              >
                <Option value="percent">Percent</Option>
                <Option value="amount">Amount</Option>
              </Select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="discount" className="form-label">
                Discount
              </label>
              <Input
                id="discount"
                value={planData.discount}
                onChange={(e) => handleChange("discount", e.target.value)}
                placeholder="Enter Discount"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="discountStartDate" className="form-label">
                Discount Start Date
              </label>
              <DatePicker
                id="discountStartDate"
                onChange={(date, dateString) =>
                  handleChange("discountStartDate", dateString)
                }
                style={{ width: "100%" }}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="discountEndDate" className="form-label">
                Discount End Date
              </label>
              <DatePicker
                id="discountEndDate"
                onChange={(date, dateString) =>
                  handleChange("discountEndDate", dateString)
                }
                style={{ width: "100%" }}
                required
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={t("are_you_sure_to_delete_this_client")}
        open={selectedEmployee}
        cancelText={t("no")}
        okText={t("yes")}
        onOk={async () => {
          try {
            await userDeleteHandler(selectedEmployee).then((res) => {
              if (res?.status === 200) {
                responseNotification(
                  t("client_deleted_successfully"),
                  "success"
                );
                setSelectedEmployee(false);
              } else {
                setSelectedEmployee(false);
                responseNotification(t("something_went_wrong"), "danger");
              }
            });
          } catch (error) {
            setSelectedEmployee(false);
          }
        }}
        onCancel={() => {
          setSelectedEmployee(false);
        }}
      >
        <p>{t("this_will_delete_the_client_permanently")}</p>
      </Modal>
    </div>
  );
}

import React, { useState } from 'react';
import './Sidebar.scss';

function Sidebar({ toggleSidebar, isOpen, setIsOpen }) {

    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);



    const toggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    };

    const handleBackdropClick = (e) => {
        e.stopPropagation();
        setIsOpen(true);
    };

    return (
        <>
            {/* <button className="btn btn-primary" onClick={toggleSidebar}>
                Toggle Sidebar
            </button> */}

            {isOpen && <div className="backdrop" onClick={handleBackdropClick}></div>}

            <div className={`offcanvas-custom ${isOpen ? 'open' : ''}`}
                tabIndex="-1"
                id="sidebarMenu"
                aria-labelledby="sidebarLabel"
                onClick={(e) => e.stopPropagation()}
            >

                <div className="side_menu_body">
                    <button type="button" className="btn-close" onClick={toggleSidebar}></button>

                    <div class="profile_card">
                        <div class="profile_img">
                            <img src="https://mh-user-bucket.s3.amazonaws.com/public/users/profile/lbTIzKl83-1111.jpeg" alt="profile" />
                        </div><div class="profile_info">
                            <h2> Ashiful Islam</h2>
                            <h5>Software Engineer</h5>
                        </div>
                    </div>


                    <ul className="list-group sidebar_menu_ul">
                        <a href="#" className="list-group-item list-group-item-action">
                            <i className="fas fa-home"></i> <span className='ms-1'> Home</span> 
                        </a>

                        <a href="#" className="list-group-item list-group-item-action">
                            <i className="fas fa-user"></i> <span className='ms-1'>  My Profile</span>
                        </a>

                        <button
                            className="list-group-item list-group-item-action"
                            onClick={toggleSubmenu}
                        >
                            <i className="fas fa-language"></i> <span className='ms-1'> Language</span>
                            <i className={`fas ${isSubmenuOpen ? 'fa-chevron-up' : 'fa-chevron-down'} float-end`}></i>
                        </button>

                        {isSubmenuOpen && (
                            <div className="submenu">
                                <a href="#" className="list-group-item list-group-item-action ps-4">
                                    <i className="far fa-flag"></i> English
                                </a>
                                <a href="#" className="list-group-item list-group-item-action ps-4">
                                    <i className="far fa-flag"></i> Bangla
                                </a>
                                <a href="#" className="list-group-item list-group-item-action ps-4">
                                    <i className="far fa-flag"></i>  Hindi
                                </a>
                            </div>
                        )}

                        <a href="#" className="list-group-item list-group-item-action">
                            <i className="fas fa-bell"></i>
                            <span className='position-relative ms-2'>
                                Notification 
                                <span className="position-absolute ms-1 translate-end badge rounded-pill bg-danger">
                                    99+
                                    <span className="visually-hidden">unread messages</span>
                                </span>
                            </span>


                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            <i className="fas fa-comment-dots"></i><span className='ms-1'> Chat</span> 
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            <i className="fas fa-sign-out-alt"></i> <span className='ms-1'> Logout</span> 
                        </a>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Sidebar;

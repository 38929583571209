import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "../../../constants/Colors";

function RegisterWelcome() {
  const navigate = useNavigate();
  const buttonStyle = {
    backgroundColor: Colors.primary, // Custom color
    color: "white",
    border: "none",
    padding: "10px 20px",
    textDecoration: "none", // Remove underline for the link
    borderRadius: "5px",
  };
  return (
    <div className="col-lg-6 justify-content-center p-5 offset-lg-3">
      <div className="card">
        <div
          onClick={() => navigate(-1)}
          style={{
            marginLeft: "10px",
            marginTop: "10px",
            cursor: "pointer",
          }}
        >
          <button className="innerdashboardBackButton">
            <img
              src="assets/frontend/images/InnerDashboard/arrow.png"
              className="img-fluid"
              alt="arrow"
            />
          </button>
        </div>
        <div className="text-right" style={{ display: "none" }}>
          {" "}
          <i className="fa fa-times" />{" "}
        </div>
        <div className="card-body text-center">
          <a href="/">
            <img
              style={{ width: "100px", height: "100px" }}
              src="logo.png"
              alt="logo"
            />
          </a>
          <h4 style={{ marginTop: "15px" }}>
            Congratulations! Register successfully.
          </h4>
          {/* <p>Our hr will contact within 24 hours.</p> */}
          <div className="my-5">
            <Link to="/login" style={buttonStyle}>
              Login
            </Link>
          </div>

          <p>Download our app for more experience</p>
        </div>
        <div className="p-4 d-flex justify-content-around align-items-center">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.invain.mh"
            className="p-4 row justify-content-md-center"
          >
            <img
              src="assets/frontend/images/indexImages/Group 116147.png"
              alt="logo"
            />
          </a>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/mh/id6446052294"
            className="p-4 row justify-content-md-center"
          >
            <img
              src="assets/frontend/images/indexImages/appleButton.png"
              alt="logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default RegisterWelcome;

import React from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import AcademyCard from "./AcademyCard";
import path1 from "./images/path217.png";

import { Link } from "react-router-dom";
import t2 from "./images/t2.png";

const Expensive = () => {
  return (
    <div
      className="card  pb-5 pt-3 container my-5  "
      style={{ borderRadius: "20px" }}
    >
      <div className="d-flex justify-content-between my-5">
        <Link
          to="/plagit-academy"
          style={{ textDecoration: "none", color: "gray" }}
        >
          <p className="mt-3">
            <BsFillArrowLeftCircleFill
              style={{ color: "#58c8c8", fontSize: "30px" }}
            />{" "}
            Back
          </p>
        </Link>

        <div className="d-flex">
          <img src={t2} alt="the" height={50} />
          <p
            className="mt-3 mx-1"
            style={{ fontSize: "20px", fontWeight: 500 }}
          >
            EXPRESSIVE & EFFICIENT TRAINING PROGRAM
          </p>
        </div>
        <p></p>
      </div>

      <p style={{ color: "#7B7B7B", fontWeight:"bold" }} className="text-center">
       Designed to provide intelligent and rapid support to professionals thriving in the industry. Our classes feature intensive training sessions, each meticulously tailored to your unique requirements and objectives.
      </p>

      <div class="row gy-5 mt-5">
        <div class="col-lg-4 ">
          <AcademyCard
            course={"FAST & SMART"}
            // price={
            //   <div className="d-flex" style={{ marginLeft: "15%" }}>
            //     <img src={path2} alt="the" height={15} className="mt-1" />
            //     <p
            //       style={{
            //         color: "#7B7B7B",
            //         font: "Montserrat",
            //         fontWeight: 400,
            //         fontSize: "15px",
            //         marginLeft: "8%",
            //       }}
            //     >
            //       Price : <strong>£ 450</strong>
            //     </p>
            //   </div>
            // }
            duration={
              <div className="d-flex" style={{ marginLeft: "15%" }}>
                <img src={path1} alt="the" height={15} className="mt-1" />
                <p
                  style={{
                    color: "#7B7B7B",
                    font: "Montserrat",
                    fontWeight: 400,
                    fontSize: "15px",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                >
                  Duration : <strong>6 - 9 hours</strong> 
                </p>
              </div>
            }
            description={`This package provide intelligent and quick support for those already working in this industry. The class include intensive trainings with a customized training program according to our customers' needs. `}
          />
        </div>
        <div class="col-lg-4 ">
          <AcademyCard
            comming={""}
            description={`This package extends to a through consulation, where we analyse your needs to identify precise areas of intervention. We offer tailored classes to adderess your unique requirements. Ideal for business seeking targeted improvements within their team. `}
            course={"CUSTOMISED"}
          />
        </div>
        <div class="col-lg-4 ">
          {/* <AcademyCard
            comming={"Details Comming Soon"}
            course={"Advance Course"}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Expensive;

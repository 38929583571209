import { toast } from "react-hot-toast";

const success = (msg, position = "top-right") => {
  return toast.success(msg, {
    style: {
      backgroundColor: "#4caf50",
      color: "#fff",
    },
    position: position,
  });
};

const error = (msg, position = "top-right") => {
  toast.error(msg, {
    style: {
      backgroundColor: "#f44336",
      color: "#fff",
    },
    position: position,
  });
};

export const Toast = {
  success,
  error,
};

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import axios from "axios";

import _ from "lodash";
import moment from "moment";

import { IoChevronBackCircleOutline } from "react-icons/io5";
import {
  employeeUpdateHandler,
  fetchReferPersonListForDropdownHandler,
} from "../../../api/employee";
import { fetchPositionListForDropdownHandler } from "../../../api/position";
import { fetchSkillListForDropdownHandler } from "../../../api/skill";
import { fetchSourceListForDropdownHandler } from "../../../api/source";
import defaultImage from "../../../assets/images/default.png";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import { responseNotification } from "../../../utils/notifcation";
import CountryWiseValidationRules from "../../../utils/static/countryList";
import { staticLanguageName } from "../../../utils/static/languageList";
import Loader from "../../../components/loadar/Loader";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function EmployeeDetails() {
  const { t } = useTranslation();
  const { currencyType } = useContext(ContextApi);

  const { id } = useParams();

  const [getSingleEmployeeDetails, setSingleEmployeeDetails] = useState([]);
  const [referPerson, setReferPerson] = useState([]);
  const [skill, setSkill] = useState([]);
  const [position, setPosition] = useState([]);
  const [sourceFrom, setSourceFrom] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bankUpdateLoading, setBankUpdateLoading] = useState(false);
  const [basicInfoUpdateloading, setBasicInfoUpdateloading] = useState(false);
  const [getError, setError] = useState();
  const [getDateOfBirth, setDateOfBirth] = useState(undefined);
  const [profilePicture, setProfilePicture] = useState([]);
  const [summaryPdf, setSummaryPdf] = useState([]);
  const [summaryPdfFileShow, setSummaryPdfFileShow] = useState(undefined);
  const [nationalities, setNationalities] = useState([]);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [documents, setDocuments] = useState([]);
  const [docUpload, setDocUpload] = useState(false);
  const [docSubmit, setDocSubmit] = useState(false);
  const [updatedDoc, setUpdatedDoc] = useState([]);
  const [documentIndex, setDocumentIndex] = useState();

  // console.log("position", position);

  // const validateBio = (_, value) => {
  //   console.log("value", value);
  //   const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  //   const phoneRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/;
  //   const consecutiveNumbersRegex = /\b\d\s?\d\s?\d\s?\d\s?\d\b/;
  //   const spaceRegex = /\s{2,}/;

  //   if (emailRegex.test(value) || phoneRegex.test(value)) {
  //     return Promise.reject(
  //       "Please avoid including your email or phone number in the bio.",
  //     );
  //   }

  //   if (consecutiveNumbersRegex.test(value)) {
  //     return Promise.reject(
  //       "Please avoid including consecutive numbers in the bio.",
  //     );
  //   }

  //   if (spaceRegex.test(value)) {
  //     return Promise.reject(
  //       "Please avoid including consecutive spaces in bio.",
  //     );
  //   }

  //   return Promise.resolve();
  // };

  const validateBio = (_, value) => {
    // console.log("value", value);
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const phoneRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/;
    const consecutiveNumbersRegex = /\b\d\s?\d\s?\d\s?\d\s?\d\b/;
    const spaceRegex = /\s{2,}/;
    const digitRegex = /\d/g;
    const digitCount = (value.match(digitRegex) || []).length;
    // max length will be 100 characters
    if (value.length > 100) {
      return Promise.reject(
        "Please ensure that the bio is at most 100 characters."
      );
    }

    if (emailRegex.test(value) || phoneRegex.test(value)) {
      return Promise.reject(
        "Please avoid including your email or phone number in the bio."
      );
    }

    if (consecutiveNumbersRegex.test(value)) {
      return Promise.reject(
        "Please avoid including consecutive numbers in the bio."
      );
    }

    if (spaceRegex.test(value)) {
      return Promise.reject(
        "Please avoid including consecutive spaces in bio."
      );
    }

    if (digitCount > 5) {
      return Promise.reject(
        "Please ensure that the bio contains at most five numbers."
      );
    }

    return Promise.resolve();
  };

  // image preview
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  //Fetch refer person list for dropdown
  const fetchSingleEmployeeData = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      //Employee Basic Information
      formEdit.setFieldsValue({
        firstName: res?.data?.details.firstName,
        lastName: res?.data?.details.lastName,
        email: res?.data?.details.email,
        phoneNumber: res?.data?.details.phoneNumber,
        positionId: res?.data?.details.positionId,
        gender: res?.data?.details.gender,
        presentAddress: res?.data?.details.presentAddress,
        permanentAddress: res?.data?.details.permanentAddress,
        countryName: res?.data?.details.countryName,
        nationality: res?.data?.details.nationality,
        higherEducation: res?.data?.details.higherEducation,
        licensesNo: res?.data?.details.licensesNo,
        emmergencyContact: res?.data?.details.emmergencyContact,
        employeeExperience: res?.data?.details.employeeExperience,
        languages: res?.data?.details.languages,
        sourceId: res?.data?.details.sourceId,
        referPersonId: res?.data?.details.referPersonId,
        hourlyRate: res?.data?.details.hourlyRate,
        contractorHourlyRate: res?.data?.details.contractorHourlyRate,
        height: res?.data?.details.height,
        // weight: res?.data?.details.weight,
        dressSize: res?.data?.details.dressSize,
        bio: res?.data?.details.bio,
        currentOrganisation: res?.data?.details.currentOrganisation,
      });

      //bank information
      form.setFieldsValue({
        bankName: res?.data?.details.bankName,
        accountNumber: res?.data?.details.accountNumber,
        routingNumber: res?.data?.details.routingNumber,
        dressSize: res?.data?.details.dressSize,
        bio: res?.data?.details.bio,
        additionalOne: res?.data?.details.additionalOne,
        additionalTwo: res?.data?.details.additionalTwo,
        additionalThree: res?.data?.details.additionalThree,
      });

      if (
        res?.data?.details?.documents &&
        res?.data?.details?.documents[0] != ""
      ) {
        setDocuments(res?.data?.details?.documents);
      }

      setSingleEmployeeDetails(res?.data?.details);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchSingleEmployeeData();
  }, [id]);

  const onFinish = async (values) => {
    const receivedEmployeeFields = {
      id: id,
      bankName: values?.bankName,
      accountNumber: values?.accountNumber,
      routingNumber: values?.routingNumber,
    };

    if (values?.dressSize) receivedEmployeeFields.dressSize = values?.dressSize;
    if (values?.bio) receivedEmployeeFields.bio = values?.bio;

    if (values?.additionalOne)
      receivedEmployeeFields.additionalOne = values?.additionalOne;
    if (values?.additionalTwo)
      receivedEmployeeFields.additionalTwo = values?.additionalTwo;
    if (values?.additionalThree)
      receivedEmployeeFields.additionalThree = values?.additionalThree;

    try {
      if (id && receivedEmployeeFields) {
        setBankUpdateLoading(true);

        const res = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/update-bank-dress`,
          receivedEmployeeFields,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (res?.data?.statusCode === 200) {
          setError(undefined);
          setBankUpdateLoading(false);
          responseNotification(
            "Employee bank updated successfully!",
            "success"
          );
        } else if (res?.data?.statusCode === 400) {
          setError(res?.data?.errors?.[0].msg);
          setBankUpdateLoading(false);
        } else if (res?.data?.statusCode === 500) {
          setError(res?.message);
          setBankUpdateLoading(false);
        }
      }
    } catch (error) {
      setError(error?.response?.data?.errors?.[0].msg);
      setBankUpdateLoading(false);
    }
  };

  //Fetch refer person list for dropdown
  const fetchReferPersonData = useCallback(async () => {
    await fetchReferPersonListForDropdownHandler().then((res) => {
      setReferPerson(res?.data?.users);
    });
  }, []);

  //Fetch skill list for dropdown
  const fetchSkillData = useCallback(async () => {
    await fetchSkillListForDropdownHandler().then((res) => {
      setSkill(res?.data?.skills);
    });
  }, []);

  //Fetch position list for dropdown
  const fetchPositionData = useCallback(async () => {
    await fetchPositionListForDropdownHandler().then((res) => {
      setPosition(res?.data?.positions);
    });
  }, []);

  //Fetch source list for dropdown
  const fetchSourceFromData = useCallback(async () => {
    await fetchSourceListForDropdownHandler().then((res) => {
      setSourceFrom(res?.data?.sources);
    });
  }, []);

  // fetch nationality list for dropdown

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/commons/nationality`)
      .then((res) => res.json())
      .then((data) => {
        setNationalities(data.nationalities);
        // console.log(data.nationalities);
      });
  }, []);

  useEffect(() => {
    fetchSkillData();
    fetchPositionData();
    fetchSourceFromData();
    fetchReferPersonData();
  }, []);

  const onFinishBasicInfoUpdate = async (values) => {
    if (true) {
      setBasicInfoUpdateloading(true);

      const formData = new FormData();

      formData.append("id", id);
      formData.append("firstName", values?.firstName);
      formData.append("lastName", values?.lastName);
      formData.append("email", values?.email);
      formData.append("phoneNumber", values?.phoneNumber);
      formData.append("countryName", values?.countryName);
      formData.append("nationality", values?.nationality);
      formData.append("emmergencyContact", values?.emmergencyContact);
      formData.append("gender", values?.gender);
      formData.append("higherEducation", values?.higherEducation);
      formData.append("permanentAddress", values?.permanentAddress);
      formData.append("positionId", values?.positionId);
      formData.append("presentAddress", values?.presentAddress);
      formData.append("sourceId", values?.sourceId);
      formData.append("hourlyRate", values?.hourlyRate);
      formData.append("employeeExperience", values?.employeeExperience);
      formData.append("licensesNo", values?.licensesNo);

      // console.log(values);
      if (getDateOfBirth) {
        formData.append("dateOfBirth", getDateOfBirth);
      } else {
        formData.append(
          "dateOfBirth",
          getSingleEmployeeDetails?.dateOfBirth
            ? moment(getSingleEmployeeDetails?.dateOfBirth).format("YYYY-MM-DD")
            : undefined
        );
      }

      if (_.size(summaryPdf)) {
        formData.append("cv", summaryPdf[0].originFileObj);
      }

      if (_.size(profilePicture)) {
        formData.append(
          "profilePicture",
          profilePicture[0].originFileObj ||
            getSingleEmployeeDetails?.profilePicture
        );
      } else {
        formData.append("profilePicture", defaultImage); // 'defaultImage' can be a path or a base64 encoded string
      }

      values?.languages.forEach((element, index) => {
        formData.append(`languages[${index}]`, element);
      });

      values?.skills.forEach((element, index) => {
        formData.append(`skills[${index}]`, element);
      });

      if (values?.referPersonId) {
        formData.append("referPersonId", values?.referPersonId);
      }

      if (values?.contractorHourlyRate) {
        formData.append("contractorHourlyRate", values?.contractorHourlyRate);
      }
      // Append the new fields only if they exist
      if (values?.height) {
        formData.append("height", values?.height);
      }
      // if (values?.weight) {
      //   formData.append("weight", values?.weight);
      // }
      if (values?.dressSize) {
        formData.append("dressSize", values?.dressSize);
      }
      if (values?.bio) {
        formData.append("bio", values?.bio);
      }
      if (values?.currentOrganisation) {
        formData.append("currentOrganisation", values?.currentOrganisation);
      }
      await employeeUpdateHandler(formData)
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Employee information updated successfully!",
              "success"
            );

            window.location.reload();
          } else if (res?.statusCode === 400) {
            responseNotification(
              "Bad request please upload valid file or check you internet",
              "warning"
            );
            setError(res?.errors?.[0]?.msg);
            setBasicInfoUpdateloading(false);
          } else {
            setBasicInfoUpdateloading(false);
            setError(res?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setBasicInfoUpdateloading(false);
        });
    }
  };

  //CV onchange
  const summaryPdfChange = ({ fileList: newFileList }) => {
    setSummaryPdf(newFileList);
    setSummaryPdfFileShow(newFileList[0]?.originFileObj?.name);
  };

  //document onchange
  const summaryDocumentChange = async (file, data, index) => {
    setDocUpload(true);
    setDocumentIndex(index);
    const doc = [...documents];

    const formData = new FormData();
    formData.append("document", file.originFileObj);

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/document/upload-document`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        if (res.data?.statusCode == 201) {
          const updated = doc?.map((item) => {
            return {
              ...item,
              value:
                item.placeholder == data.placeholder
                  ? res.data?.details?.document[0]
                  : item.value,
            };
          });
          // console.log({ updated });
          setUpdatedDoc(updated);
          setDocUpload(false);
          setDocumentIndex();
        }
      });
    setDocUpload(false);
  };

  const handleUploadDoc = async () => {
    setDocSubmit(true);
    const payload = {
      id: id,
      documents: updatedDoc,
    };

    await axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/users/update-employee-doc`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setDocSubmit(false);
        setUpdatedDoc([]);
        fetchSingleEmployeeData();
      });

    setDocSubmit(false);
  };

  const onProfileChange = ({ fileList: newFileList }) => {
    // console.log(newFileList);
    setProfilePicture(newFileList);
  };

  useEffect(() => {
    setProfilePicture([
      {
        uid: "2",
        name: "image.png",
        status: "done",
        url: getSingleEmployeeDetails?.profilePicture?.includes(
          "lh3.googleusercontent.com"
        )
          ? getSingleEmployeeDetails?.profilePicture
          : `${process.env.REACT_APP_ASSETs_BASE_URL}/${getSingleEmployeeDetails?.profilePicture}`,
        // url: `${process.env.REACT_APP_ASSETs_BASE_URL}/${getSingleEmployeeDetails?.profilePicture}`,
      },
    ]);
  }, [getSingleEmployeeDetails]);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "10px",
            height: "50px",
            color: "#58c8c8",
          }}
          onClick={goBack}
        >
          <IoChevronBackCircleOutline
            size={25}
            backgroundColor="#A6A6A6"
            color="#58c8c8"
          />
          <span style={{ marginLeft: "10px" }}>{t("go_back")}</span>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h5>{t("edit_employee_information")}</h5>
            <Link
              to={`/admin/view-certificate/${id}`}
              style={{ background: "#58c8c8", color: "white" }}
              className="btn btn-sm"
            >
              {t("view_certificate")}
            </Link>
          </div>
        </div>

        {loading ? (
          <div className="card-body">
            <Loader /> <br />
            <br />
            <br />
          </div>
        ) : (
          <div className="card-body">
            <Form
              className="ant-form ant-form-vertical"
              layout="vertical"
              onFinish={onFinishBasicInfoUpdate}
              form={formEdit}
            >
              <div className="col-12">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Item
                      label={t("first_name")}
                      name="firstName"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_first_name"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_first_name")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("last_name")}
                      name="lastName"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_last_name"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_last_name")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("email")}
                      name="email"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_email"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_email")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("phone_number")}
                      name="phoneNumber"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_phone_number"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_phone_number")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("position")}
                      name="positionId"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_select_position"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("select_position")}
                        optionFilterProp="children"
                      >
                        {position?.map((item, index) => (
                          <Option key={index} value={item?._id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("gender")}
                      name="gender"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_gender"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("select_gender")}
                        optionFilterProp="children"
                      >
                        <Option value="MALE">MALE</Option>
                        <Option value="FEMALE">FEMALE</Option>
                        <Option value="OTHERS">OTHERS</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("date_of_birth")}
                      name="dateOfBirth"
                      hasFeedback
                      rules={[
                        {
                          // required: true,
                          message: t("please_enter_date_of_birth"),
                        },
                      ]}
                    >
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Tooltip
                          title={t(
                            "please_deselect_the_date_with_(x)_if_you_want_to_change_this"
                          )}
                        >
                          <DatePicker
                            id="dateOfBirth"
                            placeholder={t("date_of_birth")}
                            style={{ width: "100% !important" }}
                            className="w-100"
                            defaultValue={
                              getSingleEmployeeDetails?.dateOfBirth
                                ? moment(getSingleEmployeeDetails?.dateOfBirth)
                                : undefined
                            }
                            onChange={(value, dateOfBirthString) => {
                              setDateOfBirth(
                                moment(dateOfBirthString)
                                  .format("YYYY-MM-DD")
                                  .valueOf()
                              );
                            }}
                          />
                        </Tooltip>
                      </Space>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("present_address")}
                      name="presentAddress"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_present_address"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_present_address")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("post_code")}
                      name="permanentAddress"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_your_post_code"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_your_post_code")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("country_name")}
                      name="countryName"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_country_name"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_country_name")}
                        optionFilterProp="children"
                      >
                        {CountryWiseValidationRules?.map((item, index) => (
                          <Option key={index} value={item?.name}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("nationality")}
                      name="nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_nationality"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("select_nationality")}
                        optionFilterProp="children"
                      >
                        {nationalities?.map((item, index) => (
                          <Option key={index} value={item?.nationality}>
                            {item?.nationality}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("higher_education")}
                      name="higherEducation"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_higher_education"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_higher_education")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("licenses_no")}
                      name="licensesNo"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_licenses_no"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_licenses_no")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("emergency_contact")}
                      name="emmergencyContact"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_emergency_contact"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_emergency_contact")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("employee_experience")}
                      name="employeeExperience"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_employee_experience"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_employee_experience")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={`${t(
                        "restaurant_per_hour_rate"
                      )}(${currencyType})`}
                      name="hourlyRate"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_restaurant_per_hour_rate"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_restaurant_per_hour_rate")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={`${t(
                        "contractor_per_hour_rate"
                      )}(${currencyType})`}
                      name="contractorHourlyRate"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_contractor_per_hour_rate"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_contractor_per_hour_rate")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("languages")}
                      name="languages"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_languages"),
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch={true}
                        placeholder={t("please_select_languages")}
                        optionFilterProp="children"
                      >
                        {staticLanguageName?.map((item, index) => (
                          <Option key={index} value={item?.name}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("skills")}
                      name="skills"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_skills"),
                        },
                      ]}
                      initialValue={getSingleEmployeeDetails?.skills?.map(
                        (item) => item.skillId
                      )}
                    >
                      <Select
                        mode="multiple"
                        showSearch={true}
                        placeholder={t("please_select_skill")}
                        optionFilterProp="children"
                      >
                        {skill?.map((skillName, index) => (
                          <Option key={index} value={skillName?._id}>
                            {skillName?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("how_you_know_about_us")}
                      name="sourceId"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_how_you_know_about_us"),
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select")}
                        optionFilterProp="children"
                      >
                        {sourceFrom?.map((item, index) => (
                          <Option key={index} value={item?._id}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("refer_person_name")}
                      name="referPersonId"
                      hasFeedback
                    >
                      <Select
                        showSearch={true}
                        placeholder={t("please_select_refer_person")}
                        optionFilterProp="children"
                      >
                        {referPerson?.map((refer, index) => (
                          <Option key={index} value={refer?._id}>
                            {refer?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("height") + " (" + t("centimeter") + ")"}
                      name="height"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_height"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_height")}
                        type="number"
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  {/* <div className="col-md-4">
                    <Form.Item
                      label="Weight (kg)"
                      name="weight"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please enter weight",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter weight"
                        type="number"
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div> */}

                  <div className="col-md-4">
                    <Form.Item
                      label={t("dress_size")}
                      name="dressSize"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_dress_size"),
                        },
                      ]}
                    >
                      <Select>
                        <Option value="S">S</Option>
                        <Option value="M">M</Option>
                        <Option value="L">L</Option>
                        <Option value="XL">XL</Option>
                        <Option value="XXL">XXL</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-4">
                    <Form.Item
                      label={t("current_organization")}
                      name="currentOrganisation"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("please_enter_current_organization"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("enter_current_organization")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col">
                    <Form.Item
                      label={t("Bio")}
                      name="bio"
                      hasFeedback
                      rules={[
                        {
                          message: t("Please Enter Bio"),
                        },
                        {
                          validator: validateBio,
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("Enter bio")}
                        className="ant-input ant-input-lg"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      name="profilePicture"
                      label={t("profile_picture")}
                    >
                      <ImgCrop rotate aspect={500 / 600}>
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          fileList={profilePicture}
                          onChange={onProfileChange}
                          onPreview={onPreview}
                        >
                          {profilePicture?.length < 1 && (
                            <>
                              <img
                                style={{ height: "60px", width: "60px" }}
                                src={defaultImage}
                                alt="Default"
                              />
                            </>
                          )}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      name="summaryPdf"
                      className="p-1 m-0"
                      label={t("curriculam_vitea") + " (CV)"}
                      rules={[
                        {
                          // required: true,
                          message: t("please_enter_curriculam_vitea") + "(CV)",
                        },
                      ]}
                    >
                      <div>
                        <Upload
                          listType="picture"
                          defaultFileList={[...summaryPdf]}
                          fileList={summaryPdf}
                          onChange={summaryPdfChange}
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>
                            {!summaryPdfFileShow ? t("upload") : t("uploaded")}
                          </Button>
                        </Upload>

                        <div className="mt-3">
                          <a
                            target="_blank"
                            href={
                              `${process.env.REACT_APP_ASSETs_BASE_URL}/` +
                              getSingleEmployeeDetails?.cv
                            }
                            rel="noreferrer"
                          >
                            {t("download")}
                          </a>
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </div>

              {getError ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="error-message">
                      <p className="text-danger">{getError}</p>
                    </div>
                  </div>
                </div>
              ) : undefined}

              <div className="col-md-4">
                <Form.Item>
                  <button
                    disabled={basicInfoUpdateloading}
                    className="btn"
                    style={{ background: "#58c8c8", color: "white" }}
                    type="submit"
                  >
                    {!basicInfoUpdateloading && t("update")}
                    {basicInfoUpdateloading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {t("please_wait...")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </Form.Item>
              </div>
            </Form>
          </div>
        )}
      </div>
      <br />

      {documents && documents.length > 0 && (
        <div className="card">
          <div className="card-header">{t("documents")}</div>
          <div className="card-body">
            {loading ? (
              <div className="card-body">
                <Loader /> <br />
                <br />
                <br />
              </div>
            ) : (
              <Fragment>
                <div className="row">
                  {documents &&
                    documents.length > 0 &&
                    documents.map((item, i) => (
                      <div className="col-md-4" key={i}>
                        <Form.Item
                          name="summaryPdf"
                          className="p-1 m-0"
                          label={item.placeholder}
                          rules={[
                            {
                              // required: true,
                              message: t("please_enter_curriculam_vitea"),
                            },
                          ]}
                        >
                          <div>
                            <Upload
                              listType="picture"
                              defaultFileList={[...summaryPdf]}
                              fileList={summaryPdf}
                              onChange={(val) => {
                                summaryDocumentChange(val.file, item, i);
                              }}
                              maxCount={1}
                            >
                              <Button icon={<UploadOutlined />}>
                                {!summaryPdfFileShow
                                  ? t("upload")
                                  : t("uploaded")}
                              </Button>
                            </Upload>
                            {documentIndex == i && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                {t("please_wait...")}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}

                            <div className="mt-3">
                              <a
                                target="_blank"
                                href={
                                  `${process.env.REACT_APP_ASSETS_FRONTEND_URL}/` +
                                  item.value
                                }
                                rel="noreferrer"
                              >
                                {t("download")}
                              </a>
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    ))}
                </div>
                {updatedDoc && updatedDoc.length > 0 && (
                  <button
                    disabled={docUpload || docSubmit ? true : false}
                    className="btn mt-4"
                    style={{ background: "#58c8c8", color: "white" }}
                    type="button"
                    onClick={handleUploadDoc}
                  >
                    {!docSubmit && t("update_document")}
                    {docSubmit && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {t("please_wait...")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                )}
              </Fragment>
            )}
          </div>
        </div>
      )}

      <br />

      <div className="card">
        <div className="card-header">{t("bank_information")}</div>
        <div className="card-body">
          {loading ? (
            <div className="card-body">
              <Loader /> <br />
              <br />
              <br />
            </div>
          ) : (
            <div>
              <Form
                className="ant-form ant-form-vertical"
                layout="vertical"
                onFinish={onFinish}
                form={form}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Item
                        label={t("bank_name")}
                        name="bankName"
                        hasFeedback
                        initialValue={getSingleEmployeeDetails?.name}
                        rules={[
                          {
                            required: true,
                            message: t("please_enter_bank_name"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("enter_bank_name")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("account_number")}
                        name="accountNumber"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t("please_enter_account_number"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("enter_bank_account_number")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("sort_code")}
                        name="routingNumber"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t("please_enter_sort_code"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("enter_sort_code")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                    {/* <div className="col-md-4">
                      <Form.Item
                        label="Dress Size"
                        name="dressSize"
                        hasFeedback
                        rules={[
                          {
                            message: "Please enter dress size",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter dress size"
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div> */}

                    <div className="col-md-4">
                      <Form.Item
                        label={t("additional_one")}
                        name="additionalOne"
                        hasFeedback
                        rules={[
                          {
                            message: "Please enter additional one",
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("enter_additional_one")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <Form.Item
                        label={t("additional_two")}
                        name="additionalTwo"
                        hasFeedback
                        rules={[
                          {
                            message: "Please enter additional two",
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("enter_additional_two")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        label={t("additional_three")}
                        name="additionalThree"
                        hasFeedback
                        rules={[
                          {
                            message: "Please enter additional Three",
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("enter_additional_three")}
                          className="ant-input ant-input-lg"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                {getError ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="error-message">
                        <p className="text-danger">{getError}</p>
                      </div>
                    </div>
                  </div>
                ) : undefined}

                <div className="col-md-6">
                  <Form.Item>
                    <button
                      disabled={bankUpdateLoading}
                      className="btn"
                      style={{ background: "#58c8c8", color: "white" }}
                      type="submit"
                    >
                      {!bankUpdateLoading && t("update_bank")}
                      {bankUpdateLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {t("please_wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeDetails;

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";

import _ from "lodash";
import moment from "moment";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import { responseNotification } from "../../../utils/notifcation";
import { donwloadCSV } from "../../../utils/static/donwloadCSV";
import Loader from "../../../components/loadar/Loader";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  getUserCurrencyByCountry,
  paramsToQueryString2,
} from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { FiInfo } from "react-icons/fi";

function InvoiceList() {
  const { t } = useTranslation();
  const jwtDecode = jwtTokenDecode();
  const [getEmployee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [modal, setModal] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [totalEmployee, setTotalEmployee] = useState([]);
  const { currencyType } = useContext(ContextApi);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedClientsData, setselectedClientsData] = useState(null);
  const [pageSize, setpageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [feedbackModal, setFeedbackModal] = useState(false);
  const navigate = useNavigate();

  const [dateFilter, setDateFilter] = useState(null);
  const handleDateFilter = (date, dateString) => {
    setDateFilter(dateString);
    console.log("date", dateString);
  };

  console.log({ getEmployee });

  const isEditable = (checkInTime) => {
    const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000); // 12 hours ago
    return new Date(checkInTime) >= twelveHoursAgo;
  };

  const showModal = (_id, data) => {
    setModalVisible(true);
    // console.log(_id);
    setSelectedItemId(data.currentHiredEmployeeId);
    setselectedClientsData(data.checkInCheckOutDetails);
    // console.log("data", data);
  };

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedItemId(null);
    setselectedClientsData(null);
  };

  const minutestoHours = (secs) => {
    const isMinus = secs < 0 ? true : false;
    const sec = isMinus ? secs * -1 : secs;
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);
    const seconds = (sec - hours * 3600 - minutes * 60).toFixed(0);
    return (
      (isMinus ? "-" : "") +
      (hours < 10 ? "0" : "") +
      hours +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds +
      " H"
    );
  };

  //Set filter data
  const [getName, setName] = useState(undefined);

  const fetchEmployees = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: currentPage,
        limit: pageSize,
        status: status,
        filterDate: dateFilter,
        clientId: selectedClient,
        employeeId: employeeName,
      };
      const data = paramsToQueryString2(params);
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories?${data}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (res && res?.data.statusCode === 200) {
        const checkInCheckOutHistories = res.data.result.map((item) => {
          const finalCheckIn =
            item?.checkInCheckOutDetails?.clientCheckInTime ||
            item?.checkInCheckOutDetails?.checkInTime;
          const finalCheckOut =
            item?.checkInCheckOutDetails?.clientCheckOutTime ||
            item?.checkInCheckOutDetails?.checkOutTime;
          const finalBreakTime =
            item?.checkInCheckOutDetails?.clientBreakTime ||
            item?.checkInCheckOutDetails?.breakTime;
          const isEditCheckIn = item?.checkInCheckOutDetails?.clientCheckInTime
            ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ) ===
              dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
                "YYYY-MM-DD HH:mm:ss"
              )
              ? false
              : true
            : false;
          const isEditCheckOut = item?.checkInCheckOutDetails
            ?.clientCheckOutTime
            ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ) ===
              dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
                "YYYY-MM-DD HH:mm:ss"
              )
              ? false
              : true
            : false;
          const isEditBreakTime = item?.checkInCheckOutDetails?.clientBreakTime
            ? item?.checkInCheckOutDetails?.breakTime ===
              item?.checkInCheckOutDetails?.clientBreakTime
              ? false
              : true
            : false;
          console.log(item?.checkInCheckOutDetails?.checkInTime);
          return {
            date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
            id: item?._id,
            country: item?.restaurantDetails?.countryName,
            restaurantName: item?.restaurantDetails?.restaurantName,
            employeeName: item?.employeeDetails?.name,
            position: item?.employeeDetails?.positionName,
            contractorHourlyRate: item?.employeeDetails?.contractorHourlyRate,
            restaurantRate:
              getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
              " " +
              item?.employeeDetails?.hourlyRate,
            customerRate:
              item?.employeeDetails?.contractorHourlyRate +
              " " +
              getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
              "/Hour",
            checkIn: item?.checkInCheckOutDetails?.checkInTime
              ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
                  .format("hh:mm:ss A")
                  .toString()
              : null,
            finalCheckIn: finalCheckIn
              ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
              : null,
            isEditCheckIn: isEditCheckIn,

            checkOut: item?.checkInCheckOutDetails?.checkOutTime
              ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
                  .format("hh:mm:ss A")
                  .toString()
              : null,
            finalCheckOut: finalCheckOut
              ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
              : null,
            isEditCheckOut: isEditCheckOut,

            break: item?.checkInCheckOutDetails?.breakTime,
            finalBreakTime: finalBreakTime ? finalBreakTime : 0,
            isEditBreakTime: isEditBreakTime,

            totalHours: finalCheckOut
              ? minutestoHours(
                  dayjs(finalCheckOut)
                    .diff(dayjs(finalCheckIn), "seconds", true)
                    .toFixed(0) -
                    finalBreakTime * 60
                )
              : null,

            totalAmount:
              getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
              " " +
              parseFloat(item?.totalAmount).toFixed(2),
            clientComment: item?.checkInCheckOutDetails?.clientComment,
            vat: item?.vat,
            vatAmount: item?.vatAmount,
            totalAmountWithVat: item?.totalAmount,
            employeeAmount: item?.employeeAmount,
            status: item?.status,
            workedHour: item?.workedHour,
            mainItem: item,
          };
        });

        setEmployee(checkInCheckOutHistories || []);
        setTotalData(res.data.total);
        setLoading(false);
      } else if (res && res?.data.statusCode === 400) {
        setError(res.errors);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(true);
      console.log(error);
    }
  }, [
    currentPage,
    pageSize,
    dateFilter,
    getName,
    status,
    selectedClient,
    employeeName,
  ]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  // client list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users?requestType=CLIENT&skipLimit=YES
        `,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setClients(
          res.data.users.map((item) => ({
            ...item,
            label: item.restaurantName,
            value: item._id,
          }))
        );
      });
  }, []);
  // employee list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users?requestType=EMPLOYEE&skipLimit=YES`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setTotalEmployee(
          res.data.users.map((item) => ({
            ...item,
            label: item.name,
            value: item._id,
          }))
        );
      });
  }, []);

  const handleExportData = () => {
    const excelData = getEmployee.map((item) => {
      return {
        Date: item?.date,
        Country: item?.country,
        RestaurantName: item?.restaurantName,
        EmployeeName: item?.employeeName,
        Position: item?.position,
        RestaurantRate: item?.restaurantRate,
        CustomerRate: item?.customerRate,
        CheckIn: item?.finalCheckIn,
        CheckOut: item?.finalCheckOut,
        Break: item?.finalBreakTime,
        TotalHours: item?.totalHours,
        TotalAmount: item?.totalAmount,
        ClientComment: item?.clientComment,
      };
    });
    donwloadCSV(excelData, "check-in-check-out-histories");
  };
  const dynamicInvoiceLinkGenerator = (country) => {
    console.log(country);
    if (country === "United Kingdom") {
      return "payment-invoice-details";
    } else if (country === "United Arab Emirates") {
      return "payment-invoice-details";
    } else {
      return "payment-invoice-details";
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      // render upto totalData
      render: (value, record, index) => {
        return (
          <span>
            {(currentPage - 1) * pageSize + (index + 1)}
            {/* {index + 1} */}
          </span>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: t("contractor"),
      dataIndex: "restaurantName",
    },
    {
      title: t("employee"),
      dataIndex: "employeeName",
    },
    {
      title: t("position"),
      dataIndex: "position",
      // sorter: (a, b) => a.active.length - b.active.length,
    },
    {
      title: t("restaurant_per_hour_rate"),
      dataIndex: "restaurantRate",
      render: (restaurantRate) => {
        return `${restaurantRate}`;
      },
    },

    {
      title: t("check_in"),
      dataIndex: "checkIn",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckIn && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkIn}
              </div>
            )}
            <div
              style={
                record?.isEditCheckIn
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckIn}
            </div>
          </div>
        );
      },
    },
    {
      title: t("check_out"),
      dataIndex: "checkOut",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckOut && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkOut}
              </div>
            )}
            <div
              style={
                record?.isEditCheckOut
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckOut}
            </div>
          </div>
        );
      },
    },

    {
      title: t("break"),
      dataIndex: "break",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditBreakTime && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.break} min
              </div>
            )}
            <div
              style={
                record?.isEditBreakTime
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalBreakTime} min
            </div>
          </div>
        );
      },
    },
    {
      title: t("total_hours"),
      dataIndex: "workedHour",
    },
    {
      title: t("total_amount"),
      dataIndex: "totalAmount",
      render: (totalAmount) => {
        return `${totalAmount}`;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text, record) => {
        return (
          <div>
            {record?.status === "PAID" ? (
              <div
                style={{
                  color: "green",
                }}
              >
                {record?.status}
              </div>
            ) : (
              <div
                style={{
                  color: "red",
                }}
              >
                {record?.status}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("client_comment"),
      dataIndex: "clientComment",
    },
    {
      title: t("view_invoice"),
      dataIndex: "action",
      render: (text, record) => (
        <Link
          to={`/${dynamicInvoiceLinkGenerator(record?.countryName)}/${
            record?.id
          }`}
          target="_blank"
          className="text-decoration-none"
        >
          <Button
            type="primary"
            style={{ background: "#58c8c8", color: "white" }}
          >
            {t("view")}
          </Button>
        </Link>
      ),
    },
    {
      title: "Complain",
      dataIndex: "_id",
      width: 120,
      align: "center",
      fixed: "right",
      render: (_id, record) => {
        return (
          <div className="text-center">
            {record?.isEditCheckIn ||
            record?.isEditCheckOut ||
            record?.isEditBreakTime ? (
              <span
                style={{
                  color: "red",
                }}
              >
                Complained
              </span>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      width: 120,
      render: (text, record) => {
        return (
          <div>
            {record?.feedback ? (
              <Button
                onClick={() => {
                  setFeedbackModal(record);
                }}
              >
                <FiInfo />
              </Button>
            ) : null}
          </div>
        );
      },
    },
    /* {
      title: t('action'),
      dataIndex: "_id",
      render: (_id, record) => {
        return isEditable(
          record?.mainItem?.checkInCheckOutDetails?.checkInTime
        ) ? (
          <div className="text-center">
            <Button
              className="InnerTableButton"
              onClick={() => {
                // showModal(_id, record);
                console.log("record", record);
                setModal(true);
                form.setFieldsValue({
                  _id: record?.mainItem?.currentHiredEmployeeId,
                  checkInTime:
                    record?.mainItem?.checkInCheckOutDetails
                      ?.clientCheckInTime ||
                    record?.mainItem?.checkInCheckOutDetails?.checkInTime
                      ? dayjs(
                          record?.mainItem?.checkInCheckOutDetails
                            ?.clientCheckInTime ||
                            record?.mainItem?.checkInCheckOutDetails
                              ?.checkInTime
                        )
                      : null,
                  checkOutTime:
                    record?.mainItem?.checkInCheckOutDetails
                      ?.clientCheckOutTime ||
                    record?.mainItem?.checkInCheckOutDetails?.checkOutTime
                      ? dayjs(
                          record?.mainItem?.checkInCheckOutDetails
                            ?.clientCheckOutTime ||
                            record?.mainItem?.checkInCheckOutDetails
                              ?.checkOutTime
                        )
                      : null,
                  breakTime:
                    record?.mainItem?.checkInCheckOutDetails?.clientBreakTime ||
                    record?.mainItem?.checkInCheckOutDetails?.breakTime
                      ? record?.mainItem?.checkInCheckOutDetails
                          ?.clientBreakTime ||
                        record?.mainItem?.checkInCheckOutDetails?.breakTime
                      : null,
                  clientComment: record?.mainItem?.checkInCheckOutDetails
                    ?.clientComment
                    ? record?.mainItem?.checkInCheckOutDetails?.clientComment
                    : null,
                });
              }}
            >
              Update
            </Button>
          </div>
        ) : (
          <div className="text-center">
            <Tooltip title="Can't edit after 12 hours">
              <span style={{ color: "red" }}>
                <FcInfo />
              </span>
            </Tooltip>
          </div>
        );
      },
    }, */
  ];
  const [form] = Form.useForm();
  return (
    <div className="card">
      {/* Inner Dashboard Search Part Start */}
      <section className="InnnerDashboardSearch">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <span className="innerDashSearchItemsSpan">Invoice List</span>
            </div>
          </div>
          <Row gutter={[8, 8]}>
            <Col sm={24} md={12} lg={4}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("select_date")}
                size="large"
                onChange={handleDateFilter}
              />
            </Col>
            <Col sm={24} md={12} lg={4}>
              <Select
                showSearch={true}
                placeholder={t("restaurant")}
                optionFilterProp="children"
                size="large"
                allowClear
                style={{ width: "100%" }}
                onChange={(value, option) => {
                  setSelectedClient(value);
                }}
              >
                {clients?.map((item, index) => (
                  <Select key={index} value={item?._id}>
                    {item?.label}
                  </Select>
                ))}
              </Select>
            </Col>
            <Col sm={24} md={12} lg={4}>
              <Select
                showSearch={true}
                placeholder={t("employee")}
                optionFilterProp="children"
                size="large"
                allowClear
                style={{ width: "100%" }}
                onChange={(value, option) => {
                  setEmployeeName(value);
                }}
              >
                {totalEmployee?.map((item, index) => (
                  <Select key={index} value={item?._id}>
                    {item?.label}
                  </Select>
                ))}
              </Select>
            </Col>
            <Col sm={24} md={12} lg={4}>
              <Select
                defaultValue=""
                style={{ width: 120 }}
                size="large"
                onChange={(value) => {
                  setStatus(value);
                }}
              >
                <Select.Option value="">ALL</Select.Option>
                <Select.Option value="PAID">PAID</Select.Option>
                <Select.Option value="DUE">DUE</Select.Option>
              </Select>
            </Col>
          </Row>
        </div>
      </section>

      <section className="InnnerDashboardTable">
        <div className="container">
          <Table
            columns={columns}
            dataSource={getEmployee}
            loading={loading}
            pagination={{
              pageSize: Number(pageSize),
              current: Number(currentPage),
              showSizeChanger: false,
              onChange: (page, pageSize) => {
                setCurrentPage(page);
                setpageSize(pageSize);
                navigate(`?page=${page}&limit=${pageSize}`);
              },
              total: totalData,
            }}
          />
        </div>
      </section>
      <Modal
        title="Feedback"
        visible={feedbackModal}
        width={400}
        onCancel={() => {
          setFeedbackModal(false);
        }}
        footer={
          <>
            <button
              className="btn btn-primary"
              style={{
                marginRight: "10px",
              }}
              onClick={() => {
                setFeedbackModal(false);
              }}
              disabled={false}
            >
              {t("ok")}
            </button>
          </>
        }
      >
        <div
          style={{
            fontSize: "16px",
          }}
        >
          <b>Refund Amount: </b>
          {getUserCurrencyByCountry(
            feedbackModal?.mainItem?.restaurantDetails?.countryName
          )}{" "}
          {feedbackModal?.refundAmount}
          <br />
          <b>Feedback:</b> {feedbackModal?.feedback}
        </div>
      </Modal>
    </div>
  );
}

export default InvoiceList;

import jwt_decode from "jwt-decode";
import { token } from "./authentication";

// user token decode using jwt-decode
export const jwtTokenDecode = () => {
  return jwt_decode(token());
};

// export const jwtTokenDecode = () => {
//   try {
//     const tokenData = token(); // Or wherever your token is stored
//     if (!tokenData) {
//       throw new Error("Token not found");
//     }
//     return jwt_decode(tokenData);
//   } catch (error) {
//     console.error("Error decoding token:", error);
//     return null; // Return null if there's an error
//   }
// };

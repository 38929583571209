import { Tooltip } from "antd";
import { FcDownload } from "react-icons/fc";
const dynamicInvoiceLinkGenerator = (country, id) => {
  if (!id) {
    return;
  }
  // console.log(country);
  if (country === "United Kingdom") {
    return `payment-invoice-details/${id}`;
  } else if (country === "United Arab Emirates") {
    return `payment-invoice-details2/${id}`;
  } else {
    return `payment-invoice-details3/${id}`;
  }
};

export const InvoiceDownloadButton = ({ country, id }) => {
  const handleDownload = () => {
    const invoiceLink = dynamicInvoiceLinkGenerator(country, id);
    window.open(invoiceLink, "_blank"); // Open the link in a new tab for download
  };

  return (
    <Tooltip title="Download the invoice">
      <span
        style={{ color: "green", cursor: "pointer" }}
        onClick={handleDownload}
      >
        <FcDownload />
      </span>
    </Tooltip>
  );
};

import axiosInstance from "../utils/API";

export const fetchJobDetails = async (id) => {
  const response = await axiosInstance.get(`/job/${id}`);
  return response.data?.details || {};
};
export const jobUpdate = async (data) => {
  const response = await axiosInstance.put(`/job/update`, data);
  return response || {};
};
export const jobCreate = async (data) => {
  const response = await axiosInstance.post(`/job/create`, data);
  return response || {};
};
export const jobDelete = async (id) => {
  const response = await axiosInstance.delete(`/job/${id}`);
  return response || {};
};
export const fetchMyJobs = async (payload) => {
  const queryString = new URLSearchParams(payload).toString();
  const response = await axiosInstance.get(`/job?${queryString}`);
  return response.data || {};
};

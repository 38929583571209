import React from "react";

const MessageCard = ({ message }) => {
  return (
    <>
      {message?.myMessage ? (
        <div className="message-box my-message">
          <p>
            {message?.message}
            <br />
            <span>{message?.time}</span>
          </p>
        </div>
      ) : (
        <div className="message-box friend-message">
          <p>
            {message.message}
            <br />
            <span>{message?.time}</span>
          </p>
        </div>
      )}
    </>
  );
};

export default MessageCard;

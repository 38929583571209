import { Button, Drawer, notification } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { token } from "../../../utils/authentication";
import { getUserId } from "../../../utils/commonFunctions";
import { io } from "socket.io-client";
import Loader from "../../../components/loadar/Loader";
import { useSocket } from "../../../hooks/useSocket";

const GetMsgType = ({ message, myRole }) => {
  if (!message.myMessage) {
    return (
      <li>
        <div className="message-data">
          <span className="message-data-time">{message.time}</span>
        </div>
        <div className="message my-message">{message.message}</div>
      </li>
    );
  } else {
    return (
      <li className="clearfix">
        <div className="message-data align-right">
          <span className="message-data-time">{message.time}</span>{" "}
        </div>
        <div className="message other-message float-right">
          {message.message}
        </div>
      </li>
    );
  }
};

const ChatWithAdmin = ({ setOpen, open, sender, receiver, isAdmin }) => {
  const userId = getUserId();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [previousMessages, setPreviousMessages] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [conversationId, setConversationId] = useState("");
  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [socketData, setSocketData] = useState({});
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getMessages, sendMessage } = useSocket();

  const onClose = () => {
    setOpen(false);
    setMessages([]);
    setNewMessage("");
    setPage(1);
    setLimit(10);
    setPreviousMessages(false);
    setScrollToBottom(false);
    setScrollToTop(false);
  };

  // get conversation id
  useEffect(() => {
    if (open) {
      const payload = {
        senderId: sender, // here sender is the user id
        isAdmin: isAdmin, // admin created the conversation
      };
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setConversationId(res.data.details._id); // set conversation id
        });
    }
  }, [sender, receiver, isAdmin, open]);

  // get messages by conversation id
  useEffect(() => {
    if (conversationId && open) {
      setLoading(true);
      setPreviousMessages(false);
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/messages?conversationId=${conversationId}&limit=${limit}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          const descendingMessages = res.data.messages.reverse();
          const modifiedMessages = descendingMessages.map((message) => {
            return {
              id: message._id,
              myMessage:
                message.senderDetails?.role === "ADMIN" ||
                message.senderDetails?.role === "SUPER_ADMIN"
                  ? true
                  : false,
              senderName: message.senderDetails?.name,
              message: message.text,
              time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
            };
          });
          setPreviousMessages(res.data.next);
          setMessages([...modifiedMessages, ...messages]);
          if (page > 1) {
            setScrollToTop(true);
            setScrollToBottom(false);
          } else {
            setScrollToBottom(true);
            setScrollToTop(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, limit, page, open]);

  const sendMessageHandler = () => {
    if (!newMessage) {
      notification.error({
        message: "Message cannot be empty",
      });
      return;
    }
    setSendDisabled(true);
    const payload = {
      senderId: userId,
      conversationId: conversationId,
      dateTime: new Date(),
      text: newMessage,
    };
    // const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    //   withCredentials: false,
    //   transports: ["websocket", "polling", "flashsocket"],
    // });
    // socket.emit("message", payload);
    sendMessage(payload);
    setSendDisabled(false);
    setNewMessage("");
    setScrollToBottom(true);
    setScrollToTop(false);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/messages/create`, payload, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        setSendDisabled(false);
      });
  };

  useEffect(() => {
    // const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    //   withCredentials: false,
    //   transports: ["websocket", "polling", "flashsocket"],
    // });
    // socket.on("connect", () => {
    //   console.log("Socket connected");
    // });
    // socket.on("message", (data) => {
    //   setSocketData(data);
    // });
    // socket.on("error", (error) => {
    //   console.error("Socket error:", error);
    // });
    // socket.on("disconnect", () => {
    //   console.log("Socket disconnected");
    // });
    // return () => {
    //   console.log("Socket disconnecting");
    //   socket.disconnect();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (getMessages) {
      setSocketData(getMessages);
    }
  }, [getMessages]);

  useEffect(() => {
    console.log("Socket data", socketData);
    if (socketData && socketData?.conversationId === conversationId && open) {
      setMessages([
        ...messages,
        {
          id: socketData._id,
          myMessage: socketData.senderId === getUserId(),
          senderName: socketData.senderDetails?.name,
          message: socketData.text,
          time: moment(socketData.dateTime).format("DD MMM YY @ h:mm A"),
        },
      ]);
      setScrollToBottom(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  useLayoutEffect(() => {
    if (scrollToBottom) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, scrollToBottom]);

  useLayoutEffect(() => {
    if (scrollToTop) {
      chatContainerRef.current.scrollTop = 0;
    }
  }, [messages, scrollToTop]);

  return (
    <Drawer
      title="Chat"
      placement={"right"}
      width={500}
      onClose={onClose}
      open={open}
      className="chatDrawerRight"
    >
      <div className="chat">
        {previousMessages && (
          <div
            style={{
              textAlign: "center",
              color: "blue",
              cursor: previousMessages ? "pointer" : "not-allowed",
              marginBottom: "10px",
              backgroundColor: "lightgray",
            }}
            onClick={() => {
              if (previousMessages) {
                setLimit(limit);
                setPage(page + 1);
              }
            }}
          >
            Load More
          </div>
        )}

        <ul className="chat-history" ref={chatContainerRef}>
          {loading && messages?.length === 0 ? (
            <Loader />
          ) : (
            messages.map((message, index) => {
              return (
                <GetMsgType
                  key={message?.id}
                  message={message}
                  myRole={"ADMIN"}
                />
              );
            })
          )}
        </ul>
        <div className="chat-message clearfix">
          <textarea
            name="message-to-send"
            id="message-to-send"
            placeholder="Type your message"
            rows="3"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          ></textarea>
          <Button
            loading={sendDisabled}
            onClick={sendMessageHandler}
            style={{
              color: "#6DACE4",
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default ChatWithAdmin;

import moment from "moment";
import { useState, useEffect } from "react";
import userImage from "./images/user.jpg";
import { IoMdSend } from "react-icons/io";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import { MdCancel } from "react-icons/md";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import { jwtTokenDecode } from "../../../../utils/jwtDecode";
import axios from "axios";
import { token } from "../../../../utils/authentication";
import { useUser } from "../../../../contexts/user-context";
import { Colors } from "../../../../constants/Colors";
import { useQuery } from "react-query";
import { fetchSingleEmployeeData } from "../../../../services/userServices";
import SmallLoading from "../../../Shared/SmallLoading";
import { Popconfirm } from "antd";
import { getFormateName } from "../../../../utils/commonFunctions";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

function Comment({
  comment,
  depth = 0,
  onReply,
  jwtDecode,
  submitting,
  isReply,
  post,
  setEdit,
  edit,
  setId,
  handleDelete,
  setShowReplyBox,
  showReplyBox,
  id,
}) {
  const [replyValue, setReplyValue] = useState("");
  // const [showReplyBox, setShowReplyBox] = useState(false);
  const { currentUser, setCurrentUser } = useUser();

  const { data: getSingleEmployeeDetails = {} } = useQuery(
    ["singleEmployee", jwtDecode?._id],
    () => fetchSingleEmployeeData(jwtDecode?._id), // Fetching function
    {
      enabled: !!jwtDecode?._id,
    }
  );

  useEffect(() => {
    if (
      getSingleEmployeeDetails &&
      Object.keys(getSingleEmployeeDetails).length > 0
    ) {
      setCurrentUser(getSingleEmployeeDetails);
    }
  }, [getSingleEmployeeDetails, setCurrentUser]);

  // const nestedComments = (comment.children || [])
  //   ?.sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt))
  //   .map((comment) => {
  //     return (
  //       <Comment
  //         key={comment._id}
  //         comment={comment}
  //         depth={depth + 1}
  //         onReply={onReply}
  //         jwtDecode={jwtDecode}
  //       />
  //     );
  //   });

  // fixed 0 read only issue
  const nestedComments = [...(comment.children || [])] // Create a shallow copy
    ?.sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt))
    .map((comment) => {
      return (
        <Comment
          key={comment._id}
          comment={comment}
          depth={depth + 1}
          onReply={onReply}
          jwtDecode={jwtDecode}
          isReply={true}
          setEdit={setEdit}
          edit={edit}
          setId={setId}
          handleDelete={handleDelete}
          showReplyBox={showReplyBox}
          setShowReplyBox={setShowReplyBox}
          id={id}
        />
      );
    });

  const handleReply = (e) => {
    e.preventDefault();
    if (!replyValue) return;

    onReply(comment._id, replyValue, setReplyValue);
    setReplyValue("");
    setShowReplyBox(false);
  };

  const profilePicture =
    currentUser?.profilePicture && currentUser.profilePicture != "undefined"
      ? currentUser?.profilePicture?.includes("lh3.googleusercontent.com")
        ? currentUser?.profilePicture
        : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${currentUser?.profilePicture}`
      : jwtDecode?.profilePicture
      ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`
      : currentUser?.role === "CLIENT"
      ? "/assets/Client.jpg"
      : "/assets/Employee.jpg";

  return (
    <div style={{ marginLeft: `${depth * 20}px`, marginTop: "10px" }}>
      <li className="list-group">
        <div className="d-flex align-items-start">
          <div>
            <img
              src={
                comment?.user?.profilePicture &&
                comment?.user?.profilePicture != "undefined"
                  ? comment?.user?.profilePicture?.includes(
                      "lh3.googleusercontent.com"
                    )
                    ? comment?.user?.profilePicture
                    : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${comment?.user?.profilePicture}`
                  : comment?.user?.role == "CLIENT"
                  ? "/assets/Client.jpg"
                  : "/assets/Employee.jpg"
              }
              alt="Avatar"
              className="rounded-circle me-3"
              width="40"
              height="40"
            />
          </div>
          <div>
            <div className="d-flex gap-3">
              <h6>
                {comment.user?.role == "CLIENT"
                  ? comment.user?.restaurantName
                  : comment.user?.name}
              </h6>
              {" • "}
              <small className="text-muted">
                <ReactTimeAgo date={comment?.createdAt} locale="en-US" />
              </small>
            </div>
            <p className="mb-1">{comment.text}</p>
            {depth == 1 && (
              <div className="d-flex gap-3 text-black  fw-semibold">
                {jwtDecode?._id == comment?.user?._id && (
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    className=""
                    onClick={() => {
                      setShowReplyBox(true);
                      setReplyValue(comment?.text);
                      setEdit(!edit);
                      setId(comment?._id);
                    }}
                  >
                    Edit
                  </span>
                )}

                {(jwtDecode?._id == comment?.user?._id ||
                  post?.user?._id == jwtDecode?._id) && (
                  <Popconfirm
                    title="Are you sure to delete this comment?"
                    onConfirm={() => handleDelete(comment?._id)}
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                    okButtonProps={{
                      style: {
                        backgroundColor: "#ff4d4f",
                        borderColor: "#ff4d4f",
                      },
                    }}
                    cancelButtonProps={{
                      style: {
                        backgroundColor: Colors.primary,
                        borderColor: Colors.primary,
                        color: "white",
                      },
                    }}
                  >
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      className="text-black  fw-semibold"
                    >
                      Delete
                    </span>
                  </Popconfirm>
                )}
              </div>
            )}
            {depth < 1 && (
              <>
                <p className="d-flex gap-3 text-black  fw-semibold">
                  {jwtDecode?._id == comment?.user?._id && (
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowReplyBox(true);
                        setReplyValue(comment?.text);
                        setEdit(!edit);
                        setId(comment?._id);
                      }}
                    >
                      Edit
                    </span>
                  )}
                  {showReplyBox ? null : (
                    <span
                      style={{
                        color:
                          comment?.children?.length > 0
                            ? Colors.primary
                            : "inherit",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowReplyBox(!showReplyBox);
                        setId(comment?._id);
                      }}
                      className="reply_button text-black  fw-semibold"
                    >
                      Reply
                    </span>
                  )}

                  {(jwtDecode?._id == comment?.user?._id ||
                    post?.user?._id == jwtDecode?._id) && (
                    <Popconfirm
                      title="Are you sure to delete this comment?"
                      onConfirm={() => handleDelete(comment?._id)}
                      okText="Yes"
                      cancelText="No"
                      placement="topRight"
                      okButtonProps={{
                        style: {
                          backgroundColor: "#ff4d4f",
                          borderColor: "#ff4d4f",
                        },
                      }}
                      cancelButtonProps={{
                        style: {
                          backgroundColor: Colors.primary,
                          borderColor: Colors.primary,
                          color: "white",
                        },
                      }}
                    >
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        className="text-black  fw-semibold"
                      >
                        Delete
                      </span>
                    </Popconfirm>
                  )}
                </p>
              </>
            )}

            {showReplyBox && comment?._id == id && (
              <div class="d-flex align-items-center gap-2 flex-row add-comment-section mt-4 mb-4">
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setShowReplyBox(!showReplyBox);
                    setEdit(false);
                    setReplyValue("");
                    setId();
                  }}
                >
                  <MdCancel />
                </button>
                <div>
                  <img
                    class="rounded-circle mr-2"
                    src={profilePicture}
                    // src={
                    //   jwtDecode?.profilePicture?.includes(
                    //     "lh3.googleusercontent.com"
                    //   )
                    //     ? jwtDecode?.profilePicture
                    //     : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`
                    // }
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "100%",
                    }}
                  />
                </div>
                <input
                  type="text"
                  class="form-control mr-3"
                  placeholder="Write your comment here..."
                  value={replyValue}
                  onChange={(e) => setReplyValue(e.target.value)}
                />
                <button
                  className={`${
                    !replyValue
                      ? "submit_comment_disabled"
                      : "submit_comment_btn"
                  }`}
                  type="button"
                  onClick={handleReply}
                  disabled={submitting || !replyValue}
                >
                  {submitting ? <SmallLoading /> : <IoMdSend />}
                </button>
              </div>
            )}
          </div>
        </div>
      </li>
      {nestedComments}
    </div>
  );
}

export default function CommentComponents({
  comment,
  postId,
  handleUpdatePost,
  post,
}) {
  const jwtDecode = jwtTokenDecode();
  const { currentUser } = useUser();
  const [comments, setComments] = useState(comment ?? []);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [replyTo, setReplyTo] = useState(null); // To keep track of which comment we're replying to
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState();
  const [showReplyBox, setShowReplyBox] = useState(false);

  // console.log({ jwtDecode });
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = async () => {
    // if (!value) return;
    setSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/social-feed/create-comment`,
        {
          text: value,
          postId,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`, // Ensure token is properly imported or available
          },
        }
      );

      const newComment = {
        ...response.data.comment,
        user: {
          _id: jwtDecode?._id,
          name: currentUser?.name || jwtDecode?.name,
          restaurantName:
            currentUser?.restaurantName || jwtDecode?.restaurantName,
          positionId: "63ea4ee911aef3765b25190d",
          positionName: "Manager",
          email: "demoemployee@gmail.com",
          role: currentUser?.role || jwtDecode?.role,
          profilePicture:
            currentUser?.profilePicture || jwtDecode?.profilePicture,
        },
      };
      setComments((prevComments) => {
        const updatedComments = replyTo
          ? prevComments.map((comment) =>
              comment._id === replyTo
                ? {
                    ...comment,
                    children: [...(comment.children || []), newComment],
                  }
                : comment
            )
          : [...prevComments, newComment];

        // Update the post with the new comments data
        handleUpdatePost({ ...post, comments: updatedComments });

        return updatedComments;
      });
      setValue("");
      setReplyTo(null); // Reset replyTo after submission
    } catch (error) {
      console.error("Error creating comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/social-feed/delete-comment`,
        {
          postId,
          id: commentId,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`, // Ensure token is properly imported or available
          },
        }
      );

      const newComment = {
        ...response.data.socialFeed,
        user: {
          _id: jwtDecode?._id,
          name: currentUser?.name || jwtDecode?.name,
          restaurantName:
            currentUser?.restaurantName || jwtDecode?.restaurantName,
          positionId: "63ea4ee911aef3765b25190d",
          positionName: "Manager",
          email: "demoemployee@gmail.com",
          role: currentUser?.role || jwtDecode?.role,
          profilePicture:
            currentUser?.profilePicture || jwtDecode?.profilePicture,
        },
      };

      setComments((prevComments) => {
        const deleteCommentById = (comments, id) => {
          return comments
            .map((comment) => {
              if (comment._id === id) {
                // Skip this comment, effectively removing it
                return null;
              } else if (comment.children) {
                // Recursively check children for nested comments
                return {
                  ...comment,
                  children: deleteCommentById(comment.children, id),
                };
              }
              return comment;
            })
            .filter((comment) => comment !== null); // Remove any null comments (deleted comments)
        };

        const updatedComments = deleteCommentById(prevComments, commentId);

        // Update the post with the new comments data
        handleUpdatePost({ ...post, comments: updatedComments });

        return updatedComments;
      });
    } catch (error) {
      console.error("Error creating comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleReply = async (replyTo, value, setReplyValue) => {
    // if (!value) return;

    setSubmitting(true);
    try {
      if (edit) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/social-feed/update-comment`,
          {
            text: value,
            postId,
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token()}`, // Ensure token is properly imported or available
            },
          }
        );

        const newComment = {
          ...response.data.socialFeed,
          user: {
            _id: jwtDecode?._id,
            name: currentUser?.name,
            restaurantName: currentUser?.restaurantName,
            positionId: "63ea4ee911aef3765b25190d",
            positionName: "Manager",
            email: currentUser?.email,
            role: currentUser?.role,
            profilePicture: currentUser?.profilePicture,
          },
        };

        console.log({ newComment });
        setComments((prevComments) => {
          const updateCommentById = (comments, id, updatedComment) => {
            return comments.map((comment) => {
              if (comment._id === id) {
                // Update the specific comment
                return { ...comment, text: updatedComment.text };
              } else if (comment.children) {
                // Recursively search in children for nested comments
                return {
                  ...comment,
                  children: updateCommentById(
                    comment.children,
                    id,
                    updatedComment
                  ),
                };
              }
              return comment;
            });
          };

          const updatedComments = updateCommentById(
            prevComments,
            id,
            newComment
          );

          // Update the post with the new comments data
          handleUpdatePost({ ...post, comments: updatedComments });

          return updatedComments;
        });

        setReplyValue("");
        setReplyTo(null); // Reset replyTo after submission
        setEdit(false);
        setShowReplyBox(false);
        setId();
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/social-feed/create-comment`,
          {
            text: value,
            postId,
            parentId: replyTo ?? undefined, // Include parentId if replying to a comment
          },
          {
            headers: {
              Authorization: `Bearer ${token()}`, // Ensure token is properly imported or available
            },
          }
        );

        const newComment = {
          ...response.data.comment,
          user: {
            _id: jwtDecode?._id,
            name: currentUser?.name,
            restaurantName: currentUser?.restaurantName,
            positionId: "63ea4ee911aef3765b25190d",
            positionName: "Manager",
            email: "demoemployee@gmail.com",
            role: currentUser?.role,
            profilePicture: currentUser?.profilePicture,
          },
        };
        setComments((prevComments) => {
          const updatedComments = replyTo
            ? prevComments.map((comment) =>
                comment._id === replyTo
                  ? {
                      ...comment,
                      children: [...(comment.children || []), newComment],
                    }
                  : comment
              )
            : [...prevComments, newComment];

          // Update the post with the new comments data
          handleUpdatePost({ ...post, comments: updatedComments });

          return updatedComments;
        });
        setReplyValue("");
        setReplyTo(null); // Reset replyTo after submission
      }
      setEdit(false);
      setShowReplyBox(false);
      setId();
    } catch (error) {
      console.error("Error creating comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const profilePicture =
    currentUser?.profilePicture && currentUser.profilePicture != "undefined"
      ? currentUser?.profilePicture?.includes("lh3.googleusercontent.com")
        ? currentUser?.profilePicture
        : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${currentUser?.profilePicture}`
      : jwtDecode?.profilePicture
      ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`
      : currentUser?.role === "CLIENT"
      ? "/assets/Client.jpg"
      : "/assets/Employee.jpg";

  return (
    <>
      <div class="d-flex gap-2 flex-row add-comment-section mt-4 mb-4">
        <div>
          <img
            class="rounded-circle mr-2"
            src={profilePicture}
            // src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`}
            alt=""
            width="40"
            height="40"
          />
        </div>
        <input
          type="text"
          class="form-control mr-3"
          placeholder="Write your comment here..."
          onChange={handleChange}
          value={value}
        />
        <button
          className={`${
            !value ? "submit_comment_disabled" : "submit_comment_btn"
          }`}
          type="button"
          onClick={handleSubmit}
          disabled={submitting || !value}
        >
          {submitting ? <SmallLoading /> : <IoMdSend />}
        </button>
      </div>

      {/* Display comments */}

      {/* <div className="mt-4">
        {comments.length > 0 && (
          <ul className="list-group">
            {[...comments]
              ?.sort((a, b) => {
                const dateA = a?.createdAt || "";
                const dateB = b?.createdAt || "";
                return dateB.localeCompare(dateA);
              })
              ?.map((comment) => (
                <Comment
                  key={comment._id}
                  comment={comment}
                  onReply={handleReply}
                  jwtDecode={jwtDecode}
                  submitting={submitting}
                  post={post}
                  setEdit={setEdit}
                  edit={edit}
                  setId={setId}
                  handleDelete={handleDelete}
                  showReplyBox={showReplyBox}
                  setShowReplyBox={setShowReplyBox}
                  id={id}
                />
              ))}
          </ul>
        )}
      </div> */}
      <div className="mt-4">
        {comments.length > 0 && (
          <ul className="list-group">
            {comments
              .slice(0)
              ?.sort((a, b) => a.createdAt - b.createdAt)
              .map((comment) => (
                <Comment
                  key={comment._id}
                  {...{
                    comment,
                    onReply: handleReply,
                    jwtDecode,
                    submitting,
                    post,
                    setEdit,
                    edit,
                    setId,
                    handleDelete,
                    showReplyBox,
                    setShowReplyBox,
                    id,
                  }}
                />
              ))}
          </ul>
        )}
      </div>
    </>
  );
}

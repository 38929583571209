import { Skeleton } from "antd";
import { FaBriefcase, FaMapMarkerAlt, FaBuilding } from "react-icons/fa";
import { getFlagByCountry } from "../../../utils/commonFunctions";

const ProfileSkeleton = ({ loading, currentUser }) => {
  return (
    <div className="container profile_image">
      <div className="images">
        {loading ? (
          <Skeleton.Avatar active size={64} shape="circle" />
        ) : (
          <img
            src={
              currentUser?.profilePicture
                ? currentUser?.profilePicture.includes(
                    "lh3.googleusercontent.com"
                  )
                  ? currentUser?.profilePicture
                  : `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${currentUser?.profilePicture}`
                : "/assets/Employee.jpg"
            }
            alt={currentUser?.restaurantName}
          />
        )}
      </div>

      <div className="heading_info_box">
        {loading ? (
          <>
            <Skeleton.Input
              active
              style={{ width: 200, height: 24, marginBottom: 8 }}
            />
            <div className="mt-3 user-profile-position">
              <Skeleton.Input active style={{ width: 100, height: 20 }} />
            </div>
            <div className="h6 user-profile-countryname">
              <Skeleton.Input active style={{ width: 120, height: 20 }} />
            </div>
          </>
        ) : currentUser?.role === "EMPLOYEE" ? (
          <>
            <h2 className="h2">{currentUser?.name}</h2>
            <div className="mt-3 user-profile-position">
              <FaBriefcase style={{ color: "#58c8c8" }} />{" "}
              <p>{currentUser?.positionName}</p>
            </div>
            <div className="h6 user-profile-countryname">
              <FaMapMarkerAlt style={{ color: "#58c8c8" }} />{" "}
              <p>{currentUser?.countryName}</p>
            </div>
          </>
        ) : (
          <>
            <h2 className="mb-2">
              <FaBuilding /> {currentUser?.restaurantName}
            </h2>
            <div className="h6 pt-3 user-profile-countryname">
              <FaMapMarkerAlt style={{ color: "#58c8c8" }} />{" "}
              <p>{currentUser?.restaurantAddress}</p>
            </div>
            <div className="user-profile-countryname">
              <img
                src={getFlagByCountry(currentUser?.countryName)}
                alt="flag"
                style={{
                  width: "20px",
                  height: "15px",
                }}
              />
              <p> {currentUser?.countryName}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileSkeleton;

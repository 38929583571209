import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  user: {},
  accessToken: null,
  notifications: [],
  unreadCount: 0,
  loading: true,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const authSlice = createSlice({
  name: "Auth",
  initialState: initState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload?.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.loading = false;
      } else {
        state.actionsLoading = false;
        state.loading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.loading = true;
      } else {
        state.actionsLoading = true;
        state.loading = true;
      }
    },

    login: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;

      const { payload } = action;
      state.accessToken = payload;
    },

    setUser: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.user = action.payload;
      state.loading = false;
    },

    setLoading: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.loading = action.payload;
    },

    fetchNotificationUnreadCount: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.unreadCount = action.payload.unreadCount;
    },

    fetchNotificationList: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      let allNotification;
      if (action.payload.page == 1) {
        allNotification = action.payload.notifications;
      } else {
        allNotification = [
          ...state.notifications,
          ...action.payload.notifications,
        ];
      }
      const data = [
        ...new Map(allNotification.map((item) => [item["_id"], item])).values(),
      ];
      state.notifications = data;
    },

    logout: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.user = {};
      state.accessToken = undefined;
    },
  },
});

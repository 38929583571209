export const buildCheckInOutHistoryUrl = ({
  clientId,
  currentPage,
  pageSize,
  dateFilter,
  getName,
  status,
}) => {
  let url = `/check-in-check-out-histories?clientId=${clientId}&page=${currentPage}&limit=${pageSize}`;
  if (dateFilter) url += `&filterDate=${dateFilter}`;
  if (getName) url += `&employeeName=${getName}`;
  if (status) url += `&status=${status}`;
  return url;
};

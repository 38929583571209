/* eslint-disable jsx-a11y/anchor-is-valid */
import { Input, Select, Slider, Pagination } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { BsFillBookmarkFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";
import { MdFlag, MdWork } from "react-icons/md";
import { useQuery } from "react-query";
import { fetchPositionListForDropdownHandler } from "../../../api/position";
import { addShortHandler } from "../../../api/shortList";
import { ContextApi } from "../../../hooks/ContextApi";
import { token } from "../../../utils/authentication";
import {
  getUserId,
  getUserInfo,
  paramsToQueryString,
} from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { responseNotification } from "../../../utils/notifcation";
import italy from "./Italy.svg.png";
import arab_emirates from "./United_Arab_Emirates.svg.png";
import uk from "./United_Kinkdom.png";
import us from "./United_State.png";
import verifiedLogo from "./verified.png";
import { el } from "date-fns/locale";
import EmployeeSkeletonCard from "../../Cards/EmployeeCard/EmployeeSkeletonCard";
import { Colors } from "../../../constants/Colors";

const { Option } = Select;

export default function EmployeeSection() {
  const params = new URLSearchParams(window.location.search);
  const position = params.get("position");
  const { t } = useTranslation();
  const jwtDecode = jwtTokenDecode();
  const navigate = useNavigate();
  const [getEmployee, setEmployee] = useState([]);
  const { shouldRefetchShortList, setShouldRefetchShortList, currencyType } =
    useContext(ContextApi);

  const [addShortListData, setAddShortListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shortListLoading, setShortListLoading] = useState(false);
  const [getError, setError] = useState();
  const [positions, setPositions] = useState([]);
  const [isSubscriber, setIsSubscriber] = useState(false);

  //Set filter data
  const [getName, setName] = useState(undefined);
  const [getExperience, setExperience] = useState(undefined);
  const [getTotalMinHour, setTotalMinHour] = useState(undefined);
  const [getTotalMaxHour, setTotalMaxHour] = useState(undefined);
  const [rangeValue, setRangeValue] = useState([
    getTotalMinHour,
    getTotalMaxHour,
  ]);

  const [rangeUpdateValue, setRangeUpdateValue] = useState([]);

  const [getMinHeight, setMinHeight] = useState(0);
  const [getMaxHeight, setMaxHeight] = useState(300);
  const [heightRangeValue, setHeightRangeValue] = useState([
    getMinHeight,
    getMaxHeight,
  ]);

  const [heightUpdateRangeValue, setHeightUpdateRangeValue] = useState([]);

  // const railStyle = { backgroundColor: "#58c8c8" };
  const trackStyle = [{ backgroundColor: "#58c8c8" }];
  const handleStyle = {
    backgroundColor: "#58c8c8",
    borderColor: "#58c8c8",
  };

  const [getPosition, setPosition] = useState(undefined);

  //get filter data from on change
  const [getFilterPosition, setFilterPosition] = useState(undefined);
  const [getFilterExperience, setFilterExperience] = useState(undefined);
  const [getFilterNationality, setNationalityFilter] = useState(undefined);
  const [getDressSize, setDressSize] = useState(undefined);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [jobPostCount, setJobPostCount] = useState(0);
  const [todaysEmployeeCount, setTodaysEmployeeCount] = useState(0);
  const [positionfromApi, setPositionfromApi] = useState([]);
  const [positionfromCarousel, setPositionfromCarousel] = useState(undefined);
  // console.log(`positionfromCarousel`, positionfromCarousel);

  const [sortListIds, setSortListIds] = useState([]);

  // console.log(`getFilterRating`, getFilterRating);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [isPligitPlusModalOpen, setIsPligitPlusModalOpen] = useState(false);

  const showProfileModalOpen = () => {
    setProfileModalOpen(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //Fetch refer person list for dropdown
  const fetchSubscriptionData = useCallback(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/subscription/check-subscription`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (res.data?.status == "success") {
        setIsSubscriber(true);
        setIsPligitPlusModalOpen(false);
      } else {
        setIsSubscriber(false);
      }
    } catch (error) {
      setIsSubscriber(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  useEffect(() => {
    // Make an API call to get the minimum and maximum values
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/users/hourly-rate-info`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((response) => {
        // console.log(`response`, response);
        const { min, max } = response.data.result;
        setTotalMinHour(min);
        setTotalMaxHour(max);
        setRangeValue([min, max]);
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
      });
  }, []);

  useEffect(() => {
    if (position) {
      setFilterPosition(position);
    } else {
      setFilterPosition(undefined);
    }
  }, [position]);

  const handleRangeChange = (values) => {
    // console.log(`values`, values);
    setRangeValue(values);
    // You can perform filtering or other actions here based on the selected range values
  };

  const handleHeightRangeChange = (values) => {
    // console.log(`values`, values);
    console.log(`values`, values);
    setHeightRangeValue(values);
  };

  const handlePligitPlusCancel = () => {
    setIsPligitPlusModalOpen(false);
  };

  async function fetchEmployees2({
    getName,
    getFilterExperience,
    getFilterNationality,
    getFilterPosition,
    getDressSize,
    rangeUpdateValue,
    currentPage,
    heightUpdateRangeValue,
    positionfromCarousel,
    itemsPerPage = 12,
  }) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/users?active=YES&requestType=EMPLOYEE` +
        (getName ? `&searchKeyword=${getName}` : ``) +
        (getFilterPosition !== undefined && getFilterPosition !== "all"
          ? `&positionId=${getFilterPosition}`
          : ``) +
        (getFilterExperience
          ? `&employeeExperience=${getFilterExperience}`
          : ``) +
        (getFilterNationality ? `&nationality=${getFilterNationality}` : ``) +
        (getDressSize ? `&dressSize=${getDressSize}` : ``) +
        (rangeUpdateValue && rangeUpdateValue.length > 0
          ? `&minHourlyRate=${rangeUpdateValue[0]}&maxHourlyRate=${rangeUpdateValue[1]}`
          : ``) +
        (heightUpdateRangeValue && heightUpdateRangeValue.length > 0
          ? `&minHeight=${heightUpdateRangeValue[0]}&maxHeight=${heightUpdateRangeValue[1]}`
          : ``) +
        (positionfromCarousel && positionfromCarousel._id
          ? `&positionId=${positionfromCarousel._id}`
          : ``) +
        `&page=${currentPage}&limit=${itemsPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );

    return response.data; // Assuming the API response format includes the data we're interested in
  }

  const { data, error, isLoading } = useQuery(
    [
      "fetchEmployees",
      getName,
      getFilterExperience,
      getFilterNationality,
      getFilterPosition,
      getDressSize,
      rangeUpdateValue,
      currentPage,
      heightUpdateRangeValue,
      positionfromCarousel,
    ],
    () =>
      fetchEmployees2({
        getName,
        getFilterExperience,
        getFilterNationality,
        getFilterPosition,
        getDressSize,
        rangeUpdateValue,
        currentPage,
        heightUpdateRangeValue,
        positionfromCarousel,
      })
  );

  // console.log(data, error, isLoading);

  // get all nationality
  const [getNationality, setNationality] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/commons/nationality`)
      .then((res) => {
        // console.log(`res`, res.data.nationalities);
        setNationality(res.data.nationalities);
      });
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage <= Math.ceil(data?.total / 12)) {
      setCurrentPage(newPage);
    }
    // console.log(`newPage`, newPage);
  };

  const fetchPositionData = useCallback(async () => {
    await fetchPositionListForDropdownHandler().then((res) => {
      setPositions(res?.data?.positions);
    });
  }, []);

  const fetchShortListData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/short-list/list`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
    if (res?.status === 201) {
      const idArr = [];
      res.data?.shortList.forEach((emp) => {
        emp?.employeeDetails.forEach((singleEmp) => {
          idArr.push(singleEmp?.employeeDetails?.employeeId);
        });
      });
      setSortListIds(idArr);
    }
  };

  useEffect(() => {
    fetchShortListData();
    fetchPositionData();
  }, []);

  const info = getUserInfo();
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "https://embed.tawk.to/654d0edd958be55aeaae197c/1heqg44oc";
  //   script.charset = "UTF-8";
  //   script.setAttribute("crossorigin", "*");

  //   // Append the script to the document body
  //   if (info.role === "CLIENT") {
  //     document.body.appendChild(script);
  //   }
  //   // Cleanup function to remove the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [info]);
  const fetchInvoiceList = async () => {
    try {
      const responseData = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/invoices?clientId=${jwtDecode?._id}&skipLimit=YES`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (responseData && responseData?.data.statusCode === 200) {
        setInvoiceCount(
          responseData?.data?.invoices?.filter((item) => item?.status === "DUE")
            ?.length
        );
        setLoading(false);
      } else if (responseData && responseData?.data.statusCode === 400) {
        setError(responseData.errors);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(true);
      // console.log(error);
    }
  };
  useEffect(() => {
    setLoading(true);
    const params = {
      userType: "CLIENT",
      clientId: getUserId(),
    };
    const query = paramsToQueryString(params);
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/job?${query}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        })
        .then((res) => {
          setJobPostCount(res?.data?.total);
        });
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }, []);
  const fecthMyEmployee = async () => {
    const query = {
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      hiredBy: getUserInfo()?._id,
    };
    const queryToString = paramsToQueryString(query);
    const url = `${process.env.REACT_APP_API_BASE_URL}/book-history/client-employee?${queryToString}`;
    try {
      const responseData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (responseData && responseData?.data.statusCode === 200) {
        const data = responseData?.data?.details?.result[0]?.employee;
        setTodaysEmployeeCount(data?.length);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchInvoiceList();
    fecthMyEmployee();
  }, []);

  useEffect(() => {
    const fetchPositionData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/positions?skipLimit=YES`,
          {
            headers: {
              Authorization: `Bearer ${token()}`, // Assuming token() is a function to get the token
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          //  console.log(`data`, data.positions);
          setPositionfromApi(data.positions);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };

    fetchPositionData();
  }, []);
  const [message, setMessage] = useState("");
  useEffect(() => {
    const payload = {
      senderId: getUserId(), // sender id
      isAdmin: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        const conversationId = res.data.details._id;
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/messages/unread-msg?conversationId=${conversationId}`,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          )
          .then((res) => {
            setMessage(res.data.details.count);
          });
      });
  }, []);
  const addShortListOnclikHandler = (employeeId, uniform) => {
    console.log(`employeeId`, employeeId);
    console.log(`uniform`, uniform);
    const shortListReceivedField = { employeeId };
    if (shortListReceivedField) {
      setAddShortListData(shortListReceivedField);

      setShortListLoading(true);

      addShortHandler(shortListReceivedField)
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 201) {
            setError(undefined);
            setShortListLoading(false);
            setShouldRefetchShortList(!shouldRefetchShortList); // Toggle to trigger refetch

            responseNotification("Short list created successfully!", "success");
            console.log(`res`, res);
            // setLoadingShortList(true);
            // window.location.reload();
            navigate(`/short-list`);
          } else if (res?.statusCode === 400) {
            setError(res?.errors?.[0].msg);
            setShortListLoading(false);
          } else if (res?.statusCode === 500) {
            setError(res?.message);
            setShortListLoading(false);
          }
        });
    }
  };

  // Extracting country name from jwtDecode
  const countryName = jwtDecode?.countryName;

  // Function to get the flag image based on the country name
  const getFlagImage = (countryName) => {
    switch (countryName) {
      case "Italy":
        return italy;
      case "United States":
        return us;
      case "United Kingdom":
        return uk;
      case "United Arab Emirates":
        return arab_emirates;
      // Add more cases for other countries as needed
      default:
        return null; // Return a default flag or handle as per your requirement
    }
  };
  const flagImage = getFlagImage(countryName);

  // console.log("data~~", data);

  const goToEmployeeProfileHandler = (id) => {
    if (!id) {
      return;
    }
    window.open(`/profile/${id}`, "_blank");
  };

  console.log("data.users~~", data?.users);

  return (
    <>
      <section className="dashboard2 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 DashboardColXL9">
              {/* candidate count section */}
              <div className="container showEmployeerow">
                <div className="row ">
                  <div className="col-lg-6 col-md-6">
                    <div className="MHEmployeeImageandText">
                      <img
                        src="assets/frontend/images/Dashboardimages/dashboard2/search.png"
                        className="img-fluid"
                        alt="search"
                      />
                      <span>Plagit</span>
                      <span>{t("candidates")}</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {position ? (
                      <div className="MHEmployeeText text-end">
                        {!isLoading && (
                          <span>{data?.total > 0 ? data?.total : "No"} </span>
                        )}

                        {!isLoading && (
                          <span>
                            {
                              positions?.find((item) => item?._id === position)
                                ?.name
                            }{" "}
                            are showing
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="MHEmployeeText text-end">
                        <span>{data?.total} </span>
                        <span>{t("candidates_are_showing")}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="row">
                  <div className="col-md-4">
                    <EmployeeSkeletonCard />
                  </div>
                  <div className="col-md-4">
                    <EmployeeSkeletonCard />
                  </div>
                  <div className="col-md-4">
                    <EmployeeSkeletonCard />
                  </div>
                  <div className="col-md-4">
                    <EmployeeSkeletonCard />
                  </div>
                  <div className="col-md-4">
                    <EmployeeSkeletonCard />
                  </div>
                  <div className="col-md-4">
                    <EmployeeSkeletonCard />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card-group">
                      {data?.users.length === 0 ? (
                        <div
                          style={{
                            height: "200px",
                            marginTop: "100px",
                          }}
                          className=" w-100  row "
                        >
                          <p
                            className="text-center fw-500"
                            style={{
                              color: Colors.primary,
                            }}
                          >
                            No available candidate
                          </p>
                        </div>
                      ) : (
                        _.map(data?.users, (item, index) => (
                          <div
                            key={index}
                            className="col-lg-4 col-md-6 Dashboard2CardbottomMarginFixForSmallScreens mb-3"
                          >
                            {item ? (
                              <div className="card DashboardEmployeeCard">
                                <div className="imageContainer">
                                  <div
                                    onClick={() =>
                                      goToEmployeeProfileHandler(item?._id)
                                    }
                                    className="employee-profile-card-image"
                                  >
                                    {item?.profilePicture &&
                                    item?.profilePicture !== "undefined" ? (
                                      <img
                                        src={
                                          item?.profilePicture &&
                                          item?.profilePicture.includes(
                                            "lh3.googleusercontent.com"
                                          )
                                            ? item?.profilePicture
                                            : process.env
                                                .REACT_APP_ASSETs_BASE_URL +
                                              "/" +
                                              item?.profilePicture
                                        }
                                        className="Dashboard2-card-img-top"
                                        alt="profilePicture"
                                      />
                                    ) : (
                                      <div className="no_profile_img">
                                        <h2>
                                          {item?.name?.split(" ")[0][0]}
                                          {
                                            item?.name
                                              ?.split(" ")
                                              .slice(-1)[0][0]
                                          }
                                        </h2>
                                      </div>
                                    )}
                                  </div>

                                  <div className="hoverOverlay">
                                    <span className="viewMoreText">
                                      <Link
                                        className="text-decoration-none text-white"
                                        to={`/employee-view-details/${item?._id}`}
                                      >
                                        View Profile
                                      </Link>
                                    </span>
                                  </div>
                                </div>

                                <div className="card-body Dashboard2CardbodyPaddingFixfor768">
                                  <h5 className="card-title Dashboard2CardTItle">
                                    {item?.name}
                                    {item?.certified && (
                                      <img
                                        src={verifiedLogo}
                                        alt="verified"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      />
                                    )}
                                  </h5>
                                  <div className="row">
                                    <div className="col-lg-9 col-md-5">
                                      <div className="dashboard2chefwrapper">
                                        <img
                                          src="assets/frontend/images/Dashboardimages/dashboard2/chef.png"
                                          className="img-fluid"
                                          alt="chef"
                                        />
                                        <span> {item?.positionName}</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                      <div className="DashboardratingimgWraper">
                                        <img
                                          src="assets/frontend/images/Dashboardimages/dashboard2/Star 1.png"
                                          className="img-fluid"
                                          alt="Star"
                                        />
                                        <span className="Dashboard2Card_rating">
                                          &nbsp; {item?.rating}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="col-lg-12 col-md-8"
                                      style={
                                        {
                                          // display: "flex",
                                          // alignItems: "center",
                                        }
                                      }
                                    >
                                      <MdWork
                                        style={{
                                          fontSize: "20px",
                                          color: "#58c8c8",
                                          marginBottom: "5px",
                                          marginRight: "2px",
                                        }}
                                      />
                                      <span
                                        className="dashboard2totalhoursspent"
                                        style={{
                                          fontWeight: "semibold",
                                        }}
                                      >
                                        &nbsp;
                                        {item?.employeeExperience}{" "}
                                        {item?.employeeExperience > 1
                                          ? t("years")
                                          : t("year")}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="dashboard2TotalHourwrapper">
                                      <MdFlag
                                        style={{
                                          fontSize: "22px",
                                          color: "#58c8c8",
                                        }}
                                      />
                                      <span className="dashboard2totalhourspan">
                                        {t("nationality")}:
                                      </span>
                                      <span className="dashboard2totalhoursspent">
                                        {item?.nationality}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="dashboard2Rate">
                                      <img
                                        src="assets/frontend/images/Dashboardimages/dashboard2/rate.png"
                                        className="img-fluid"
                                        alt="rate"
                                      />
                                      <span className="Dashboard2Rate">
                                        {" "}
                                        {t("rate")}:{" "}
                                      </span>
                                      <span className="Dashboard2Perhour">
                                        {item?.hourlyRate}
                                        {currencyType}
                                        {t("per_hour")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="dashboard2Rate">
                                      <span className="Dashboard2Rate">
                                        <FcCalendar
                                          style={{
                                            fontSize: "20px",
                                            color: "#58c8c8",
                                          }}
                                        />{" "}
                                        {t("available")}:{" "}
                                      </span>
                                      <span className="Dashboard2Perhour">
                                        {item?.available}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                      <div className="Dashboard2BookNowButton">
                                        {sortListIds.includes(item?._id) ? (
                                          <BsFillBookmarkFill
                                            style={{
                                              fontSize: "22px",
                                              color: "#58c8c8",
                                              cursor: "not-allowed",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            onClick={() => {
                                              addShortListOnclikHandler(
                                                item?._id,
                                                item?.hasUniform
                                              );
                                            }}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            src="assets/frontend/images/Dashboardimages/dashboard2/bookmark.png"
                                            alt="bookmark"
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="Dashboard2BookNowButton">
                                        <Link
                                          to={`/booknow/${item?._id}?positionId=${item?.positionId}`}
                                          state={{
                                            positionId: item,
                                          }}
                                        >
                                          <button
                                            style={{
                                              background: "#58c8c8",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {t("book_now")}
                                          </button>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <h2>No Candidate Found</h2>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-xl-3 ">
              <div className="container Dashboard2FilterpartContainer">
                <div className="row firstFilterRow">
                  <div className="col-lg-5 ">
                    <div className="filtersTitle">
                      <p>{t("filters")}</p>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="resetData">
                      <button onClick={() => window.location.reload()}>
                        {t("reset_data")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h6 style={{ color: "#000000" }}>{t("position")}:</h6>
                </div>
                <div className="row">
                  <div>
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      allowClear={false}
                      optionFilterProp="children"
                      showSearch={true}
                      placeholder="Select Position"
                      onChange={(value) => {
                        setFilterPosition(value);
                      }}
                      defaultValue={"all"}
                    >
                      <Option value={"all"}>All Positions</Option>

                      {positions?.map((item, index) => (
                        <Option key={index} value={item?._id}>
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="row mt-3">
                  <h6 className="experienceH6">{t("experience")}:</h6>
                </div>
                <div>
                  <Input
                    placeholder={t("search_experience")}
                    onChange={(e) => {
                      setFilterExperience(e.target.value);
                    }}
                    className="ant-input ant-input-lg"
                  />
                </div>

                {/* <div className="row mt-3">
                  <h6 className="ratingH6">Rating:</h6>
                </div> */}
                {/* <div>
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select Rating"
                    onChange={(value) => {
                      setFilterRating(value);
                    }}
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div> */}

                <div className="row mt-3">
                  <h6 className="nationality">{t("nationality")}:</h6>
                </div>
                <div>
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    allowClear
                    optionFilterProp="children"
                    showSearch={true}
                    placeholder={t("select_nationality")}
                    onChange={(value) => {
                      setNationalityFilter(value);
                    }}
                  >
                    {getNationality?.map((item, index) => (
                      <Option key={index} value={item?.nationality}>
                        {item?.nationality}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="row mt-3">
                  <h6 className="dressSize">{t("dress_size")}:</h6>
                </div>
                <div>
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    allowClear
                    optionFilterProp="children"
                    showSearch={true}
                    placeholder={t("select_dress_size")}
                    onChange={(value) => {
                      setDressSize(value);
                    }}
                  >
                    <Option value="S">S</Option>
                    <Option value="M">M</Option>
                    <Option value="L">L</Option>
                    <Option value="XL">XL</Option>
                    <Option value="XXL">XXL</Option>
                  </Select>
                </div>

                <div>
                  <div className="row mt-3">
                    <h6 className="hourlyRate">{t("hourly_rate")}:</h6>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="minHourlyRate">
                          {t("min")}{" "}
                          <span className="minHourlyRateSpan">
                            {rangeValue[0]} {currencyType}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="minHourlyRate">
                          {t("max")}{" "}
                          <span className="minHourlyRateSpan">
                            {rangeValue[1]} {currencyType}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Slider
                    range
                    min={getTotalMinHour}
                    max={getTotalMaxHour}
                    onAfterChange={(val) => {
                      setRangeUpdateValue(val);
                    }}
                    value={rangeValue}
                    onChange={handleRangeChange}
                    trackStyle={trackStyle}
                    handleStyle={[handleStyle, handleStyle]}
                  />
                </div>
                <div>
                  <div className="row mt-3">
                    <h6 className="hourlyRate">{t("height")}:</h6>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="minHourlyRate">
                          {t("min")}{" "}
                          <span className="minHourlyRateSpan">
                            {heightRangeValue[0]} cm
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="minHourlyRate">
                          {t("max")}{" "}
                          <span className="minHourlyRateSpan">
                            {heightRangeValue[1]} cm
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Slider
                    range
                    min={getMinHeight}
                    max={getMaxHeight}
                    value={heightRangeValue}
                    onAfterChange={(val) => {
                      setHeightUpdateRangeValue(val);
                    }}
                    onChange={handleHeightRangeChange}
                    trackStyle={trackStyle}
                    handleStyle={[handleStyle, handleStyle]}
                  />
                </div>

                <div className="row mt-4">
                  {/*<button
                    type="button"
                    onClick={handleApplyOnclick}
                    className="filterApply"
                  >
                    Apply
                  </button>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-4 d-flex mx-auto justify-content-center">
        <Pagination
          current={currentPage}
          total={data?.total}
          pageSize={12}
          size="default"
          align="center"
          showSizeChanger={false}
          onChange={(page, limit) => {
            setCurrentPage(page);
          }}
        />
      </section>
      {/* <section className="pagination">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination paginationLiA">
                 
                  <li className="page-item">
                    <a
                      className={`page-link ${
                        currentPage === 1
                          ? "employee-disable-prev-button"
                          : "employee-prev-button"
                      }  `}
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                      style={
                        currentPage === 1
                          ? { backgroundColor: "transparentt" }
                          : {}
                      }
                    >
                      <span aria-hidden="true">«</span>
                    </a>
                  </li>
                  {Array.from(
                    { length: Math.ceil(data?.total / 12) },
                    (_, index) => (
                      <li
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                        key={index}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handlePageChange(index + 1)}
                          style={
                            currentPage === index + 1
                              ? { backgroundColor: "#58c8c8" }
                              : {}
                          }
                        >
                          {index + 1}
                        </a>
                      </li>
                    )
                  )}

                  <li className="page-item">
                    <button
                      className={`page-link ${
                        currentPage === Math.ceil(data?.total / 12)
                          ? "employee-disable-next-button"
                          : "employee-next-button"
                      }  `}
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">»</span>
                    </button>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>  */}
    </>
  );
}

import React, { useState } from "react";
import { Form, Input, Button, Upload, Row, Col, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import axiosInstance from "../../../utils/API";
import { Toast } from "../../../utils/toast";
import { Colors } from "../../../constants/Colors";
import { useTranslation } from "react-i18next";

const AddAndViewCertification = ({
  isEdit,
  getSingleEmployeeDetails,
  setIsEdit,
  fetchEmployeeDetails,
}) => {
  const { t } = useTranslation();

  const [certificationForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [editingCertificate, setEditingCertificate] = useState(null); // Tracks the certificate being edited

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleSubmit = async (values) => {
    if (fileList.length === 0 && !editingCertificate) {
      Toast.error("Please attach the certificate file!");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("id", getSingleEmployeeDetails?._id);
    formData.append("certificateName", values.certificateName);

    if (editingCertificate) {
      formData.append("certificateId", editingCertificate.certificateId);
    }

    if (fileList.length > 0) {
      formData.append("attachment", fileList[0]?.originFileObj);
    }

    const url = editingCertificate
      ? `/users/certificate/upload` // Update URL if editing
      : "/users/certificate/upload";

    const response = await axiosInstance.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response?.data?.status === "success") {
      await fetchEmployeeDetails();
      certificationForm.resetFields();
      setFileList([]);
      Toast.success(
        editingCertificate
          ? "Certificate updated successfully!"
          : "Certificate uploaded successfully!"
      );
    } else {
      Toast.error("Failed to upload/update the certificate.");
    }
    setLoading(false);
    setIsEdit(false);
    setEditingCertificate(null);
  };

  const handleDelete = async (id) => {
    setDeletingId(id);
    const response = await axiosInstance.put(`/users/certificate/remove`, {
      id: getSingleEmployeeDetails?._id,
      certificateId: id,
    });
    if (response?.data?.status === "success") {
      Toast.success("Certificate deleted successfully!");
      await fetchEmployeeDetails();
    } else {
      Toast.error("Failed to delete the certificate.");
    }
    setDeletingId(null);
  };

  const handleEdit = (certificate) => {
    setIsEdit(true);
    setEditingCertificate(certificate); // Set the certificate being edited

    // Populate form fields with existing values
    certificationForm.setFieldsValue({
      certificateName: certificate.certificateName,
    });

    // If the certificate has an existing file, we don't need to upload a new one
    setFileList([]);
  };

  return (
    <>
      {isEdit ? (
        <div>
          <Form
            form={certificationForm}
            layout="vertical"
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <Form.Item
              label="Document Name"
              name="certificateName"
              rules={[
                {
                  required: true,
                  message: "Please enter the document name!",
                },
              ]}
            >
              <Input placeholder="Enter document name" />
            </Form.Item>

            <Form.Item label="Attach Certificate" name="attachment">
              <Upload
                accept=".pdf,.jpg,.jpeg,.png"
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={() => false} // Prevents automatic upload
                listType="text"
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            {editingCertificate?.attachment && (
              <p className="lead">{editingCertificate.attachment}</p>
            )}

            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  float: "right",
                  marginTop: "1rem",
                  backgroundColor: Colors.primary,
                }}
                loading={loading}
              >
                {loading
                  ? "Uploading..."
                  : editingCertificate
                  ? "Update"
                  : "Upload"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div>
          {getSingleEmployeeDetails?.certificates?.map((item, index) => (
            <Row key={index} align="middle" className="mb-2">
              <Col flex="auto">
                <span className="d-flex align-items-center">
                  <img
                    src="/assets/frontend/images/selectedEmployee/certificateICon.png"
                    alt="Certificate Icon"
                    style={{
                      marginRight: "10px",
                      height: "25px",
                      width: "25px",
                    }}
                  />
                  <Link
                    to={`${process.env.REACT_APP_ASSETs_BASE_URL}/${item?.attachment}`}
                    className="text-decoration-none text-muted"
                    target="_blank"
                  >
                    <span>{item?.certificateName}</span>
                  </Link>
                </span>
              </Col>
              <Col>
                <Button
                  type="default"
                  size="small"
                  className="mx-1"
                  onClick={() => handleEdit(item)}
                >
                  {t("edit")}
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete this certificate?"
                  onConfirm={() => handleDelete(item.certificateId)}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{
                    style: {
                      backgroundColor: Colors.primary,
                      borderColor: Colors.primary,
                      color: "#fff",
                    },
                  }}
                >
                  <Button
                    type="danger"
                    size="small"
                    style={{ backgroundColor: Colors.primary, color: "#fff" }}
                  >
                    {t("delete")}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          ))}
          <div className="mt-3">
            <Button
              type="default"
              className="mx-1 "
              onClick={() => {
                setIsEdit(true);
                setEditingCertificate(null);
                certificationForm.setFieldsValue({
                  certificateName: "",
                });
              }}
              style={{
                backgroundColor: Colors.primary,
                color: "#fff",
                border: "0px",
              }}
            >
              {t("add_new_document")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAndViewCertification;

import axiosInstance from "../utils/API";

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .filter((key) => obj[key] != null)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
    .join("&");
};
export const fetchNationalities = async (id) => {
  const res = await axiosInstance.get("/commons/nationality");
  return res?.data?.nationalities;
};
export const fetchUserStatistics = async (query) => {
  const queryString = objectToQueryString(query);
  const res = await axiosInstance.get(`/users/user-statistics?${queryString}`);
  return res?.data?.details;
};

export const fetchSkills = async (id) => {
  const res = await axiosInstance.get("/skills/list-for-dropdown");
  return res?.data?.skills;
};

import React from "react";
import { Colors } from "../../constants/Colors";

const JobNotFoundCard = () => {
  return (
    <div className="container d-flex justify-content-center mt-5 ">
      <div className="card text-center pt-5 pb-5" style={{ width: "80%" }}>
        <div className="card-body">
          <h5 className="card-title">Job Not Found</h5>
          <p className="card-text">Unfortunately, we couldn't find any job</p>
          <button
            className="btn "
            style={{
              backgroundColor: Colors.primary,
              color: "#fff",
            }}
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobNotFoundCard;
